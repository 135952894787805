import { DocumentNode, useQuery, gql } from "@apollo/client";
import {
  selectProductYearMetadata,
  updateDeflatorsMetadata,
  selectCountryYearMetadata,
  updateCountryYearMetadata,
  selectGroupYearMetadata,
  updateGroupYearMetadata,
  resetMetaData,
} from "../store/sharedDataSlice";
import {
  updateCountriesMetadata,
  updateRegionsMetadata,
  updateSubregionsMetadata,
  updateProductsHs92Metadata,
  updateProductsHs12Metadata,
  updateProductsSitcMetadata,
  updateProductYearMetadata,
} from "../store/sharedDataSlice";
import getLocationsMetadata from "../graphql/queries/getLocationsMetadata";
import getCountriesMetadata from "../graphql/queries/getCountriesMetadata";
import getHs92ProductsMetadata from "../graphql/queries/getHs92ProductsMetadata";
import getHs12ProductsMetadata from "../graphql/queries/getHs12ProductsMetadata";
import getSitcProductsMetadata from "../graphql/queries/getSitcProductsMetadata";
import getProductYearMetadata from "../graphql/queries/getProductYearMetadata";
import getDeflatorsMetadata from "../graphql/queries/getDeflatorsMetadata";
import getCountryYearMetadata from "../graphql/queries/getCountryYearMetadata";
import getGroupYearMetadata from "../graphql/queries/getGroupYearMetadata";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { ProductClass, ProductLevel } from "../visualization/Utils";
import { groups, range } from "d3";
import { LocationLevel } from "../graphql/types";
import { getNumericIdFromStringLocationId } from "./Utils";
import { usePageQueryParams } from "../visualization/defaultSettings";
import { useEffect, useState } from "react";

export enum MetadataFetchType {
  Location = "location",
  Country = "country",
  ProductsHs92 = "productsHs92",
  ProductsHs12 = "productsHs12",
  ProductsSitc = "productsSitc",
  ProductYear = "productYear",
  ProductComplexity = "productComplexity",
  Deflators = "deflators",
  LocationYear = "locationYear",
}

export enum MetadataFetchStatus {
  Success = "success",
  Loading = "loading",
  Error = "error",
  Undefined = "undefined",
}

interface FetchProps {
  metadataFetchType: MetadataFetchType;
  yearRange?: { yearMin: number; yearMax: number } | undefined;
  productClass?: ProductClass | undefined;
  locationReferenceId?: string | undefined;
  locationReferenceLevel?: LocationLevel | undefined;
}

interface FetchResponse {
  metadataStatus: MetadataFetchStatus | undefined;
  error: any;
  metadata: any;
}

export const generateProductClassDigitLevelYearKey = ({
  productClass,
  productLevel,
  year,
}: any) => {
  return `${productClass}_${productLevel}digit_${year}`;
};

export const generateCountryKey = ({
  countryId,
}: {
  countryId: number | undefined;
}): string | undefined => {
  if (countryId) {
    return `location${countryId}`;
  } else {
    return undefined;
  }
};

export const generateGroupKey = ({
  groupId,
}: {
  groupId: number | undefined;
}): string | undefined => {
  if (groupId) {
    return `group${groupId}`;
  } else {
    return undefined;
  }
};

const useFetchMetadata = ({
  metadataFetchType,
  yearRange,
  productClass,
  locationReferenceId,
  locationReferenceLevel,
}: FetchProps) => {
  const sharedData = useAppSelector((state) => state.sharedData);
  const dispatch = useAppDispatch();
  const productYearMetadata = useAppSelector(selectProductYearMetadata);
  const countryYearMetadata = useAppSelector(selectCountryYearMetadata);
  const groupYearMetadata = useAppSelector(selectGroupYearMetadata);
  const [{ servicesClass }] = usePageQueryParams();
  const [yearMin, yearMax] = yearRange
    ? [yearRange.yearMin, yearRange.yearMax]
    : [];
  // useEffect(() => {
  //   dispatch(resetMetaData());
  // }, [servicesClass]);

  let productLevel: ProductLevel;
  if (metadataFetchType === MetadataFetchType.ProductComplexity) {
    productLevel = ProductLevel.Product4digit;
  } else {
    // The only other instance where we retrieve ProductYear data is for Market Share;
    // for that chart, we just need 1-digit data to compute market share from total exports and imports
    productLevel = ProductLevel.ProductSection;
  }

  let fetchedStatus: MetadataFetchStatus | undefined;
  let fetchedError: any;
  let fetchedMetadata: any;

  let queryToUse: DocumentNode;
  let metadataStatusSelector: string | undefined = undefined;
  let skipQuery = false;
  let queryVariables;

  if (metadataFetchType === MetadataFetchType.Country) {
    queryToUse = getCountriesMetadata;
    metadataStatusSelector = "countries";
  } else if (metadataFetchType === MetadataFetchType.Location) {
    queryToUse = getLocationsMetadata;
    metadataStatusSelector = "locationMetadataFetched";
  } else if (metadataFetchType === MetadataFetchType.ProductsHs92) {
    queryToUse = getHs92ProductsMetadata;
    metadataStatusSelector = "productsHs92";
    queryVariables = { servicesClass };
  } else if (metadataFetchType === MetadataFetchType.ProductsHs12) {
    queryToUse = getHs12ProductsMetadata;
    metadataStatusSelector = "productsHs12";
    queryVariables = { servicesClass };
  } else if (metadataFetchType === MetadataFetchType.ProductsSitc) {
    queryToUse = getSitcProductsMetadata;
    metadataStatusSelector = "productsSitc";
    queryVariables = { servicesClass };
  } else if (metadataFetchType === MetadataFetchType.ProductYear) {
    queryToUse = getProductYearMetadata;
    metadataStatusSelector = "productYear";
    queryVariables = { servicesClass };
  } else if (metadataFetchType === MetadataFetchType.ProductComplexity) {
    queryToUse = getProductYearMetadata;
    metadataStatusSelector = "productYear";
  } else if (metadataFetchType === MetadataFetchType.Deflators) {
    queryToUse = getDeflatorsMetadata;
    metadataStatusSelector = "deflators";
  } else if (metadataFetchType === MetadataFetchType.LocationYear) {
    if (locationReferenceLevel === LocationLevel.Country) {
      queryToUse = getCountryYearMetadata;
      metadataStatusSelector = "countryYear";
    } else if (locationReferenceLevel === LocationLevel.Group) {
      queryToUse = getGroupYearMetadata;
      metadataStatusSelector = "groupYear";
    } else {
      fetchedStatus = MetadataFetchStatus.Undefined;
      fetchedError = undefined;
      fetchedMetadata = undefined;
      queryToUse = gql``;
    }
  } else {
    fetchedStatus = MetadataFetchStatus.Undefined;
    fetchedError = undefined;
    fetchedMetadata = undefined;
    queryToUse = gql``;
  }

  const metadataStoreStatus = useAppSelector(
    (state) => {
      if (metadataStatusSelector) {
        if (metadataFetchType === MetadataFetchType.Location) {
          let countries = state.sharedData.countries;
          let subregions = state.sharedData.subregions;
          let regions = state.sharedData.regions;
          if (
            countries !== undefined &&
            subregions !== undefined &&
            regions !== undefined
          ) {
            return { countries, subregions, regions };
          } else {
            return undefined;
          }
        } else {
          return state.sharedData[metadataStatusSelector];
        }
      } else {
        return undefined;
      }
    },
    (oldValue: any, newValue: any) => {
      if (metadataFetchType === MetadataFetchType.Location) {
        return oldValue !== undefined && newValue !== undefined;
      } else {
        return oldValue === newValue;
      }
    },
  );

  if (
    metadataFetchType === MetadataFetchType.ProductYear ||
    metadataFetchType === MetadataFetchType.ProductComplexity
  ) {
    queryVariables = {
      yearMin,
      yearMax,
      productClass,
      productLevel,
      servicesClass,
    };

    if (yearMin && yearMax) {
      if (yearMin === yearMax) {
        let yearProductClassKey: string = generateProductClassDigitLevelYearKey(
          { productClass, productLevel, year: yearMin },
        );
        if (
          metadataStoreStatus !== undefined &&
          yearProductClassKey in productYearMetadata
        ) {
          fetchedStatus = MetadataFetchStatus.Success;
          fetchedError = undefined;
          fetchedMetadata = productYearMetadata[yearProductClassKey];
          skipQuery = true;
        }
      } else {
        // If a year range is specified for productYear, need to loop through
        // range of years and determine if all years are already stored in redux store;
        // if even a single year is missing, run full query
        if (metadataStoreStatus) {
          skipQuery = range(yearMin, yearMax + 1, 1).every((year: number) => {
            let yearProductClassKey: string =
              generateProductClassDigitLevelYearKey({
                productClass,
                productLevel,
                year,
              });
            return yearProductClassKey in productYearMetadata;
          });
          if (skipQuery) {
            fetchedStatus = MetadataFetchStatus.Success;
            fetchedError = undefined;
            fetchedMetadata = range(yearMin, yearMax + 1, 1).map(
              (year: number) => {
                let yearProductClassKey: string =
                  generateProductClassDigitLevelYearKey({
                    productClass,
                    productLevel,
                    year,
                  });
                return {
                  groupYear: year,
                  groupData: productYearMetadata[yearProductClassKey],
                };
              },
            );
          }
        }
      }
    }
  } else if (metadataFetchType === MetadataFetchType.LocationYear) {
    if (locationReferenceLevel === LocationLevel.Country) {
      let numericCountryId =
        getNumericIdFromStringLocationId(locationReferenceId);

      queryVariables = { countryId: numericCountryId };

      let countryKey: string | undefined = generateCountryKey({
        countryId: numericCountryId,
      });

      if (
        metadataStoreStatus !== undefined &&
        countryKey &&
        countryKey in countryYearMetadata
      ) {
        fetchedStatus = MetadataFetchStatus.Success;
        fetchedError = undefined;
        fetchedMetadata = countryYearMetadata[countryKey];
        skipQuery = true;
      }
    } else if (locationReferenceLevel === LocationLevel.Group) {
      let numericGroupId =
        getNumericIdFromStringLocationId(locationReferenceId);

      queryVariables = { groupId: numericGroupId };

      let groupKey: string | undefined = generateGroupKey({
        groupId: numericGroupId,
      });

      if (
        metadataStoreStatus !== undefined &&
        groupKey &&
        groupKey in groupYearMetadata
      ) {
        fetchedStatus = MetadataFetchStatus.Success;
        fetchedError = undefined;
        fetchedMetadata = groupYearMetadata[groupKey];
        skipQuery = true;
      }
    }
  } else {
    if (metadataStoreStatus !== undefined) {
      fetchedStatus = MetadataFetchStatus.Success;
      fetchedError = undefined;
      fetchedMetadata = metadataStoreStatus;

      skipQuery = true;
    }
  }

  const { loading, error, data } = useQuery(queryToUse, {
    variables: queryVariables,
    skip: skipQuery,
  });

  if (skipQuery === false) {
    // `dataToReturn` is the data to return in the desired data structure,
    // typically a destructured array
    let dataToReturn;

    if (loading) {
      fetchedStatus = MetadataFetchStatus.Loading;
      fetchedError = undefined;
      fetchedMetadata = undefined;
    } else if (error) {
      fetchedStatus = MetadataFetchStatus.Error;
      fetchedError = error;
      fetchedMetadata = undefined;
    } else if (data) {
      if (metadataFetchType === MetadataFetchType.Country) {
        const { countries } = data;
        dispatch(updateCountriesMetadata(countries));

        dataToReturn = countries;
      } else if (metadataFetchType === MetadataFetchType.Location) {
        let { countries, regions, subregions } = data;
        dispatch(updateCountriesMetadata(countries));
        dispatch(updateRegionsMetadata(regions));
        dispatch(updateSubregionsMetadata(subregions));

        dataToReturn = { countries, regions, subregions };
      } else if (metadataFetchType === MetadataFetchType.ProductsHs92) {
        dispatch(updateProductsHs92Metadata(data));

        dataToReturn = data;
      } else if (metadataFetchType === MetadataFetchType.ProductsHs12) {
        dispatch(updateProductsHs12Metadata(data));
        dataToReturn = data;
      } else if (metadataFetchType === MetadataFetchType.ProductsSitc) {
        dispatch(updateProductsSitcMetadata(data));

        dataToReturn = data;
      } else if (metadataFetchType === MetadataFetchType.Deflators) {
        let { deflators } = data;
        dispatch(updateDeflatorsMetadata(deflators));
        dataToReturn = deflators;
      } else if (metadataFetchType === MetadataFetchType.LocationYear) {
        if (locationReferenceLevel === LocationLevel.Country) {
          let { countryYear } = data;
          let numericCountryId =
            getNumericIdFromStringLocationId(locationReferenceId);
          let dispatchData = { countryId: numericCountryId, data: countryYear };
          dispatch(updateCountryYearMetadata(dispatchData));
          dataToReturn = countryYear;
        } else if (locationReferenceLevel === LocationLevel.Group) {
          let { groupYear } = data;
          let numericGroupId =
            getNumericIdFromStringLocationId(locationReferenceId);
          let dispatchData = { groupId: numericGroupId, data: groupYear };
          dispatch(updateGroupYearMetadata(dispatchData));
          dataToReturn = groupYear;
        }
      } else if (
        metadataFetchType === MetadataFetchType.ProductYear ||
        metadataFetchType === MetadataFetchType.ProductComplexity
      ) {
        if (yearMin && yearMax) {
          let { productYear } = data;

          if (yearMin == yearMax) {
            let dispatchData = {
              year: yearMin,
              productClass,
              productLevel,
              data: productYear,
              servicesClass,
            };

            dispatch(updateProductYearMetadata(dispatchData));

            dataToReturn = productYear;
          } else {
            const productYearGroupedByYear = groups(
              productYear,
              ({ year }: any) => year,
            ).map((groupedProductYear: any) => {
              let groupYear = groupedProductYear[0];
              let groupData = groupedProductYear[1];
              return { groupYear, groupData };
            });

            for (let year = yearMin; year <= yearMax; year++) {
              let matchingProductYearData = productYearGroupedByYear.find(
                (productYearGroup: any) => productYearGroup.groupYear == year,
              );
              if (matchingProductYearData) {
                const { groupData } = matchingProductYearData;
                let dispatchData = {
                  year,
                  productClass,
                  productLevel,
                  data: groupData,
                };
                dispatch(updateProductYearMetadata(dispatchData));
              }
            }

            dataToReturn = productYearGroupedByYear;
          }
        }
      }

      fetchedStatus = MetadataFetchStatus.Success;
      fetchedError = undefined;
      fetchedMetadata = dataToReturn;
    } else {
      fetchedStatus = MetadataFetchStatus.Undefined;
      fetchedError = undefined;
      fetchedMetadata = undefined;
    }
  }

  const fetchResponseObject: FetchResponse = {
    metadataStatus: fetchedStatus,
    error: fetchedError,
    metadata: fetchedMetadata,
  };

  return fetchResponseObject;
};

export default useFetchMetadata;
