import React, { useEffect } from "react";
import { ContentContainer } from "../about";
import { H1, Paragraph } from "../../components/styling/TextStyling";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { menuSecondaryColor } from "../../components/styling/cssVariables";

const Blockquote = styled.blockquote`
  margin: 1em 0;
  padding: 0.5em 10px;
  border-left: 6px solid ${menuSecondaryColor};
  font-style: italic;

  & a {
    color: #007bff;
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
`;
const Use = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ContentContainer>
      <H1>Use and Permissions</H1>
      <Paragraph>
        The Atlas is a free, public resource intended to advance the
        understanding of inclusive economic growth. We encourage collaboration
        on and sharing of Atlas concepts, visualizations and data. Previous
        projects inspired by The Atlas of Economic Complexity includes{" "}
        <Link to="https://globe.cid.harvard.edu">
          The Globe of Economic Complexity
        </Link>{" "}
        and{" "}
        <Link to="https://pathways.cid.harvard.edu">
          Pathways through the Product Space
        </Link>
        . When referring to concepts, visualizations and data from the Atlas,
        please cite the following reference(s):
      </Paragraph>
      <Blockquote>
        The Growth Lab at Harvard University. The Atlas of Economic Complexity.{" "}
        <a href="http://www.atlas.cid.harvard.edu">
          http://www.atlas.cid.harvard.edu
        </a>
        .
      </Blockquote>
      <Paragraph>
        When using data downloaded through{" "}
        <Link to="https://dataverse.harvard.edu/dataverse/atlas">
          The Atlas of Economic Complexity Dataverse
        </Link>
        , please use the citation materials as-generated for the specific
        dataset(s) you are using. For example:
      </Paragraph>
      <Blockquote>
        The Growth Lab at Harvard University. (2019). "Growth Projections and
        Complexity Rankings, V2" [Data set].{" "}
        <a href="https://doi.org/10.7910/dvn/xtaqmc">
          https://doi.org/10.7910/dvn/xtaqmc
        </a>
      </Blockquote>
      <Paragraph>
        When referring to the book,{" "}
        <Link to="https://growthlab.cid.harvard.edu/publications/atlas-economic-complexity-mapping-paths-prosperity-0">
          The Atlas of Economic Complexity
        </Link>
        , please cite the following reference:
      </Paragraph>
      <Blockquote>
        Hausmann, R., Hidalgo, C., Bustos, S., Coscia, M., Chung, S., Jimenez,
        J., Simoes, A., Yildirim, M. (2013). The Atlas of Economic Complexity.
        Cambridge, MA: MIT Press.
      </Blockquote>
    </ContentContainer>
  );
};

export default Use;
