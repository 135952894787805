import styled from "@emotion/styled";
import { UIView, VizType } from "../../visualization/Utils";
import { worldGroupDatum } from "../../graphql/queries/getLocationsMetadata";
import { allProductsDatum } from "../../graphql/queries/getProductsMetadata";
import { usePageQueryParams } from "../../visualization/defaultSettings";

interface Props {
  uiViewOption: UIView;
  selected: boolean;
  vizType: VizType | undefined;
}

const UIViewListItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 2px 4px;
  color: rgb(58, 65, 72);
  justify-content: space-around;
  flex-grow: 1;
  flex-basis: 0px;
  font-size: 0.75rem;
  height: 100%;
  box-sizing: border-box;
  border: none;
  background: none;

  &:hover {
    background-color: rgb(185, 191, 197);
    cursor: pointer;
  }

  &.selected {
    background-color: #334c60;
    color: #ffffff;
  }
`;

const DisabledUIViewListItem = styled(UIViewListItem)`
  && {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export default function UIViewSelectorButton({
  uiViewOption,
  selected,
  vizType,
}: Props) {
  const [{ yAxis, importer, exporter }, setQuery] = usePageQueryParams();

  let buttonLabel: string | undefined;
  let changeAction: any;
  let disabled: boolean = false;
  if (uiViewOption === UIView.Markets) {
    buttonLabel = "Locations";
    changeAction = () => {
      const regionSelectionOverrides = {} as any;
      if (exporter === worldGroupDatum.groupId) {
        regionSelectionOverrides.exporter = "";
      }

      setQuery({
        view: "markets",
        importer: worldGroupDatum.groupId,
        product: allProductsDatum.productId,
        //reset to default
        colorBy: undefined,
        //per capita not available for markets view
        yAxis: yAxis?.includes("Capita") ? undefined : yAxis,
        ...regionSelectionOverrides,
      });
    };
  } else if (uiViewOption === UIView.Products) {
    buttonLabel = "Products";
    const regionSelectionOverrides = {} as any;
    if (importer === "") {
      regionSelectionOverrides.importer = worldGroupDatum.groupId;
    }
    if (exporter === "") {
      regionSelectionOverrides.exporter = worldGroupDatum.groupId;
    }
    changeAction = () =>
      setQuery({
        view: "products",
        product: allProductsDatum.productId,
        ...regionSelectionOverrides,
      });
    if (vizType === VizType.Geo) {
      // If on Geomap, disable Product view selector because not relevant
      disabled = true;
    }
  }

  if (disabled) {
    return <DisabledUIViewListItem>{buttonLabel}</DisabledUIViewListItem>;
  } else {
    return (
      <UIViewListItem
        className={selected ? "selected" : ""}
        onClick={() => {
          changeAction();
        }}
      >
        {buttonLabel}
      </UIViewListItem>
    );
  }
}
