import React from "react";
import styled from "@emotion/styled";
import { failIfValidOrNonExhaustive } from "./Table";

export enum Fill {
  None,
  Half,
  Full,
  InvertedNone,
  InvertedFull,
}

const filledColor = "#808080";
const unfilledColor = "white";
const invertedFilledColor = "white";
const invertedunfilledColor = "rgba(0, 0, 0, 0)";

// The vertical/horizontal size of the already-rotated shape:
const diamondSize = 10; // in px

const Root = styled.div`
  width: ${diamondSize}px;
  height: ${diamondSize}px;
  border: 1px solid ${filledColor};
  transform: rotate(45deg);

  &:not(:first-child) {
    margin-left: 10px;
  }

  @media (max-width: 1250px) {
    width: 7px;
    height: 7px;

    &:not(:first-child) {
      margin-left: 7px;
    }
  }

  @media (max-width: 950px) {
    width: 5px;
    height: 5px;

    &:not(:first-child) {
      margin-left: 5px;
    }
  }
`;
interface Props {
  fill: Fill;
}
const Diamond = ({ fill }: Props) => {
  let style: React.CSSProperties;
  if (fill === Fill.None) {
    style = {};
  } else if (fill === Fill.Half) {
    style = {
      backgroundImage: `linear-gradient(to top right, ${filledColor}, ${filledColor} 50%, ${unfilledColor} 50.1%, ${unfilledColor})`,
    };
  } else if (fill === Fill.Full) {
    style = {
      backgroundColor: filledColor,
    };
  } else if (fill === Fill.InvertedNone) {
    style = {
      backgroundColor: invertedunfilledColor,
      borderColor: invertedFilledColor,
    };
  } else if (fill === Fill.InvertedFull) {
    style = {
      backgroundColor: invertedFilledColor,
      borderColor: invertedFilledColor,
    };
  } else {
    failIfValidOrNonExhaustive(fill, "Invalid fill " + fill);
    // These lines will never be executed:
    style = {};
  }

  return <Root style={style} />;
};

export default Diamond;
