import styled from "@emotion/styled";
import { usePageQueryParams } from "../../defaultSettings";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

const OptionButton = styled.button<any>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #d7dbdd;
  margin: 0px;
  padding: 8px;
  height: 40px;
  width: 140px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#334C60" : "white")};
  color: ${(props) => (props.active ? "white" : "#334C60")};
  transform-origin: left center;
  transform: rotate(270deg) translateX(-50px);
  white-space: nowrap;
  &:hover {
    background-color: #2d2f48;
    color: white;
  }
`;
const TopButton = styled(OptionButton)`
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-left: 0px;
  top: 80px;
`;

const BottomButton = styled(OptionButton)`
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right: 0px;
  top: 220px;
`;

export const YAxisOptions = () => {
  const [{ yAxis }, setQuery] = usePageQueryParams();
  return (
    <div
      style={{
        position: "relative",
        width: "40px",
        height: "300px",
        margin: "0 20px",
      }}
    >
      <TopButton
        onClick={() => setQuery({ yAxis: "cog" })}
        active={yAxis === "cog"}
      >
        Opportunity Gain
      </TopButton>
      <BottomButton
        onClick={() => setQuery({ yAxis: "pci" })}
        active={yAxis === "pci"}
      >
        Product Complexity
      </BottomButton>
    </div>
  );
};

const YAxisLable = styled.div`
  transform: rotate(270deg);
  white-space: nowrap;
  pointer-events: none;
  user-select: none;
  font-size: 16px;
  height: 100%;
  width: 20px;
  padding: 0px;
  display: flex;
  align-items: center;
`;

export const YAxisLabels = ({ topLabel, bottomLabel }) => (
  <div
    style={{
      paddingLeft: "4px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      position: "relative",
    }}
  >
    <YAxisLable
      style={{
        position: "absolute",
        bottom: "20%",
        transform: "rotate(270deg) translateX(-50%)",
      }}
    >
      {topLabel} <ArrowDropUp sx={{ transform: "rotate(90deg)" }} />
    </YAxisLable>
    <YAxisLable
      style={{
        position: "absolute",
        top: "30%",
        transform: "rotate(270deg) translateX(50%)",
      }}
    >
      <ArrowDropDown sx={{ transform: "rotate(90deg)" }} /> {bottomLabel}
    </YAxisLable>
  </div>
);

const XAxisLable = styled.div`
  pointer-events: none;
  user-select: none;
  font-size: 16px;
`;

export const XAxisLabels = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        width: 300,
      }}
    >
      <XAxisLable>◀ More Nearby</XAxisLable>
      <XAxisLable>Less Nearby ▶</XAxisLable>
    </div>
    <div style={{ fontWeight: "semiBold", fontSize: "22px", color: "#334C60" }}>
      Distance
    </div>
  </div>
);
