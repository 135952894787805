import React, { memo } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

// TO DO: Make this much simpler and use an image for the spinner
// Spinner taken from
// https://github.com/tobiasahlin/SpinKit/blob/fca79cdfea288767ac2cfbe0d654949ae77e6f84/examples/8-circle.html
const animation = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
  opacity: 0.8;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
`;

const Spinner = styled.div`
  width: var(--spinner-size);
  height: var(--spinner-size);
  position: relative;
`;

const Circle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &::before {
    content: "";
    display: block;
    margin: 0 auto;
    width: var(--circle-size);
    height: var(--circle-size);
    background-color: var(--circle-color);
    border-radius: 100%;
    animation: ${animation} 1.2s infinite ease-in-out both;
  }
`;
const Circle1 = Circle;

const Circle2 = styled(Circle)`
  transform: rotate(30deg);

  &::before {
    animation-delay: -1.1s;
  }
`;
const Circle3 = styled(Circle)`
  transform: rotate(60deg);

  &::before {
    animation-delay: -1s;
  }
`;
const Circle4 = styled(Circle)`
  transform: rotate(90deg);

  &::before {
    animation-delay: -0.9s;
  }
`;
const Circle5 = styled(Circle)`
  transform: rotate(120deg);

  &::before {
    animation-delay: -0.8s;
  }
`;
const Circle6 = styled(Circle)`
  transform: rotate(150deg);

  &::before {
    animation-delay: -0.7s;
  }
`;
const Circle7 = styled(Circle)`
  transform: rotate(180deg);

  &::before {
    animation-delay: -0.6s;
  }
`;
const Circle8 = styled(Circle)`
  transform: rotate(210deg);

  &::before {
    animation-delay: -0.5s;
  }
`;
const Circle9 = styled(Circle)`
  transform: rotate(240deg);

  &::before {
    animation-delay: -0.4s;
  }
`;
const Circle10 = styled(Circle)`
  transform: rotate(270deg);

  &::before {
    animation-delay: -0.3s;
  }
`;
const Circle11 = styled(Circle)`
  transform: rotate(300deg);

  &::before {
    animation-delay: -0.2s;
  }
`;
const Circle12 = styled(Circle)`
  transform: rotate(330deg);

  &::before {
    animation-delay: -0.1s;
  }
`;

interface IProps {
  spinnerSize?: string;
  backgroundColor?: string;
  circleColor?: string;
  circleSize?: string;
  zIndex?: number;
}

// Allow CSS custom properties
declare module "csstype" {
  interface Properties {
    "--spinner-size"?: string;
    "--background-color"?: string;
    "--circle-color"?: string;
    "--circle-size"?: string;
  }
}

export const graphLoadingElementId = "graph-loading-element";

export default memo((props: IProps) => {
  const { spinnerSize, backgroundColor, circleColor, circleSize, zIndex } =
    props;

  const finalSpinnerSize = spinnerSize === undefined ? "40px" : spinnerSize;
  const finalBackgroundColor =
    backgroundColor === undefined ? "#7f8c8d" : backgroundColor;
  const finalCircleColor = circleColor === undefined ? "white" : circleColor;
  const finalCircleSize = circleSize === undefined ? "15%" : circleSize;
  const style = {
    "--spinner-size": finalSpinnerSize,
    "--background-color": finalBackgroundColor,
    "--circle-color": finalCircleColor,
    "--circle-size": finalCircleSize,
    zIndex,
  };
  return (
    <Container
      id={graphLoadingElementId}
      style={style}
      onMouseMove={(e: any) => e.stopPropagation()}
      onClick={(e: any) => e.stopPropagation}
    >
      <Spinner>
        <Circle1 />
        <Circle2 />
        <Circle3 />
        <Circle4 />
        <Circle5 />
        <Circle6 />
        <Circle7 />
        <Circle8 />
        <Circle9 />
        <Circle10 />
        <Circle11 />
        <Circle12 />
      </Spinner>
    </Container>
  );
});
