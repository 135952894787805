import { useRef, useEffect, memo } from "react";
import { axisBottom, format, select } from "d3";
import { chartMargin, AxisGElement } from "./styles";

const XAxisYear = ({ scale, containerSize }: any) => {
  const axisRef = useRef<SVGGElement | null>(null);

  /* FORMATTING X-AXIS TICKS:

    The type of scale used to generate the x-axis for year in visualizations 
    is `scaleLinear`, so there are situations where the automatically computed 
    number of ticks for the scale produces tick labels that are decimal values 
    for years, e.g., 2014.5, 2015.5, etc. 

    The following block of code aims to avoid this situation. Specifically, we 
    define a threshold, `tickDifferenceLimit`, that controls the maximum difference 
    between the scale's minimum and maximum domain value under which we want to 
    explicitly define the number of ticks:
    
    - When the difference between the scale's minimum and maximum value is less than or 
        equal to `tickDifferenceLimit`, then we want to produce N number of ticks on 
        the axis equal to that computed difference between the scale's minimum and maximum 
        values -- i.e., we want the same number of ticks as there are values in the domain,
        yielding 1 (nicely rounded, non-decimal) tick per domain value

    - When the difference between the scale's minimum and maximum value is greater than 
        `tickDifferenceLimit`, then we want to set the controlled tick count to `undefined` --
        in other words, let the axis generator itself determine the best number of ticks 
        to produce for the given domain

    */
  const [scaleMin, scaleMax] = scale.domain();
  let tickCount;
  const tickDifferenceLimit = 16;
  if (scaleMax - scaleMin > tickDifferenceLimit) {
    tickCount = null;
  } else {
    tickCount = scaleMax - scaleMin;
  }
  const axisGenerator = axisBottom(scale)
    .ticks(tickCount)
    .tickFormat((value: any) => format(".0f")(value));

  useEffect(() => {
    if (axisRef && axisRef.current) {
      const gElement = select(axisRef.current);

      // Render the result of calling the axis generator to the <g> element that will hold the axis
      axisGenerator(gElement);
    }
  }, [axisRef, scale.domain()]);

  return (
    <AxisGElement
      ref={axisRef}
      transform={`translate(0, ${containerSize.height - chartMargin.bottom})`}
    ></AxisGElement>
  );
};

export default memo(XAxisYear);
