import { moneyFormatter } from "../Utils";
import { useTotalValue } from "../TotalValueContext";
import { Box, Paper, Tooltip } from "@mui/material";

const TotalValue = () => {
  const [totalValue] = useTotalValue();
  if (!totalValue) {
    return <div style={{ width: 100 }} />;
  }
  return (
    <Tooltip
      title={
        <Box>
          <Paper sx={{ p: 1 }}>
            Total Trade Value: Represents the sum of all trade data currently
            displayed. This value dynamically updates based on your selected
            adjustments in the visualization.
          </Paper>
        </Box>
      }
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "white",
            color: "black",
            "& .MuiTooltip-arrow": {
              color: "white",
            },
          },
        },
      }}
    >
      <div
        style={{
          fontSize: "clamp(14px, 1.5vw, 18px)",
          fontWeight: 600,
          borderBottom: "1px dashed",
          cursor: "help",
          whiteSpace: "nowrap",
        }}
      >
        Total Value: ${moneyFormatter.format(totalValue)}
      </div>
    </Tooltip>
  );
};

export default TotalValue;
