import { gql } from "@apollo/client";

const query = gql`
  query GetDeflators {
    deflators: year {
      year
      deflator
    }
  }
`;

export default query;
