import {
  memo,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Tooltip, {
  TooltipContent,
  TooltipLineMarker,
} from "../../components/Tooltip";
import {
  VisualizationContentContainer,
  VisualizationBottomRowContainer,
  VisualizationHandlesContainer,
} from "../../VizGrid";
import CategoryLabels from "../../components/CategoryLabels";
import {
  determineAPICallLocationOrder,
  getCategoriesForChartLegend,
  getTradeDirection,
} from "../../Utils";
import { TradeDirection } from "../../Utils";
import { mapQueryArgumentsToAPIEndpointInputs } from "../../../graphql/Utils";
import determineEndpointFacet from "../../../graphql/determineEndpointFacet";
import { useQuery } from "@apollo/client";
import GraphLoading from "../../components/GraphLoading";
import Chart from "./Chart";
import { ChartContainerSizeContext } from "../../components/GenericResizeContainer";
import { CategoryDatum } from "../../components/LegendLabel";
import { hs92OtherCategory } from "../../../sharedUtilities/Utils";
import { chartMargin } from "../../components/styles";
import { useOutletContext } from "react-router-dom";

import GraphNotice, { GraphNoticeType } from "../../components/GraphNotice";
import { usePageQueryParams } from "../../defaultSettings";
import { worldGroupDatum } from "../../../graphql/queries/getLocationsMetadata";
import { useTotalValue } from "../../TotalValueContext";

export const removeOtherProducts = (product: any) => {
  /* NOTE: For market share, we specifically filter out `Other` products from the visualization */
  let { id, productId } = product;
  if (id) {
    return product.id !== hs92OtherCategory;
  } else if (productId) {
    return product.productId !== hs92OtherCategory;
  }
};

const MarketShare = () => {
  const [
    {
      exporter,
      view,
      productClass,
      productLevel,
      startYear: yearMin,
      endYear: yearMax,
      servicesClass,
    },
  ] = usePageQueryParams();
  const importer = useMemo(() => worldGroupDatum.groupId, []);
  const { chartHeight } = useContext(ChartContainerSizeContext);
  const {
    visualizationElementRef: marketshareElement,
    setHighlightedItem,
  }: any = useOutletContext();

  const tooltipContentRef = useRef<HTMLDivElement | null>(null);
  const [chartContainerOffset, setChartContainerOffset] = useState<
    { left: number; top: number } | undefined
  >(undefined);

  const [tooltipContent, setTooltipContent] = useState<
    | {
        isFixed: boolean | undefined;
        isFixedCoords: [number, number] | undefined;
        contentData: any | undefined;
      }
    | undefined
  >(undefined);
  const [totalValue] = useTotalValue();

  const [hiddenCategories, setHiddenCategories] = useState<string[]>([]);

  const [xAxisTooltipValue, setXAxisTooltipValue] = useState<any>({
    year: undefined,
    x: undefined,
    y: undefined,
  });

  /* Note: Here, we are caching the onHover function with useCallback; this is to prevent unnecessary rerenders of <Chart>
    whenever `tooltipContent` state gets updated (and the whole <Treemap> component gets rerendered) */

  const onHover = useCallback(
    ({ data, setTooltipFixed }: any) => {
      if (data === undefined) {
        if (setTooltipFixed !== undefined) {
          setTooltipContent({
            isFixed: setTooltipFixed,
            contentData: undefined,
            isFixedCoords: undefined,
          });
        } else {
          setTooltipContent(undefined);
        }
      } else {
        let filteredData = data.filter(
          (d: any) => d.year === xAxisTooltipValue.year,
        );

        let mappedDatum = {
          year: xAxisTooltipValue.year,
          dataForYear: filteredData,
        };

        setTooltipContent({
          isFixed: setTooltipFixed,
          isFixedCoords: undefined,
          contentData: mappedDatum,
        });
      }
    },
    [xAxisTooltipValue],
  );

  const [snapTooltipToCoordinates, setSnapTooltipToCoordinates] = useState<
    [number, number] | undefined
  >(undefined);

  useEffect(() => {
    let snapCoordinates: [number, number] | undefined;

    if (xAxisTooltipValue && xAxisTooltipValue && chartContainerOffset) {
      let { x } = xAxisTooltipValue;
      let { left, top } = chartContainerOffset;
      snapCoordinates = [
        x + left,
        chartMargin.top +
          top +
          (chartHeight - chartMargin.bottom - chartMargin.top) / 2,
      ];
    } else {
      snapCoordinates = undefined;
    }

    setSnapTooltipToCoordinates(snapCoordinates);
  }, [chartContainerOffset, chartHeight, xAxisTooltipValue]);

  const clearTooltip = () => {
    setTooltipContent(undefined);
  };

  /* SIDE EFFECTS */
  // This useEffect computes the width and height of the chart container element,
  // to be used to render the chart contents later
  useEffect(() => {
    if (marketshareElement && marketshareElement.current) {
      const node = marketshareElement.current;
      const { left, top } = node.getBoundingClientRect();

      if (
        chartContainerOffset === undefined ||
        chartContainerOffset.left !== left ||
        chartContainerOffset.top !== top
      ) {
        setChartContainerOffset({ left, top });
      }
    }
  }, [chartContainerOffset, marketshareElement]);

  const narrowedMarketShareInputVariables = useMemo(
    () => mapQueryArgumentsToAPIEndpointInputs({ exporter, importer, view }),
    [exporter, importer, view],
  );

  const marketShareInputVariables = useMemo(
    () => ({
      productClass,
      yearMin,
      yearMax,
      productLevel,
      productId: undefined,
      servicesClass,
      ...narrowedMarketShareInputVariables,
    }),
    [
      productClass,
      yearMin,
      yearMax,
      productLevel,
      servicesClass,
      narrowedMarketShareInputVariables,
    ],
  );

  /* DETERMINE API CALL LOCATION ORDER OF VARIABLES, for use in correctly computing trade direction */
  const { locationForAPI, partnerForAPI } = useMemo(
    () =>
      determineAPICallLocationOrder({
        variables: marketShareInputVariables,
      }),
    [marketShareInputVariables],
  );

  const tradeDirection: TradeDirection | undefined = useMemo(
    () =>
      getTradeDirection({
        exporter,
        importer,
        locationForAPI,
        partnerForAPI,
      }),
    [exporter, importer, locationForAPI, partnerForAPI],
  );

  /* DETERMINE GRAPHQL ENDPOINT TO USE 
    
        The endpoint we want to use is `countryProductYear`, with the following inputs:

        {
            "productClass": "HS92",
            "yearMin": Int,
            "yearMax": Int,
            "productLevel": Int,
            "countryId": Int,
            "groupId": undefined,
            "partnerCountryId": undefined,
            "partnerGroupId": undefined,
            "productId": undefined,
        }

        Note that "productId" is intentionally undefined, although in the URL the value 
        of `product` is `all-products`. Using undefined is required for the requested GraphQL 
        query to return data for all products.
    
    */

  const { queryToUse, queryIsInvalid } = useMemo(
    () =>
      determineEndpointFacet({
        view,
        variables: marketShareInputVariables as any,
      }),
    [view, marketShareInputVariables],
  );

  /* RUN GRAPHQL QUERY */

  const { loading, error, data, previousData } = useQuery(queryToUse, {
    variables: marketShareInputVariables,
    skip: queryIsInvalid,
  });

  /* GENERATE CHART CONTENT */
  const content = useMemo(() => {
    const successResponse = data ? data : previousData;
    if (error) {
      console.log(error);
      return <GraphNotice graphNoticeType={GraphNoticeType.Error} />;
    } else if (successResponse) {
      return (
        <Chart
          inputData={successResponse}
          queryVariables={{ exporter, yearMin, yearMax, productClass }}
          hiddenCategories={hiddenCategories}
          tradeDirection={tradeDirection}
          setXAxisTooltipValue={setXAxisTooltipValue}
          handleTooltip={onHover}
        />
      );
    } else if (loading) {
      return <GraphLoading />;
    }
    return undefined;
  }, [
    data,
    previousData,
    error,
    loading,
    exporter,
    yearMin,
    yearMax,
    productClass,
    hiddenCategories,
    tradeDirection,
    setXAxisTooltipValue,
    onHover,
  ]);
  let [tooltipLineMarkerElement, setTooltipLineMarkerElement] =
    useState<ReactElement | null>(null);
  let [tooltipExplanationElement, setTooltipExplanationElement] =
    useState<ReactElement | null>(null);

  useEffect(() => {
    let tooltipContentElement;
    let lineMarkerElement;
    if (
      tooltipContent &&
      tooltipContent.contentData !== undefined &&
      loading !== true
    ) {
      tooltipContentElement = (
        <TooltipContent
          datum={tooltipContent && tooltipContent.contentData}
          view={view}
          totalValue={totalValue}
          tradeDirection={tradeDirection}
          productClass={productClass}
          ref={tooltipContentRef}
        />
      );

      if (xAxisTooltipValue && xAxisTooltipValue) {
        let { year, x } = xAxisTooltipValue;
        lineMarkerElement = (
          <TooltipLineMarker
            year={year}
            x={x}
            yAxisMarkerExtent={{
              min: chartHeight - chartMargin.bottom,
              max: chartMargin.top,
            }}
          />
        );
      } else {
        lineMarkerElement = null;
      }
    } else {
      lineMarkerElement = null;
      tooltipContentElement = null;
    }

    setTooltipExplanationElement(tooltipContentElement);
    setTooltipLineMarkerElement(lineMarkerElement);
  }, [
    tooltipContent,
    loading,
    view,
    totalValue,
    tradeDirection,
    productClass,
    chartHeight,
    xAxisTooltipValue,
  ]);

  const categoriesForLegend: CategoryDatum[] = useMemo(
    () =>
      getCategoriesForChartLegend({
        view,
        productClass,
      }),
    [view, productClass],
  );
  const resetText = useMemo(() => "Show All Sectors", []);

  const graphLoading = <GraphLoading />;
  const clearHighlightedItem = useCallback(() => {
    setHighlightedItem(undefined);
    setTooltipLineMarkerElement(undefined);
  }, [setHighlightedItem]);

  return (
    <>
      <Tooltip
        explanation={tooltipExplanationElement}
        cursor={"default"}
        overrideStyles={true}
        clearHighlightedItem={() => clearHighlightedItem}
        isFixed={tooltipContent && tooltipContent.isFixed}
        isFixedCoords={snapTooltipToCoordinates}
        snapToPosition={true}
      >
        <VisualizationContentContainer ref={marketshareElement}>
          {content}
          {!loading && tooltipLineMarkerElement}
          {loading && graphLoading}
        </VisualizationContentContainer>
      </Tooltip>
      <VisualizationBottomRowContainer>
        <VisualizationHandlesContainer>
          <CategoryLabels
            categories={categoriesForLegend}
            allowToggle={true}
            hiddenCategories={hiddenCategories}
            setHiddenCategories={setHiddenCategories}
            resetText={resetText}
            fullWidth={true}
          />
        </VisualizationHandlesContainer>
      </VisualizationBottomRowContainer>
    </>
  );
};

const MarketShareEntry = () => {
  return <MarketShare />;
};

export default memo(MarketShareEntry);
