import styled from "@emotion/styled";
import { BaseMenuItem } from "../../mainDropdownPrimitive/styles";

const FindInVizMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 2px;
  width: 400px;
  && * {
    text-transform: none; // To unset capitalization style of parent container
  }
`;

const LabelContainer = styled.div`
  flex-basis: 100%;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface FindInVizDropdownItemInput {
  item: any;
  hashFunction: any;
  getItemProps: any;
  mappedItemIndex: number;
  index: number;
  highlightedIndex: number;
  selectedItem: any;
}

const FindInVizDropdownItem = ({
  item,
  hashFunction,
  index,
  selectedItem,
  highlightedIndex,
  mappedItemIndex,
  getItemProps,
}: FindInVizDropdownItemInput) => {
  const { id, label } = item;

  return (
    <BaseMenuItem
      className={
        mappedItemIndex === highlightedIndex ||
        (selectedItem && item.id === selectedItem.id)
          ? "menu-item--highlighted"
          : ""
      }
      key={`item_${item.id}`}
      {...getItemProps({ item, index: mappedItemIndex })}
    >
      <FindInVizMenuItem>
        <LabelContainer>{label}</LabelContainer>
      </FindInVizMenuItem>
    </BaseMenuItem>
  );
};

export default FindInVizDropdownItem;
