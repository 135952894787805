import { groups } from "d3";
import { min, max, scaleLog } from "d3";
import {
  getOrdersOfMagnitudeDifference,
  spectrumStartPercent,
  spectrumEndPercent,
  colorRangeStart,
  colorRangeEnd,
} from "./Utils";
import {
  defaultMapFillColor,
  primaryCountryColor,
  defaultMapStrokeColor,
  primaryCountryStrokeColor,
} from "./Utils";
import { worldGroupDatum } from "../../../graphql/queries/getLocationsMetadata";
import { getLocationLevelFromStringLocationId } from "../../../sharedUtilities/Utils";
import { LocationLevel } from "../../../graphql/types";
import {
  TradeDirection,
  computeMonetaryValueByTradeFlow,
  computeTotalSumByTradeFlow,
} from "../../Utils";

export const groupAndSummarizeByLocationLevel = ({
  data,
  location,
  locationLevel,
  tradeFlow,
}: any) => {
  let groupedFilteredData;

  if (locationLevel === LocationLevel.Country) {
    groupedFilteredData = groups(data, (d: any) => d.partnerCountryId).map(
      (d: any) => {
        const partnerCountryId = d[0];
        const partnerLevel = d[1][0].partnerLevel;
        const sumExportValue = computeTotalSumByTradeFlow({
          data: d[1],
          tradeFlow,
          tradeDirection: TradeDirection.Exports,
        });
        const sumImportValue = computeTotalSumByTradeFlow({
          data: d[1],
          tradeFlow,
          tradeDirection: TradeDirection.Imports,
        });

        return {
          ...d[1][1],
          countryId: location,
          locationLevel: "country",
          partnerCountryId,
          partnerLevel,
          exportValue: sumExportValue,
          importValue: sumImportValue,
          productsData: d[1],
        };
      },
    );
  } else if (locationLevel === LocationLevel.Group) {
    groupedFilteredData = groups(data, (d: any) => d.partnerCountryId).map(
      (d: any) => {
        const partnerCountryId = d[0];
        const partnerLevel = d[1][0].partnerLevel;
        const sumExportValue = computeTotalSumByTradeFlow({
          data: d[1],
          tradeFlow,
          tradeDirection: TradeDirection.Exports,
        });
        const sumImportValue = computeTotalSumByTradeFlow({
          data: d[1],
          tradeFlow,
          tradeDirection: TradeDirection.Imports,
        });

        return {
          ...d[1][1],
          groupId: location,
          partnerCountryId,
          locationLevel: "country",
          partnerLevel,
          exportValue: sumExportValue,
          importValue: sumImportValue,
          productsData: d[1],
        };
      },
    );
  } else {
    groupedFilteredData = [];
  }

  return groupedFilteredData;
};

const transformLocations = ({
  geojson,
  tradeFlow,
  data,
  location,
  regions,
  subregions,
  countries,
}: any) => {
  const locationLevel = getLocationLevelFromStringLocationId(location);

  let selectedLocationIso3CodeSet = new Set();

  if (locationLevel === LocationLevel.Country) {
    let matchedIso3Code = countries.find(
      ({ countryId }: any) => countryId === location,
    )["iso3Code"];
    if (matchedIso3Code) selectedLocationIso3CodeSet.add(matchedIso3Code);
  } else if (locationLevel === LocationLevel.Group) {
    if (location !== worldGroupDatum.groupId) {
      let groupMembers = [...regions, ...subregions].find(
        ({ groupId }: any) => groupId === location,
      )["members"];
      if (groupMembers) {
        groupMembers.forEach((memberCountryId: string) => {
          let matchedIso3Code = countries.find(
            ({ countryId }: any) => countryId === memberCountryId,
          )["iso3Code"];
          if (matchedIso3Code) selectedLocationIso3CodeSet.add(matchedIso3Code);
        });
      }
    }
  }

  const groupedFilteredData = groupAndSummarizeByLocationLevel({
    data,
    location,
    locationLevel,
    tradeFlow,
  });

  const dataWithParents = groupedFilteredData.map((d: any) => {
    const partnerCountryId = d.partnerCountryId;
    let findMatchingMetadata = regions.find((region: any) =>
      region.members.includes(partnerCountryId),
    );
    let topLevelParent = undefined;
    let nameEn = undefined;
    let iso = undefined;
    let hybrid_level_1;
    let hybrid_level_2;
    if (findMatchingMetadata) {
      topLevelParent = findMatchingMetadata.groupId;
      hybrid_level_1 = {
        id: findMatchingMetadata.groupId,
        nameEn: findMatchingMetadata.groupName,
      };
    } else {
      topLevelParent = "undisclosed";
      hybrid_level_1 = { id: "undisclosed", nameEn: "undisclosed" };
    }

    let findMatchingSubregion = subregions.find((subregion: any) =>
      subregion.members.includes(partnerCountryId),
    );
    if (findMatchingSubregion) {
      hybrid_level_2 = {
        id: findMatchingSubregion.groupId,
        nameEn: findMatchingSubregion.groupName,
      };
    } else {
      hybrid_level_2 = { id: "undisclosed", nameEn: "undisclosed" };
    }

    let findMatchingCountryMetadata = countries.find(
      (country: any) => country.countryId === partnerCountryId,
    );
    if (findMatchingCountryMetadata) {
      nameEn = findMatchingCountryMetadata.nameShortEn;
      iso = findMatchingCountryMetadata.iso3Code;
    } else {
      nameEn = "undisclosed";
    }

    return {
      ...d,
      id: d.partnerCountryId,
      topLevelParent,
      nameEn,
      hybrid_level_1,
      hybrid_level_2,
      iso3Code: iso,
    };
  });

  /* For exports */
  const dataWithoutPctExports = dataWithParents.filter((country: any) => {
    let monetaryValue = computeMonetaryValueByTradeFlow({
      datum: country,
      tradeFlow,
      tradeDirection: TradeDirection.Exports,
    });
    return monetaryValue && monetaryValue > 0;
  });
  const allValuesExports: (number | undefined)[] = dataWithoutPctExports.map(
    (country: any) =>
      computeMonetaryValueByTradeFlow({
        datum: country,
        tradeFlow,
        tradeDirection: TradeDirection.Exports,
      }),
  );

  let minValueExports = min(allValuesExports, (value) => value) as number;
  let maxValueExports = max(allValuesExports, (value) => value) as number;

  const [domainStartExports, domainEndExports] = scaleLog()
    .domain([minValueExports, maxValueExports])
    .nice()
    .domain();
  const ordersOfMagnitudeDifferenceExports = getOrdersOfMagnitudeDifference(
    domainStartExports,
    domainEndExports,
  );
  const spectrumStartValueExports =
    10 **
    (Math.log10(domainStartExports) +
      ordersOfMagnitudeDifferenceExports * spectrumStartPercent);
  const spectrumEndValueExports =
    10 **
    (Math.log10(domainStartExports) +
      ordersOfMagnitudeDifferenceExports * spectrumEndPercent);

  const spectrumScaleExports = scaleLog<string, string>()
    .domain([spectrumStartValueExports, spectrumEndValueExports])
    .range([colorRangeStart, colorRangeEnd]);

  /* For imports */
  const dataWithoutPctImports = dataWithParents.filter((country: any) => {
    let monetaryValue = computeMonetaryValueByTradeFlow({
      datum: country,
      tradeFlow,
      tradeDirection: TradeDirection.Imports,
    });
    return monetaryValue && monetaryValue > 0;
  });
  const allValuesImports: (number | undefined)[] = dataWithoutPctImports.map(
    (country: any) =>
      computeMonetaryValueByTradeFlow({
        datum: country,
        tradeFlow,
        tradeDirection: TradeDirection.Imports,
      }),
  );

  let minValueImports = min(allValuesImports, (value) => value) as number;
  let maxValueImports = max(allValuesImports, (value) => value) as number;

  const [domainStartImports, domainEndImports] = scaleLog()
    .domain([minValueImports, maxValueImports])
    .nice()
    .domain();
  const ordersOfMagnitudeDifferenceImports = getOrdersOfMagnitudeDifference(
    domainStartImports,
    domainEndImports,
  );
  const spectrumStartValueImports =
    10 **
    (Math.log10(domainStartImports) +
      ordersOfMagnitudeDifferenceImports * spectrumStartPercent);
  const spectrumEndValueImports =
    10 **
    (Math.log10(domainStartImports) +
      ordersOfMagnitudeDifferenceImports * spectrumEndPercent);

  const spectrumScaleImports = scaleLog<string, string>()
    .domain([spectrumStartValueImports, spectrumEndValueImports])
    .range([colorRangeStart, colorRangeEnd]);

  const transformedFeatures = geojson.features.map((feature: any) => {
    const featureIso3Code = feature.properties.iso_alpha3;
    const matchingDatumWithParent = dataWithParents.find(
      (country: any) => country.iso3Code === featureIso3Code,
    );
    let importValue: number | undefined;
    let exportValue: number | undefined;
    let exportsColor: string | undefined;
    let importsColor: string | undefined;
    let strokeColor: string | undefined;
    let countryId: string | undefined;
    let nameEn: string | undefined = matchingDatumWithParent
      ? matchingDatumWithParent.nameEn
      : undefined;
    if (selectedLocationIso3CodeSet.has(featureIso3Code)) {
      importValue = undefined;
      exportValue = undefined;
      exportsColor = primaryCountryColor;
      importsColor = primaryCountryColor;
      strokeColor = primaryCountryStrokeColor;
      countryId = undefined;
    } else if (matchingDatumWithParent) {
      importValue = computeMonetaryValueByTradeFlow({
        datum: matchingDatumWithParent,
        tradeFlow,
        tradeDirection: TradeDirection.Imports,
      });
      exportValue = computeMonetaryValueByTradeFlow({
        datum: matchingDatumWithParent,
        tradeFlow,
        tradeDirection: TradeDirection.Exports,
      });
      if (exportValue! == 0) {
        exportsColor = defaultMapFillColor;
      } else if (exportValue! < 0) {
        exportsColor = defaultMapFillColor;
      } else if (exportValue! > spectrumEndValueExports) {
        exportsColor = colorRangeEnd;
      } else if (exportValue! < spectrumStartValueExports) {
        exportsColor = colorRangeStart;
      } else {
        exportsColor = spectrumScaleExports(exportValue!);
      }

      if (importValue! == 0) {
        importsColor = defaultMapFillColor;
      } else if (importValue! < 0) {
        importsColor = defaultMapFillColor;
      } else if (importValue! > spectrumEndValueImports) {
        importsColor = colorRangeEnd;
      } else if (importValue! < spectrumStartValueImports) {
        importsColor = colorRangeStart;
      } else {
        importsColor = spectrumScaleImports(importValue!);
      }

      countryId = matchingDatumWithParent.partnerCountryId;
      strokeColor = defaultMapStrokeColor;
    } else {
      importValue = undefined;
      exportValue = undefined;
      exportsColor = defaultMapFillColor;
      importsColor = defaultMapFillColor;
      strokeColor = defaultMapStrokeColor;
      countryId = undefined;
    }

    const updatedFeature = Object.assign({}, feature);

    updatedFeature.properties = {
      ...feature.properties,
      countryId,
      importValue,
      exportValue,
      exportsColor,
      importsColor,
      strokeColor,
      nameEn,
    };

    return updatedFeature;
  });

  return { type: "FeatureCollection", features: transformedFeatures };
};

export default transformLocations;
