import { useSelect } from "downshift";
import { useState } from "react";
import styled from "@emotion/styled";
import {
  SelectBoxContainer,
  DropdownContainer,
  ToggleButton,
  ArrowUp,
  ArrowDown,
  MainMenu,
} from "./styles";

interface SelectBoxInput {
  items: any[];
  hashFunction: any;
  placeholder: string;
  ItemRenderComponent: any;
  onChangeEvent: any;
  selectedValueFromStore: any | undefined;
}

const SelectedItemLabel = styled.span`
  width: 100%;
  padding: 1px 2px 1px 6px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const SelectBox = ({
  items,
  hashFunction,
  ItemRenderComponent,
  onChangeEvent,
  selectedValueFromStore,
}: SelectBoxInput) => {
  function ComboBox() {
    const [displayItems, setDisplayItems] = useState(items);

    const {
      // Prop getters:
      getToggleButtonProps, // Attributes to be applied to a menu toggle button <button>
      getLabelProps, // Attributes to be applied to <label> element, e.g., aria-label
      getMenuProps, // Attributes to be applied to <ul> or root of menu to be rendered
      getItemProps, // Attributes to be applied to menu items, e.g., <li> elements

      // State values:
      selectedItem, // Current selected item
      highlightedIndex,
      isOpen, // Is the dropdown open?
      inputValue, // the value in the input field

      // Actions:
      closeMenu, // closes the menu
      openMenu, // opens the menu
      selectItem, // selects a given item
      setHighlightedIndex, // sets a new highlighted item index
      toggleMenu, // toggles menu open state
    } = useSelect({
      // This handles the autocomplete behavior, i.e., as the user types, filtered list of items displayed
      onSelectedItemChange({ selectedItem }) {
        if (selectedItem) {
          if (!selectedValueFromStore) {
            onChangeEvent({ value: selectedItem.value });
          } else if (
            selectedValueFromStore &&
            selectedValueFromStore.value !== selectedItem.value
          ) {
            onChangeEvent({ value: selectedItem.value });
          }
        } else if (!selectedItem) {
          onChangeEvent({ value: undefined });
        }
      },
      // The actual items to be displayed in the dropdown at time of render
      items: displayItems,
      // Accessor function to get label for item in dropdown
      itemToString(item) {
        return item ? hashFunction(item) : "";
      },
    });

    if (selectedValueFromStore && !selectedItem) {
      selectItem(selectedValueFromStore);
    }

    return (
      <SelectBoxContainer>
        {/* Note: We are applying the Downshift-generated toggle button props 
                to the `DropdownContainer` here so that the whole dropdown behaves like a button,
                instead of just the arrow button itself */}
        <DropdownContainer
          aria-label="toggle menu"
          type="button"
          {...getToggleButtonProps()}
          className={isOpen ? "dropdown-open" : ""}
        >
          <SelectedItemLabel>
            {selectedItem ? selectedItem.label : ""}
          </SelectedItemLabel>
          <ToggleButton>{isOpen ? <ArrowUp /> : <ArrowDown />}</ToggleButton>
        </DropdownContainer>
        {isOpen && (
          <MainMenu {...getMenuProps()}>
            {
              // Mapping `items` to <li> elements, if menu is open
              displayItems.map((item: any, index: number) => {
                // getItemProps is called here for each item in the <ul> --
                // passing `item` and `index` helps Downshift keep track of which item
                // gets selected or highlighted from user interact
                return (
                  <ItemRenderComponent
                    item={item}
                    index={index}
                    highlightedIndex={highlightedIndex}
                    selectedItem={selectedItem}
                    getItemProps={getItemProps}
                    hashFunction={hashFunction}
                    displayItems={displayItems}
                    setDisplayItems={setDisplayItems}
                  />
                );
              })
            }
          </MainMenu>
        )}
      </SelectBoxContainer>
    );
  }
  return <ComboBox />;
};

export default SelectBox;
