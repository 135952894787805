import styled from "@emotion/styled";
import VizHubIcon from "../../assets/images/VizHubIcon.svg";
import GrowthLabLogo from "../../assets/images/GL_logo_black.png";
import { H3, H4 } from "../../components/styling/TextStyling";

const Root = styled.div`
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 2rem;
  padding-top: 2vh;
  min-height: 240px;
`;

const LocalH3 = styled(H3)`
  text-transform: uppercase;
  color: #777;
`;

const Ul = styled.ul`
  display: flex;
  line-height: 0.85;
  margin-top: 20px;
  padding: 0px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Li = styled.li`
  flex: 1;
  display: flex;
  align-items: center;

  & + li {
    margin-left: 30px;
  }

  @media (max-width: 700px) {
    & + li {
      margin-left: 0;
    }
  }
`;

const ResearchIcon = styled.div`
  flex: 2;
  margin-right: 6%;

  img {
    width: 100%;
  }
`;

const ResearchCitation = styled.a`
  flex: 5;
  display: block;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.4;
  color: #000000;

  &&:hover {
    text-decoration: none;
  }
`;

const ResearchFooter = () => {
  return (
    <Root>
      <LocalH3>Explore more Growth Lab work</LocalH3>
      <Ul>
        <Li>
          <ResearchIcon>
            <img src={VizHubIcon} />
          </ResearchIcon>
          <ResearchCitation
            href="https://growthlab.app/"
            target="_blank"
            rel="noopener"
          >
            <H4>
              Visit the Viz Hub, the Growth Lab’s portfolio of award-winning,
              interactive visualizations powered by our research and insights.
            </H4>
          </ResearchCitation>
        </Li>
        <Li>
          <ResearchIcon>
            <img src={GrowthLabLogo} />
          </ResearchIcon>
          <ResearchCitation
            href="https://growthlab.cid.harvard.edu/"
            target="_blank"
            rel="noopener"
          >
            <H4>
              Read the Growth Lab’s latest published research covering a range
              of academic papers, books and policy pieces.
            </H4>
          </ResearchCitation>
        </Li>
      </Ul>
    </Root>
  );
};

export default ResearchFooter;
