import styled from "@emotion/styled";
import { linkColor } from "../../components/styling/cssVariables";
import {
  h4Style,
  H1,
  H2,
  H3,
  Paragraph,
} from "../../components/styling/TextStyling";
import { AboutRouteID } from "../../routing/routes";
import {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import ResearchFooter from "../sharedComponents/ResearchFooter";
import Sidebar from "../sharedComponents/Sidebar";

export const ParentContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  width: 85%;
  padding-right: 10%;
  flex: 1 0 85%;
  box-sizing: border-box;
`;

const TeamMember = styled.div`
  margin: 2rem 0 0.5rem 3rem;
`;

const TeamMemberHeading = styled.a`
  ${h4Style}
  border-left: 4px solid ${linkColor};
  display: block;
  padding-left: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(81, 131, 193);
  margin-bottom: 0.5rem;

  &&:hover {
    text-decoration: none;
  }
`;

const TeamMemberName = styled.span`
  color: ${linkColor};
`;

const TeamMemberTitle = styled.span`
  color: rgb(102, 102, 102);

  &::before {
    display: inline;
    content: "-";
    margin: 0 0.5rem;
  }
`;

const getCurrentSection = (entries: IntersectionObserverEntry[]) => {
  const visibleSections = entries.filter((entry) => entry.isIntersecting);
  if (visibleSections.length > 0) {
    return visibleSections[0].target;
  }
  return undefined;
};

const About = () => {
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash;
  const activeHeadingId = hash.replace("#", "");

  const headingIdsWithRefs = new Map<
    AboutRouteID,
    MutableRefObject<HTMLElement | null>
  >([
    [AboutRouteID.WhatIsTheAtlas, useRef<HTMLElement | null>(null)],
    [AboutRouteID.OurTeam, useRef<HTMLElement | null>(null)],
    [AboutRouteID.MediaOutreach, useRef<HTMLElement | null>(null)],
    [AboutRouteID.GrowthLab, useRef<HTMLElement | null>(null)],
    [AboutRouteID.SupportMission, useRef<HTMLElement | null>(null)],
    [AboutRouteID.ContactUs, useRef<HTMLElement | null>(null)],
  ]);

  const orderedHeadingIdsWithRefs = [...headingIdsWithRefs.entries()].map(
    (h) => ({ id: h[0], ref: h[1] }),
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const currentSection = getCurrentSection(entries);
        if (currentSection) {
          const currentSectionId = orderedHeadingIdsWithRefs.find(
            (h) => h.ref.current === currentSection,
          )?.id;
          if (currentSectionId) {
            navigate(`#${currentSectionId}`, { replace: true });
          }
        }
      },
      { threshold: 0.01, rootMargin: "0px 0px -80% 0px" },
    );

    orderedHeadingIdsWithRefs.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      orderedHeadingIdsWithRefs.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  useLayoutEffect(() => {
    if (activeHeadingId && firstRender) {
      const nodeRef = headingIdsWithRefs.get(activeHeadingId as AboutRouteID);
      if (nodeRef && nodeRef.current) {
        const node = nodeRef.current;
        const headerOffset = 100;

        const y =
          node.getBoundingClientRect().top + window.scrollY - headerOffset;

        window.scrollTo({
          top: y,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setFirstRender(false);
    }
  }, [activeHeadingId, firstRender]);
  useEffect(() => {
    if (!activeHeadingId) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, activeHeadingId]);

  const content = (
    <ContentContainer>
      <H1>About</H1>
      <section ref={headingIdsWithRefs.get(AboutRouteID.WhatIsTheAtlas)}>
        <H2>What is The Atlas of Economic Complexity?</H2>
        <Paragraph>
          <p>
            The Atlas of Economic Complexity is an award-winning data
            visualization tool that allows people to explore global trade flows
            across markets, track these dynamics over time and discover new
            growth opportunities for every country. Built at the Harvard Kennedy
            School of Government, The Atlas is powered by{" "}
            <a
              href="https://growthlab.cid.harvard.edu/"
              target="_blank"
              rel="noopener"
            >
              Harvard's Growth Lab's
            </a>{" "}
            research and is the flagship tool of{" "}
            <a href="https://growthlab.app/" target="_blank" rel="noopener">
              The Viz Hub
            </a>
            , the Growth Lab's portfolio of visualization tools.
          </p>
          <p>
            The Atlas places the industrial capabilities and knowhow of a
            country at the heart of its growth prospects, where the diversity
            and complexity of existing capabilities heavily influence how growth
            happens. The tool combines trade data with synthesized insights from
            the{" "}
            <a
              href="https://growthlab.hks.harvard.edu/research"
              target="_blank"
              rel="noopener"
            >
              Growth Lab's research
            </a>{" "}
            in a way that is accessible and interactive. As a dynamic resource,
            the tool is continually evolving with new data and features to help
            answer questions such as:
          </p>
          <ul>
            <li>What does a location import and export?</li>
            <li>How has its trade evolved over time?</li>
            <li>What are the drivers of export growth?</li>
            <li>
              Which new industries are likely to emerge in a given location?
              Which are likely to disappear?
            </li>
            <li>
              What are the GDP growth prospects of a given country in the next
              5-10 years, based on its productive capabilities?
            </li>
          </ul>
          <p>
            The original online Atlas was launched in 2013 as a companion tool
            to the book,{" "}
            <a
              href="https://growthlab.cid.harvard.edu/publications/atlas-economic-complexity-mapping-paths-prosperity-0"
              target="_blank"
              rel="noopener"
            >
              The Atlas of Economic Complexity: Mapping paths to Prosperity
            </a>
            . Today, The Atlas is used worldwide by policymakers, investors,
            entrepreneurs, and academics as an important resource for
            understanding a country's economic structure.
          </p>
        </Paragraph>
      </section>

      <section ref={headingIdsWithRefs.get(AboutRouteID.OurTeam)}>
        <H2>Our Team</H2>
        <Paragraph>
          <p>
            Led by{" "}
            <a
              href="https://growthlab.cid.harvard.edu/people/ricardo-hausmann-0"
              target="_blank"
              rel="noopener"
            >
              Ricardo Hausmann
            </a>
            , The Atlas is the result of a multi-year, interdisciplinary
            collaboration between the Growth Lab's Digital Development & Design
            Team, researchers, staff, and a rich network of alumni.
          </p>
          <p>Today, The Atlas team includes:</p>
        </Paragraph>

        <H3>Web Development & Data Visualization</H3>
        <TeamMember>
          <TeamMemberHeading
            href="https://bleonard.dev/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Brendan Leonard</TeamMemberName>
            <TeamMemberTitle>Senior Back-End & Data Developer</TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Brendan leads the development of The Atlas data pipeline and API
            architecture. He works to create technical solutions for new Atlas
            features implementing research-driven concepts. He is also
            responsible for managing the cloud-based server and database
            infrastructure of The Atlas. He holds degrees in government studies
            and economics from Harvard University and UNC-Chapel Hill.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://www.linkedin.com/in/ellie-jackson-6750b539/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Ellie Jackson</TeamMemberName>
            <TeamMemberTitle>Junior Back-end Developer</TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Ellie builds, maintains and optimizes the back-end architecture and
            infrastructure of The Atlas. She also contributes to the acquisition
            and analysis of various Atlas datasets. Ellie has a master's degree
            in computational analysis and public policy from the University of
            Chicago's Harris School.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://www.niltuzcu.net/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Nil Tuzcu</TeamMemberName>
            <TeamMemberTitle>
              Senior UX/UI & Data Visualization Designer
            </TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Nil leads The Atlas design vision including the creation of new
            features, enhancements and the integration of new research. Before
            joining the Growth Lab, Nil worked as a research fellow at MIT's
            Department of Urban Studies and as a research associate at Harvard
            Graduate School of Design. She holds a master's degree from Cornell
            University and was a SPURS Fellow at MIT (2014-2015).
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://www.bortfolio.net/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Robert Christie</TeamMemberName>
            <TeamMemberTitle>Front-end Developer</TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Robert is responsible for the front-end development of The Atlas. He
            architects and implements interactive interfaces and visualizations
            with an emphasis on cartography.
          </Paragraph>
        </TeamMember>

        <H3>Product Team</H3>
        <TeamMember>
          <TeamMemberHeading
            href="https://growthlab.hks.harvard.edu/people/annie-white"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Annie White</TeamMemberName>
            <TeamMemberTitle>Director, Software Tools</TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Annie oversees the strategy and creation of The Atlas, from research
            to design, development, and launch. Prior to her work at the Growth
            Lab, Annie was a Director of Digital Product at Sustainalytics, an
            ESG research and consulting firm. She is interested in the
            application of software tools to positively impact economic
            development, equity, and the public interest.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://growthlab.hks.harvard.edu/people/chuck-mckenney"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Chuck McKenney</TeamMemberName>
            <TeamMemberTitle>
              Director, Communications and Outreach
            </TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Chuck is the Associate Director of Communications & Outreach at
            Harvard's Growth Lab. Chuck has more than 20 years experience in
            broadcast, digital and print communications and has worked for
            academic, media, corporate, and nonprofit organizations.
          </Paragraph>
        </TeamMember>

        <H3>Research Team</H3>
        <TeamMember>
          <TeamMemberHeading href="#" target="_blank" rel="noopener">
            <TeamMemberName>Muhammed Yildirim</TeamMemberName>
            <TeamMemberTitle>
              Research Director, Academic Research
            </TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Muhammed is a physicist turned economist from Turkey. He worked on
            the theory behind The Atlas, determining optimal ways to calculate
            many of The Atlas variables. He co-developed The Atlas methodology,
            the data cleaning code and contributed in the preparation of The
            Atlas book.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://growthlab.hks.harvard.edu/people/sebastian-bustos"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Sebastian Bustos</TeamMemberName>
            <TeamMemberTitle>Senior Research Fellow</TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Sebastian is a Research Fellow at the Center for International
            Development at Harvard University and a Doctoral candidate in Public
            Policy at Harvard University. His research interests are the
            development of the private sector and how governments can solve
            market failures to accelerate the process.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://growthlab.hks.harvard.edu/people/shreyas-matha"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Shreyas Gadgin Matha</TeamMemberName>
            <TeamMemberTitle>
              Senior Computational Social Scientist
            </TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Shreyas applies a computational lens to questions in economics. His
            research interests center around understanding complex
            socio-economic systems, from labor markets and innovation ecosystems
            to international trade, using non-traditional data sources such as
            satellite imagery, textual data, and networks.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://growthlab.hks.harvard.edu/people/tim-cheston"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Tim Cheston</TeamMemberName>
            <TeamMemberTitle>Senior Manager, Applied Research</TeamMemberTitle>
          </TeamMemberHeading>
          <Paragraph>
            Tim is a Research Fellow at the Center for International Development
            at Harvard University. His research interests focus on the
            intersection of social policy and economic policy, including the use
            of growth diagnostics to unlock structural transformation processes.
          </Paragraph>
        </TeamMember>

        <H3>Atlas Alumni</H3>
        <TeamMember>
          <TeamMemberHeading
            href="http://akmanalp.com/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Mali Akmanalp</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Mali is a software engineer from Turkey. He worked on data updates
            for the original Atlas, while optimizing its performance and
            reliability. In the development of Atlas 2.0, Mali built the data
            ingestion pipeline, the back-end architecture and other
            infrastructure like the automated deployment setup.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="http://www.michelecoscia.com/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Michele Coscia</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Michele is an assistant professor from Italy. He teaches network
            analysis and data visualization at the IT University of Copenhagen.
            His research interests involve applying network science to economic
            development, human mobility, and understanding governments. He
            worked on the visualizations and the network analysis of the
            original Atlas book.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://www.fluidencodings.com/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Steven Geofrey</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            From 2022-2023, Steven led the front-end development of various
            Atlas initiatives including the rebuilding of the Atlas front-end
            architecture, user interface and data visualizations.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading href="#" target="_blank" rel="noopener">
            <TeamMemberName>Quinn Lee</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Quinn was a developer on the first version of the Atlas as well as
            on CID's sub-national Atlas projects for Columbia, Mexico, and Peru.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading href="#" target="_blank" rel="noopener">
            <TeamMemberName>Ben Leichter</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Ben was an intern on the first version of the Atlas. He was
            responsible for updating the user interface and creating static and
            interactive design assets for both the Atlas and CID.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://www.huy.dev/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Huy Nguyen</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Between 2017 - 2019 Huy designed the Atlas front-end architecture,
            implemented the user interface and visualizations and created a
            sophisticated build pipeline to bundle assets efficiently. He's
            passionate about making beautiful, engaging and high-performance web
            applications using the latest web technologies.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="http://katherinanguyen.com/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Katherina Nguyen</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Kat led the design vision, developed interactive prototypes and
            supported launch activities in the development of Atlas 2.0. She
            also applied her design techniques and processes to support various
            CID research initiatives.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading href="#" target="_blank" rel="noopener">
            <TeamMemberName>Greg Shapiro</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Greg led development of the first version of the Atlas and the
            launching of localized projects for Mexico, Colombia, and Peru.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="http://www.soeltz.com/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Kyle Soeltz</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Kyle was a front-end developer for the Atlas, responsible for
            implementing user interface features, data visualizations, and
            collaborating with research, design and data counterparts.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading
            href="https://romain.vuillemot.net/"
            target="_blank"
            rel="noopener"
          >
            <TeamMemberName>Romain Vuillemot</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Romain is a Data Visualization Researcher from France. He worked on
            improving the user experience of The Atlas of Economic Complexity.
            Romain published several research papers to improve The Atlas
            ranking navigation using novel interaction techniques, and
            visualization using text-based search.
          </Paragraph>
        </TeamMember>

        <TeamMember>
          <TeamMemberHeading href="#" target="_blank" rel="noopener">
            <TeamMemberName>Gus Wezerek</TeamMemberName>
          </TeamMemberHeading>
          <Paragraph>
            Gus is a visual journalist. He supported the design and development
            of The Atlas in 2015.
          </Paragraph>
        </TeamMember>
      </section>

      <section ref={headingIdsWithRefs.get(AboutRouteID.MediaOutreach)}>
        <H2>Media & Outreach</H2>
        <Paragraph>
          <p>
            The Atlas has reached more than 2 million people and has active
            users in almost every country worldwide.
          </p>
          <p>
            The Atlas has been featured in various media outlets including the{" "}
            <a
              href="http://www.nytimes.com/interactive/2011/05/15/magazine/art-of-economic-complexity.html"
              target="_blank"
              rel="noopener"
            >
              New York Times
            </a>
            ,{" "}
            <a
              href="https://blogs.wsj.com/indiarealtime/2016/01/01/india-will-be-fastest-growing-economy-for-coming-decade-harvard-researchers-predict/"
              target="_blank"
              rel="noopener"
            >
              Wall Street Journal
            </a>
            ,{" "}
            <a
              href="https://www.washingtonpost.com/business/germanys-inconvenient-truth-its-too-complicated/2019/10/14/899ffd72-ee50-11e9-bb7e-d2026ee0c199_story.html"
              target="_blank"
              rel="noopener"
            >
              Washington Post
            </a>
            ,{" "}
            <a
              href="https://www.bloomberg.com/opinion/articles/2019-10-14/german-economic-downturn-is-symptom-of-deeper-growth-problem"
              target="_blank"
              rel="noopener"
            >
              Bloomberg
            </a>
            ,{" "}
            <a
              href="https://www.ft.com/content/0297ff7c-524e-11e8-b3ee-41e0209208ec"
              target="_blank"
              rel="noopener"
            >
              Financial Times
            </a>{" "}
            and the{" "}
            <a
              href="https://news.harvard.edu/gazette/story/2019/09/kennedy-schools-growth-lab-tool-helps-chart-paths-for-economic-growth/"
              target="_blank"
              rel="noopener"
            >
              Harvard Gazette
            </a>{" "}
            and was short-listed for an{" "}
            <a
              href="https://www.informationisbeautifulawards.com/"
              target="_blank"
              rel="noopener"
            >
              Information is Beautiful Award
            </a>{" "}
            in 2018 and 2019.
          </p>
          <p>
            For media inquiries, contact{" "}
            <a href="mailto:chuck_mckenney@hks.harvard.edu">Chuck McKenney</a>.
          </p>
        </Paragraph>
      </section>

      <section ref={headingIdsWithRefs.get(AboutRouteID.GrowthLab)}>
        <H2>Harvard's Growth Lab</H2>
        <Paragraph>
          <p>
            Located at the Harvard Kennedy School, the Growth Lab works in the
            pursuit of inclusive prosperity and a quality of life for everyone
            that we know is achievable. It places increased economic diversity
            and complexity at the center of the growth story and uncovers how
            places move into industries that offer increased productivity.
          </p>
          <p>
            For more information on the Growth Lab, find us on{" "}
            <a
              href="https://twitter.com/HarvardGrwthLab"
              target="_blank"
              rel="noopener"
            >
              Twitter
            </a>{" "}
            or subscribe to our quarterly{" "}
            <a
              href="https://growthlab.hks.harvard.edu/subscribe"
              target="_blank"
              rel="noopener"
            >
              newsletter
            </a>
            . For direct inquiries, contact{" "}
            <a href="mailto:chuck_mckenney@hks.harvard.edu">Chuck McKenney</a>.
          </p>
        </Paragraph>
      </section>

      <section ref={headingIdsWithRefs.get(AboutRouteID.SupportMission)}>
        <H2>Support our Mission</H2>
        <Paragraph>
          The Atlas of Economic Complexity is made possible through the support
          of organizations that align with our mission to advance inclusive
          growth and prosperity. To learn more about how you can support the
          Growth Lab and The Atlas, please email Executive Director,{" "}
          <a href="mailto:andrea_carranza@hks.harvard.edu">Andrea Carranza</a>.
        </Paragraph>
      </section>

      <section ref={headingIdsWithRefs.get(AboutRouteID.ContactUs)}>
        <H2>Contact Us</H2>
        <Paragraph>
          Whether you'd like to request an Atlas demo, ask a question about the
          data, report a problem or learn more about working with us, we're
          happy to help. Please email us at{" "}
          <a href="mailto:growthlabtools@hks.harvard.edu">
            growthlabtools@hks.harvard.edu
          </a>
        </Paragraph>
      </section>
    </ContentContainer>
  );

  const sidebarItems = [
    {
      id: AboutRouteID.WhatIsTheAtlas,
      label: "What is The Atlas of Economic Complexity?",
    },
    { id: AboutRouteID.OurTeam, label: "Our Team" },
    { id: AboutRouteID.MediaOutreach, label: "Media & Outreach" },
    { id: AboutRouteID.GrowthLab, label: "Harvard's Growth Lab" },
    { id: AboutRouteID.SupportMission, label: "Support Our Mission" },
    { id: AboutRouteID.ContactUs, label: "Contact Us" },
  ];

  return (
    <>
      <ParentContainer>
        {content}
        <Sidebar
          activeHeadingId={activeHeadingId}
          setFirstRender={setFirstRender}
          items={sidebarItems}
        />
      </ParentContainer>
      <ResearchFooter />
    </>
  );
};

export default About;
