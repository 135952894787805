import styled from "@emotion/styled";
import { navigationBarColor } from "../styling/cssVariables";

export const hamburgerMenuContainerHeight = "60px";

export const MenuHeaderContainer = styled.div`
  width: 100%;
  flex: 0 0 ${hamburgerMenuContainerHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentPageMenuHeaderContainer = styled.div`
  width: 100%;
  flex: 0 0 ${hamburgerMenuContainerHeight};
  background-color: ${navigationBarColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MenuBodyContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const HamburgerMenuButton = styled.button`
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  width: 65px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.span`
  display: flex;
  width: 30px;
  height: 16px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  flex-direction: column;
  justify-content: space-between;
`;
export const Bar = styled.span`
  display: inline-block;
  width: 100%;
  height: 0;
  border-top: 2px solid #fff;
  transition: all 0.2s ease;
`;
export const CenterBar = styled(Bar)`
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0;
    border-top: 2px solid #fff;
    position: absolute;
    transform-origin: center;
    top: -2px;
    left: 0;
    transition: all 0.2s ease;
  }

  &.close__menu {
    transform: rotate(45deg);

    &:before {
      transform: rotate(90deg);
    }
  }
`;
