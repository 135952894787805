import { RouteID } from "../../routing/routes";

/*
These are the links that should appear in the header navigation;
this list is recycled for both the landing page and the main Explore pages,
and styled separately for those respective contexts
*/
export interface LinkMetadataItemProps {
  routeId: RouteID;
  route: string;
  label: string;
}

interface LinkMetadataItemWithChildrenProps extends LinkMetadataItemProps {
  children: LinkMetadataItemProps[] | undefined;
}

export const countryProfileRoutes = [
  RouteID.CountryProfiles,
  RouteID.GrowthProjections,
  RouteID.CountryRankings,
  RouteID.Announcements,
];

export const headerLinkMetadata: LinkMetadataItemWithChildrenProps[] = [
  { routeId: RouteID.Landing, route: "/", label: "Home", children: undefined },
  {
    routeId: RouteID.Explore,
    route: "/explore/treemap",
    label: "Explore",
    children: undefined,
  },
  {
    routeId: RouteID.CountryProfiles,
    route: "/countries",
    label: "Countries",
    children: [
      {
        routeId: RouteID.CountryProfiles,
        route: "/countries",
        label: "Country Profiles",
      },
      {
        routeId: RouteID.CountryRankings,
        route: "/rankings",
        label: "Complexity Rankings",
      },
      {
        routeId: RouteID.GrowthProjections,
        route: "/growth-projections",
        label: "Growth Projections",
      },
    ],
  },
  {
    routeId: RouteID.Data,
    route: "/about-data",
    label: "Data",
    children: [
      {
        routeId: RouteID.Data,
        route: "/data-downloads",
        label: "Data Downloads",
      },
      { routeId: RouteID.Data, route: "/about-data", label: "About the Data" },
      {
        routeId: RouteID.Data,
        route: "/data-use-permissions",
        label: "Use & Permissions",
      },
    ],
  },
  {
    routeId: RouteID.Learn,
    route: "/glossary",
    label: "Learn",
    children: [
      { routeId: RouteID.Glossary, route: "/glossary", label: "Glossary" },
      { routeId: RouteID.FAQ, route: "/faq", label: "FAQ" },
    ],
  },
  {
    routeId: RouteID.Announcements,
    route: "/announcements",
    label: "Announcements",
    children: undefined,
  },
  {
    routeId: RouteID.About,
    route: "/about",
    label: "About",
    children: [
      {
        routeId: RouteID.About,
        route: "/about#what-is-the-atlas",
        label: "The Atlas of Economic Complexity",
      },
      { routeId: RouteID.About, route: "/about#our-team", label: "Our Team" },
      {
        routeId: RouteID.About,
        route: "/about#media-outreach",
        label: "Media & Outreach",
      },
      {
        routeId: RouteID.About,
        route: "/about#growth-lab",
        label: "Harvard's Growth Lab",
      },
      {
        routeId: RouteID.About,
        route: "/about#contributors",
        label: "Contributors",
      },
      {
        routeId: RouteID.About,
        route: "/about#support-mission",
        label: "Support Our Mission",
      },
      {
        routeId: RouteID.About,
        route: "/about#contact-us",
        label: "Contact Us",
      },
    ],
  },
];
