import { ReactLocalization } from "@fluent/react";
import { FluentBundle, FluentResource } from "@fluent/bundle";
import raw from "raw.macro";

const getLocalizationInfo = (messages: string) => {
  const bundle = new FluentBundle(["en-US"]);
  const resource = new FluentResource(messages);
  bundle.addResource(resource);
  function* generateBundles(_locales: string[]) {
    yield bundle;
  }
  const localization = new ReactLocalization(generateBundles(["en-US"]));

  return localization;
};

const localization = getLocalizationInfo(raw("./messages.ftl"));

export default localization;
