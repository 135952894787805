import { useCombobox } from "downshift";
import { useState } from "react";
import {
  MainMenu,
  ToggleButton,
  ArrowDown,
  ArrowUp,
  DropdownContainer,
  DropdownInputField,
  ComboBoxContainer,
} from "./styles";
import { debounce } from "lodash-es";
import SearchDropdown from "./SearchDropdown";

interface ComboBoxInput {
  items: any[];
  hashFunction: any;
  searchStringFunction?: any | undefined;
  placeholder: string;
  ItemRenderComponent: any;
  onChangeEvent: any;
  selectedValueFromStore: any | undefined;
  updateVisibilityFunction: any;
  filterFunction?: any;
  dropdownRole?: any;
  productItemsWithHeights?: any[] | undefined;
}

export enum DropdownRole {
  LocationExporter = "exporter",
  LocationImporter = "importer",
  Product = "product",
}

const MainDropdownCombobox = ({
  items,
  hashFunction,
  searchStringFunction,
  placeholder,
  ItemRenderComponent,
  updateVisibilityFunction,
  filterFunction,
  onChangeEvent,
  selectedValueFromStore,
  dropdownRole,
  productItemsWithHeights,
}: ComboBoxInput) => {
  function getOriginalIndexOfItem({
    originalItems,
    selectedItem,
  }: any): number {
    const ids = originalItems.map((item: any) => item.id);
    const idToFind = selectedItem.id;
    return ids.indexOf(idToFind);
  }

  if (searchStringFunction === undefined) {
    searchStringFunction = hashFunction;
  }

  function ComboBox() {
    const [displayItems, setDisplayItems] = useState(items);
    const [isSearching, setIsSearching] = useState(false);

    const {
      // Prop getters:
      getToggleButtonProps, // Attributes to be applied to a menu toggle button <button>
      getLabelProps, // Attributes to be applied to <label> element, e.g., aria-label
      getMenuProps, // Attributes to be applied to <ul> or root of menu to be rendered
      getInputProps, // Attributes to be applied to <input> element, e.g., aria-label
      getItemProps, // Attributes to be applied to menu items, e.g., <li> elements

      // State values:
      selectedItem, // Current selected item
      highlightedIndex,
      isOpen, // Is the dropdown open?
      inputValue, // the value in the input field

      // Actions:
      closeMenu, // closes the menu
      openMenu, // opens the menu
      selectItem, // selects a given item
      setHighlightedIndex, // sets a new highlighted item index
      setInputValue, // sets a new value in the input
      toggleMenu, // toggles menu open state
    } = useCombobox({
      // This handles the autocomplete behavior, i.e., as the user types, filtered list of items displayed
      onInputValueChange: debounce(({ inputValue, isOpen }) => {
        if (
          isOpen &&
          inputValue &&
          inputValue !== "" &&
          inputValue !== placeholder
        ) {
          const lowerCasedInputValue = inputValue
            ? inputValue.toLowerCase()
            : "";
          if (filterFunction) {
            filterFunction({
              searchTerm: lowerCasedInputValue,
              displayItems,
              setDisplayItems,
            });
          }
          if (isSearching !== true) setIsSearching(true);
        } else {
          if (isSearching !== false) setIsSearching(false);
          updateVisibilityFunction({ displayItems, setDisplayItems });
        }
      }, 350),
      onIsOpenChange({ isOpen, selectedItem, highlightedIndex }) {
        if (isOpen) {
          setInputValue("");
          if (selectedItem) {
            updateVisibilityFunction({ displayItems, setDisplayItems });
          }
        } else {
          if (selectedItem) {
            setInputValue(hashFunction(selectedItem));
          } else {
            setInputValue("");
          }
          if (isSearching !== false) setIsSearching(false);
        }
      },
      onSelectedItemChange({ selectedItem }) {
        if (selectedItem) {
          if (!selectedValueFromStore) {
            onChangeEvent({ id: selectedItem.id });
          } else if (
            selectedValueFromStore &&
            selectedValueFromStore.id !== selectedItem.id
          ) {
            onChangeEvent({ id: selectedItem.id });
          }
        } else if (!selectedItem) {
          onChangeEvent({ id: undefined });
        }
        if (isSearching !== false) setIsSearching(false);
      },
      // The actual items to be displayed in the dropdown at time of render
      items: displayItems,
      // Accessor function to get label for item in dropdown
      itemToString(item) {
        return item ? hashFunction(item) : "";
      },
    });

    if (selectedValueFromStore && !selectedItem) {
      selectItem(selectedValueFromStore);
    }

    let menuItems;

    if (isSearching === true && dropdownRole === DropdownRole.Product) {
      menuItems = (
        <SearchDropdown
          displayItems={displayItems}
          setDisplayItems={setDisplayItems}
          items={items}
          selectedItem={selectedItem}
          highlightedIndex={highlightedIndex}
          hashFunction={hashFunction}
          updateVisibilityFunction={updateVisibilityFunction}
          getOriginalIndexOfItem={getOriginalIndexOfItem}
          ItemRenderComponent={ItemRenderComponent}
          getItemProps={getItemProps}
          dropdownRole={dropdownRole}
          productItemsWithHeights={productItemsWithHeights}
        />
      );
    } else {
      menuItems = displayItems
        .filter((item: any) => item.isVisible)
        .map((item: any, index: number) => {
          // getItemProps is called here for each item in the <ul> --
          // passing `item` and `index` helps Downshift keep track of which item
          // gets selected or highlighted from user interact
          const mappedItemIndex = getOriginalIndexOfItem({
            originalItems: items,
            selectedItem: item,
          });
          let keyString;
          if (dropdownRole) {
            keyString = `dropdown_${dropdownRole}_item_${item.id}`;
          } else {
            keyString = `dropdown_item_${item.id}`;
          }

          return (
            <ItemRenderComponent
              key={keyString}
              item={item}
              index={index}
              highlightedIndex={highlightedIndex}
              selectedItem={selectedItem}
              mappedItemIndex={mappedItemIndex}
              getItemProps={getItemProps}
              hashFunction={hashFunction}
              updateVisibilityFunction={updateVisibilityFunction}
              displayItems={displayItems}
              setDisplayItems={setDisplayItems}
              isSearching={isSearching}
            />
          );
        });
    }

    return (
      <ComboBoxContainer>
        <DropdownContainer>
          <DropdownInputField placeholder={placeholder} {...getInputProps()} />
          <ToggleButton
            aria-label="toggle menu"
            type="button"
            {...getToggleButtonProps()}
          >
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </ToggleButton>
        </DropdownContainer>
        {isOpen && <MainMenu {...getMenuProps()}>{menuItems}</MainMenu>}
      </ComboBoxContainer>
    );
  }
  return <ComboBox />;
};

export default MainDropdownCombobox;
