export enum RouteID {
  /* Content Pages */
  StaticPageEntry = "StaticPage",
  Landing = "Landing",
  Learn = "Learn",
  // KeyConcepts = "KeyConcepts",
  Glossary = "Glossary",
  FAQ = "FAQ",
  Announcements = "Announcements",
  About = "About",
  GrowthProjections = "GrowthProjections",
  CountryRankings = "CountryRankings",
  Data = "Data",
  DataDownloads = "DataDownloads",
  DataAbout = "DataAbout",
  DataUse = "DataUse",
  /* Visualization routes */
  Explore = "Explore",
  ExploreDefault = "ExploreDefault",
  TreeMap = "TreeMap",
  GeoMap = "GeoMap",
  OverTime = "OverTime",
  MarketShare = "MarketShare",
  ProductSpace = "ProductSpace",
  Feasibility = "Feasibility",
  RingsGraph = "RingsGraph",
  CountryProfiles = "CountryProfiles",
}

export enum AboutRouteID {
  // About Page
  WhatIsTheAtlas = "what-is-the-atlas",
  OurTeam = "our-team",
  MediaOutreach = "media-outreach",
  GrowthLab = "growth-lab",
  Contributors = "contributors",
  SupportMission = "support-mission",
  ContactUs = "contact-us",
}
