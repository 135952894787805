import { HeaderContainer } from "../../pages/ContentPageGrid";
import { HeaderAtlasLogo, HeaderNavigationList } from "./styles";
import { Link } from "react-router-dom";
import GrowthLabLogoLight from "../../assets/images/GL_logo_white.png";

const HeaderBanner = () => {
  return (
    <HeaderContainer>
      <HeaderAtlasLogo>
        <Link to={"/"}>
          <img
            src={GrowthLabLogoLight}
            alt="The Atlas of Economic Complexity"
          />
        </Link>
      </HeaderAtlasLogo>
    </HeaderContainer>
  );
};

export default HeaderBanner;
