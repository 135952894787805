import styled from "@emotion/styled";
import {
  TradeDirection,
  getLocationQualifiers,
} from "../../visualization/Utils";
import { worldGroupDatum } from "../../graphql/queries/getLocationsMetadata";
import { usePageQueryParams } from "../../visualization/defaultSettings";

interface Props {
  targetOption: TradeDirection;
  selected: boolean;
}

const TradeDirectionListItem = styled.li`
  margin: 0px 3px 0px 0px;
  padding: 0px;
  font-size: 0.75rem;
  color: #888888;

  &.selected {
    color: black;
    font-weight: bold;
  }
`;

export default function TradeDirectionSelectorButton({
  targetOption,
  selected,
}: Props) {
  let buttonLabel: string | undefined;
  const [{ importer: currentImporter, exporter: currentExporter }, setQuery] =
    usePageQueryParams();

  const { importerIsWorld, exporterIsWorld } = getLocationQualifiers({
    exporter: currentExporter,
    importer: currentImporter,
  });

  if (targetOption === TradeDirection.Exports) {
    buttonLabel = "Exporter";
  } else if (targetOption === TradeDirection.Imports) {
    buttonLabel = "Importer";
  }

  const handleClick = () => {
    if (
      (importerIsWorld || currentImporter === "") &&
      (exporterIsWorld || currentExporter === "")
    ) {
      // Both are world, toggle between exports and imports
      if (exporterIsWorld) {
        setQuery({
          importer: worldGroupDatum.groupId,
          exporter: "",
        });
      } else {
        setQuery({
          importer: "",
          exporter: worldGroupDatum.groupId,
        });
      }
    } else if (importerIsWorld) {
      setQuery({
        importer: currentExporter,
        exporter: worldGroupDatum.groupId,
      });
    } else {
      setQuery({
        importer: worldGroupDatum.groupId,
        exporter: currentImporter,
      });
    }
  };

  return (
    <TradeDirectionListItem
      className={selected ? "selected" : ""}
      onClick={handleClick}
    >
      {buttonLabel}
    </TradeDirectionListItem>
  );
}
