/** @jsxImportSource @emotion/react */
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Button, Box } from "@mui/material";
import { H1, H2, Paragraph } from "../../components/styling/TextStyling";
import { ContentContainer, ParentContainer } from "../about";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../sharedComponents/Sidebar";

const DataDownloadsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash;
  const activeHeadingId = hash.replace("#", "");

  const [firstRender, setFirstRender] = useState<boolean>(true);

  const headingRefs = {
    trade: useRef<HTMLElement | null>(null),
    growth: useRef<HTMLElement | null>(null),
    classifications: useRef<HTMLElement | null>(null),
    networks: useRef<HTMLElement | null>(null),
  };

  const orderedHeadingRefs = Object.entries(headingRefs).map(([id, ref]) => ({
    id,
    ref,
  }));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleSections = entries.filter((entry) => entry.isIntersecting);
        if (visibleSections.length > 0) {
          const currentSection = visibleSections[0].target;
          const currentSectionId = orderedHeadingRefs.find(
            (h) => h.ref.current === currentSection,
          )?.id;
          if (currentSectionId) {
            navigate(`#${currentSectionId}`, { replace: true });
          }
        }
      },
      { threshold: 0.01, rootMargin: "0px 0px -80% 0px" },
    );

    orderedHeadingRefs.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      orderedHeadingRefs.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  useLayoutEffect(() => {
    if (activeHeadingId && firstRender) {
      const nodeRef = headingRefs[activeHeadingId];
      if (nodeRef && nodeRef.current) {
        const node = nodeRef.current;
        node.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setFirstRender(false);
    }
  }, [activeHeadingId, firstRender]);

  useEffect(() => {
    if (!activeHeadingId) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, activeHeadingId]);

  const content = (
    <ContentContainer>
      <Box my={4}>
        <H1>Data Downloads</H1>
        <Paragraph>
          The most recent goods, services, complexity and network data found in
          The Atlas can be downloaded at{" "}
          <a href="https://dataverse.harvard.edu/dataverse/atlas">
            Harvard Dataverse
          </a>
          . When using data obtained through Dataverse, please adhere to our{" "}
          <Link to="/data-use-permissions">referencing conventions</Link>.
        </Paragraph>
        <Button
          variant="contained"
          color="primary"
          href="https://dataverse.harvard.edu/dataverse/atlas"
        >
          Download International Trade Data
        </Button>
        <H2>Through Dataverse, we provide the following data sets:</H2>

        <section ref={headingRefs.trade}>
          <H2>International Trade Data (HS and SITC)</H2>
          <Paragraph>
            Our trade data is provided in both Harmonized System (HS, 1992) and
            Standard International Trade Classification (SITC, rev. 2) formats.
            Each of these is contained in a separate dataset in Dataverse. Files
            contained within these sets generally take one of two forms:
          </Paragraph>
          <ul>
            <li>
              <Paragraph>
                Country-Product-Year: These files contain all years of
                unilateral trade data for a given set combined into a single
                file.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Country-Partner-Product-Year: These files contain a single year
                of bilateral trade data for a given set (with the exception of
                section and 2-digit products) and include all years for the set.
              </Paragraph>
            </li>
          </ul>
          <Paragraph>
            Each of these repositories additionally contains a file named
            "data_dictionary.pdf" containing information on the different
            columns contained in files within the dataset. For more information
            on the difference in classifications, year availability, and more,
            please see our <Link to="/about-data">About the Data</Link> page.
          </Paragraph>
        </section>

        <section ref={headingRefs.growth}>
          <H2>Growth Projections and Complexity Rankings</H2>
          <Paragraph>
            Each year, researchers at Harvard's Growth Lab release growth
            forecasts for the upcoming decade as well as annual rankings of
            countries by economic complexity. The Economic Complexity Index
            (ECI) ranking is a measure of the amount of capabilities and knowhow
            of a given country determined by the diversity, ubiquity, and
            complexity of the products it exports.
          </Paragraph>
          <Paragraph>
            Growth projections are calculated through a process largely based on
            determining whether a country's economic complexity is higher or
            lower than expected given its level of income. We expect countries
            whose economic complexity is greater than we would expect for its
            level of income to grow faster than those that are "too rich" for
            their current level of complexity. In this data, a country's growth
            projection value for a given year is for the decade beginning with
            that year. For example, a value in a 2017 row is the projection of
            annualized growth for 2017–2027.
          </Paragraph>
        </section>

        <section ref={headingRefs.classifications}>
          <H2>Classifications Metadata</H2>
          <Paragraph>
            The classifications data set contains metadata on the two
            international classification systems presented in The Atlas: HS and
            SITC. Note that these classifications are slightly augmented for use
            in the Atlas and do not exactly match their base systems.
          </Paragraph>
        </section>

        <section ref={headingRefs.networks}>
          <H2>Product Space Networks</H2>
          <Paragraph>
            The Product Space network layouts for both HS and SITC are provided
            in JSON format in D3-style node-link lists. The node data contains
            an ID and (X, Y) coordinates, while link data is an adjacency list
            with source and target fields matching the node ID. This network has
            been cleaned, the nodes carefully laid out, and uninformative links
            removed for visualization purposes. To maintain accuracy, economic
            complexity calculations are made using the full network and these
            proximities are also available within this repository.
          </Paragraph>
          <Paragraph>
            More information on individual datasets can be found within each
            Dataverse repository and further information about our
            classifications and methods can be found{" "}
            <Link to="/about-data">here</Link>. To request additional help on
            Atlas data downloads, please contact us{" "}
            <a href="mailto:growthlabtools@hks.harvard.edu">here</a>.
          </Paragraph>
        </section>
      </Box>
    </ContentContainer>
  );

  const sidebarItems = [
    { id: "trade", label: "International Trade Data" },
    { id: "growth", label: "Growth Projections" },
    { id: "classifications", label: "Classifications Data" },
    { id: "networks", label: "Product Space Networks" },
  ];

  return (
    <ParentContainer>
      {content}
      <Sidebar
        activeHeadingId={activeHeadingId}
        setFirstRender={setFirstRender}
        items={sidebarItems}
      />
    </ParentContainer>
  );
};

export default DataDownloadsPage;
