import { Location } from "react-router-dom";
import { worldGroupDatum } from "../graphql/queries/getLocationsMetadata";
import { format, index, sum } from "d3";
import {
  LocationStringPrefixes,
  clusterColorsMap,
  getLocationLevelFromStringLocationId,
  getNumericIdFromStringLocationId,
  groupNames,
  groupedRegionColorMap,
  hs12ColorsMap,
  hs12Names,
  hs12ServicesCategory,
  hs92ColorsMap,
  hs92Names,
  hs92ServicesCategory,
  sitcColorsMap,
  sitcNames,
  sitcServicesCategory,
} from "../sharedUtilities/Utils";
import { LocationLevel } from "../graphql/types";
import {
  earliestHs12Year,
  earliestHs92Year,
  earliestSitcYear,
  latestProductYear,
  latestHs12ProductYear,
} from "../graphql/Utils";
import { random } from "lodash-es";
import { CategoryDatum } from "./components/LegendLabel";
import { getLegendCategories } from "./charts/productspace/greenColorScale";

/*
Note:

The following values of `VizType` are based on 
the conventions used in legacy Atlas. The formal names
of these visualizations have changed in the process 
of Atlas Rewrite -- e.g., the "feasibility" visualization 
is now "Growth Opportunity".

In the future, you may want to revise the values of 
the VizType enum to align them more closely with the 
new names of visualizations.

*/
export enum VizType {
  Tree = "treemap",
  Geo = "geomap",
  OverTime = "overtime",
  MarketShare = "marketshare",
  ProductSpace = "productspace",
  Feasibility = "feasibility",
}

export const defaultVizType = VizType.Tree;

export enum UIView {
  Markets = "markets",
  Products = "products",
}

export enum LocationDetailLevel {
  country = "country",
  subregion = "subregion",
  region = "region",
}

export enum ProductClass {
  HS92Products = "HS92",
  HS12Products = "HS12",
  SITCProducts = "SITC",
}

export enum ServicesClass {
  bilateral = "bilateral",
  unilateral = "unilateral",
}

export enum ProductLevel {
  ProductSection = 1,
  Product2digit = 2,
  Product4digit = 4,
  Product6digit = 6,
}

export enum TradeDirection {
  Exports = "exports",
  Imports = "imports",
}

export enum TradeFlow {
  Gross = "gross",
  Net = "net",
}

export enum ColorBy {
  Sector = "sector",
  Green = "green",
  Complexity = "complexity",
  Cluster = "cluster",
}

export enum StackOrder {
  Community = "community",
  Totals = "totals",
}

export const getVizTypeFromPath = (location: Location) => {
  const parsedPath = location.pathname.replace("/explore/", "");
  let computedVizType: VizType;
  if (parsedPath === "treemap") {
    computedVizType = VizType.Tree;
  } else if (parsedPath === "geomap") {
    computedVizType = VizType.Geo;
  } else if (parsedPath === "overtime") {
    computedVizType = VizType.OverTime;
  } else if (parsedPath === "marketshare") {
    computedVizType = VizType.MarketShare;
  } else if (parsedPath === "productspace") {
    computedVizType = VizType.ProductSpace;
  } else if (parsedPath === "feasibility") {
    computedVizType = VizType.Feasibility;
  } else {
    // Fallback to default viz type
    computedVizType = defaultVizType;
  }

  return computedVizType;
};

export const highlightInVizReducedOpacity = 0.4;

export const overlayPortalContainerId = "overlayPortalContainerId";

const overlayPortalZIndex = 3000;

export const OverlayPortal = () => (
  <div
    id={overlayPortalContainerId}
    style={{
      position: "relative",
      zIndex: overlayPortalZIndex,
      pointerEvents: "all",
    }}
    tabIndex={-1}
  />
);

/* A utility function to convert rgb() value to rgba() with alpha transparency */
export const rgba = (input: string, alpha: number) => {
  const re = /(\d+)/g;

  if (input.includes("rgb")) {
    const matches = input.match(re);
    if (matches && matches.length == 3 && alpha) {
      return `rgba(${matches[0]},${matches[1]},${matches[2]},${alpha})`;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getTradeDirection = ({
  exporter,
  importer,
  locationForAPI,
  partnerForAPI,
}: any) => {
  let tradeDirection: TradeDirection | undefined;
  if (exporter === worldGroupDatum.groupId && importer === "") {
    tradeDirection = TradeDirection.Exports;
  } else if (importer === worldGroupDatum.groupId && exporter === "") {
    tradeDirection = TradeDirection.Imports;
  } else if (exporter === worldGroupDatum.groupId && importer !== undefined) {
    tradeDirection = TradeDirection.Imports;
  } else if (importer === worldGroupDatum.groupId && exporter !== undefined) {
    tradeDirection = TradeDirection.Exports;
  } else if (exporter === worldGroupDatum.groupId && importer === undefined) {
    tradeDirection = TradeDirection.Exports;
  } else if (importer === worldGroupDatum.groupId && exporter === undefined) {
    tradeDirection = TradeDirection.Imports;
  } else {
    if (locationForAPI && partnerForAPI) {
      if (locationForAPI === exporter && partnerForAPI === importer) {
        tradeDirection = TradeDirection.Exports;
      } else if (locationForAPI === importer && partnerForAPI === exporter) {
        tradeDirection = TradeDirection.Imports;
      } else {
        tradeDirection = TradeDirection.Exports;
      }
    } else if (locationForAPI && partnerForAPI === undefined) {
      if (locationForAPI === exporter) {
        tradeDirection = TradeDirection.Exports;
      } else if (locationForAPI === importer) {
        tradeDirection = TradeDirection.Imports;
      } else {
        tradeDirection = TradeDirection.Exports;
      }
    } else if (locationForAPI === undefined && partnerForAPI) {
      if (partnerForAPI === importer) {
        tradeDirection = TradeDirection.Exports;
      } else if (partnerForAPI === exporter) {
        tradeDirection = TradeDirection.Imports;
      } else {
        tradeDirection = TradeDirection.Exports;
      }
    }
  }

  return tradeDirection;
};

export enum TradeDirectionSelectorString {
  Exports = "exportValue",
  Imports = "importValue",
}

export const getTradeDirectionSelector = ({ tradeDirection }: any) => {
  let tradeDirectionSelector: string = "";
  if (tradeDirection === TradeDirection.Exports) {
    tradeDirectionSelector = TradeDirectionSelectorString.Exports;
  } else if (tradeDirection === TradeDirection.Imports) {
    tradeDirectionSelector = TradeDirectionSelectorString.Imports;
  }

  return tradeDirectionSelector;
};

export const generateTotalValueDisplayLabel = ({
  exporter,
  importer,
  view,
}: {
  exporter: string | undefined;
  importer: string | undefined;
  view: UIView | undefined;
}) => {
  let totalValueDisplayLabel: string | undefined;
  const {
    exporterIsUndefined,
    importerIsUndefined,
    exporterIsWorld,
    importerIsWorld,
  } = getLocationQualifiers({ exporter, importer });

  if (view === UIView.Markets) {
    if (!exporterIsUndefined && !importerIsUndefined) {
      if (exporterIsWorld && !importerIsWorld) {
        // If `exporter` is world, then we are viewing imports for selected location
        totalValueDisplayLabel = "Total Imports";
      } else if (importerIsWorld && !exporterIsWorld) {
        // If `importer` is world, then we are viewing exports for selected location
        totalValueDisplayLabel = "Total Exports";
      }
    } else if (exporterIsWorld && importerIsUndefined) {
      totalValueDisplayLabel = "Total Exports";
    } else if (importerIsWorld && exporterIsUndefined) {
      totalValueDisplayLabel = "Total Imports";
    }
  } else if (view === UIView.Products) {
    totalValueDisplayLabel = "Total Exports";
  }

  return "Total";
  // return totalValueDisplayLabel;
};

export const formatTotalValue = (value: number): string => {
  return format(".3s")(value).replace("G", "B");
};

export const formatYAxisValue = (value: number): string => {
  return format(".2s")(value).replace("G", "B");
};

export const formatAsPercentage = (value: number): string => {
  return format(",.2f")(value * 100);
};

export const moneyFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});

export const narrow = ({
  variable,
  view,
  vizType,
  exporter,
  importer,
}: any) => {
  let narrowedValue;

  const {
    exporterIsUndefined,
    exporterIsWorld,
    exporterIsGroup,
    exporterIsCountry,
    importerIsUndefined,
    importerIsWorld,
    importerIsGroup,
    importerIsCountry,
  } = getLocationQualifiers({ exporter, importer });

  if (variable === "countryId") {
    if (
      !exporterIsUndefined &&
      !importerIsUndefined &&
      !exporterIsWorld &&
      !importerIsWorld
    ) {
      if (exporterIsCountry && importerIsCountry) {
        narrowedValue = exporter;
      } else if (exporterIsCountry && importerIsGroup) {
        narrowedValue = exporter;
      } else if (exporterIsGroup && importerIsCountry) {
        narrowedValue = importer;
      } else {
        narrowedValue = undefined;
      }
    } else if (!exporterIsUndefined && importerIsWorld) {
      if (exporterIsCountry) {
        narrowedValue = exporter;
      } else if (exporterIsGroup) {
        narrowedValue = undefined;
      } else {
        narrowedValue = undefined;
      }
    } else if (exporterIsWorld && !importerIsUndefined) {
      if (importerIsCountry) {
        narrowedValue = importer;
      } else if (importerIsGroup) {
        narrowedValue = undefined;
      } else {
        narrowedValue = undefined;
      }
    } else if (!exporterIsUndefined && importerIsUndefined) {
      if (exporterIsCountry) {
        narrowedValue = exporter;
      } else if (exporterIsGroup) {
        narrowedValue = undefined;
      } else {
        narrowedValue = undefined;
      }
    } else if (!importerIsUndefined && exporterIsUndefined) {
      if (importerIsCountry) {
        narrowedValue = importer;
      } else if (importerIsGroup) {
        narrowedValue = undefined;
      } else {
        narrowedValue = undefined;
      }
    }

    let narrowedValueLevel =
      getLocationLevelFromStringLocationId(narrowedValue);
    if (narrowedValueLevel !== LocationLevel.Country) {
      narrowedValue = undefined;
    }
  } else if (variable === "partnerCountryId") {
    if (
      !exporterIsUndefined &&
      !importerIsUndefined &&
      !exporterIsWorld &&
      !importerIsWorld
    ) {
      if (exporterIsCountry && importerIsCountry) {
        narrowedValue = importer;
      } else if (exporterIsCountry && importerIsGroup) {
        narrowedValue = undefined;
      } else if (exporterIsGroup && importerIsCountry) {
        narrowedValue = undefined;
      } else {
        narrowedValue = undefined;
      }
    } else if (!exporterIsUndefined && importerIsWorld) {
      if (exporterIsCountry) {
        narrowedValue = undefined;
      } else if (exporterIsGroup) {
        narrowedValue = undefined;
      } else {
        narrowedValue = undefined;
      }
    } else if (exporterIsWorld && !importerIsUndefined) {
      if (importerIsCountry) {
        narrowedValue = undefined;
      } else if (importerIsGroup) {
        narrowedValue = undefined;
      } else {
        narrowedValue = undefined;
      }
    }

    let narrowedValueLevel =
      getLocationLevelFromStringLocationId(narrowedValue);
    if (narrowedValueLevel !== LocationLevel.Country) {
      narrowedValue = undefined;
    }
  } else if (variable === "groupId") {
    if (view === UIView.Markets) {
      narrowedValue = undefined;
    } else {
      if (
        !exporterIsUndefined &&
        !importerIsUndefined &&
        !exporterIsWorld &&
        !importerIsWorld
      ) {
        if (exporterIsGroup && importerIsGroup) {
          narrowedValue = exporter; // GGPY, groupId is exporter
        } else if (exporterIsCountry && importerIsGroup) {
          narrowedValue = undefined;
        } else if (exporterIsGroup && importerIsCountry) {
          narrowedValue = undefined;
        } else {
          narrowedValue = undefined;
        }
      } else if (!exporterIsUndefined && importerIsWorld) {
        if (exporterIsGroup) {
          // GGPY, groupId is exporter, partnerGroupId is world
          narrowedValue = exporter;
        } else if (exporterIsCountry) {
          narrowedValue = undefined;
        } else {
          narrowedValue = undefined;
        }
      } else if (exporterIsWorld && !importerIsUndefined) {
        if (importerIsGroup) {
          // GGPY, groupId is importer, partnerGroupId is world
          narrowedValue = importer;
        } else if (importerIsCountry) {
          narrowedValue = undefined;
        } else {
          narrowedValue = undefined;
        }
      }

      let narrowedValueLevel =
        getLocationLevelFromStringLocationId(narrowedValue);
      if (narrowedValueLevel !== LocationLevel.Group) {
        narrowedValue = undefined;
      }
    }
  } else if (variable === "partnerGroupId") {
    if (view === UIView.Markets) {
      if (importerIsWorld && !exporterIsUndefined) {
        narrowedValue = exporter;
      } else if (exporterIsWorld && !importerIsUndefined) {
        narrowedValue = importer;
      } else if (
        (importerIsWorld && exporterIsWorld) ||
        (importerIsWorld && exporterIsUndefined) ||
        (exporterIsWorld && importerIsUndefined)
      ) {
        // Special case for geomap, "product" view: exporter/importer = world, product = something
        narrowedValue = worldGroupDatum.groupId;
      } else if (!exporterIsUndefined && importerIsUndefined) {
        narrowedValue = exporter;
      } else if (exporterIsUndefined && !importerIsUndefined) {
        // In the case where Exporter = World, Importer = group,
        // need to pass in the non-world argument as the partnerGroupId
        narrowedValue = importer;
      } else if (!exporterIsUndefined && importerIsWorld) {
        narrowedValue = undefined;
      } else if (exporterIsWorld && !importerIsUndefined) {
        narrowedValue = undefined;
      } else {
        narrowedValue = undefined;
      }

      let narrowedValueLevel =
        getLocationLevelFromStringLocationId(narrowedValue);
      if (narrowedValueLevel !== LocationLevel.Group) {
        narrowedValue = undefined;
      }
    } else {
      if (
        !exporterIsUndefined &&
        !importerIsUndefined &&
        !exporterIsWorld &&
        !importerIsWorld
      ) {
        if (exporterIsGroup && importerIsGroup) {
          narrowedValue = importer;
        } else if (exporterIsCountry && importerIsGroup) {
          narrowedValue = importer;
        } else if (exporterIsGroup && importerIsCountry) {
          narrowedValue = exporter;
        } else {
          narrowedValue = undefined;
        }
      } else if (!exporterIsUndefined && importerIsWorld) {
        if (exporterIsGroup) {
          // GGPY, groupId is exporter, partnerGroupId is world
          narrowedValue = worldGroupDatum.groupId;
        } else if (exporterIsCountry) {
          narrowedValue = undefined;
        } else {
          narrowedValue = undefined;
        }
      } else if (exporterIsWorld && !importerIsUndefined) {
        if (importerIsGroup) {
          // GGPY, groupId is importer, partnerGroupId is world
          narrowedValue = worldGroupDatum.groupId;
        } else if (importerIsCountry) {
          narrowedValue = undefined;
        } else {
          narrowedValue = undefined;
        }
      }

      let narrowedValueLevel =
        getLocationLevelFromStringLocationId(narrowedValue);
      if (narrowedValueLevel !== LocationLevel.Group) {
        narrowedValue = undefined;
      }
    }
  }

  return narrowedValue !== undefined
    ? getNumericIdFromStringLocationId(narrowedValue)
    : narrowedValue;
};

export const getColorMap = ({ view, productClass }: any) => {
  if (view === UIView.Markets) {
    return groupedRegionColorMap;
  } else if (view === UIView.Products) {
    if (productClass === ProductClass.HS92Products) {
      return hs92ColorsMap;
    } else if (productClass === ProductClass.HS12Products) {
      return hs12ColorsMap;
    } else if (productClass === ProductClass.SITCProducts) {
      return sitcColorsMap;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getProductClassYearRange = ({
  productClass,
}: {
  productClass: ProductClass | undefined;
}) => {
  let earliestYear: number;
  let latestYear: number;
  if (productClass === ProductClass.HS92Products) {
    earliestYear = earliestHs92Year;
    latestYear = latestProductYear;
  } else if (productClass === ProductClass.HS12Products) {
    earliestYear = earliestHs12Year;
    latestYear = latestHs12ProductYear;
  } else if (productClass === ProductClass.SITCProducts) {
    earliestYear = earliestSitcYear;
    latestYear = latestProductYear;
  } else {
    // This will never execute
    earliestYear = earliestHs92Year;
    latestYear = latestProductYear;
  }

  return { startYear: earliestYear, endYear: latestYear };
};

export const getLocationQualifiers = ({ exporter, importer }: any) => {
  let exporterLocationLevel = getLocationLevelFromStringLocationId(exporter);
  let importerLocationLevel = getLocationLevelFromStringLocationId(importer);
  let exporterIsUndefined = !exporter;
  let exporterIsCountry = exporterLocationLevel === LocationLevel.Country;
  let exporterIsGroup = exporterLocationLevel === LocationLevel.Group;
  let exporterIsWorld = exporter === worldGroupDatum.groupId;
  let importerIsUndefined = !importer;
  let importerIsCountry = importerLocationLevel === LocationLevel.Country;
  let importerIsGroup = importerLocationLevel === LocationLevel.Group;
  let importerIsWorld = importer === worldGroupDatum.groupId;

  return {
    exporterLocationLevel,
    importerLocationLevel,
    exporterIsUndefined,
    exporterIsCountry,
    exporterIsGroup,
    exporterIsWorld,
    importerIsUndefined,
    importerIsCountry,
    importerIsGroup,
    importerIsWorld,
  };
};

export const sortByLocationName = (a: any, b: any) => {
  const { nameEn: nameEnA, groupName: groupNameA } = a;
  const { nameEn: nameEnB, groupName: groupNameB } = b;
  let useNameA, useNameB;
  if (nameEnA) {
    useNameA = nameEnA;
  } else if (groupNameA) {
    useNameA = groupNameA;
  } else {
    useNameA = undefined;
  }

  if (nameEnB) {
    useNameB = nameEnB;
  } else if (groupNameB) {
    useNameB = groupNameB;
  } else {
    useNameB = undefined;
  }

  const upperCaseNameA = useNameA ? useNameA.toUpperCase() : undefined;
  const upperCaseNameB = useNameB ? useNameB.toUpperCase() : undefined;
  if (upperCaseNameA < upperCaseNameB) {
    return -1;
  }
  if (upperCaseNameA > upperCaseNameB) {
    return 1;
  }

  return 0;
};

export const sortByProductCode = (a: any, b: any) => {
  const productCodeA = a.productCode;
  const productCodeB = b.productCode;
  if (productCodeA < productCodeB) {
    return -1;
  }
  if (productCodeA > productCodeB) {
    return 1;
  }
  return 0;
};

export const determineAPICallLocationOrder = ({ variables }: any) => {
  let { countryId, partnerCountryId, groupId, partnerGroupId } = variables;
  let locationForAPI: string | undefined = undefined;
  let partnerForAPI: string | undefined = undefined;

  // In the following lines, we need to recover the location string prefix
  // for countries and groups; otherwise, we'll be storing Ints with unknown prefixes
  // in the Redux store
  if (countryId !== undefined) {
    locationForAPI = `${LocationStringPrefixes.Country}-${countryId}`;
  } else if (groupId !== undefined) {
    locationForAPI = `${LocationStringPrefixes.Group}-${groupId}`;
  }

  if (partnerCountryId !== undefined) {
    partnerForAPI = `${LocationStringPrefixes.Country}-${partnerCountryId}`;
  } else if (partnerGroupId !== undefined) {
    partnerForAPI = `${LocationStringPrefixes.Group}-${partnerGroupId}`;
  }

  return { locationForAPI, partnerForAPI };
};

export const defaultCountryPool = [
  "country-840", // US
  "country-826", // UK
  "country-392", // Japan
  "country-156", // China,
  "country-702", // Singapore
  "country-124", // Canada
  "country-484", // Mexico
  "country-170", // Colombia
  "country-250", // France
  "country-643", // Russia,
  "country-356", // India,
  "country-76", // Brazil,
  "country-792", // Turkey
  "country-276", // Germany,
  "country-704", // Vietnam,
  "country-288", // Ghana,
  "country-36", // Australia,
  "country-724", // Spain,
  "country-400", // Jordan,
  "country-682", // Saudi Arabia,
];

export const generateRandomCountrySelection = () => {
  let randomlySelectedCountry =
    defaultCountryPool[random(defaultCountryPool.length - 1)];
  return randomlySelectedCountry;
};

export enum MetadataMergeType {
  Region = "region",
  Sector = "sector",
}
export const mergeDataWithTopLevelParent = ({
  data,
  mergeType,
  regionsMetadata,
  productsMetadata,
}: any) => {
  let dataMergedWithTopLevelParent;
  if (mergeType === MetadataMergeType.Region && regionsMetadata !== undefined) {
    dataMergedWithTopLevelParent = data
      .map((datum: any) => {
        const partnerId = datum.partnerCountryId;
        let findMatchingMetadata = regionsMetadata.find((region: any) =>
          region.members.includes(partnerId),
        );
        let topLevelParent;
        if (findMatchingMetadata) {
          topLevelParent = findMatchingMetadata.groupId;
        } else {
          topLevelParent = undefined;
        }

        return {
          ...datum,
          topLevelParent,
        };
      })
      .filter((datum: any) => datum.topLevelParent !== undefined); // Filter out data without matching region assignment
  } else if (
    mergeType === MetadataMergeType.Sector &&
    productsMetadata !== undefined
  ) {
    const productIdLookup = index(productsMetadata, (d) => d.productId) as any;
    dataMergedWithTopLevelParent = data.map((datum: any) => {
      const productId = datum.productId;
      let findMatchingMetadata = productIdLookup.get(productId);
      let topLevelParent;
      if (findMatchingMetadata) {
        topLevelParent = findMatchingMetadata.topParent.productId;
      } else {
        topLevelParent = undefined;
      }

      return {
        ...datum,
        topLevelParent,
      };
    });
  } else {
    dataMergedWithTopLevelParent = data;
  }

  return dataMergedWithTopLevelParent;
};

export const computeMonetaryValueByTradeFlow = ({
  datum,
  tradeFlow,
  tradeDirection,
}: any) => {
  let monetaryValue: number | undefined;
  if (tradeFlow === TradeFlow.Gross) {
    if (tradeDirection === TradeDirection.Exports) {
      monetaryValue = +datum[TradeDirectionSelectorString.Exports];
    } else if (tradeDirection === TradeDirection.Imports) {
      monetaryValue = +datum[TradeDirectionSelectorString.Imports];
    } else {
      // This will never run
      monetaryValue = undefined;
    }
  } else if (tradeFlow === TradeFlow.Net) {
    if (tradeDirection === TradeDirection.Exports) {
      monetaryValue =
        Number(datum[TradeDirectionSelectorString.Exports]) -
        Number(datum[TradeDirectionSelectorString.Imports]);
    } else if (tradeDirection === TradeDirection.Imports) {
      monetaryValue =
        Number(datum[TradeDirectionSelectorString.Imports]) -
        Number(datum[TradeDirectionSelectorString.Exports]);
    } else {
      monetaryValue = undefined;
    }
    if (monetaryValue < 0) {
      monetaryValue = 0;
    }
  } else {
    // This will never execute;
  }

  return monetaryValue;
};

export const computeTotalSumByTradeFlow = ({
  data,
  tradeFlow,
  tradeDirection,
  scalingOption,
}: any) => {
  let totalSum: number | undefined;
  if (tradeFlow === TradeFlow.Gross) {
    if (tradeDirection === TradeDirection.Exports) {
      if (scalingOption) {
        totalSum = sum(
          data,
          (d: any) => +d[scalingOption][TradeDirectionSelectorString.Exports],
        );
      } else {
        totalSum = sum(
          data,
          (d: any) => +d[TradeDirectionSelectorString.Exports],
        );
      }
    } else if (tradeDirection === TradeDirection.Imports) {
      if (scalingOption) {
        totalSum = sum(
          data,
          (d: any) => +d[scalingOption][TradeDirectionSelectorString.Imports],
        );
      } else {
        totalSum = sum(
          data,
          (d: any) => +d[TradeDirectionSelectorString.Imports],
        );
      }
    } else {
      totalSum = undefined;
    }
  } else if (tradeFlow === TradeFlow.Net) {
    totalSum = sum(data, (d: any) => {
      let difference;
      if (tradeDirection === TradeDirection.Exports) {
        if (scalingOption) {
          difference =
            Number(d[scalingOption][TradeDirectionSelectorString.Exports]) -
            Number(d[scalingOption][TradeDirectionSelectorString.Imports]);
        } else {
          difference =
            Number(d[TradeDirectionSelectorString.Exports]) -
            Number(d[TradeDirectionSelectorString.Imports]);
        }
      } else if (tradeDirection === TradeDirection.Imports) {
        if (scalingOption) {
          difference =
            Number(d[scalingOption][TradeDirectionSelectorString.Imports]) -
            Number(d[scalingOption][TradeDirectionSelectorString.Exports]);
        } else {
          difference =
            Number(d[TradeDirectionSelectorString.Imports]) -
            Number(d[TradeDirectionSelectorString.Exports]);
        }
      } else {
        difference = 0;
      }

      return difference > 0 ? difference : 0;
    });
  } else {
    // This will never execute;
  }
  return totalSum;
};

export enum VizClassification {
  Trade = "tradeViz",
  Complexity = "complexityViz",
}
export const getVizClassification = ({ vizType }: { vizType: VizType }) => {
  let vizClassification;
  if (vizType === VizType.Feasibility || vizType === VizType.ProductSpace) {
    vizClassification = VizClassification.Complexity;
  } else {
    vizClassification = VizClassification.Trade;
  }
  return vizClassification;
};

export const getCategoriesForChartLegend = ({
  view,
  productClass,
  clusters,
  currentColorBySelection,
  rcaThreshold,
}: any) => {
  let categoriesForLegend: CategoryDatum[] = [];

  if (currentColorBySelection === ColorBy.Green) {
    categoriesForLegend = getLegendCategories(rcaThreshold);
  } else if (view === UIView.Markets) {
    categoriesForLegend = [...groupedRegionColorMap.entries()].map(
      (group: any) => ({
        id: group[0],
        color: group[1],
        name: groupNames.get(group[0]),
      }),
    );
  } else if (view === UIView.Products) {
    if (productClass === ProductClass.HS92Products) {
      if (clusters) {
        categoriesForLegend = [...clusterColorsMap.entries()].map(
          (group: any) => ({
            id: group[0],
            color: group[1],
            name: group[0].split(" and ").join(" "),
          }),
        );
      } else {
        categoriesForLegend = [...hs92ColorsMap.entries()]
          .map((group: any) => ({
            id: group[0],
            color: group[1],
            name: hs92Names.get(group[0]),
          }))
          .sort((a: any, b: any) => {
            if (
              a.id === hs92ServicesCategory ||
              b.id === hs92ServicesCategory
            ) {
              return -1;
            } else {
              return 0;
            }
          });
      }
    } else if (productClass === ProductClass.HS12Products) {
      categoriesForLegend = [...hs12ColorsMap.entries()]
        .map((group: any) => ({
          id: group[0],
          color: group[1],
          name: hs12Names.get(group[0]),
        }))
        .sort((a: any, b: any) => {
          if (a.id === hs12ServicesCategory || b.id === hs12ServicesCategory) {
            return -1;
          } else {
            return 0;
          }
        });
    } else if (productClass === ProductClass.SITCProducts) {
      categoriesForLegend = [...sitcColorsMap.entries()]
        .map((group: any) => ({
          id: group[0],
          color: group[1],
          name: sitcNames.get(group[0]),
        }))
        .sort((a: any, b: any) => {
          if (a.id === sitcServicesCategory || b.id === sitcServicesCategory) {
            return -1;
          } else {
            return 0;
          }
        });
    }
  }

  return categoriesForLegend;
};
