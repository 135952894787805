import React, { useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";

export const primaryColor1 = "#5065a8";
export const overlayPortalContainerId = "overlay-portal-container";

const backgroundColor = "#67a3ae";

//#region Styling
const RootBase = styled.span`
  border-bottom-style: dotted;
  border-bottom-width: 2px;
  cursor: help;
  pointer-events: auto;
`;
const StandardRoot = styled(RootBase)`
  border-bottom-color: ${primaryColor1};
`;
const PolicyRoot = styled(RootBase)`
  border-bottom-color: inherit;
`;
const TooltipBase = styled.div`
  position: fixed;
  z-index: 3000;
  width: 18rem;
  font-size: 0.95rem;
  line-height: 1.4;
  text-transform: none;
  padding: 0.8rem;
  opacity: 0;
  transition: opacity 0.15s ease;
`;
const StandardTooltip = styled(TooltipBase)`
  color: #fff;
  background-color: ${backgroundColor};
`;
const PolicyTooltip = styled(TooltipBase)`
  background-color: #fff;
  border-style: solid;
  border-width: 2px;
`;
//#endregion

export enum TooltipAlign {
  Standard,
  YAxis,
}

export enum TooltipType {
  Standard,
  Policy,
}

interface BaseProps {
  term: any;
  explanation: any;
  citation: any;
  align: any;
  attachment: any;
}

type Props = BaseProps &
  (
    | {
        type: TooltipType.Standard;
      }
    | {
        type: TooltipType.Policy;
        color: string;
      }
  );

const GlossaryTerm = (props: Props) => {
  const { term, explanation, citation, type, align, attachment } = props;
  const rootEl = useRef<HTMLDivElement | null>(null);
  const tooltipEl = useRef<HTMLDivElement | null>(null);
  const overlayPortalContainerNodeRef = useRef<HTMLElement | null>(null);

  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);

  useLayoutEffect(() => {
    const node = document.querySelector<HTMLElement>(
      `#${overlayPortalContainerId}`,
    );
    overlayPortalContainerNodeRef.current = node;
    const tooltipElm = tooltipEl.current;
    const rootElm = rootEl.current;
    if (tooltipElm !== null && rootElm !== null) {
      const { top, left } = rootElm.getBoundingClientRect();
      const tooltipSpacing = 5;
      const tooltipHeight = tooltipElm.offsetHeight;
      const tooltipWidth = tooltipElm.offsetWidth;
      let tooltipTopValue = top + rootElm.offsetHeight + tooltipSpacing;
      let tooltipLeftValue = left;
      if (tooltipTopValue + tooltipHeight > window.innerHeight) {
        // tooltip will exceed the windows height
        tooltipTopValue = top - tooltipSpacing - tooltipHeight;
      }
      if (left + tooltipWidth > window.innerWidth) {
        // tooltip will exceed the windows width
        tooltipLeftValue = window.innerWidth - tooltipWidth - tooltipSpacing;
      }
      const tooltipColors =
        props.type === TooltipType.Policy
          ? `
          color: ${props.color};
          borderColor: ${props.color};
        `
          : "";
      tooltipElm.style.cssText = `
        left: ${tooltipLeftValue}px;
        top: ${tooltipTopValue}px;
        opacity: 1;
        ${tooltipColors}
      `;
    }
  }, [isTooltipShown]);
  const overlayPortalContainerNode = overlayPortalContainerNodeRef.current;

  const rootStyles: React.CSSProperties =
    align === TooltipAlign.YAxis
      ? {
          borderBottom: "none",
          borderLeft: `2px dotted ${primaryColor1}`,
        }
      : {};
  const Tooltip =
    type === TooltipType.Standard ? StandardTooltip : PolicyTooltip;
  const citationElm = citation !== undefined ? <em>{citation}</em> : null;
  let tooltip: React.ReactPortal | null;
  if (isTooltipShown !== false && overlayPortalContainerNode !== null) {
    tooltip = ReactDOM.createPortal(
      <Tooltip ref={tooltipEl}>
        {explanation} {citationElm}
        {attachment}
      </Tooltip>,
      overlayPortalContainerNode,
    );
  } else {
    tooltip = null;
  }
  const Root = type === TooltipType.Standard ? StandardRoot : PolicyRoot;
  return (
    <Root
      onMouseEnter={() => setIsTooltipShown(true)}
      onMouseLeave={() => setIsTooltipShown(false)}
      style={rootStyles}
      ref={rootEl}
    >
      {term}
      {tooltip}
    </Root>
  );
};

export default GlossaryTerm;
