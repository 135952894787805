import { useCallback, useState } from "react";
import { saveAs } from "file-saver";
import DownloadIcon from "../../assets/images/ui/download.svg";
import DataDownloadIcon from "../../assets/images/dataDownload.svg";
import ImageDownloadIcon from "../../assets/images/imageDownload.svg";
import {
  OptionIcon,
  OptionLabel,
  OptionsListItem,
} from "../navigationBar/Components";
import { Modal, Box, Button } from "@mui/material";
import { modalStyle } from "./GuideModalButton";
import { useDownload } from "../../visualization/DownloadContext";

const DownloadModalButton = ({
  menuOptionClassName,
  isNavigationBarOpen,
}: any) => {
  const [isDownloadOpen, setIsDownloadOpen] = useState<boolean>(false);
  const { svgRef, dataRef, canvasRef, titleRef } = useDownload();
  const toggleDownload = () => {
    setIsDownloadOpen(!isDownloadOpen);
  };

  const closeModal = () => {
    setIsDownloadOpen(false);
  };

  const handleImageDownload = useCallback(() => {
    const titleString = `${titleRef.current
      ?.trim()
      ?.split(/[^a-zA-Z0-9]+/)
      .filter(Boolean)
      ?.join("-")}`;
    if (svgRef.current) {
      const svgData = new XMLSerializer().serializeToString(svgRef.current);
      const svgBlob = new Blob([svgData], {
        type: "image/svg+xml;charset=utf-8",
      });
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            if (blob) {
              saveAs(blob, `${titleString}.png`);
            }
          });
        };
        img.src = e.target.result as string;
      };
      reader.readAsDataURL(svgBlob);
    } else if (canvasRef.current) {
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `${titleString}.png`);
        }
      });
    }
  }, [titleRef, svgRef, canvasRef]);

  const handleDataDownload = useCallback(() => {
    const titleString = `${titleRef.current
      ?.trim()
      ?.split(/[^a-zA-Z0-9]+/)
      ?.join("-")}`;

    if (dataRef.current && dataRef.current.length > 0) {
      const headers = Object.keys(dataRef.current[0]);
      const csvContent = [
        headers.join(","),
        ...dataRef.current.map((row) =>
          headers.map((header) => JSON.stringify(row[header])).join(","),
        ),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `${titleString}.csv`);
    } else {
      console.error("No data available for download");
    }
  }, [dataRef, titleRef]);

  return (
    <>
      <OptionsListItem className={menuOptionClassName} onClick={toggleDownload}>
        <OptionIcon>
          <img src={DownloadIcon} alt={"Download"} />
        </OptionIcon>
        {isNavigationBarOpen === true && <OptionLabel>Download</OptionLabel>}
      </OptionsListItem>
      <Modal open={isDownloadOpen} onClose={closeModal}>
        <Box sx={{ ...modalStyle, width: "auto", maxWidth: "600px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              width: "100%",
              mb: 2,
            }}
          >
            <Button
              onClick={handleImageDownload}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 3,
                backgroundColor: "#E3EAF0",
                color: "black",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#CCD7E0",
                },
              }}
            >
              <img
                src={ImageDownloadIcon}
                alt="Download Image"
                style={{ width: "40px", height: "40px", marginBottom: "10px" }}
              />
              Download Image
            </Button>
            <Button
              onClick={handleDataDownload}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 3,
                backgroundColor: "#E3EAF0",
                color: "black",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#CCD7E0",
                },
              }}
            >
              <img
                src={DataDownloadIcon}
                alt="Download Data"
                style={{ width: "40px", height: "40px", marginBottom: "10px" }}
              />
              Download Data
            </Button>
          </Box>
          <Button
            sx={{
              mt: 2,
              backgroundColor: "lightgray",
              color: "black",
              "&:hover": {
                backgroundColor: "#E0E0E0",
              },
            }}
            onClick={closeModal}
          >
            CLOSE
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default DownloadModalButton;
