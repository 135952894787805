import { getNumericIdFromStringLocationId } from "../sharedUtilities/Utils";
import { UIView, getLocationQualifiers } from "../visualization/Utils";
import { worldGroupDatum } from "./queries/getLocationsMetadata";
/* Start and end years for product classes */
export const earliestSitcYear = 1962;
export const earliestHs92Year = 1995;
export const earliestHs12Year = 2012;
export const latestProductYear = 2022;
export const latestHs12ProductYear = 2022;

export const mapQueryArgumentsToAPIEndpointInputs = ({
  exporter,
  importer,
  view,
}: any) => {
  const {
    exporterIsUndefined,
    exporterIsWorld,
    exporterIsGroup,
    exporterIsCountry,
    importerIsUndefined,
    importerIsWorld,
    importerIsGroup,
    importerIsCountry,
  } = getLocationQualifiers({ exporter, importer });
  let countryId: string | undefined = undefined;
  let partnerCountryId: string | undefined = undefined;
  let groupId: string | undefined = undefined;
  let partnerGroupId: string | undefined = undefined;

  if (view === UIView.Products) {
    if (!exporterIsUndefined && !importerIsUndefined) {
      if (!exporterIsWorld && !importerIsWorld) {
        // Both exporter and importer are defined; neither is World
        if (exporterIsCountry && importerIsCountry) {
          // Triggers: CCPY, get back P
          countryId = exporter;
          partnerCountryId = importer;
        } else if (exporterIsCountry && importerIsGroup) {
          // Triggers: CGPY, get back P
          countryId = exporter;
          partnerGroupId = importer;
        } else if (exporterIsGroup && importerIsCountry) {
          // Triggers: GCPY, get back P
          groupId = exporter;
          partnerCountryId = importer;
        } else if (exporterIsGroup && importerIsGroup) {
          // Triggers: GGPY, get back P
          groupId = exporter;
          partnerGroupId = importer;
        }
      } else if (exporterIsWorld && !importerIsWorld) {
        if (importerIsCountry) {
          // Triggers CPY, get back P (What did C import in Y?)
          countryId = importer;
        } else if (importerIsGroup) {
          // Triggers: GGPY, get back P (What did G import in Y?)
          groupId = importer;
          partnerGroupId = worldGroupDatum.groupId;
        }
      } else if (!exporterIsWorld && importerIsWorld) {
        if (exporterIsCountry) {
          // Triggers CPY, get back P (What did C export in Y?)
          countryId = exporter;
        } else if (exporterIsGroup) {
          // Triggers GGPY, get back P (What did G export in Y?)
          groupId = exporter;
          partnerGroupId = worldGroupDatum.groupId;
        }
      } else if (exporterIsWorld && importerIsWorld) {
        // Triggers GGPY, get back P (What did all countries export to all countries in Y?)
        groupId = worldGroupDatum.groupId;
        partnerGroupId = worldGroupDatum.groupId;
      }
    } else if (!exporterIsUndefined && importerIsUndefined) {
      if (exporterIsWorld) {
        // Do nothing; we want to trigger the CPY endpoint, with Product (P) and Year (Y) defined
        // but countryId undefined
      } else if (exporterIsCountry) {
        // Triggering CPY endpoint for market share for countries
        countryId = exporter;
      } else if (exporterIsGroup) {
      }
    } else if (exporterIsUndefined && !importerIsUndefined) {
      if (importerIsWorld) {
        // Do nothing; we want to trigger the CPY endpoint, with Product (P) and Year (Y) defined
        // but countryId undefined
      }
    }
  } else if (view === UIView.Markets) {
    if (exporterIsWorld && !importerIsUndefined && !importerIsWorld) {
      if (importerIsCountry) {
        // Triggers: CCPY, get back C
        countryId = importer;
      } else if (importerIsGroup) {
        // Triggers: GCPY, get back C
        groupId = importer;
      }
    } else if (!exporterIsWorld && !exporterIsUndefined && importerIsWorld) {
      if (exporterIsCountry) {
        // Triggers: CCPY, get back C
        countryId = exporter;
      } else if (exporterIsGroup) {
        // Triggers GCPY, get back C
        groupId = exporter;
      }
    } else if (
      (exporterIsWorld && importerIsWorld) ||
      (exporterIsWorld && importerIsUndefined) ||
      (exporterIsUndefined && importerIsWorld)
    ) {
      // Triggers: CPY, what countries exported P?
      // Do nothing
      groupId = worldGroupDatum.groupId;
    }
  }

  return {
    countryId: countryId
      ? getNumericIdFromStringLocationId(countryId)
      : undefined,
    groupId: groupId ? getNumericIdFromStringLocationId(groupId) : undefined,
    partnerCountryId: partnerCountryId
      ? getNumericIdFromStringLocationId(partnerCountryId)
      : undefined,
    partnerGroupId: partnerGroupId
      ? getNumericIdFromStringLocationId(partnerGroupId)
      : undefined,
  };
};
