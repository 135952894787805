import SelectBox from "./SelectBoxPrimitive";
import YearDropdownItem from "./YearDropdownItem";
import {
  earliestHs92Year,
  earliestHs12Year,
  earliestSitcYear,
  latestProductYear,
  latestHs12ProductYear,
} from "../../../graphql/Utils";
import { range } from "d3";
import { ProductClass } from "../../../visualization/Utils";
import { memo } from "react";
import { usePageQueryParams } from "../../../visualization/defaultSettings";

export enum YearSelectionRole {
  StartYear = "StartYear",
  EndYear = "EndYear",
  FixedYear = "FixedYear",
}

interface YearSelectBoxInput {
  yearSelectionRole: YearSelectionRole;
}

const YearDropdown = ({ yearSelectionRole }: YearSelectBoxInput) => {
  const [
    {
      year: currentYear,
      productClass: currentProductClass,
      startYear: currentStartYear,
      endYear: currentEndYear,
    },
    setQuery,
  ] = usePageQueryParams();

  let dispatchAction;
  if (yearSelectionRole === YearSelectionRole.FixedYear) {
    dispatchAction = ({ value }: { value: string }) => {
      setQuery({ year: parseFloat(value) });
    };
  } else if (yearSelectionRole === YearSelectionRole.StartYear) {
    dispatchAction = ({ value }: { value: string }) => {
      setQuery({ startYear: parseFloat(value) });
    };
  } else if (yearSelectionRole === YearSelectionRole.EndYear) {
    dispatchAction = ({ value }: { value: string }) => {
      setQuery({ endYear: parseFloat(value) });
    };
  }

  let yearRangeStart;
  let yearRangeEnd;
  if (currentProductClass === ProductClass.HS92Products) {
    yearRangeStart = earliestHs92Year;
    yearRangeEnd = latestProductYear;
  } else if (currentProductClass === ProductClass.HS12Products) {
    yearRangeStart = earliestHs12Year;
    yearRangeEnd = latestHs12ProductYear;
  } else if (currentProductClass === ProductClass.SITCProducts) {
    yearRangeStart = earliestSitcYear;
    yearRangeEnd = latestProductYear;
  } else {
    // This will never run
    yearRangeStart = earliestHs92Year;
    yearRangeEnd = latestProductYear;
  }

  const items = range(yearRangeStart, yearRangeEnd + 1, 1)
    .map((year: number) => {
      return {
        label: String(year),
        value: year,
      };
    })
    .reverse(); // Reversing here so that year values are in descending order

  let matchSelectedItem: any = undefined;
  let valueToMatch: number | undefined;
  if (yearSelectionRole === YearSelectionRole.FixedYear) {
    valueToMatch = Number(currentYear);
  } else if (yearSelectionRole === YearSelectionRole.StartYear) {
    valueToMatch = Number(currentStartYear);
  } else if (yearSelectionRole === YearSelectionRole.EndYear) {
    valueToMatch = Number(currentEndYear);
  } else {
    valueToMatch = undefined;
  }

  if (valueToMatch) {
    matchSelectedItem = items.find((item) => item.value === valueToMatch);
  }

  return (
    <>
      <SelectBox
        items={items}
        hashFunction={(item: any) => item.value}
        placeholder="Please select a location"
        ItemRenderComponent={YearDropdownItem}
        onChangeEvent={dispatchAction}
        selectedValueFromStore={matchSelectedItem}
      />
    </>
  );
};

export default memo(YearDropdown);
