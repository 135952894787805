import React from "react";
import Diamond, { Fill } from "./Diamond";

export enum DecileClassification {
  Top = "Top",
  Second = "Second",
  Third = "Third",
  Fourth = "Fourth",
  Fifth = "Fifth",
  Sixth = "Sixth",
  Seventh = "Seventh",
  Eighth = "Eighth",
  Ninth = "Ninth",
  Last = "Last",
  Empty = "Empty",
}

const decileToNumericRating: Record<DecileClassification, number> = {
  [DecileClassification.Top]: 5,
  [DecileClassification.Ninth]: 4.5,
  [DecileClassification.Eighth]: 4,
  [DecileClassification.Seventh]: 3.5,
  [DecileClassification.Sixth]: 3,
  [DecileClassification.Fifth]: 2.5,
  [DecileClassification.Fourth]: 2,
  [DecileClassification.Third]: 1.5,
  [DecileClassification.Second]: 1,
  [DecileClassification.Last]: 0.5,
  [DecileClassification.Empty]: 0,
};

interface Props {
  decile: DecileClassification;
}

const maxRating = 5;

const StarRating = (props: Props) => {
  const { decile } = props;
  const rating = decileToNumericRating[decile];

  // https://stackoverflow.com/a/6138087/7075699
  const roundedRating = Math.round(rating * 2) / 2;

  if (roundedRating > maxRating) {
    throw new Error("rating must be between 0 and 5");
  }
  const numWhollyFilledDiamonds = Math.floor(roundedRating);
  const isAnyDiamondHalfFilled = roundedRating - numWhollyFilledDiamonds > 0;
  const numHalfFilledDiamonds = isAnyDiamondHalfFilled === true ? 1 : 0;
  const numUnfilledDiamonds =
    maxRating - numWhollyFilledDiamonds - numHalfFilledDiamonds;

  const diamonds: Array<React.ReactElement<any>> = [];
  for (let i = 0; i < numWhollyFilledDiamonds; i += 1) {
    diamonds.push(<Diamond fill={Fill.Full} key={`filled-${i}`} />);
  }
  if (isAnyDiamondHalfFilled === true) {
    diamonds.push(<Diamond fill={Fill.Half} key={`half`} />);
  }
  for (let i = 0; i < numUnfilledDiamonds; i += 1) {
    diamonds.push(<Diamond fill={Fill.None} key={`none-${i}`} />);
  }
  return <>{diamonds}</>;
};

export default StarRating;
