import { UIView } from "../../../visualization/Utils";
import SearchBoxPrimitive from "./SearchBoxPrimitive";
import FindInVizDropdownItem from "./FindInVizDropdownItem";
import { usePageQueryParams } from "../../../visualization/defaultSettings";
import { memo } from "react";

const FindInVizDropdown = ({
  findInVizOptions,
  currentHighlightedItem,
  setHighlightedItem,
  setHovered,
  setOpen,
}: any) => {
  const [{ view: currentUiView }] = usePageQueryParams();

  const onChangeEvent = ({ id }: { id: string | undefined }) => {
    setHighlightedItem(id);
    if (id === undefined) {
      setHighlightedItem(undefined);
      setHovered(false);
      setOpen(false);
    }
  };

  let placeholderText;
  if (currentUiView === UIView.Markets) {
    placeholderText = "Find location in graph";
  } else if (currentUiView === UIView.Products) {
    placeholderText = "Find product in graph";
  } else {
    placeholderText = "Find in graph";
  }

  const matchSelectedItem = currentHighlightedItem
    ? findInVizOptions.find(
        (option: any) =>
          option.id === currentHighlightedItem ||
          option.productId === currentHighlightedItem,
      )
    : undefined;

  return (
    <SearchBoxPrimitive
      items={findInVizOptions}
      hashFunction={(item: any) => item.label}
      placeholder={placeholderText}
      ItemRenderComponent={FindInVizDropdownItem}
      onChangeEvent={onChangeEvent}
      selectedValue={matchSelectedItem}
    />
  );
};

export default memo(FindInVizDropdown);
