import styled from "@emotion/styled";
import { stratify, treemap, treemapSquarify } from "d3";
import {
  getLocationLevelFromStringLocationId,
  getProductClassServicesCategoryId,
} from "../../../sharedUtilities/Utils";
import {
  ColorBy,
  MetadataMergeType,
  ProductClass,
  ProductLevel,
  TradeDirection,
  UIView,
  getColorMap,
  getLocationQualifiers,
  mergeDataWithTopLevelParent,
  sortByProductCode,
} from "../../Utils";
import TreemapCell from "./TreemapCell";
import TreemapCellLabel from "./TreemapCellLabel";
import useFetchMetadata, {
  MetadataFetchType,
  MetadataFetchStatus,
} from "../../../sharedUtilities/useFetchMetadata";
import transformProducts from "./transformProducts";
import transformLocations from "./transformLocations";
import {
  getTradeDirectionSelector,
  computeMonetaryValueByTradeFlow,
  computeTotalSumByTradeFlow,
} from "../../Utils";
import { memo, useContext, useEffect, useMemo } from "react";
import complexityColorScale from "./complexityColorScale";
import { sortByLocationName } from "../../Utils";
import { LocationDetailLevel } from "../../Utils";
import { ChartContainerSizeContext } from "../../components/GenericResizeContainer";
import GraphNotice, { GraphNoticeType } from "../../components/GraphNotice";
import { isEqual } from "lodash-es";
import GraphLoading from "../../components/GraphLoading";
import { useDownload } from "../../DownloadContext";
import { formatNumberAsUSD } from "../../../sharedUtilities/formatNumbers";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
`;

const SvgAndLabelsContainer = styled.div`
  position: relative;
  margin: 0px;
  padding: 0px;

  & svg {
    margin: 0px;
    padding: 0px;
  }
`;

const mergeProductDataWithPci = ({ data, complexityData }: any) => {
  let dataMergedWithPci = data.map((product: any) => {
    let matchingComplexityData = complexityData.find(
      (pciProduct: any) => pciProduct.productId === product.productId,
    );
    let pci: number | undefined;
    if (matchingComplexityData) {
      pci = matchingComplexityData.pci;
    } else {
      pci = undefined;
    }

    return {
      ...product,
      pci,
    };
  });

  return dataMergedWithPci;
};

/* The tiling method to be used for treemap rendering */
const tilingMethod = treemapSquarify.ratio(1);

const Chart = ({
  inputData,
  hiddenCategories,
  handleTooltip,
  setTotalValue,
  setFindInVizOptions,
  highlightedItem,
  tradeDirection,
  queryVariables,
  setGraphLoadingElement,
  findInVizOptions: fivO,
}: any) => {
  const { dataRef } = useDownload();
  const {
    tradeFlow,
    currentColorBy: currentColorBySelection,
    view,
    exporter,
    importer,
    year,
    productClass,
    productLevel,
    currentLocationDetailLevel,
  } = queryVariables;

  let tradeDirectionSelector: string = getTradeDirectionSelector({
    tradeDirection,
  });

  let { chartWidth, chartHeight } = useContext(ChartContainerSizeContext);

  const useColorMap = useMemo(() => {
    if (
      view === UIView.Products &&
      productLevel === ProductLevel.Product4digit &&
      currentColorBySelection === ColorBy.Complexity
    ) {
      return complexityColorScale;
    } else {
      return getColorMap({ view, productClass });
    }
  }, [view, productLevel, currentColorBySelection, productClass]);
  const { svgRef } = useDownload();

  const {
    metadataStatus: locationMetadataStatus,
    metadata: locationMetadata,
    error: locationError,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.Location });
  const {
    metadataStatus: productsHs92MetadataStatus,
    metadata: productsHs92Metadata,
    error: productsHs92Error,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.ProductsHs92 });
  const {
    metadataStatus: productsHs12MetadataStatus,
    metadata: productsHs12Metadata,
    error: productsHs12Error,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.ProductsHs12 });

  const {
    metadataStatus: productsSitcMetadataStatus,
    metadata: productsSitcMetadata,
    error: productsSitcError,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.ProductsSitc });
  const {
    metadataStatus: productComplexityMetadataStatus,
    metadata: productComplexityMetadata,
    error: productComplexityError,
  } = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductComplexity,
    yearRange: { yearMin: year, yearMax: year },
    productClass: productClass as any,
  });
  const {
    root,
    servicesRoot,
    nonServicesRoot,
    totalSum,
    locationHasServices,
    servicesProportion,
    nonServicesProportion,
    noDataToDisplay,
    findInVizOptions,
    products,
  } = useMemo(() => {
    let root: any = undefined;
    let servicesRoot: any;
    let nonServicesRoot: any;
    let totalSum: number | undefined = undefined;
    let locationHasServices: boolean | undefined = undefined;
    let servicesProportion: number | undefined = undefined;
    let nonServicesProportion: number | undefined = 1;
    let noDataToDisplay;
    let findInVizOptions: any[] | undefined = undefined;
    let products: any[] | undefined;

    if (view === UIView.Products) {
      let { data } = inputData;
      let productComplexity: any[] | undefined;
      if (
        productClass === ProductClass.HS92Products &&
        productsHs92MetadataStatus === MetadataFetchStatus.Success
      ) {
        let { section, twoDigit, fourDigit, sixDigit } = productsHs92Metadata;
        products = [...section, ...twoDigit, ...fourDigit, ...sixDigit];
      } else if (
        productClass === ProductClass.HS12Products &&
        productsHs12MetadataStatus === MetadataFetchStatus.Success
      ) {
        let { section, twoDigit, fourDigit, sixDigit } = productsHs12Metadata;
        products = [...section, ...twoDigit, ...fourDigit, ...sixDigit];
      } else if (
        productClass === ProductClass.SITCProducts &&
        productsSitcMetadataStatus === MetadataFetchStatus.Success
      ) {
        let { section, twoDigit, fourDigit } = productsSitcMetadata;
        products = [...section, ...twoDigit, ...fourDigit];
      } else {
        products = undefined;
      }

      // For 4-digit products, we also need product complexity metadata
      if (
        productLevel === ProductLevel.Product4digit &&
        productComplexityMetadataStatus === MetadataFetchStatus.Success
      ) {
        productComplexity = productComplexityMetadata;
      } else {
        productComplexity = undefined;
      }

      let servicesProductId = getProductClassServicesCategoryId({
        productClass: productClass as any,
      });

      let dataWithRoot;
      let servicesDataWithRoot;
      let nonServicesDataWithRoot;
      let servicesTotal;

      if (products !== undefined) {
        let dataWithTopLevelProductId = mergeDataWithTopLevelParent({
          data,
          mergeType: MetadataMergeType.Sector,
          productsMetadata: products,
        });

        let filteredData = dataWithTopLevelProductId.filter((d: any) => {
          const topLevelParent = d.topLevelParent;
          let monetaryValue = computeMonetaryValueByTradeFlow({
            datum: d,
            tradeFlow,
            tradeDirection,
          });

          if (!monetaryValue) {
            // Filter out data with trade value <= 0, to make sure
            // we aren't drawing cells and labels for them
            return false;
          } else {
            return !hiddenCategories.includes(topLevelParent);
          }
        });
        let servicesData = filteredData.filter(
          (d: any) => d.topLevelParent === servicesProductId,
        );
        let nonServicesData = filteredData.filter(
          (d: any) => d.topLevelParent !== servicesProductId,
        );
        locationHasServices = servicesData.length > 0 ? true : false;
        servicesTotal = locationHasServices
          ? computeTotalSumByTradeFlow({
              data: servicesData,
              tradeFlow,
              tradeDirection,
              tradeDirectionSelector,
            })
          : 0;
        totalSum =
          filteredData.length > 0
            ? computeTotalSumByTradeFlow({
                data: filteredData,
                tradeFlow,
                tradeDirection,
                tradeDirectionSelector,
              })
            : 0;

        if (productLevel === ProductLevel.Product4digit) {
          if (productComplexity) {
            let filteredDataMergedWithPci = mergeProductDataWithPci({
              data: filteredData,
              complexityData: productComplexity,
            });
            let servicesDataMergedWithPci = mergeProductDataWithPci({
              data: servicesData,
              complexityData: productComplexity,
            });
            let nonServicesDataMergedWithPci = mergeProductDataWithPci({
              data: nonServicesData,
              complexityData: productComplexity,
            });

            dataWithRoot = transformProducts({
              productLevel: productLevel,
              data: filteredDataMergedWithPci,
              products,
              totalValue: totalSum,
              tradeDirection,
              tradeFlow,
            });
            servicesDataWithRoot = transformProducts({
              productLevel: productLevel,
              data: servicesDataMergedWithPci,
              products,
              totalValue: totalSum,
              tradeDirection,
              tradeFlow,
            });
            nonServicesDataWithRoot = transformProducts({
              productLevel: productLevel,
              data: nonServicesDataMergedWithPci,
              products,
              totalValue: totalSum,
              tradeDirection,
              tradeFlow,
            });
          } else {
            dataWithRoot = transformProducts({
              productLevel: productLevel,
              data: filteredData,
              products,
              totalValue: totalSum,
              tradeDirection,
              tradeFlow,
            });
            servicesDataWithRoot = transformProducts({
              productLevel: productLevel,
              data: servicesData,
              products,
              totalValue: totalSum,
              tradeDirection,
              tradeFlow,
            });
            nonServicesDataWithRoot = transformProducts({
              productLevel: productLevel,
              data: nonServicesData,
              products,
              totalValue: totalSum,
              tradeDirection,
              tradeFlow,
            });
          }
        } else {
          dataWithRoot = transformProducts({
            productLevel: productLevel,
            data: filteredData,
            products,
            totalValue: totalSum,
            tradeDirection,
            tradeFlow,
          });
          servicesDataWithRoot = transformProducts({
            productLevel: productLevel,
            data: servicesData,
            products,
            totalValue: totalSum,
            tradeDirection,
            tradeFlow,
          });
          nonServicesDataWithRoot = transformProducts({
            productLevel: productLevel,
            data: nonServicesData,
            products,
            totalValue: totalSum,
            tradeDirection,
            tradeFlow,
          });
        }
        if (dataWithRoot) {
          servicesProportion =
            servicesTotal && totalSum ? servicesTotal / totalSum : 0;
          nonServicesProportion = 1 - servicesProportion;
          root = stratify()([{ id: "root", children: [] }]);
          try {
            root = stratify()
              .id((d: any) => d.productId)
              .parentId((d: any) => d.topLevelParent)(dataWithRoot);
          } catch {}

          root
            .sum((d: any) =>
              computeMonetaryValueByTradeFlow({
                datum: d,
                tradeFlow,
                tradeDirection,
                tradeDirectionSelector,
              }),
            )
            .sort((a: any, b: any) => {
              if (a.data.groupedProduct || b.data.groupedProduct) {
                return 1;
              } else {
                return a.value !== undefined && b.value !== undefined
                  ? b.value - a.value
                  : 0;
              }
            });

          const tree = treemap()
            .size([chartWidth, chartHeight])
            .tile(tilingMethod)(root);
          /* For services */
          servicesRoot = stratify()([{ id: "root" }]);
          try {
            servicesRoot = stratify()
              .id((d: any) => d.productId)
              .parentId((d: any) => d.topLevelParent)(servicesDataWithRoot);
          } catch {}

          servicesRoot
            .sum((d: any) =>
              computeMonetaryValueByTradeFlow({
                datum: d,
                tradeFlow,
                tradeDirection,
              }),
            )
            .sort((a: any, b: any) => {
              if (a.data.groupedProduct || b.data.groupedProduct) {
                return 1;
              } else {
                return a.value !== undefined && b.value !== undefined
                  ? b.value - a.value
                  : 0;
              }
            });

          treemap()
            .size([chartWidth * servicesProportion, chartHeight])
            .tile(tilingMethod)(servicesRoot);
          nonServicesRoot = stratify()([{ id: "root" }]);
          try {
            /* For non-services */
            nonServicesRoot = stratify()
              .id((d: any) => d.productId)
              .parentId((d: any) => d.topLevelParent)(nonServicesDataWithRoot);
          } catch {}

          nonServicesRoot
            .sum((d: any) =>
              computeMonetaryValueByTradeFlow({
                datum: d,
                tradeFlow,
                tradeDirection,
                tradeDirectionSelector,
              }),
            )
            .sort((a: any, b: any) => {
              if (a.data.groupedProduct || b.data.groupedProduct) {
                return 1;
              } else {
                return a.value !== undefined && b.value !== undefined
                  ? b.value - a.value
                  : 0;
              }
            });

          treemap()
            .size([chartWidth * nonServicesProportion, chartHeight])
            .tile(tilingMethod)(nonServicesRoot);
        }

        findInVizOptions = dataWithRoot || [];
      }
    } else if (view === UIView.Markets) {
      let { data } = inputData;
      let locations: any | undefined;
      if (locationMetadataStatus === MetadataFetchStatus.Success) {
        locations = locationMetadata;
      } else {
        locations = undefined;
      }

      if (locations) {
        const { regions, subregions, countries } = locations;

        const {
          exporterIsWorld,
          exporterIsUndefined,
          importerIsWorld,
          importerIsUndefined,
        } = getLocationQualifiers({ exporter, importer });
        let referenceLocation;
        if (!exporterIsUndefined && !importerIsUndefined) {
          if (!exporterIsWorld && importerIsWorld) {
            referenceLocation = exporter;
          } else if (!importerIsWorld && exporterIsWorld) {
            referenceLocation = importer;
          } else if (exporterIsWorld && importerIsWorld) {
            referenceLocation = exporter;
          }
        } else if (
          (exporterIsWorld && importerIsUndefined) ||
          (importerIsWorld && exporterIsUndefined)
        ) {
          if (exporterIsWorld) {
            referenceLocation = exporter;
          } else if (importerIsWorld) {
            referenceLocation = importer;
          }
        } else {
          // This will never run
          referenceLocation = exporter;
        }

        let dataWithTopLevelParentId = mergeDataWithTopLevelParent({
          data,
          mergeType: MetadataMergeType.Region,
          regionsMetadata: regions,
        });

        const referenceLocationLevel =
          getLocationLevelFromStringLocationId(referenceLocation);

        let filteredData = dataWithTopLevelParentId.filter((d: any) => {
          let monetaryValue = computeMonetaryValueByTradeFlow({
            datum: d,
            tradeFlow,
            tradeDirection,
            tradeDirectionSelector,
          });
          if (monetaryValue && monetaryValue <= 0) {
            // Filter out data with trade value <= 0, to make sure
            // we aren't drawing cells and labels for them
            return false;
          } else {
            if (hiddenCategories.includes(d.topLevelParent)) {
              return false;
            }

            return true;
          }
        });

        totalSum =
          filteredData.length > 0
            ? computeTotalSumByTradeFlow({
                data: filteredData,
                tradeFlow,
                tradeDirection,
                tradeDirectionSelector,
              })
            : 0;

        const dataWithRoot =
          transformLocations({
            view: view,
            data: filteredData,
            year,
            location: referenceLocation,
            currentLocationDetailLevel,
            regions,
            subregions,
            countries,
          }) || [];
        if (dataWithRoot) {
          root = stratify()([{ id: "root", children: [] }]);
          try {
            root = stratify()
              .id((d: any) => d.id)
              .parentId((d: any) => d.topLevelParent)(dataWithRoot);
          } catch {}
          root
            .sum((d: any) =>
              computeMonetaryValueByTradeFlow({
                datum: d,
                tradeFlow,
                tradeDirection,
                tradeDirectionSelector,
              }),
            )
            .sort((a: any, b: any) =>
              a.value !== undefined && b.value !== undefined
                ? b.value - a.value
                : 0,
            );

          const tree = treemap()
            .size([chartWidth, chartHeight])
            .tile(tilingMethod)(root);

          findInVizOptions = [...dataWithRoot];
        } else {
          noDataToDisplay = true;
        }
      }
    }
    return {
      root,
      servicesRoot,
      nonServicesRoot,
      totalSum,
      locationHasServices,
      servicesProportion,
      nonServicesProportion,
      noDataToDisplay,
      findInVizOptions,
      products,
    };
  }, [
    chartHeight,
    chartWidth,
    currentLocationDetailLevel,
    exporter,
    hiddenCategories,
    importer,
    inputData,
    locationMetadata,
    locationMetadataStatus,
    productClass,
    productComplexityMetadata,
    productComplexityMetadataStatus,
    productLevel,
    productsHs12Metadata,
    productsHs12MetadataStatus,
    productsHs92Metadata,
    productsHs92MetadataStatus,
    productsSitcMetadata,
    productsSitcMetadataStatus,
    tradeDirection,
    tradeDirectionSelector,
    tradeFlow,
    view,
    year,
  ]);

  // Update the total value for display
  useEffect(() => {
    if (totalSum) {
      setTotalValue(totalSum);
    }
  });
  useEffect(() => {
    let cleanedFindInVizOptions;
    if (findInVizOptions) {
      if (view === UIView.Markets) {
        cleanedFindInVizOptions = findInVizOptions
          .filter(
            (location: any) =>
              location.id && (location.nameEn || location.groupName),
          )
          .sort(sortByLocationName)
          .map((location: any) => {
            let { id, nameEn, groupName, countryIso3Code } = location;
            let label;
            if (nameEn) {
              if (countryIso3Code) {
                label = `${nameEn} (${countryIso3Code})`;
              } else {
                label = nameEn;
              }
            } else if (groupName) {
              label = groupName;
            } else {
              label = undefined;
            }
            return {
              id,
              label,
            };
          });
      } else if (view === UIView.Products) {
        cleanedFindInVizOptions = findInVizOptions
          .filter((product: any) => product.productId && product.nameEn)
          .sort(sortByProductCode)
          .map((product: any) => {
            let { productId, nameEn, productCode } = product;

            return {
              id: productId,
              label: productCode
                ? `${nameEn} (${productCode} ${productClass})`
                : nameEn,
            };
          });
      }
    } else {
      cleanedFindInVizOptions = undefined;
    }
    if (cleanedFindInVizOptions && !isEqual(cleanedFindInVizOptions, fivO)) {
      setFindInVizOptions(cleanedFindInVizOptions);
    }
  }, [findInVizOptions, fivO, productClass, setFindInVizOptions, view]);
  const filteredServicesRootLeaves = useMemo(() => {
    return (
      servicesRoot &&
      servicesRoot
        .leaves()
        .filter((leaf: any) => leaf.data.nameEn !== undefined)
    );
  }, [servicesRoot]);

  const filteredNonServicesRootLeaves = useMemo(() => {
    return (
      nonServicesRoot &&
      nonServicesRoot.leaves().filter((leaf: any) => leaf.data.nameEn)
    );
  }, [nonServicesRoot]);

  const filteredRootLeaves = useMemo(() => {
    if (view === UIView.Markets) {
      return root?.leaves().filter((leaf: any) => {
        return leaf.data.hybrid_level_1 && leaf.data.hybrid_level_2;
      });
    } else if (view === UIView.Products) {
      return root?.leaves().filter((leaf: any) => {
        return leaf.data.nameEn;
      });
    }
    return [];
  }, [root, view]);

  useEffect(() => {
    if (view === UIView.Products) {
      if (
        filteredServicesRootLeaves?.length &&
        filteredNonServicesRootLeaves?.length
      ) {
        dataRef.current = [
          ...filteredServicesRootLeaves,
          ...filteredNonServicesRootLeaves,
        ].map((d) => {
          const value =
            tradeDirection === TradeDirection.Exports
              ? d.data.exportValue
              : d.data.importValue;
          return {
            Name: d.data.nameEn,
            [`Gross ${tradeDirection === TradeDirection.Exports ? "Export" : "Import"}`]:
              formatNumberAsUSD(value),
            Code: d.data.productCode,
            Sector: products.find((p) => p.productId === d.data.sectorId)
              ?.nameShortEn,
            Share: `${(value / totalSum) * 100}%`,
          };
        });
      } else {
        dataRef.current = [];
      }
    } else if (view === UIView.Markets) {
      if (filteredRootLeaves?.length) {
        dataRef.current = [...filteredRootLeaves].map((d) => {
          const value =
            tradeDirection === TradeDirection.Exports
              ? d.data.exportValue
              : d.data.importValue;
          return {
            Name: d.data.nameEn,
            [`Gross ${tradeDirection === TradeDirection.Exports ? "Export" : "Import"}`]:
              formatNumberAsUSD(value),
            Share: `${(value / totalSum) * 100}%`,
          };
        });
      } else {
        dataRef.current = [];
      }
    }
  }, [
    filteredServicesRootLeaves,
    filteredNonServicesRootLeaves,
    dataRef,
    tradeDirection,
    products,
    view,
    filteredRootLeaves,
    totalSum,
  ]);

  const contentToRender = useMemo(() => {
    if (root === undefined && noDataToDisplay === true) {
      return <GraphNotice graphNoticeType={GraphNoticeType.NoData} />;
    } else if (view === UIView.Products) {
      return (
        <>
          <SvgAndLabelsContainer>
            <svg
              ref={svgRef}
              style={{ backgroundColor: "white" }}
              width={chartWidth}
              height={chartHeight}
            >
              {filteredServicesRootLeaves &&
                filteredServicesRootLeaves.map((leaf: any, i: number) => {
                  let cellKey = `cell_${leaf.id}`;

                  let useId;
                  if (view === UIView.Products) {
                    if (leaf.data.topLevelParent === "root") {
                      useId = leaf.data.sectorId;
                    } else {
                      useId = leaf.data.topLevelParent;
                    }
                  } else if (view === UIView.Markets) {
                    if (
                      currentLocationDetailLevel === LocationDetailLevel.region
                    ) {
                      useId = leaf.data.hybrid_level_1.id;
                    } else {
                      useId = leaf.data.topLevelParent;
                    }
                  }
                  return (
                    <TreemapCell
                      key={cellKey}
                      leaf={leaf}
                      handleTooltip={handleTooltip}
                      colorBy={currentColorBySelection}
                      colorMap={useColorMap}
                      useId={useId}
                      highlightedItem={highlightedItem}
                    />
                  );
                })}

              {filteredNonServicesRootLeaves &&
                filteredNonServicesRootLeaves.map((leaf: any, i: number) => {
                  let cellKey = `cell_${leaf.id}`;
                  let useId;
                  if (view === UIView.Products) {
                    if (
                      leaf.data.productId === undefined &&
                      leaf.data.productLevel === ProductLevel.ProductSection
                    ) {
                      useId = leaf.data.productId;
                    } else if (leaf.data.topLevelParent === "root") {
                      useId = leaf.data.sectorId;
                    } else {
                      useId = leaf.data.topLevelParent;
                    }
                  } else if (view === UIView.Markets) {
                    if (
                      currentLocationDetailLevel === LocationDetailLevel.region
                    ) {
                      useId = leaf.data.hybrid_level_1.id;
                    } else {
                      useId = leaf.data.topLevelParent;
                    }
                  }

                  return (
                    <TreemapCell
                      key={cellKey}
                      leaf={leaf}
                      handleTooltip={handleTooltip}
                      colorBy={currentColorBySelection}
                      colorMap={useColorMap}
                      useId={useId}
                      highlightedItem={highlightedItem}
                      servicesWidth={
                        servicesProportion && chartWidth * servicesProportion
                      }
                      nonServices
                    />
                  );
                })}
              {filteredServicesRootLeaves &&
                filteredServicesRootLeaves.map((leaf: any, i: number) => {
                  let cellLabelKey = `label_${exporter}_${importer}_${i}_${leaf.id}`;
                  return (
                    <TreemapCellLabel
                      key={cellLabelKey}
                      leaf={leaf}
                      totalSum={totalSum}
                    />
                  );
                })}

              {filteredNonServicesRootLeaves &&
                filteredNonServicesRootLeaves.map((leaf: any, i: number) => {
                  let cellLabelKey = `label_${exporter}_${importer}_${i}_${leaf.id}}`;
                  return (
                    <TreemapCellLabel
                      key={cellLabelKey}
                      leaf={leaf}
                      totalSum={totalSum}
                      servicesWidth={
                        servicesProportion && chartWidth * servicesProportion
                      }
                      nonServices
                    />
                  );
                })}
            </svg>
          </SvgAndLabelsContainer>
        </>
      );
    } else {
      return (
        <SvgAndLabelsContainer>
          <svg ref={svgRef} width={chartWidth} height={chartHeight}>
            {filteredRootLeaves &&
              filteredRootLeaves.map((leaf: any, i: number) => {
                let cellKey = `cell_${leaf.id}`;

                let useId;
                if (view === UIView.Products) {
                  if (
                    leaf.data.productId === undefined &&
                    leaf.data.productLevel === ProductLevel.ProductSection
                  ) {
                    useId = leaf.data.productId;
                  } else if (leaf.data.topLevelParent === "root") {
                    useId = leaf.data.sectorId;
                  } else {
                    useId = leaf.data.topLevelParent;
                  }
                } else if (view === UIView.Markets) {
                  if (
                    currentLocationDetailLevel === LocationDetailLevel.region
                  ) {
                    useId = leaf.data.hybrid_level_1.id;
                  } else {
                    useId = leaf.data.topLevelParent;
                  }
                }

                return (
                  <TreemapCell
                    key={cellKey}
                    leaf={leaf}
                    handleTooltip={handleTooltip}
                    colorBy={currentColorBySelection}
                    colorMap={useColorMap}
                    useId={useId}
                    highlightedItem={highlightedItem}
                  />
                );
              })}
            {filteredRootLeaves &&
              filteredRootLeaves.map((leaf: any, i: number) => {
                let cellLabelKey = `label_${exporter}_${importer}_${i}_${leaf.id}`;
                return (
                  <TreemapCellLabel
                    key={cellLabelKey}
                    leaf={leaf}
                    totalSum={totalSum}
                  />
                );
              })}
          </svg>
        </SvgAndLabelsContainer>
      );
    }
  }, [
    root,
    noDataToDisplay,
    view,
    svgRef,
    chartWidth,
    servicesProportion,
    chartHeight,
    filteredServicesRootLeaves,
    filteredNonServicesRootLeaves,
    handleTooltip,
    currentColorBySelection,
    useColorMap,
    highlightedItem,
    currentLocationDetailLevel,
    exporter,
    importer,
    totalSum,
    filteredRootLeaves,
  ]);

  if (contentToRender !== null) {
    return <Root>{contentToRender}</Root>;
  } else {
    return <GraphLoading />;
  }
};

const arePropsEqual = (_, { isLoading }) => {
  return isLoading;
};

export default memo(Chart, arePropsEqual);
