import { useState } from "react";
import styled from "@emotion/styled";
import DataNotesOnOrangeIcon from "../../assets/images/ui/datanotes_on_orange.svg";
import DataNotesOffIcon from "../../assets/images/ui/datanotes_off.svg";
import ModalBase, { ModalType } from "../modals/ModalBase";
import { Link } from "react-router-dom";
import { getRoutePathFromRouteID } from "../../routing/Utils";
import { RouteID } from "../../routing/routes";
import { getReferenceLocation } from "../../visualization/charts/geomap/Utils";
import useFetchMetadata, {
  MetadataFetchStatus,
  MetadataFetchType,
} from "../../sharedUtilities/useFetchMetadata";
import { getLocationLevelFromStringLocationId } from "../../sharedUtilities/Utils";
import { LocationLevel } from "../../graphql/types";
import {
  OptionIcon,
  OptionLabel,
  OptionsListItem,
} from "../navigationBar/Components";
import { usePageQueryParams } from "../../visualization/defaultSettings";

const DataNotesSubheader = styled.h2`
  font-size: 1.3rem;
  margin-top: 0px;
`;

const DataNotesModalButton = ({
  menuOptionClassName,
  isNavigationBarOpen,
}: any) => {
  let [isDataNotesModalOpen, setIsDataNotesModalOpen] =
    useState<boolean>(false);
  const [{ importer: currentImporter, exporter: currentExporter }] =
    usePageQueryParams();

  let referenceLocation = getReferenceLocation({
    exporter: currentExporter,
    importer: currentImporter,
  });
  let referenceLocationLevel =
    getLocationLevelFromStringLocationId(referenceLocation);

  const {
    metadataStatus: countryMetadataStatus,
    metadata: countryMetadata,
    error,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.Country });

  const toggleDataNotesModal = () => {
    const newIsDataNotesModalOpen = !isDataNotesModalOpen;
    setIsDataNotesModalOpen(newIsDataNotesModalOpen);
  };

  let dataPageRoutePath = getRoutePathFromRouteID({
    routeId: RouteID.DataAbout,
  });
  let countryNotes;
  let servicesNotes;
  let dataNotesIcon;
  let dataNotesModalType;
  let countryIsTrusted: boolean | undefined = undefined;
  let reportedServices: boolean | undefined = undefined;
  let reportedServicesRecently: boolean | undefined = undefined;
  if (
    countryMetadataStatus === MetadataFetchStatus.Success &&
    referenceLocationLevel === LocationLevel.Country
  ) {
    let foundMetadatumForCountry = countryMetadata.find(
      (country: any) => country.countryId === referenceLocation,
    );
    if (foundMetadatumForCountry) {
      countryIsTrusted = foundMetadatumForCountry.isTrusted;
      reportedServices = foundMetadatumForCountry.reportedServ;
      reportedServicesRecently = foundMetadatumForCountry.reportedServRecent;
    } else {
      countryIsTrusted = undefined;
      reportedServices = undefined;
      reportedServicesRecently = undefined;
    }
  }

  let triggerDataIssues: boolean = false;

  if (countryIsTrusted !== undefined && countryIsTrusted === false) {
    countryNotes = (
      <>
        <DataNotesSubheader>Country Alert</DataNotesSubheader>
        <p>
          Due to limited, delayed, or inaccurate reporting,{" "}
          <strong>
            trade data from this country may contain a significant degree of
            error
          </strong>
          . Our data cleaning method accounts for some of these inconsistencies.
          But be advised that the data shown for these countries reflect best
          estimates and calls for careful consideration before using.
        </p>
        <p>
          <em>
            For more information on data found in the Atlas, please visit our{" "}
            <Link to={dataPageRoutePath ? dataPageRoutePath : "/"}>
              Data page &rarr;
            </Link>
          </em>
        </p>
      </>
    );

    triggerDataIssues = true;
  } else {
    countryNotes = null;
  }

  if (
    (reportedServices !== undefined && reportedServices === false) ||
    (reportedServicesRecently !== undefined &&
      reportedServicesRecently === false)
  ) {
    servicesNotes = (
      <>
        <DataNotesSubheader>Services Data Alert</DataNotesSubheader>
        <p>
          Services data in The Atlas is subject to the following limitations:
        </p>
        <h4>Trade Flows</h4>
        <p>
          Countries report their services exports and imports{" "}
          <strong>unilaterally</strong> to the IMF. Therefore, we cannot mirror
          countries’ service reporting with partner reporting, to improve the
          quality of the data. As a result, location-partner data for services
          is currently unavailable in The Atlas.
        </p>
        <h4>Year Range</h4>
        <p>
          Services data is available between <strong>1980 to 2021</strong> When
          viewed in combination with HS goods, services data spans the full HS
          year range (1995 - 2021). When viewed in combination with SITC goods,
          services data spans a segment of the SITC year range (1980 - 2021).
          Within these ranges however, certain years may be unavailable due to
          undisclosed or delayed country reporting.
        </p>
        <h4>Country Coverage</h4>
        <p>
          Due to significantly limited or delayed reporting, services data is
          available for roughly 50 to 75 percent of all countries in The Atlas.
        </p>
        <p>
          Services data is available in 2 Atlas visualizations:{" "}
          <Link to={"/explore/treemap"}>Tree Map</Link> and{" "}
          <Link to={"/explore/overtime"}>Over Time</Link>.
        </p>
        <p>
          <em>
            For more information on data found in the Atlas, please visit our{" "}
            <Link to={dataPageRoutePath ? dataPageRoutePath : "/"}>
              Data page &rarr;
            </Link>
          </em>
        </p>
      </>
    );
    triggerDataIssues = true;
  } else {
    servicesNotes = null;
  }

  if (triggerDataIssues === true) {
    dataNotesIcon = DataNotesOnOrangeIcon;
    dataNotesModalType = ModalType.DataIssues;
  } else {
    countryNotes = null;
    dataNotesIcon = DataNotesOffIcon;
    dataNotesModalType = ModalType.DataNotes;
  }

  let dataNotesModal = null;

  if (isDataNotesModalOpen) {
    dataNotesModal = (
      <ModalBase
        modalType={dataNotesModalType}
        toggleModal={toggleDataNotesModal}
      >
        {servicesNotes}
        {countryNotes}
        <DataNotesSubheader>Data Notes</DataNotesSubheader>
        <p>
          The raw trade data on goods are derived from countries’ reporting to
          the{" "}
          <a href="https://comtrade.un.org/" target="_blank">
            United Nations Statistical Division (COMTRADE)
          </a>
          . The trade data on services are from the International Monetary Fund
          (IMF){" "}
          <a
            href="http://data.imf.org/?sk=9D6028D4-F14A-464C-A2F2-59B2CD424B85"
            target="_blank"
          >
            Direction of Trade Statistics database
          </a>
          , via the World Development Indicators.
        </p>
        <p>
          The Atlas uses both commodity trade classification types - Harmonized
          System (HS) and Standard International Trade Classification (SITC). HS
          data offers a contemporary and detailed classification of goods, but
          covers a relatively shorter time period. SITC data offers a longer
          time-series but covers fewer goods.
        </p>
        <p>
          SITC categories disaggregate to 1-, 2-, or 4-digit detail levels. HS
          categories disaggregate to 1-, 2-, 4-, or 6-digit detail levels.
          6-digit data is available in the treemap and should be considered less
          reliable than 1-, 2-, and 4-digit.
        </p>
        <p>
          Atlas services data is classified using the{" "}
          <a href="https://www.oecd.org/sdd/its/EBOPS-2010.pdf" target="_blank">
            Extended Balance of Payments 2010
          </a>{" "}
          (EBOPS) classification, across 5 categories.
        </p>
        <p>
          <em>
            For more information on Atlas data, please visit our{" "}
            <Link to={dataPageRoutePath ? dataPageRoutePath : "/"}>
              Data page &rarr;
            </Link>
          </em>
        </p>
      </ModalBase>
    );
  }

  return (
    <>
      <OptionsListItem
        className={menuOptionClassName}
        onClick={toggleDataNotesModal}
      >
        <OptionIcon>
          <img src={dataNotesIcon} alt={"Data Notes"} />
        </OptionIcon>
        {isNavigationBarOpen === true && <OptionLabel>Data Notes</OptionLabel>}
      </OptionsListItem>
      {dataNotesModal}
    </>
  );
};

export default DataNotesModalButton;
