export const globalPageMargin = "50px";
export const menuBaseColor = "rgb(90, 112, 140)";
export const navigationBarColor = "#334c60";
export const controlsPanelBackgroundColor = "none";

/* Mostly unused, from old Atlas styling -- preserving in case needed in future */

export const narrowGlobalPageMargin = "1rem";
export const mobileBreakpoint = "1000px";
export const semiBoldFontWeight = 600;
export const boldFontWeight = 700;
export const normalFontWeight = 400;
export const demiFontWeight = 300;
export const backgroundColor = "rgb(250, 250, 250)";
export const deltaPositiveColor = "#0f0";
export const deltaNegativeColor = "#f00";
export const rootFontSize = 16; // in pixels

export const localTooltipZIndex = 20;
export const localTooltipZIndexHighPriority = 30;
export const localTooltipZIndexHighestPriority = 50;

export const sourceSansPro = "Source Sans Pro";

export const activeUIComponentBackgroundColor = "#eeeeef";
export const menuSecondaryColor = "rgb(137, 166, 202)";
export const linkColor = "rgb(81, 131, 193)";

export const primaryGray = "#b9bfc5";
export const secondaryGray = "#a7aeb5";
export const darkGray = "#717a82";
export const darkGrayHover = "#4d565f";

export const highlightColor = "rgba(0, 126, 255, 0.08)";
