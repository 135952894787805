import { gql } from "@apollo/client";

const query = gql`
  query GetProductYearData(
    $productClass: ProductClass!
    $productLevel: Int!
    $yearMin: Int
    $yearMax: Int
    $servicesClass: ServicesClass!
  ) {
    productYear(
      productClass: $productClass
      productLevel: $productLevel
      yearMin: $yearMin
      yearMax: $yearMax
      servicesClass: $servicesClass
    ) {
      productId
      year
      pci
      exportValue
      importValue
    }
  }
`;

export default query;
