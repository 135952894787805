const ClickIndicator = () => {
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        top: 5,
        left: 5,
        backgroundColor: "rgba(255,255,255,.75)",
        borderRadius: "4px",
      }}
    >
      <img
        alt="instruction: click on a node to see related products "
        style={{ height: "40px" }}
        src="/click.png"
      />
      <div style={{ width: "140px", fontSize: 16 }}>
        Click on a node to see related products
      </div>
    </div>
  );
};
export default ClickIndicator;
