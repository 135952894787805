import styled from "@emotion/styled";
import {
  menuBaseColor,
  menuSecondaryColor,
} from "../../components/styling/cssVariables";
import { ParagraphStyling } from "../../components/styling/TextStyling";

const borderColor = "#999";

export const SideBarContainer = styled.div`
  flex: 1 15%;
  position: sticky;
  top: 0px;
  align-self: flex-start;
  margin-top: 2rem;
`;

export const SideBarUl = styled.ul`
  letter-spacing: 0.07em;
  font-size: 0.8125rem;
  color: #666;
  list-style-type: none;

  margin: 0px;
  padding: 0px;

  li {
    line-height: 1.2;
  }
`;

export const sidebarNavItemHoverBackgroundColor = "rgba(0, 126, 255, 0.08)";
export const sidebarNavItemBorderLeftWidth = 8; // in pixels
export const sidebarNavItemPadding = 0.625; // in rem

export const InactiveLink = styled.li`
  display: block;
  text-transform: uppercase;
  border-left-width: ${sidebarNavItemBorderLeftWidth}px;
  border-left-style: solid;
  border-left-color: transparent;
  color: rgb(102, 102, 102);
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.07rem;
  padding: ${sidebarNavItemPadding}rem;
  cursor: pointer;

  && a {
    color: inherit;
    text-decoration: none;
  }

  &&:hover {
    background-color: ${sidebarNavItemHoverBackgroundColor};
    text-decoration: none;
  }
`;

export const ActiveLink = styled(InactiveLink)`
  color: white;
  background-color: ${menuSecondaryColor};
  border-left-color: ${menuBaseColor};
  font-weight: 700;

  &&:hover {
    background-color: ${menuSecondaryColor};
    text-decoration: none;
  }
`;

/* Glossary and FAQ components */

export const DetailsPageContainer = styled.div`
  width: 90%;
  margin-bottom: 100px;

  @keyframes details-show {
    from {
      max-height: 0px;
    }
    to {
      max-height: 2000px;
    }
  }

  details[open] > *:not(summary) {
    animation-name: details-show;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }

  details:not([open]) > *:not(summary) {
    max-height: 0vh;
  }
`;

export const Details = styled.details``;

export const DetailsToggleIcon = styled.span`
  position: absolute;
  right: 0px;
  padding-right: 10px;
  font-weight: bold;
`;

export const DetailsIconMore = "+";
export const DetailsIconLess = "-";

export const DetailsSummary = styled.summary`
  font-size: 1.5rem;
  line-height: 1.14;
  color: rgb(51, 51, 51);
  border-top: 1px solid ${borderColor};
  background-color: rgba(90, 111, 140, 0.1);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:hover {
    background-color: rgba(90, 111, 140, 0.35);
    cursor: pointer;
  }
`;

export const DetailsContent = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  border-top: 1px solid ${borderColor};

  a {
    color: rgb(39, 86, 197);

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    ${ParagraphStyling}
    padding: 1rem;
    margin-bottom: 0;
  }
`;

interface ToggleProps {
  isOpen: boolean;
}

export const ToggleIcon = ({ isOpen }: ToggleProps) => {
  if (isOpen) {
    return <DetailsToggleIcon>{DetailsIconLess}</DetailsToggleIcon>;
  } else {
    return <DetailsToggleIcon>{DetailsIconMore}</DetailsToggleIcon>;
  }
};
