import { VariableSizeList as List } from "react-window";
import { allProductsDatum } from "../../graphql/queries/getProductsMetadata";
import { memo, useEffect, useRef } from "react";

const fallbackRowHeight = 34;

/*
Note and rationale:

The Downshift library is used to render both the Location and Product dropdowns.
This library works well for displaying collapsed hierarchies, but when the
list becomes very long, performance becomes sluggish.

In the case of the Product Dropdown, which has ~6000 total products to display,
searching for matching products based on user input in the text field becomes
very slow -- because Downshift has to filter all the matching search results
and make them visible, along with the parent products of those matching results.

The Location Dropdown can handle searching with Downshift with minimal performance
effects, but only because there are not many items to render at any time.

To improve the search performance of the Product Dropdown, a separate <SearchDropdown>
component is created here. This component uses `react-window` to virtualize the
list of matching search results based on user input. This is the only place in
the codebase where `react-window` is used.

*/
const SearchDropdown = ({
  displayItems,
  getOriginalIndexOfItem,
  items,
  ItemRenderComponent,
  highlightedIndex,
  selectedItem,
  getItemProps,
  hashFunction,
  updateVisibilityFunction,
  setDisplayItems,
  dropdownRole,
  productItemsWithHeights,
}: any) => {
  const visibleItems = displayItems.filter(
    (item: any) => item.isVisible && item.id !== allProductsDatum.productId,
  );
  const visibleItemsIds: any = new Set([]);
  visibleItems.forEach((item: any) => {
    visibleItemsIds.add(item.id);
  });

  let filteredProductItemsWithHeights: any[] = [];
  [...visibleItemsIds.values()].forEach((productId: string) => {
    let matchingProductItemHeight = productItemsWithHeights.find(
      (item: any) => item.productId === productId,
    );
    if (matchingProductItemHeight) {
      let { height } = matchingProductItemHeight;
      filteredProductItemsWithHeights.push({ productId, height });
    } else {
      filteredProductItemsWithHeights.push({
        productId,
        height: fallbackRowHeight,
      });
    }
  });

  const Row = ({ index, style }: any) => {
    let item = visibleItems[index];

    const mappedItemIndex = getOriginalIndexOfItem({
      originalItems: items,
      selectedItem: item,
    });
    let keyString;
    if (dropdownRole) {
      keyString = `search_dropdown_${dropdownRole}_item_${index}`;
    } else {
      keyString = `search_dropdown_item_${index}`;
    }

    return (
      <ItemRenderComponent
        key={keyString}
        item={item}
        index={index}
        highlightedIndex={highlightedIndex}
        selectedItem={selectedItem}
        mappedItemIndex={mappedItemIndex}
        getItemProps={getItemProps}
        hashFunction={hashFunction}
        updateVisibilityFunction={updateVisibilityFunction}
        displayItems={displayItems}
        setDisplayItems={setDisplayItems}
        isSearching={true}
        virtualizedStyle={style}
      />
    );
  };

  let modifiedStyle: any = {
    width: "100%",
    boxSizing: "border-box",
    overflowX: "hidden",
    overflowY: "scroll",
    margin: "0px",
    padding: "0px",
  };

  const listRef = useRef<any>(null);

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [listRef, displayItems]);

  return (
    <List
      ref={listRef}
      style={modifiedStyle}
      height={150}
      itemCount={visibleItems.length}
      itemSize={(index: number) => {
        return filteredProductItemsWithHeights[index].height;
      }}
      width={275}
    >
      {Row}
    </List>
  );
};

export default memo(SearchDropdown);
