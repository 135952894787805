import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

const Root = styled.div`
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  font-size: 0.4rem;
  min-height: 57vh;
  max-height: 100%;
  overflow-x: hidden; // Prevent horizontal scrolling
`;

/*
Using https://publish.twitter.com/ to generate a Twitter feed for embed 
does not work out of the box with React, because the asynchronous Twitter script 
used to render the feed does not properly communicate with the required DOM element.

This solution instantiates the required <script> element for rendering the Twitter feed,
and then appends this <script> element to the Twitter feed container DOM element, 
using useEffect() -- to wait until after component rendering has completed. 

It also waits for component rendering to complete before determining what height 
should be set for the resulting feed.

// See: https://dev.to/heymarkkop/embed-twitter-widget-on-reactjs-1768

*/

const TwitterFeed = () => {
  const feedElement = useRef<HTMLDivElement | null>(null);
  const [feedHeight, setFeedHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (feedElement.current) {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      feedElement.current.appendChild(script);

      const updateHeight = () => {
        if (feedElement.current) {
          setFeedHeight(feedElement.current.getBoundingClientRect().height);
        }
      };

      updateHeight();
      window.addEventListener("resize", updateHeight);

      return () => {
        window.removeEventListener("resize", updateHeight);
      };
    }
  }, []);

  return (
    <Root ref={feedElement}>
      {feedHeight && (
        <a
          className="twitter-timeline"
          data-height={feedHeight}
          href="https://twitter.com/HarvardGrwthLab?ref_src=twsrc%5Etfw"
        >
          Tweets by HarvardGrwthLab
        </a>
      )}
    </Root>
  );
};

export default TwitterFeed;
