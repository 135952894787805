import { gql } from "@apollo/client";

const query = gql`
  query GetCountryYear($countryId: Int, $yearMin: Int, $yearMax: Int) {
    countryYear: countryYear(
      countryId: $countryId
      yearMin: $yearMin
      yearMax: $yearMax
    ) {
      countryId
      year
      population
    }
  }
`;

export default query;
