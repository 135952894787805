import styled from "@emotion/styled";
import { ProductMetadatumLevel } from "../../../graphql/types";
import { ProductClass } from "../../../visualization/Utils";
import MainDropdownCombobox, {
  DropdownRole,
} from "../../mainDropdownPrimitive";
import ProductDropdownItem from "./ProductDropdownItem";
import getHs92ProductsMetadata from "../../../graphql/queries/getHs92ProductsMetadata";
import getHs12ProductsMetadata from "../../../graphql/queries/getHs12ProductsMetadata";
import getSitcProductsMetadata from "../../../graphql/queries/getSitcProductsMetadata";
import { allProductsDatum } from "../../../graphql/queries/getProductsMetadata";
import useFetchMetadata, {
  MetadataFetchType,
  MetadataFetchStatus,
} from "../../../sharedUtilities/useFetchMetadata";
import { memo, useEffect, useRef, useState } from "react";
import {
  ComboBoxContainer,
  DropdownContainer,
  MainMenu,
} from "../../mainDropdownPrimitive/styles";
import {
  groupAndOrderProductOptions,
  updateVisibilityStatus,
  productSearchStringFunction,
  productFilterSearchResults,
  getProductClassShortLabel,
  productHashFunction,
} from "./Utils";
import ProductDropdownItemForCalculation from "./ProductDropdownItemForCalculation";
import { usePageQueryParams } from "../../../visualization/defaultSettings";

const DropdownLoading = styled.div`
  width: 100%;
  color: #aaaaaa;
  font-style: italic;
  font-family: inherit;
  display: flex;
  align-items: center;
  padding: 5px;
`;

const ProductDropdown = ({ productClass, metadata }: any) => {
  const [productItemsWithHeights, setProductItemsWithHeights] =
    useState<any>(undefined);
  const [hasRenderedOnce, setHasRenderedOnce] = useState<boolean>(false);
  const hiddenDropdownRef = useRef<HTMLDivElement | null>(null);

  const [{ product: currentProduct }, setQuery] = usePageQueryParams();

  const productClassShortLabelString = getProductClassShortLabel({
    productClass,
  });

  useEffect(() => {
    if (
      hiddenDropdownRef &&
      hiddenDropdownRef.current &&
      hasRenderedOnce === false
    ) {
      let itemHeights: any[] = [];

      let dropdownNode = hiddenDropdownRef.current;
      let itemNodes = dropdownNode.querySelectorAll(".hidden");
      [...itemNodes].forEach((node: any) => {
        let { height } = node.getBoundingClientRect();
        let productId = node.getAttribute("data-productid");
        itemHeights.push({ productId, height });
      });

      setProductItemsWithHeights(itemHeights);
      setHasRenderedOnce(true);
    }
  }, [hiddenDropdownRef, hiddenDropdownRef.current, hasRenderedOnce]);

  const { section, twoDigit, fourDigit, sixDigit } = metadata;
  const grouped = groupAndOrderProductOptions({
    section,
    twoDigit,
    fourDigit,
    sixDigit,
  });

  if (hasRenderedOnce === true) {
    const itemsWithVisibilityStatusInitial = grouped.map((item) => {
      item.isExpanded = false;
      if (
        item.level === ProductMetadatumLevel.section ||
        item.level === ProductMetadatumLevel.allProducts
      ) {
        item.isVisible = true;
      } else {
        item.isVisible = false;
      }
      return item;
    });

    let matchSelectedItem: any = undefined;
    if (currentProduct) {
      matchSelectedItem = itemsWithVisibilityStatusInitial.find(
        (item) => item.id === currentProduct,
      );
      if (matchSelectedItem && currentProduct !== allProductsDatum.productId) {
        itemsWithVisibilityStatusInitial.forEach((item) => {
          // Products in the same product level as the selected product should be visible
          if (
            item.parent &&
            item.parent.productId === matchSelectedItem.parent.productId
          ) {
            item.isVisible = true;
            item.isVisibleChange = false;
          }
        });
      }
    }

    const dispatchAction = ({ id }: { id: string }) => {
      setQuery({ product: id });
    };

    return (
      <MainDropdownCombobox
        items={itemsWithVisibilityStatusInitial}
        hashFunction={(item: any) =>
          productHashFunction({ item, productClassShortLabelString })
        }
        searchStringFunction={productSearchStringFunction}
        placeholder="Please select a product"
        ItemRenderComponent={ProductDropdownItem}
        onChangeEvent={dispatchAction}
        selectedValueFromStore={matchSelectedItem}
        updateVisibilityFunction={updateVisibilityStatus}
        filterFunction={productFilterSearchResults}
        dropdownRole={DropdownRole.Product}
        productItemsWithHeights={productItemsWithHeights}
      />
    );
  } else {
    const itemsAllVisibleForHeightCalculation = grouped.map((item) => {
      item.isVisible = true;
      return item;
    });

    const hiddenStyle: any = {
      visibility: "hidden",
      opacity: "0",
    };

    return (
      <>
        <ComboBoxContainer
          style={hiddenStyle}
          ref={hiddenDropdownRef}
          key="hidden_search"
        >
          <MainMenu style={{ overflowY: "scroll" }}>
            {itemsAllVisibleForHeightCalculation.map((item: any) => {
              let key = `hidden_${item.id}`;

              return (
                <ProductDropdownItemForCalculation
                  item={item}
                  key={key}
                  hashFunction={(item: any) =>
                    productHashFunction({ item, productClassShortLabelString })
                  }
                />
              );
            })}
          </MainMenu>
        </ComboBoxContainer>
        <DropdownContainer>
          <DropdownLoading>Loading...</DropdownLoading>
        </DropdownContainer>
      </>
    );
  }
};

const MapInputToQuery = () => {
  const [{ productClass: currentProductClass }] = usePageQueryParams();

  let queryToUse: any;
  if (currentProductClass === ProductClass.HS92Products) {
    queryToUse = getHs92ProductsMetadata;
  } else if (currentProductClass === ProductClass.HS12Products) {
    queryToUse = getHs12ProductsMetadata;
  } else if (currentProductClass === ProductClass.SITCProducts) {
    queryToUse = getSitcProductsMetadata;
  }

  let metadataFetchType: MetadataFetchType;
  if (currentProductClass === ProductClass.HS92Products) {
    metadataFetchType = MetadataFetchType.ProductsHs92;
  } else if (currentProductClass === ProductClass.HS12Products) {
    metadataFetchType = MetadataFetchType.ProductsHs12;
  } else if (currentProductClass === ProductClass.SITCProducts) {
    metadataFetchType = MetadataFetchType.ProductsSitc;
  } else {
    // This will never run
    metadataFetchType = MetadataFetchType.ProductsHs92;
  }

  const { metadataStatus, metadata, error } = useFetchMetadata({
    metadataFetchType,
  });

  if (metadataStatus === MetadataFetchStatus.Success) {
    return (
      <ProductDropdown metadata={metadata} productClass={currentProductClass} />
    );
  } else {
    return (
      <DropdownContainer>
        <DropdownLoading>Loading...</DropdownLoading>
      </DropdownContainer>
    );
  }
};

export default memo(MapInputToQuery);
