import React from "react";
import styled from "@emotion/styled";

const SortingIconBase = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 7.9 13.6"
    {...props}
  >
    <g>
      <polygon className="st0" points="4,0 0.1,5.8 7.8,5.8" />
      <polygon className="st0" points="4,13.5 7.8,7.8 0.1,7.8" />
    </g>
  </svg>
);

const SortingIcon = styled(SortingIconBase)`
  width: 100%;
  height: 100%;

  .st0 {
    fill: ${(props) => props.color || "currentColor"};
  }
`;

export default SortingIcon;
