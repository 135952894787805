import { useState } from "react";
import ModalBase, { ModalType } from "../../../components/modals/ModalBase";
import OverlayTable from "./OverlayTable";
import ProductRing from "./ProductRing";

const ProductOverlay = ({
  linkLookup,
  selection,
  nodeLookup,
  metaDataLookup,
  colorMap,
  setRingItem,
  displayData,
  totalExportLookup,
  countryExportLookup,
  currentColorBySelection,
  clusterColorLookup,
  complexityColorScale,
  rcaThreshold,
}) => {
  const [highlighted, highlight] = useState(null);

  return (
    <ModalBase
      modalType={ModalType.ProductOverlay}
      toggleModal={() => setRingItem(null)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "calc(100% - 10px)",
        }}
      >
        <div style={{ width: "55%", height: "100%" }}>
          <ProductRing
            linkLookup={linkLookup}
            selection={selection}
            nodeLookup={nodeLookup}
            metaDataLookup={metaDataLookup}
            setRingItem={setRingItem}
            colorMap={colorMap}
            highlighted={highlighted}
            highlight={highlight}
            currentColorBySelection={currentColorBySelection}
            clusterColorLookup={clusterColorLookup}
            complexityColorScale={complexityColorScale}
            totalExportLookup={totalExportLookup}
            countryExportLookup={countryExportLookup}
            rcaThreshold={rcaThreshold}
          />
        </div>
        <div style={{ height: "100%", width: "45%" }}>
          <OverlayTable
            colorMap={colorMap}
            metaDataLookup={metaDataLookup}
            totalExportLookup={totalExportLookup}
            displayData={displayData}
            selection={selection}
            linkLookup={linkLookup}
            nodeLookup={nodeLookup}
            countryExportLookup={countryExportLookup}
            highlight={highlight}
            setRingItem={setRingItem}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ProductOverlay;
