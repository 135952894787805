import { useState, useRef, useEffect, cloneElement, ReactElement } from "react";
import styled from "@emotion/styled";
import {
  Details as DetailsBase,
  DetailsSummary as GlossaryItemHeading,
  DetailsContent as GlossaryItemDefinition,
  DetailsPageContainer,
  ToggleIcon,
} from "../sharedComponents/staticPageComponents";

import { ReactNode } from "react";
import katex from "katex";
import "katex/dist/katex.css";

interface DetailsProps {
  children: ReactNode[];
}

const Details = ({ children }: DetailsProps) => {
  let [isOpen, setIsOpen] = useState<boolean>(false);
  const detailsElement = useRef<HTMLDetailsElement | null>(null);

  const handleToggle = (e: any) => {
    if (detailsElement && detailsElement.current) {
      const detailsElementOpen = detailsElement.current.open;
      setIsOpen(detailsElementOpen);
    }
  };

  let ChildrenWithToggleIcon: any | null;
  if (children) {
    ChildrenWithToggleIcon = children.map((child: any) => {
      if (child.type === GlossaryItemHeading) {
        return cloneElement(
          child,
          {},
          child.props.children,
          <ToggleIcon isOpen={isOpen} />,
        );
      } else {
        return child;
      }
    });
  }

  return (
    <DetailsBase onToggle={handleToggle} ref={detailsElement}>
      {ChildrenWithToggleIcon}
    </DetailsBase>
  );
};

const FormulaInline = styled.span`
  font-style: italic;
`;

const FormulaBlock = styled.div`
  display: block;
  width: 100%;
  margin: 0px auto 20px;
`;

enum DisplayMode {
  Inline = "inline",
  Block = "block",
}

interface FormulaRenderingProps {
  string: string;
  display: DisplayMode;
}

const FormulaRendering = ({ string, display }: FormulaRenderingProps) => {
  const el = useRef(null);

  useEffect(() => {
    if (el && el.current) {
      katex.render(string, el.current, {
        displayMode: display === DisplayMode.Block ? true : false,
      });
    }
  }, [el]);

  let renderedElement: ReactElement | null = null;
  if (display === DisplayMode.Inline) {
    renderedElement = <FormulaInline ref={el}></FormulaInline>;
  } else if (display === DisplayMode.Block) {
    renderedElement = <FormulaBlock ref={el}></FormulaBlock>;
  }

  return renderedElement;
};

const GlossaryContent = () => {
  return (
    <DetailsPageContainer>
      <Details>
        <GlossaryItemHeading>Distance</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            A measure of a location’s ability to enter a specific product. A
            product’s distance (from 0 to 1) looks to capture the extent of a
            location’s existing capabilities to make the product as measured by
            how closely related a product is to its current exports. A ‘nearby’
            product of a shorter distance requires related capabilities to those
            that are existing, with greater likelihood of success.
          </p>
          <p>
            Every two products have a notion of distance between them, where
            products that require similar know-how and capabilities are ‘closer’
            together (i.e. shorter distance, closer to 0), while two products
            that require completely different capabilities are ‘farther’ apart
            (i.e. longer distance, closer to 1). Distance can be thought of as a
            measure of risk of entering a product, where larger distances
            express little relatedness to existing know-how and the need to
            coordinate adding many missing capabilities and inputs in order to
            enter production, increasing risk. Distance reflects that not every
            new product has an equal likelihood of success in a location, but is
            dependent on its similarity to the location’s existing capabilities,
            as reflected in the Product Space.
          </p>
          <p>
            <em>Technical breakout</em>: Every two products have a globally
            defined <em>proximity</em> between them as measured by the
            probability of co-export, that if a country exports product A, what
            is the probability they also export product B. The product
            proximities are fixed globally and measured using 128 countries’
            export data over 50 years. The <em>distance</em> of a product is
            then the sum of the proximities connecting that product to all the
            products that the location is not currently exporting. Formally, for
            product <FormulaRendering display={DisplayMode.Inline} string="p" />{" "}
            and country{" "}
            <FormulaRendering display={DisplayMode.Inline} string="c" />, the
            distance{" "}
            <FormulaRendering display={DisplayMode.Inline} string="d" /> is:
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`d_{cp} = \\frac{\\sum\\nolimits_{p'}\\left(1 - M_{cp'}\\right)\\Phi_{p,p'}}{\\sum\\nolimits_{p'}\\Phi_{p,p'}}`}
          />
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Economic Complexity</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            A measure of the knowledge in a society as expressed in the products
            it makes. The economic complexity of a country is calculated based
            on the <strong>diversity</strong> of exports a country produces and
            their <strong>ubiquity</strong>, or the number of the countries able
            to produce them (and those countries’ complexity).
          </p>
          <p>
            Countries that are able to sustain a diverse range of productive
            know-how, including sophisticated, unique know-how, are found to be
            able to produce a wide diversity of goods, including complex
            products that few other countries can make.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>
          Economic Complexity Index (ECI)
        </GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            A rank of countries based on how diversified and complex their
            export basket is. Countries that are home to a great diversity of
            productive know-how, particularly complex specialized know-how, are
            able to produce a great diversity of sophisticated products.
          </p>
          <p>
            The complexity of a country’s exports is found to highly predict
            current income levels, or where complexity exceed expectations for a
            country’s income level, the country is predicted to experience more
            rapid growth in the future. ECI therefore provides a useful measure
            of economic development.
          </p>
          <p>
            <em>
              <a href="http://www.pnas.org/content/106/26/10570.full">
                Technical breakout:
              </a>
            </em>{" "}
            Economic complexity is calculated from equations for diversity and
            ubiquity to express the recursion:
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\begin{aligned}
                k_{c,n} &= \\frac{1}{k_{c,0}}\\sum\\limits_{p} M_{cp}\\frac{1}{k_{p,0}}\\sum\\limits_{c'} M_{c'p}k _{c',n-2} \\\\
                &= \\sum\\limits_{c'} k _{c',n-2}\\sum\\limits_{p}\\frac{M_{c'p}M_{cp}}{k_{c,0}k_{p,0}} \\\\
                &= \\sum\\limits_{c'} k _{c',n-2}\\tilde{M}^{C}_{c,c'} \\\\
                \\end{aligned}`}
          />
          <p>where we define</p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\tilde{M}^{C}_{c,c'} \\equiv \\sum\\limits_{p}\\frac{M_{cp}M_{c'p}}{k_{c,0}k_{p,0}}.`}
          />
          <p>
            Hence, in a vector notation, if{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`\\vec{\\mathbf{k}}_n`}
            />{" "}
            to be the vector whose{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`c`} />
            th element is{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`k_{c,n}`}
            />{" "}
            then:
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\vec{\\mathbf{k}}_n = \\tilde{\\mathbf{M}}^{C} \\times \\vec{\\mathbf{k}}_{n-2}`}
          />
          <p>
            where{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`\\tilde{\\mathbf{M}}^{C}`}
            />{" "}
            is the matrix whose{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`(c,c')`} />
            th element is{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`\\tilde{M}^{C}_{c,c'}`}
            />
          </p>
          <p>
            If we take{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`n`} /> to
            infinity, this equation leads to the distribution which remains ﬁxed
            up to a scalar factor:
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\tilde{\\mathbf{M}}^{C} \\times \\vec{\\mathbf{k}} = \\lambda \\vec{\\mathbf{k}}`}
          />
          <p>
            Therefore,{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`\\vec{\\mathbf{k}}`}
            />{" "}
            is an eigenvector of{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`\\tilde{\\mathbf{M}}^{C}`}
            />
            . We define Economic Complexity Index as the eigenvector
            corresponding to the second largest eigenvalue of the{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`\\tilde{\\mathbf{M}}^{C}`}
            />{" "}
            matrix.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>
          Economic Complexity Growth Projection
        </GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            A prediction of how much a country will grow based on its current
            level of Economic Complexity, its Complexity Outlook or
            connectedness to new complex products in the Product Space, as
            compared to its current income level in GDP per capita and expected
            natural resource exports. Economic complexity alone helps explain
            the lion’s share of variance in current income levels. But the value
            of economic complexity is in its predictive power on future growth,
            where a simple measure of current complexity and connectedness to
            new complex products, in relation to current income levels and
            expected natural resource exports, holds greater accuracy in
            predicting future growth than any other single economic indicator.
          </p>
          <p>
            To calculate Economic Complexity Growth Projections, we consider
            four factors as explanatory variables: the Economic Complexity
            Index; the Complexity Outlook Index; the current level of income;
            and the expected growth in the value of natural resource exports per
            capita. In effect, the growth projections show countries grow by
            expanding the know-how they have that allows them to produce more,
            and more complex products, depending on the connectedness of
            know-how and how many other products rely on similar capabilities,
            as well as the initial economic complexity the country held.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>
          Economic Complexity Outlook Index (COI)
        </GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            A measure of how many complex products are near a country’s current
            set of productive capabilities. The COI captures the ease of
            diversification for a country, where a high COI reflects an
            abundance of nearby complex products that rely on similar
            capabilities or know-how as that present in current production.
            Complexity outlook captures the connectedness of an economy’s
            existing capabilities to drive easy (or hard) diversification into
            related complex production, using the Product Space.
          </p>
          <p>
            A low complexity outlook reflects that a country has few products
            that are a short distance away, so will find it difficult to acquire
            new know-how and increase their economic complexity.
          </p>
          <p>
            <em>Technical breakout</em>: To calculate COI we first need to
            calculate <strong>distance</strong> of every product to existing
            production (from 0 to 1). We then sum the ‘closeness,’ i.e. 1 minus
            the distance to the products that the country is not currently
            making, weighted by the level of complexity of these products.
            Formally,
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\text{COI}_{c} = \\sum\\nolimits_{p} \\left(1-d_{cp}\\right) \\left(1-M_{cp}\\right)\\text{PCI}_p`}
          />
          <p>
            where PCI is the Product Complexity Index of product{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`p`} />. The
            term{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`1 - M_{cp}`}
            />{" "}
            ensures we only count the products that a country is not currently
            producing.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Intra-region trade</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            Intra-region trade is the export or import trade inside a region and
            includes trade between all members of the region.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Know-how</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            The tacit ability to produce a product. Also known as productive
            capability, know-how refers to the productive knowledge that goes
            into making products.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Opportunity Outlook Gain</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            Measures how much a location could benefit in opening future
            diversification opportunities by developing a particular product.
            Opportunity outlook gain quantifies how a new product can open up
            links to more, and more complex, products. Opportunity outlook gain
            classifies the strategic value of a product based on the new paths
            to diversification in more complex sectors that it opens up.
          </p>
          <p>
            Opportunity outlook gain accounts for the complexity of the products
            not being produced in a location and the distance or how close to
            existing capabilities that new product is.
          </p>
          <p>
            <em>Technical breakout</em>: Opportunity outlook gain is defined as
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\text{OG}_{cp} = \\left[\\sum\\nolimits_{p'} \\frac{\\Phi_{p,p'}}{\\sum\\nolimits_{p^{''}}\\Phi_{p^{''}, p'} } \\left(1-M_{cp'}\\right)\\text{PCI}_{p'}\\right]`}
          />
          <p>
            Where{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`PCI`} /> is
            the Product Complexity Index of product{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`p'`} />. The
            term{" "}
            <FormulaRendering
              display={DisplayMode.Inline}
              string={`1 - M_{cp'}`}
            />{" "}
            counts only the products that the country is not currently
            producing. Higher opportunity outlook gain implies that a product is
            in the vicinity of more products and/or of products that are more
            complex.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>
          Product Complexity Index (PCI)
        </GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            Ranks the diversity and sophistication of the productive know-how
            required to produce a product. PCI is calculated based on how many
            other countries can produce the product and the economic complexity
            of those countries. In effect, PCI captures the amount and
            sophistication of know-how required to produce a product.
          </p>
          <p>
            The most complex products (that only a few, highly complex countries
            can produce) include sophisticated machinery, electronics and
            chemicals, as compared to the least complex products (that nearly
            all countries including the least complex can produce) including raw
            materials and simple agricultural products. Specialized machinery is
            said to be complex as it requires a range of know-how in
            manufacturing, including the coordination of a range of highly
            skilled individuals’ know-how.
          </p>
          <p>
            <em>
              <a href="http://www.pnas.org/content/106/26/10570.full">
                Technical breakout
              </a>
            </em>
            : PCI is determined by calculating the average diversity of
            countries that make a specific product, and the average ubiquity of
            the other products that these countries make. Formally, we can
            define:
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\tilde{M}^{P}_{p,p'} \\equiv \\sum\\limits_{c}\\frac{M_{cp}M_{cp'}}{k_{c,0}k_{p,0}}.`}
          />
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Product Space</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            A visualization that depicts the connectedness between products
            based on the similarities of the know-how required to produce them.
            The product space visualizes the paths that countries can take to
            diversify. Products are linked by their <strong>proximity</strong>{" "}
            to each other, based on the probability of co-export of both of the
            two products.
          </p>
          <p>
            <a href="http://science.sciencemag.org/content/317/5837/482">
              The product space
            </a>{" "}
            details the connectedness of nearly 900 products, in color-coded
            sectors, based on real world data on the experience of countries’
            diversification over the past 50 years. We are able to map a
            country’s location in the product space from its export basket to
            understand what they are able to make, what products are nearby (at
            a short <strong>distance</strong>) that depend on similar know-how
            to that which currently exists, and to define paths to industrial
            diversification. By using real export data over time, the shape of
            the product space teaches us how diversification works in practice:
            countries move from things they know how to do, to things that are
            nearby or related, or what they call the adjacent possible. The
            irregularity of the space means that diversification occurs
            preferentially, where countries in the dense middle of the product
            space have many nearby opportunities for diversification, as
            compared to countries at the periphery. Products at the periphery
            require know-how that is less readily redeployed into many new
            industries, in cultivating coffee or extracting oil from the ground,
            while adding know-how to produce men’s shirts may open opportunities
            in several other textiles (women’s pants), but shows little
            relatedness to heavy machinery or chemical products, as fewer
            countries produce men’s shirts and car parts. The product space
            allows us to predict the evolution of a country’s industry, along
            with recommendations of those products that offer: greater economic
            complexity (higher wage levels), shorter distance (more existing
            know-how, reducing risk), and high opportunity outlook gain (opening
            more adjacent products for continued diversification opportunities).
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>
          Revealed Comparative Advantage (RCA)
        </GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            A measure of whether a country is an exporter of a product, based on
            the relative advantage or disadvantage a country has in the export
            of a certain good. We use Balassa’s definition, which says that a
            country is an effective exporter of a product if it exports more
            than its “fair share,” or a share that is at least equal to the
            share of total world trade that the product represents (RCA greater
            than 1).
          </p>
          <p>
            One example: In 2010, soybeans represented 0.35% of world trade with
            exports of $42 billion. Of this total, Brazil exported nearly $11
            billion of soybeans. Since Brazil’s total exports for that year were
            $140 billion, soybeans accounted for 7.8% of Brazil’s exports. By
            dividing 7.8% / 0.35%, we find Brazil has an RCA of 22 in soybeans,
            meaning Brazil exports 22 times its “fair share” of soybean exports
            so we can say that Brazil has a high revealed comparative advantage
            in soybeans.
          </p>
          <p>
            <i>Technical breakout</i>: Formally, if{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`X_{cp}`} />{" "}
            represents the exports of product{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`p`} /> by
            country{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`c`} />, we
            can express the RCA that country{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`c`} /> has
            in product{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`p`} /> as
          </p>

          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\text{RCA}_{cp} = \\frac{X_{cp}/\\sum\\nolimits_c X_{cp}}{\\sum\\nolimits_p X_{cp}/ \\sum\\nolimits_{c}\\sum\\nolimits_{p} X_{cp}}`}
          />

          <p>
            We can use this measure to construct a matrix that connects each
            country to the products that it makes. Entries in the matrix are 1
            if country{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`c`} />{" "}
            exports product{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`p`} /> with
            RCA greater than 1, 0 otherwise. Formally, we define this as the{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`M_{cp}`} />{" "}
            matrix, where
          </p>

          <FormulaRendering
            display={DisplayMode.Block}
            string={`M_{cp}  = \\left\\{
  \\begin{array}{l l}
    1 & \\quad \\text{RCA}_{cp} \\ge 1\\\\
    0 & \\quad  \\text{otherwise}
  \\end{array} \\right.`}
          />

          <p>
            <FormulaRendering display={DisplayMode.Inline} string={`M_{cp}`} />{" "}
            is the matrix summarizing which country makes what, and is used to
            construct the product space and our measures of economic complexity
            for countries and products.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Diversity</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            A measure of how many different types of products a country is able
            to make. The production of a good requires a specific set of
            know-how; therefore, a country’s total diversity is another way of
            expressing the amount of collective know-how held within that
            country.
          </p>
          <p>
            <em>Technical breakout</em>: Imagine a matrix,{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`M_{cp}`} />,
            in which rows represent different countries and columns represent
            different products. An element of the matrix is equal to 1 if
            country{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`C`} />{" "}
            produces product{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`P`} /> (with
            RCA greater than 1), and 0 otherwise. We can measure diversity (and
            ubiquity) simply by summing over the rows (or columns) of that
            matrix. Formally,
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\text{Diversity} = k_{c,0} = \\sum\\limits_{p} M_{cp}`}
          />
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Personbyte</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            Describes the amount of <strong>know-how</strong> held by one
            person. Most products today require more productive knowledge to
            produce than can be mastered by a single individual. To make those
            products, then, requires that individuals with different know-how
            interact with one other.
          </p>
          <p>
            Products that require 100 personbytes cannot be made by a
            micro-entrepreneur working alone, nor a small village that only has
            a diversity of 50 personbytes. Instead, this product has to be made
            by an organization with at least 100 individuals (each with a
            different personbyte), or by a network of organizations that can
            aggregate these 100 personbytes of knowledge.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Proximity</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            Measures the probability that a country exports product A given that
            it exports product B, or vice versa. Given that a country makes one
            product, proximity captures the ease of obtaining the know-how
            needed to move into another product. Proximity formalizes the
            intuitive idea that the ability of a country to produce a product
            can be revealed by looking at which other products it can produce.
          </p>
          <p>
            <em>Technical breakout</em>: Our measure of proximity is based on
            the minimum conditional probability that a country that exports
            product P will also export product R. Since conditional
            probabilities are not symmetric, we take the minimum probability of
            product P, given product R, and vice versa. For example, suppose
            that 17 countries export wine, 24 export grapes and 11 export both,
            all with RCA &gt;1. Then, the proximity between the wine and the
            grapes is 11/24 = 0.46. Note, we use 24 instead of 17 to reduce the
            likelihood the relationship is false.
          </p>
        </GlossaryItemDefinition>
      </Details>
      <Details>
        <GlossaryItemHeading>Ubiquity</GlossaryItemHeading>
        <GlossaryItemDefinition>
          <p>
            Ubiquity measures the number of countries that are able to make a
            product.
          </p>
          <p>
            <em>Technical breakout</em>: Considering the matrix{" "}
            <FormulaRendering display={DisplayMode.Inline} string={`M_{cp}`} />{" "}
            &mdash; as described for <strong>diversity</strong> and{" "}
            <strong>RCA</strong> &mdash; in which rows represent different
            countries and columns represent different products, we can measure
            ubiquity simply by summing over the column of that matrix. Formally,
          </p>
          <FormulaRendering
            display={DisplayMode.Block}
            string={`\\text{Ubiquity} = k_{p,0} = \\sum\\limits_{c} M_{cp}`}
          />
        </GlossaryItemDefinition>
      </Details>
      {/* 
            
            Template for adding new glossary items:

            <Details>
                <GlossaryItemHeading></GlossaryItemHeading>
                <GlossaryItemDefinition>
                    
                </GlossaryItemDefinition>
            </Details> 
            
            */}
    </DetailsPageContainer>
  );
};

export default GlossaryContent;
