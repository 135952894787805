import styled from "@emotion/styled";
import {
  PageContentContainer,
  BodyContainer as BodyContainerBase,
  gridAreas as contentPageGridAreas,
  FooterContainer,
} from "./ContentPageGrid";
import {
  GlobalContainer as GlobalContainerBase,
  gridAreas as globalGridAreas,
} from "../components/styling/GlobalGrid";
import { Outlet } from "react-router-dom";
import HeaderBanner from "../components/header/HeaderBanner";
import Footer from "./sharedComponents/Footer";
import NavigationBar, {
  NavigationBarPageType,
} from "../components/navigationBar/NavigationBar";
import InfoBanner from "../components/InfoBanner";

const BodyContainer = styled(BodyContainerBase)`
  margin-top: 3rem;
`;

const ContentPageGlobalContainer = styled(GlobalContainerBase)`
  height: auto;
  grid-template-areas:
    "${globalGridAreas.navigationBar} ${globalGridAreas.content}"
    "${contentPageGridAreas.footer} ${contentPageGridAreas.footer}";
`;

const ContentPage = () => {
  return (
    <ContentPageGlobalContainer>
      <NavigationBar pageType={NavigationBarPageType.ContentPage} />
      <PageContentContainer id="static-root">
        <HeaderBanner />
        <BodyContainer>
          <Outlet />
        </BodyContainer>
      </PageContentContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </ContentPageGlobalContainer>
  );
};

export default ContentPage;
