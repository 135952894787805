import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { gridAreas } from "./styling/GlobalGrid";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";

export const InfoBannerContainer = styled.div`
  grid-area: ${gridAreas.infoBanner};
  width: 100%;
  background-color: rgb(84, 163, 198);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: white;
  font-size: 16px;
`;

const GET_BANNER = gql`
  query GetBanner {
    banner {
      bannerId
      text
      startTime
      endTime
      ctaText
      ctaLink
    }
  }
`;

interface Banner {
  bannerId: string;
  text: string;
  startTime: string;
  endTime: string;
  ctaText: string;
  ctaLink: string;
}

const announcementBannerKey = "lastSeenBannerId";
const isStorageAvailable = (type: string) => {
  let storage: typeof localStorage;
  try {
    storage = (window as any)[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1_014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage!.length !== 0
    );
  }
};

const InfoBanner = () => {
  const { loading, error, data } = useQuery<{ banner: Banner[] }>(GET_BANNER);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [showBanner, setShowBanner] = useState(false);
  const [activeBanners, setActiveBanners] = useState<Banner[]>([]);

  useEffect(() => {
    if (data?.banner) {
      //   requestIdleCallback(() => {
      const currentTime = new Date().getTime();
      const filteredBanners = data.banner.filter((banner) => {
        const startTime = new Date(banner.startTime).getTime();
        const endTime = new Date(banner.endTime).getTime();
        return currentTime >= startTime && currentTime <= endTime;
      });

      setActiveBanners(filteredBanners);

      if (filteredBanners.length > 0 && isStorageAvailable("localStorage")) {
        const localStorage = window.localStorage;
        const dismissedBanners = JSON.parse(
          localStorage.getItem(announcementBannerKey) || "[]",
        );

        // Ensure dismissedBanners is an array
        const dismissedBannerIds = Array.isArray(dismissedBanners)
          ? dismissedBanners
          : [];

        const unseenBanners = filteredBanners.filter(
          (banner) => !dismissedBannerIds.includes(banner.bannerId),
        );

        if (unseenBanners.length > 0) {
          setShowBanner(true);
          setCurrentBannerIndex(0);
          setActiveBanners(unseenBanners);
        } else {
          setShowBanner(false);
        }
      }
      //   });
    }
  }, [data]);

  const markBannerAsSeen = (bannerId: string) => {
    if (isStorageAvailable("localStorage")) {
      const localStorage = window.localStorage;
      const dismissedBanners = JSON.parse(
        localStorage.getItem(announcementBannerKey) || "[]",
      );

      const dismissedBannerIds = Array.isArray(dismissedBanners)
        ? dismissedBanners
        : [];

      if (!dismissedBannerIds.includes(bannerId)) {
        dismissedBannerIds.push(bannerId);
        localStorage.setItem(
          announcementBannerKey,
          JSON.stringify(dismissedBannerIds),
        );
      }
    }
  };

  const dismissBanner = () => {
    const currentBannerId = activeBanners[currentBannerIndex].bannerId;
    markBannerAsSeen(currentBannerId);

    if (currentBannerIndex < activeBanners.length - 1) {
      setCurrentBannerIndex(currentBannerIndex + 1);
    } else {
      setShowBanner(false);
    }
  };

  const showNextBanner = () => {
    const currentBannerId = activeBanners[currentBannerIndex].bannerId;
    markBannerAsSeen(currentBannerId);

    if (currentBannerIndex < activeBanners.length - 1) {
      setCurrentBannerIndex(currentBannerIndex + 1);
    } else {
      setShowBanner(false);
    }
  };

  if (!showBanner || activeBanners.length === 0) return null;

  const { text, ctaText, ctaLink } = activeBanners[currentBannerIndex];

  return (
    <InfoBannerContainer className="visible">
      <div
        id="info-banner"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div />
        <span>
          <span style={{ flexGrow: 1 }}>{text}</span>{" "}
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href={ctaLink}
          >
            {ctaText}
          </a>
        </span>
        <span>
          <IconButton onClick={dismissBanner} size="small" aria-label="dismiss">
            <CloseIcon />
          </IconButton>
          {currentBannerIndex < activeBanners.length - 1 && (
            <IconButton
              onClick={showNextBanner}
              size="small"
              aria-label="next banner"
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </span>
      </div>
    </InfoBannerContainer>
  );
};

export default InfoBanner;
