import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { footerColor } from "../sharedComponents/Footer";

import { H2, H4, SubSectionHeader } from "./Utils";
import IntroCards from "./IntroCards";
import { breakpointSmall, PageSection, ArrowIcon } from "./Utils";
import VizHubIcon from "../../assets/images/VizHubIcon.svg";
import HarvardGazetteLogo from "./img/harvard-gazette.svg";
import AtlasBookJacket from "./img/atlas_bookjacket.svg";
import {
  semiBoldFontWeight,
  normalFontWeight,
  demiFontWeight,
} from "../../components/styling/cssVariables";
import TwitterFeed from "./TwitterFeed";

const containerMobileSpacing = "20px";
const containerDesktopSpacing = "100px";

const Container = styled.div`
  line-height: 1.5;
  margin: 0 auto;
  margin-top: -30px;
  display: grid;
  grid-template-columns: ${containerMobileSpacing} auto ${containerMobileSpacing};

  @media (min-width: 1000px) {
    grid-template-columns: ${containerDesktopSpacing} auto ${containerDesktopSpacing};
  }
`;

const InvertedSubSectionHeader = styled(SubSectionHeader)`
  color: white;
`;

const TestimonyAndNewsSection = styled(PageSection)`
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  grid-template-rows: 3rem repeat(auto, 4);
  color: white;
  background-color: ${footerColor};
  padding: 20px ${containerMobileSpacing};

  @media (min-width: 1000px) {
    padding: 20px ${containerDesktopSpacing};
  }

  @media (min-width: ${breakpointSmall}px) {
    grid-template-columns: 1fr 2fr 2fr;
    grid-column-gap: 3rem;
  }
`;

const HighlightedSection = styled(PageSection)`
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  padding: 0 ${containerMobileSpacing};

  @media (min-width: 1000px) {
    padding: 3rem ${containerDesktopSpacing};
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const VizHubSection = styled(HighlightedSection)`
  background-image: url("${require("./img/VizHubPattern.png")}");
  background-size: cover;
  background-position: center;
`;

const NewsLogoContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1;
  margin: 0 auto;
  margin-top: 2.5rem;
  max-width: 180px;
  text-decoration: none;

  img {
    width: 100%;
  }

  @media (min-width: ${breakpointSmall}px) {
    margin-top: 0;
  }
`;

const NewsLogoContainer1 = styled(NewsLogoContainer)`
  @media (min-width: ${breakpointSmall}px) {
    max-width: 80%;
  }
`;
const NewsLogoContainer4 = styled(NewsLogoContainer)`
  @media (min-width: ${breakpointSmall}px) {
    max-width: 40%;
  }
`;
const NewsTitleContainer = styled.a`
  display: block;
  font-size: 1.25rem;
  grid-column: 1;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  font-weight: ${normalFontWeight};

  @media (min-width: ${breakpointSmall}px) {
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1;

  @media (min-width: ${breakpointSmall}px) {
    grid-column: 3;
    grid-row: 2 / 6;
  }
`;

const TestimonyQuoteContainer = styled.div`
  justify-content: flex-start;
`;
const TestimonyQuote = styled(H2)`
  font-size: 1.2rem;
  color: white;
`;

const newsSectionStyle = css`
  letter-spacing: 0.1rem;
  margin-bottom: 8vh;
  grid-column: 1;
`;

const NewNewsTitle = styled.div`
  ${newsSectionStyle}
  grid-row: 1;
  grid-column: 1 / 4;
  text-align: center;
  border-bottom: 1px solid #fff;
  display: block;
  margin: 0 auto;
  width: 100%;
`;

const ResourcesContainer = styled(PageSection)`
  display: grid;
  grid-row-gap: 5%;
  margin-bottom: 10vh;

  @media (min-width: ${breakpointSmall}px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5%;
    margin-bottom: 5vh;
  }
`;

const buttonColor = "#666";
const CallToAction = styled.div`
  display: block;
  border: 1px solid ${buttonColor};
  padding: 0.5rem;
  background-color: #fff;
  color: ${buttonColor};
  float: right;
  border-radius: 30px;
  text-transform: uppercase;
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    color: #fff;
    background-color: ${buttonColor};
  }
`;
const EBookContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-size: 100% 100%;

  @media (min-width: ${breakpointSmall}px) {
    grid-row: 1;
    grid-column: 2;
  }
`;

const BookImageContainer = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 15%;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.28);
  box-sizing: border-box;
`;

const HarvardGazetteLogoContainer = styled.div`
  svg {
    width: 100%;
    max-height: 65px;

    path {
      fill: #fff;
    }
  }
`;

const VizHubTitle = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
`;
const VizHubLogo = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  img {
    width: 100%;
    height: 100%;

    .cls-1 {
      fill: #dc5052;
      opacity: 0.9;
    }

    .cls-2 {
      fill: #47566d;
    }
  }
`;
const MetroverseLogo = styled.img`
  width: 100%;
  margin-bottom: 3rem;
`;
const GoToButton = styled.a`
  background-color: #fff;
  padding: 0.5rem 1.2rem 0.5rem 2rem;
  font-size: 1.25rem;
  border-radius: 200px;
  border: solid 1px #47566d;
  color: #47566d;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: ${semiBoldFontWeight};

  svg {
    width: 0.7rem;
    height: 0.7rem;
    margin-left: 0.7rem;
    fill: #dc5052;
  }

  &:hover {
    background-color: #47566d;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
`;
const VizHubDescription = styled.div`
  font-size: 1.25rem;
  line-height: 1.7;
  background-color: #fff;
  padding: 1.5rem 2rem;
  border-left: solid 4px #bbb04a;
`;

const MetroverseDescription = styled(VizHubDescription)`
  background-color: #dcdfe5;
`;

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 3;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 5vh 0
  padding: 0 ${containerMobileSpacing};
  box-sizing: border-box;

  @media (min-width: 1000px) {
    padding: 0 ${containerDesktopSpacing};
  }
`;

const MapHeader = styled.h1`
  align-self: flex-start;
  font-weight: ${demiFontWeight};
  font-size: 2rem;
  color: rgb(153, 153, 170);
  margin-bottom: 1rem;
`;

const MapDescription = styled.p`
  font-weight: ${demiFontWeight};
  font-size: 1rem;
  letter-spacing: 0.04rem;
  opacity: 1;
  margin-bottom: 2rem;

  b {
    font-weight: ${semiBoldFontWeight};
    color: rgb(90, 112, 140);
  }
`;

const MapIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 450px;
  max-width: 100%;
  aspect-ratio: 950 / 450;

  @media (max-width: 768px) {
    height: 56.25vw; // 16:9 aspect ratio
    max-width: calc(100vw - 2 * ${containerMobileSpacing});
  }
`;

const LandingPageMap = () => {
  return (
    <MapContainer>
      <MapHeader>Atlas in Action</MapHeader>
      <MapDescription>
        The Atlas has reached more than 950,000 people in 228 countries. See the
        real-world impact of The Atlas as it's used in global trainings,
        strategic development projects, executive education, and more.{" "}
        <b>
          Click on the highlighted countries on the map to learn more about
          these projects.
        </b>
      </MapDescription>
      <MapIframe
        src="https://cid-harvard.github.io/landing-map/index.html"
        title="Highlighted World Map"
      />
    </MapContainer>
  );
};

const Content = () => {
  const bloombergLink =
    /* tslint:disable-next-line:max-line-length */
    `https://www.bloomberg.com/opinion/articles/2019-09-18/repo-market-spike-carries-echoes-of-2007-2008-crisis-k0oqoebp`;
  const harvardGazetteLink =
    /* tslint:disable-next-line:max-line-length */
    `https://news.harvard.edu/gazette/story/2019/09/kennedy-schools-growth-lab-tool-helps-chart-paths-for-economic-growth/`;
  const ftLink = `https://www.ft.com/content/0297ff7c-524e-11e8-b3ee-41e0209208ec`;
  const nprLink = `https://www.npr.org/sections/money/2018/02/28/589609380/episode-827-three-indicators`;

  return (
    <Container>
      <IntroCards />

      <VizHubSection>
        <VizHubTitle>
          <VizHubLogo>
            <img src={VizHubIcon} />
          </VizHubLogo>
          <div>
            <GoToButton
              href="https://growthlab.app/"
              target="_blank"
              rel="noopener"
            >
              Go to Viz Hub
              <ArrowIcon />
            </GoToButton>
          </div>
        </VizHubTitle>
        <VizHubDescription>
          The Atlas of Economic Complexity is part of Harvard's Growth Lab Viz
          Hub, a portfolio of award-winning, interactive visualizations powered
          by the Growth Lab's research and insights. Check out the Viz Hub to
          learn more about concepts found in the Atlas along with our other
          free, online tools and software packages.
        </VizHubDescription>
      </VizHubSection>
      <HighlightedSection>
        <VizHubTitle>
          <MetroverseLogo src={require("./img/metroverse-logo.png")} />
          <div>
            <GoToButton
              href="https://metroverse.cid.harvard.edu/"
              target="_blank"
              rel="noopener"
            >
              Go to Metroverse
              <ArrowIcon />
            </GoToButton>
          </div>
        </VizHubTitle>
        <MetroverseDescription>
          Metroverse is the Growth Lab's urban economy navigator covering over
          1000 cities across the world. By combining cutting-edge research,
          high-resolution datasets and stunning data visualizations, Metroverse
          makes visible what a city is good at today to help understand what it
          can become tomorrow.
        </MetroverseDescription>
      </HighlightedSection>
      <TestimonyAndNewsSection>
        <NewNewsTitle>
          <InvertedSubSectionHeader>In the News</InvertedSubSectionHeader>
        </NewNewsTitle>
        <NewsLogoContainer1 href={bloombergLink} target="_blank" rel="noopener">
          <img src={require("./img/Bloomberg_white.png")} />
        </NewsLogoContainer1>
        <NewsTitleContainer href={bloombergLink} target="_blank" rel="noopener">
          Markets Are Starting to Play a Haunting 2007 Tune
        </NewsTitleContainer>

        <NewsLogoContainer1
          href={harvardGazetteLink}
          target="_blank"
          rel="noopener"
        >
          <img src={HarvardGazetteLogo} />
        </NewsLogoContainer1>
        <NewsTitleContainer
          href={harvardGazetteLink}
          target="_blank"
          rel="noopener"
        >
          New interactive website helps chart paths for economic growth
        </NewsTitleContainer>

        <NewsLogoContainer1 href={ftLink} target="_blank" rel="noopener">
          <img src={require("./img/financialtimes_white.png")} />
        </NewsLogoContainer1>
        <NewsTitleContainer href={ftLink} target="_blank" rel="noopener">
          Authors' Note: Turning and turning in the widening gyre
        </NewsTitleContainer>

        <NewsLogoContainer4 href={nprLink} target="_blank" rel="noopener">
          <img src={require("./img/npr_white.png")} />
        </NewsLogoContainer4>
        <NewsTitleContainer href={nprLink} target="_blank" rel="noopener">
          Planet Money: The Three Indicators
        </NewsTitleContainer>

        <TestimonialContainer>
          <TestimonyQuoteContainer>
            <TestimonyQuote>
              "This is like adding the Periodic Table to chemistry. It organizes
              knowledge in a way that helps everyone: from student to journalist
              to policy maker to investors to economic experts."
            </TestimonyQuote>
            <H4>
              Lant Pritchett, RISE Research Director, University of Oxford
            </H4>
          </TestimonyQuoteContainer>

          <TestimonyQuoteContainer>
            <TestimonyQuote>
              "If you like data visualizations and are interested in analyzing
              trade flows and the sectoral composition of an economy, you can't
              beat this website from Harvard."
            </TestimonyQuote>
            <H4>The Guardian</H4>
          </TestimonyQuoteContainer>

          <TestimonyQuoteContainer>
            <TestimonyQuote>
              "...fascinating research done by the Center for International
              Development at Harvard University, which has a successful record
              of identifying which countries are positioned to grow."
            </TestimonyQuote>
            <H4>Financial Times</H4>
          </TestimonyQuoteContainer>
        </TestimonialContainer>
      </TestimonyAndNewsSection>

      <LandingPageMap />

      <ResourcesContainer>
        <TwitterFeed />
        <EBookContainer
          style={{
            backgroundImage: `url('${require("./img/book-pattern_image.png")}')`,
          }}
        >
          <BookImageContainer>
            <img src={AtlasBookJacket} />
          </BookImageContainer>
          <CallToAction>
            <a
              href="https://growthlab.cid.harvard.edu/publications/format/books"
              target="_blank"
              rel="noopener"
            >
              Download the 2013 and 2011 editions
            </a>
          </CallToAction>
        </EBookContainer>
      </ResourcesContainer>
    </Container>
  );
};

export default Content;
