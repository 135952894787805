import { useSelect } from "downshift";
import { useState } from "react";
import styled from "@emotion/styled";
import {
  SelectBoxContainer,
  DropdownContainer,
  ToggleButton,
  ArrowUp,
  ArrowDown,
  MainMenu,
} from "./styles";
import { ColorBy, ProductLevel, VizType } from "../../../visualization/Utils";
import { SettingOption } from "./SettingsDropdown";
import { DisabledSettingsDropdownItem } from "./SettingsDropdownItem";
import { useLocation } from "react-router-dom";
import { usePageQueryParams } from "../../../visualization/defaultSettings";

interface SelectBoxInput {
  items: any[];
  settingOption?: SettingOption;
  hashFunction: any;
  ItemRenderComponent: any;
  onChangeEvent: any;
  matchSelectedItem: any | undefined;
}

const SelectedItemLabel = styled.div`
  width: 100%;
  padding: 1px 2px 1px 6px;
  box-sizing: border-box;
  display: inline-block;
  margin: auto 0px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const SelectBox = ({
  settingOption,
  items,
  hashFunction,
  ItemRenderComponent,
  onChangeEvent,
  matchSelectedItem,
}: SelectBoxInput) => {
  const location = useLocation();
  const currentVizType = location.pathname.split("/")[2] as any;

  const [{ productLevel: currentProductLevel }] = usePageQueryParams() as any;

  function ComboBox() {
    const [displayItems, setDisplayItems] = useState(items);

    const {
      // Prop getters:
      getToggleButtonProps, // Attributes to be applied to a menu toggle button <button>
      getLabelProps, // Attributes to be applied to <label> element, e.g., aria-label
      getMenuProps, // Attributes to be applied to <ul> or root of menu to be rendered
      getItemProps, // Attributes to be applied to menu items, e.g., <li> elements

      // State values:
      selectedItem, // Current selected item
      highlightedIndex,
      isOpen, // Is the dropdown open?
      inputValue, // the value in the input field

      // Actions:
      closeMenu, // closes the menu
      openMenu, // opens the menu
      selectItem, // selects a given item
      setHighlightedIndex, // sets a new highlighted item index
      toggleMenu, // toggles menu open state
    } = useSelect({
      // This handles the autocomplete behavior, i.e., as the user types, filtered list of items displayed
      onSelectedItemChange({ selectedItem }) {
        // if (selectedItem) {
        //   if (!matchSelectedItem) {
        //     onChangeEvent({ value: selectedItem.value });
        //   } else if (
        //     matchSelectedItem &&
        //     matchSelectedItem.value !== selectedItem.value
        //   ) {
        //     onChangeEvent({ value: selectedItem.value });
        //   }
        onChangeEvent({ value: selectedItem.value });
        // }
      },
      // The actual items to be displayed in the dropdown at time of render
      items: displayItems,
      // Accessor function to get label for item in dropdown
      itemToString(item) {
        return item ? hashFunction(item) : "";
      },
    });

    if (matchSelectedItem && !selectedItem) {
      selectItem(matchSelectedItem);
    }

    return (
      <SelectBoxContainer>
        {/* <label {...getLabelProps()}>
                Choose item:
                </label> */}
        {/* Note: We are applying the Downshift-generated toggle button props 
                to the `DropdownContainer` here so that the whole dropdown behaves like a button,
                instead of just the arrow button itself */}
        <DropdownContainer
          aria-label="toggle menu"
          type="button"
          {...getToggleButtonProps()}
          className={isOpen ? "dropdown-open" : ""}
        >
          <SelectedItemLabel {...getLabelProps()}>
            {selectedItem ? selectedItem.label : ""}
          </SelectedItemLabel>
          <ToggleButton>{isOpen ? <ArrowUp /> : <ArrowDown />}</ToggleButton>
        </DropdownContainer>
        {isOpen && (
          <MainMenu {...getMenuProps()}>
            {
              // Mapping `items` to <li> elements, if menu is open
              displayItems.map((item: any, index: number) => {
                // getItemProps is called here for each item in the <ul> --
                // passing `item` and `index` helps Downshift keep track of which item
                // gets selected or highlighted from user interact

                if (
                  settingOption &&
                  settingOption === SettingOption.ColorBy &&
                  item.value === ColorBy.Complexity &&
                  currentVizType === VizType.Tree &&
                  currentProductLevel != ProductLevel.Product4digit
                ) {
                  return (
                    <DisabledSettingsDropdownItem
                      item={item}
                      index={index}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                      getItemProps={getItemProps}
                      hashFunction={hashFunction}
                    />
                  );
                } else {
                  return (
                    <ItemRenderComponent
                      item={item}
                      index={index}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                      getItemProps={getItemProps}
                      hashFunction={hashFunction}
                    />
                  );
                }
              })
            }
          </MainMenu>
        )}
      </SelectBoxContainer>
    );
  }
  return <ComboBox />;
};

export default SelectBox;
