import {
  ProductLevel,
  TradeDirection,
  computeTotalSumByTradeFlow,
} from "../../Utils";
import { groups, index } from "d3";
import { TradeValueScaledByYAxisOption } from "./Chart";

const productShareThreshold = 0.01 / 100; // Product share threshold of 0.01%

const computeScaledTradeValues = ({
  productsData,
  deflatorForYearMatchingMetadata,
  populationForYearMatchingMetadata,
  tradeFlow,
}: any) => {
  let tradeValues = {
    [TradeValueScaledByYAxisOption.Current]: {
      exportValue: 0,
      importValue: 0,
    },
    [TradeValueScaledByYAxisOption.Constant]: {
      exportValue: 0,
      importValue: 0,
    },
    [TradeValueScaledByYAxisOption.PerCapita]: {
      exportValue: 0,
      importValue: 0,
    },
    [TradeValueScaledByYAxisOption.PerCapitaConstant]: {
      exportValue: 0,
      importValue: 0,
    },
  };

  // Only try to compute values if we have valid data
  if (productsData && productsData.length > 0) {
    const sumExportValue = computeTotalSumByTradeFlow({
      data: productsData,
      tradeFlow,
      tradeDirection: TradeDirection.Exports,
    });
    const sumImportValue = computeTotalSumByTradeFlow({
      data: productsData,
      tradeFlow,
      tradeDirection: TradeDirection.Imports,
    });

    tradeValues[TradeValueScaledByYAxisOption.Current] = {
      exportValue: sumExportValue ?? 0,
      importValue: sumImportValue ?? 0,
    };

    // Only compute other scaled values if we have the required metadata
    if (deflatorForYearMatchingMetadata?.deflator) {
      let { deflator } = deflatorForYearMatchingMetadata;
      tradeValues[TradeValueScaledByYAxisOption.Constant] = {
        exportValue: sumExportValue ? sumExportValue / deflator : 0,
        importValue: sumImportValue ? sumImportValue / deflator : 0,
      };
    }

    if (populationForYearMatchingMetadata?.population) {
      let { population } = populationForYearMatchingMetadata;
      tradeValues[TradeValueScaledByYAxisOption.PerCapita] = {
        exportValue: sumExportValue ? sumExportValue / population : 0,
        importValue: sumImportValue ? sumImportValue / population : 0,
      };
    }

    if (
      deflatorForYearMatchingMetadata?.deflator &&
      populationForYearMatchingMetadata?.population
    ) {
      let { deflator } = deflatorForYearMatchingMetadata;
      let { population } = populationForYearMatchingMetadata;
      tradeValues[TradeValueScaledByYAxisOption.PerCapitaConstant] = {
        exportValue: sumExportValue
          ? sumExportValue / deflator / population
          : 0,
        importValue: sumImportValue
          ? sumImportValue / deflator / population
          : 0,
      };
    }
  }

  return tradeValues;
};

const makeCompleteObservationsProducts = ({
  inputData,
  yearRangeForProductClass,
}: any) => {
  const { startYear, endYear } = yearRangeForProductClass;

  let completeObservationsProducts: any[] = [];

  groups(
    inputData,
    (d: any) => d.productId,
    (d: any) => d.year,
  ).forEach((d: any) => {
    const yearsData = d[1];

    let prototypeDatumForYear: any;

    yearsData.forEach((yearDatum: any, i: number) => {
      const productsData = yearDatum[1];
      completeObservationsProducts.push(...productsData);
      if (i === 0) {
        prototypeDatumForYear = Object.assign({}, productsData[0]);
      }
    });

    const yearsDataLookup = index(yearsData, (d) => d[0]);
    for (let checkYear = startYear; checkYear <= endYear; checkYear++) {
      let findMatchingYear = yearsDataLookup.get(checkYear);
      if (!findMatchingYear) {
        let completeObservationForYear = {
          ...prototypeDatumForYear,
          year: checkYear,
          exportValue: 0,
          importValue: 0,
        };

        completeObservationsProducts.push(completeObservationForYear);
      }
    }
  });

  return completeObservationsProducts;
};

const transformProducts = ({
  productLevel,
  untransformedData,
  products,
  yAxisScalingOption,
  deflators,
  locationYearPopulations,
  yearRangeForProductClass,
  tradeFlow,
}: any) => {
  let flatGroupedByProductAndYear: any[] = [];

  if (untransformedData.length == 0) {
    return undefined;
  }

  const data = makeCompleteObservationsProducts({
    inputData: untransformedData,
    yearRangeForProductClass,
  });
  const productLookup = index(products, (d) => d.productId) as any;
  const deflatorsLookup = index(deflators || [], (d) => d.year) as any;
  const locationYearPopulationsLookup = index(
    locationYearPopulations || [],
    (d) => d.year,
  ) as any;
  if (productLevel === ProductLevel.ProductSection) {
    groups(
      data,
      (d: any) => d.productId,
      (d: any) => d.year,
    ).map((d: any) => {
      const productId = d[0];
      const yearsData = d[1];
      let findMatchingMetadata = productLookup.get(productId);
      let nameEn: string | undefined = undefined;
      if (findMatchingMetadata) {
        nameEn = findMatchingMetadata.nameShortEn;
      }

      const summarizedByYear = yearsData.map((d: any) => {
        const year = d[0];
        const productsData = d[1];

        let deflatorForYearMatchingMetadata = deflatorsLookup.get(year);
        let populationForYearMatchingMetadata =
          locationYearPopulations && locationYearPopulationsLookup.get(year);

        let tradeValues = computeScaledTradeValues({
          productsData,
          tradeFlow,
          deflatorForYearMatchingMetadata,
          populationForYearMatchingMetadata,
        });

        return {
          year,
          productId,
          nameEn,
          productsData,
          ...tradeValues,
        };
      });

      flatGroupedByProductAndYear.push(...summarizedByYear);
    });

    return flatGroupedByProductAndYear;
  } else {
    groups(
      data,
      (d: any) => d.productId,
      (d: any) => d.year,
    ).map((d: any) => {
      const productId = d[0];
      const yearsData = d[1];
      let findMatchingMetadata = productLookup.get(productId);
      let topLevelParent: string | undefined = undefined;
      let nameEn: string | undefined = undefined;
      if (findMatchingMetadata) {
        topLevelParent = findMatchingMetadata.topParent.productId;
        nameEn = findMatchingMetadata.nameShortEn;
      }

      const summarizedByYear = yearsData.map((d: any) => {
        const year = d[0];
        const productsData = d[1];

        let deflatorForYearMatchingMetadata = deflatorsLookup.get(year);
        let populationForYearMatchingMetadata =
          locationYearPopulations && locationYearPopulationsLookup.get(year);

        let tradeValues = computeScaledTradeValues({
          productsData,
          tradeFlow,
          deflatorForYearMatchingMetadata,
          populationForYearMatchingMetadata,
        });

        return {
          year,
          productId,
          nameEn,
          topLevelParent,
          productsData,
          ...tradeValues,
        };
      });

      flatGroupedByProductAndYear.push(...summarizedByYear);
    });

    return flatGroupedByProductAndYear;
  }
};

export default transformProducts;
