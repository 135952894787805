import styled from "@emotion/styled";
import { menuBaseColor } from "../../../components/styling/cssVariables";

export const HeaderAtlasLogo = styled.div`
  max-width: 250px;
  min-width: 100px;
  width: 100%;
  padding-top: 6px;
  img {
    width: 100%;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const HeaderNavigationList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  color: #ffffff;
  padding: 0;
  padding-left: 1rem;
`;

export const HeaderNavigationListItem = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  position: relative;
  background-color: transparent;
  color: inherit;

  & a {
    height: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 0.625rem;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    font-size: 0.9375rem;
    text-decoration: none;
    color: inherit;
  }

  & :hover {
    background-color: ${menuBaseColor};
    color: white;
  }
`;
