import { GroupLevel, LocationLevel } from "../graphql/types";
import { ProductClass } from "../visualization/Utils";
import clusterColors from "../visualization/charts/productspace/cluster_colors.json";
// The `location ID delimiter` refers to the character used
// to separate the location level from the numeric identifier,
// i.e., in `country-392`, the hyphen `-` is the delimiter
// separating these components
const locationIDDelimiter = "-";

export function determineLocationLevel({ location }: { location: string }) {
  const splitIDComponents = location.split(locationIDDelimiter);
  const locationLevel = splitIDComponents[0];

  if (locationLevel === LocationLevel.Country) {
    return LocationLevel.Country;
  } else if (locationLevel === LocationLevel.Group) {
    return LocationLevel.Group;
  }
}

export enum LocationStringPrefixes {
  Country = "country",
  Group = "group",
}

export const getNumericIdFromStringLocationId = (
  input: string | undefined,
): number | undefined => {
  if (input) {
    let strippedStringValue = input
      .replace(`${LocationStringPrefixes.Country}-`, "")
      .replace(`${LocationStringPrefixes.Group}-`, "");
    return Number(strippedStringValue);
  } else {
    return undefined;
  }
};

export const getLocationLevelFromStringLocationId = (
  input: string | undefined,
): LocationLevel | undefined => {
  let locationLevel: LocationLevel | undefined = undefined;
  if (input) {
    if (input.includes(LocationStringPrefixes.Country)) {
      locationLevel = LocationLevel.Country;
    } else if (input.includes(LocationStringPrefixes.Group)) {
      locationLevel = LocationLevel.Group;
    }
  }
  return locationLevel;
};

export const getGroupLevelFromGroupMetadatum = (input: any) => {
  let groupLevel: GroupLevel | undefined;
  if (input === undefined) {
    groupLevel = undefined;
  } else {
    let { groupType } = input;
    if (groupType === GroupLevel.Region) {
      groupLevel = GroupLevel.Region;
    } else if (groupType === GroupLevel.Subregion) {
      groupLevel = GroupLevel.Subregion;
    } else {
      groupLevel = undefined;
    }
  }

  return groupLevel;
};

/* A utility function to convert product String IDs, e.g., `product-hs92-1`, into numbers, e.g., 1,
for use in GraphQL queries */
export const convertProductStringToNumberId = (
  input: string | undefined,
): number | undefined => {
  if (input) {
    return +input
      .replace("product-HS92-", "")
      .replace("product-SITC-", "")
      .replace("product-HS12-", "");
  } else {
    return undefined;
  }
};

export enum NewRegionColor {
  Asia = "rgb(108, 197, 134)",
  Africa = "rgb(120, 59, 217)",
  Oceania = "rgb(245, 190, 105)",
  Europe = "rgb(88, 130, 184)",
  Americas = "rgb(158, 70, 67)",
  Other = "rgb(88, 103, 132)",
}

export const otherGroupId: string = "group-998";
export const undeclaredCountryId: string = "country-999";
export const unidentifiedServicesCountryId: string = "country-914";

export const groupedRegionColorMap: Map<string, string> = new Map([
  ["group-2", NewRegionColor.Africa],
  ["group-19", NewRegionColor.Americas],
  ["group-142", NewRegionColor.Asia],
  ["group-150", NewRegionColor.Europe],
  ["group-9", NewRegionColor.Oceania],
  [otherGroupId, NewRegionColor.Other],
]);

// The following is also the order of regions to be used when sorting data, e.g., in stack chart
export const groupNames: Map<string, string> = new Map([
  ["group-2", "Africa"],
  ["group-19", "Americas"],
  ["group-142", "Asia"],
  ["group-150", "Europe"],
  ["group-9", "Oceania"],
  ["group-998", "Other"],
]);

export const clusterColorsMap: Map<string, string> = new Map(
  clusterColors.map((c) => [c.cluster_name_short, c.cluster_col]),
);

enum HS92Color {
  vegetable = "#F5CF23",
  minerals = "rgb(187, 150, 138)",
  chemicals = "rgb(197, 123, 217)",
  textiles = "rgb(125, 218, 161)",
  stone = "rgb(218, 180, 125)",
  metals = "rgb(217, 123, 123)",
  machinery = "rgb(123, 162, 217)",
  electronics = "rgb(125, 218, 218)",
  transport = "rgb(141, 123, 216)",
  others = "#2a607c",
  services = "rgb(178, 61, 109)",
}

export const hs92ServicesCategory = "product-HS92-14";
export const hs92OtherCategory = "product-HS92-10";

export const hs92ColorsMap: Map<string, string> = new Map([
  ["product-HS92-1", HS92Color.textiles],
  ["product-HS92-2", HS92Color.vegetable],
  ["product-HS92-3", HS92Color.stone],
  ["product-HS92-4", HS92Color.minerals],
  ["product-HS92-5", HS92Color.metals],
  ["product-HS92-6", HS92Color.chemicals],
  ["product-HS92-7", HS92Color.transport],
  ["product-HS92-8", HS92Color.machinery],
  ["product-HS92-9", HS92Color.electronics],
  [hs92OtherCategory, HS92Color.others],
  [hs92ServicesCategory, HS92Color.services],
]);

export const hs92Names: Map<string, string> = new Map([
  ["product-HS92-1", "Textiles"],
  ["product-HS92-2", "Agriculture"],
  ["product-HS92-3", "Stone"],
  ["product-HS92-4", "Minerals"],
  ["product-HS92-5", "Metals"],
  ["product-HS92-6", "Chemicals"],
  ["product-HS92-7", "Vehicles"],
  ["product-HS92-8", "Machinery"],
  ["product-HS92-9", "Electronics"],
  [hs92OtherCategory, "Other"],
  [hs92ServicesCategory, "Services"],
]);

export const hs92CategorySortingOrder: string[] = [
  "product-HS92-1",
  "product-HS92-2",
  "product-HS92-3",
  "product-HS92-4",
  "product-HS92-5",
  "product-HS92-6",
  "product-HS92-7",
  "product-HS92-8",
  "product-HS92-9",
  hs92OtherCategory,
  hs92ServicesCategory,
];

enum HS12Color {
  vegetable = "#F5CF23",
  minerals = "rgb(187, 150, 138)",
  chemicals = "rgb(197, 123, 217)",
  textiles = "rgb(125, 218, 161)",
  stone = "rgb(218, 180, 125)",
  metals = "rgb(217, 123, 123)",
  machinery = "rgb(123, 162, 217)",
  electronics = "rgb(125, 218, 218)",
  transport = "rgb(141, 123, 216)",
  others = "#2a607c",
  services = "rgb(178, 61, 109)",
}

export const hs12ServicesCategory = "product-HS12-14";
export const hs12OtherCategory = "product-HS12-10";

export const hs12ColorsMap: Map<string, string> = new Map([
  ["product-HS12-1", HS12Color.textiles],
  ["product-HS12-2", HS12Color.vegetable],
  ["product-HS12-3", HS12Color.stone],
  ["product-HS12-4", HS12Color.minerals],
  ["product-HS12-5", HS12Color.metals],
  ["product-HS12-6", HS12Color.chemicals],
  ["product-HS12-7", HS12Color.transport],
  ["product-HS12-8", HS12Color.machinery],
  ["product-HS12-9", HS12Color.electronics],
  [hs12OtherCategory, HS12Color.others],
  [hs12ServicesCategory, HS12Color.services],
]);

export const hs12Names: Map<string, string> = new Map([
  ["product-HS12-1", "Textiles"],
  ["product-HS12-2", "Agriculture"],
  ["product-HS12-3", "Stone"],
  ["product-HS12-4", "Minerals"],
  ["product-HS12-5", "Metals"],
  ["product-HS12-6", "Chemicals"],
  ["product-HS12-7", "Vehicles"],
  ["product-HS12-8", "Machinery"],
  ["product-HS12-9", "Electronics"],
  [hs12OtherCategory, "Other"],
  [hs12ServicesCategory, "Services"],
]);

export const hs12CategorySortingOrder: string[] = [
  "product-HS12-1",
  "product-HS12-2",
  "product-HS12-3",
  "product-HS12-4",
  "product-HS12-5",
  "product-HS12-6",
  "product-HS12-7",
  "product-HS12-8",
  "product-HS12-9",
  hs12OtherCategory,
  hs12ServicesCategory,
];

enum SITCColor {
  food = "#F5CF23",
  drinks = "#FE85AD",
  materials = "rgb(217, 123, 123)",
  mineral = "rgb(187, 150, 138)",
  fats = "#FDA81B",
  chemical = "rgb(197, 123, 217)",
  goods = "#DA3329",
  machinery = "rgb(123, 162, 217)",
  commodities = "#00A6AA",
  miscellaneous = "#2a607c",
  services = "rgb(178, 61, 109)",
}

export const sitcServicesCategory = "product-SITC-14";
export const sitcColorsMap: Map<string, string> = new Map([
  ["product-SITC-1", SITCColor.food],
  ["product-SITC-2", SITCColor.drinks],
  ["product-SITC-3", SITCColor.materials],
  ["product-SITC-4", SITCColor.mineral],
  ["product-SITC-5", SITCColor.fats],
  ["product-SITC-6", SITCColor.chemical],
  ["product-SITC-7", SITCColor.goods],
  ["product-SITC-8", SITCColor.machinery],
  ["product-SITC-9", SITCColor.commodities],
  ["product-SITC-10", SITCColor.miscellaneous],
  [sitcServicesCategory, SITCColor.services],
]);

export const sitcNames: Map<string, string> = new Map([
  ["product-SITC-1", "Food"],
  ["product-SITC-2", "Beverages"],
  ["product-SITC-3", "Crude Materials"],
  ["product-SITC-4", "Fuels"],
  ["product-SITC-5", "Vegetable Oils"],
  ["product-SITC-6", "Chemicals"],
  ["product-SITC-7", "Material Manufacturers"],
  ["product-SITC-8", "Machinery & Vehicles"],
  ["product-SITC-9", "Other Manufacturers"],
  ["product-SITC-10", "Unspecified"],
  [sitcServicesCategory, "Services"],
]);

export const getCategorySortingOrderFromProductClass = ({
  productClass,
}: {
  productClass: ProductClass;
}): string[] => {
  let sortingOrder: string[];
  if (productClass === ProductClass.HS92Products) {
    sortingOrder = hs92CategorySortingOrder;
  } else if (productClass === ProductClass.HS12Products) {
    sortingOrder = hs12CategorySortingOrder;
  } else if (productClass === ProductClass.SITCProducts) {
    sortingOrder = [];
  } else {
    sortingOrder = [];
  }

  return sortingOrder;
};

export const getRegionSortingOrder = () => {
  let sortingOrder: string[] = [
    "group-2",
    "group-19",
    "group-142",
    "group-150",
    "group-9",
    "group-998",
  ];
  return sortingOrder;
};

export const getProductClassServicesCategoryId = ({
  productClass,
}: {
  productClass: ProductClass;
}) => {
  let servicesCategoryId: string | undefined;

  if (productClass === ProductClass.HS92Products) {
    servicesCategoryId = hs92ServicesCategory;
  } else if (productClass === ProductClass.HS12Products) {
    servicesCategoryId = hs12ServicesCategory;
  } else if (productClass === ProductClass.SITCProducts) {
    servicesCategoryId = sitcServicesCategory;
  } else {
    servicesCategoryId = undefined;
  }

  return servicesCategoryId;
};
