import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { navigationBarColor } from "./cssVariables";

export const gridLines = {
  globalPageLeft: "GlobalPageLeft",
  globalPageRight: "GlobalPageRight",
  globalPageTop: "GlobalPageTop",
  globalPageBottom: "GlobalPageBottom",
  infoBannerBottom: "InfoBannerBottom",
  globalControlsLeft: "GlobalControlsLeft",
  globalNavigationBarLeft: "GlobalNavigationBarLeft",
  globalNavigationBarRight: "GlobalNavigationBarRight",
  globalMainContentAreaLeft: "GlobalMainContentAreaLeft",
  globalMainContentAreaRight: "GlobalMainContentAreaRight",
  globalFooterTop: "GlobalFooterTop",
  globalFooterBottom: "GlobalFooterBottom",
};

export const gridAreas = {
  infoBanner: "infoBanner",
  navigationBar: "navigationBar",
  content: "content",
  controls: "ControlsArea",
  footer: "FooterArea",
};

/*
In the following grid-template specifications, the use of minmax()
helps constrain the size of the grid tracks. If fr units are used by
themselves, e.g., 3fr 1fr, then the tracks will be allowed to dynamically
change size according to the size of content inside of them.
Using minmax(0, __) prevents this automatic resizing from happening.
*/

export const GlobalContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
    grid-template-areas:
    "navigationBar content";
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: 65px 1fr;(0, 1fr);
  background-color: white;
`;

const slideOpenMenu = keyframes`
    from {
        width: 65px;
        padding: 0px;
    }
    to {
        width: 300px;
        padding: 0px 15px;
    }
`;

const slideCloseMenu = keyframes`
    from {
        width: 300px;
        padding: 0px 15px;
    }
    to {
        width: 65px;
        padding: 0px;
    }
`;

export const NavigationBarContainer = styled.div`
  grid-area: ${gridAreas.navigationBar};
  background-color: ${navigationBarColor};
  display: flex;
  flex-direction: column;
  padding: 0px;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 1000;

  &.expanded {
    animation-name: ${slideOpenMenu};
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    overflow-y: auto;
  }

  &.closed {
    animation-name: ${slideCloseMenu};
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    overflow-y: hidden;
  }

  &.contentPage {
    background: none;
  }

  &.contentPage.expanded {
    background-color: ${navigationBarColor};
  }
`;

export const MainContentContainer = styled.div`
  grid-area: ${gridAreas.content};
  padding: 0px;
  box-sizing: border-box;
`;

export const breakPointValues = {
  width: {
    medium: 1100,
    mediumSmall: 900,
    small: 800,
  },
  height: {
    medium: 768,
    mediumSmall: 550,
    small: 500,
  },
};

export const breakPoints = {
  medium: `screen and (max-width: ${breakPointValues.width.medium}px),
            screen and (max-height: ${breakPointValues.height.medium}px)`,
  mediumSmall: `screen and (max-width: ${breakPointValues.width.mediumSmall}px),
            screen and (max-height: ${breakPointValues.height.mediumSmall}px)`,
  small: `screen and (max-width: ${breakPointValues.width.small}px),
            screen and (max-height: ${breakPointValues.height.small}px)`,
};
