import { gql } from "@apollo/client";
export const ProductMetadataFragment = gql`
  fragment ProductMetadata on Product {
    productId
    productLevel
    code
    nameEn
    nameShortEn
    showFeasibility
    parent {
      productId
      productLevel
    }
    topParent {
      productId
      productLevel
    }
    greenProduct
  }
`;

const query = gql`
  query GetHS12Products($servicesClass: ServicesClass) {
    section: productHs12(productLevel: 1, servicesClass: $servicesClass) {
      ...ProductMetadata
    }

    twoDigit: productHs12(productLevel: 2, servicesClass: $servicesClass) {
      ...ProductMetadata
    }

    fourDigit: productHs12(productLevel: 4, servicesClass: $servicesClass) {
      ...ProductMetadata
    }

    sixDigit: productHs12(productLevel: 6, servicesClass: $servicesClass) {
      ...ProductMetadata
    }
  }
  ${ProductMetadataFragment}
`;

export default query;
