import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import sharedDataReducer from "./sharedDataSlice";

import * as Sentry from "@sentry/react";

// See: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    sharedData: sharedDataReducer,
  },
  enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
