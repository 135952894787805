import styled from "@emotion/styled";
import { easeQuadOut, select } from "d3";
import { memo, useEffect, useRef, useState } from "react";

const MarketSharePathElement = styled.path`
  stroke-width: 3;
  fill: none;
`;

const circleMarkerRadius = 5;

const MarketShareCircleElement = styled.circle`
  r: ${circleMarkerRadius};
`;

const MarketShareLineGroup = ({
  sectorProductId,
  key,
  lineData,
  lineGenerator,
  pointGenerator,
  colorMap,
}: any) => {
  const lineRef = useRef<SVGPathElement | null>(null);
  const [hasAnimated, setHasAnimated] = useState<boolean>(false);
  const [totalPathLength, setTotalPathLength] = useState<number>(0);

  let useColor = colorMap.get(sectorProductId);

  useEffect(() => {
    if (lineRef && lineRef.current) {
      let lineNode = lineRef.current;
      let totalPathLength = lineNode.getTotalLength();

      select(lineNode)
        .attr("stroke-dashoffset", hasAnimated ? 0 : totalPathLength)
        .attr("stroke-dasharray", `${totalPathLength} ${totalPathLength}`);

      setTotalPathLength(totalPathLength);
    }
  }, [lineRef, lineGenerator]);

  useEffect(() => {
    let lineNode = lineRef.current;

    if (hasAnimated === false) {
      // If path has not animated yet,
      // update stroke-dasharray and stroke-dashoffset
      // to use the new computed totalPathLength
      select(lineNode)
        .interrupt()
        .transition()
        .ease(easeQuadOut)
        .duration(750)
        .attr("stroke-dashoffset", "0")
        .on("end", () => setHasAnimated(true));
    } else {
      // If path has animated already,
      // just update stroke-dasharray with new computed totalPathLength
      // to ensure path has complete stroke
      select(lineNode)
        .attr("stroke-dasharray", `${totalPathLength} ${totalPathLength}`)
        .attr("stroke-dashoffset", 0);
    }
  }, [totalPathLength]);

  let initialStrokeDashArray, initialStrokeDashOffset;
  if (hasAnimated === false) {
    initialStrokeDashArray = `${totalPathLength} ${totalPathLength}`;
    initialStrokeDashOffset = totalPathLength;
  } else {
    initialStrokeDashArray = undefined;
    initialStrokeDashOffset = undefined;
  }

  return (
    <g>
      <MarketSharePathElement
        key={key}
        d={lineGenerator(lineData)}
        strokeDasharray={initialStrokeDashArray}
        strokeDashoffset={initialStrokeDashOffset}
        stroke={useColor}
        ref={lineRef}
        fill="none"
      />
      {lineData.map((productYear: any) => {
        let { year, globalMarketShare: value } = productYear;

        let { cx, cy } = pointGenerator({ year, value });

        return (
          <MarketShareCircleElement
            cx={cx}
            cy={cy}
            fill={useColor}
            r={circleMarkerRadius}
          />
        );
      })}
    </g>
  );
};

export default memo(MarketShareLineGroup);
