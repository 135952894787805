import {
  Details as DetailsBase,
  DetailsSummary as QuestionHeading,
  DetailsContent as AnswerToQuestion,
  ToggleIcon,
} from "../sharedComponents/staticPageComponents";
import { ReactNode, useState, useRef, cloneElement } from "react";

interface DetailsProps {
  children: ReactNode[];
}

const Details = ({ children }: DetailsProps) => {
  let [isOpen, setIsOpen] = useState<boolean>(false);
  const detailsElement = useRef<HTMLDetailsElement | null>(null);

  const handleToggle = (e: any) => {
    if (detailsElement && detailsElement.current) {
      const detailsElementOpen = detailsElement.current.open;
      setIsOpen(detailsElementOpen);
    }
  };

  let ChildrenWithToggleIcon: any | null;
  if (children) {
    ChildrenWithToggleIcon = children.map((child: any) => {
      if (child.type === QuestionHeading) {
        return cloneElement(
          child,
          {},
          child.props.children,
          <ToggleIcon isOpen={isOpen} />,
        );
      } else {
        return child;
      }
    });
  }

  return (
    <DetailsBase onToggle={handleToggle} ref={detailsElement}>
      {ChildrenWithToggleIcon}
    </DetailsBase>
  );
};

const FAQContent = () => {
  return (
    <>
      <Details>
        <QuestionHeading>
          What is The Atlas of Economic Complexity?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            The Atlas is a research and data visualization tool that allows
            people to learn more about the economic structure of their country,
            including the growth opportunities that exist in the latent
            productive capabilities a country has.
          </p>
          <p>
            The Atlas puts the capabilities and know-how of a country at the
            heart of its growth prospects, where the diversity and complexity of
            existing capabilities heavily influence how growth happens.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Where can I learn more about the ideas and methodology behind the
          Atlas?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            More detail on our ideas and methodology can be found in the
            companion book,{" "}
            <a href="https://growthlab.cid.harvard.edu/publications/atlas-economic-complexity-mapping-paths-prosperity-0">
              <strong>
                The Atlas of Economic Complexity: Mapping Paths to Prosperity
              </strong>
            </a>
            . The first part contains context, motivating questions, and then a
            detailed description of the methodology.
          </p>
          <p>
            You can also check out our research publications on the product
            space and our theory of economic growth based on these ideas:
          </p>
          <ul>
            <li>
              <em>
                <a href="http://science.sciencemag.org/content/317/5837/482">
                  The Product Space Conditions the Development of Nations
                </a>
              </em>
            </li>
            <li>
              <em>
                <a href="http://www.pnas.org/content/106/26/10570.full">
                  The Building Blocks of Economic Complexity
                </a>
              </em>
            </li>
            <li>
              *
              <a href="https://link.springer.com/article/10.1007/s10887-011-9071-4">
                The Network Structure of Economic Output
              </a>
            </li>
          </ul>
          <p>
            The{" "}
            <strong>
              <a href="/glossary/">Glossary</a>
            </strong>{" "}
            also provides a detailed definition of many terms.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>What are the Atlas data sources?</QuestionHeading>
        <AnswerToQuestion>
          <p>
            The Atlas contains trade data for 250 countries and territories,
            classified into 20 categories of goods and 5 categories of services.
            Combined, this results in coverage of over 6000 products worldwide.
          </p>
          <p>
            The raw trade data on goods are derived from countries’ reporting to
            the{" "}
            <a href="https://comtrade.un.org/">
              United Nations Statistical Division (COMTRADE)
            </a>
            . The trade data on services are from the International Monetary
            Fund (IMF) Direction of{" "}
            <a href="http://data.imf.org/?sk=9D6028D4-F14A-464C-A2F2-59B2CD424B85">
              Trade Statistics database)
            </a>
            , via the World Development Indicators.
          </p>
          <p>
            Please visit the <a href="/about-data">About the Data</a> page for
            more detailed information about the Atlas data.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>How do I access Atlas data?</QuestionHeading>
        <AnswerToQuestion>
          <p>
            For more information on Atlas data, including how to download,
            please visit our{" "}
            <strong>
              <a href="/data-downloads">Data Downloads </a>
            </strong>{" "}
            page.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>How often is the Atlas data updated?</QuestionHeading>
        <AnswerToQuestion>
          <p>
            Roughly 95% of Atlas global trade data is updated annually, usually
            sometime between April to June.{" "}
          </p>
          <p>
            Atlas data updates are dependent the disclosure of trade data, to UN
            Comtrade, by the countries themselves. To reach the minimum
            threshold of disclosure, for a full update, can take between 12 to
            18 months. As a result, it is common for the Growth Lab to release a
            full Atlas data update up to a year and a half following the
            reporting year.{" "}
          </p>
          <p>
            Since countries disclose additional updates and corrections at
            varying times throughout the year, we release minor revisions to the
            Atlas data approximately once per quarter. We also make occasional
            updates as our data cleaning and validation processes improve.
          </p>
          <p>
            For more information on country trade data availability, please
            visit{" "}
            <strong>
              <a href="https://comtrade.un.org/data/da">Comtrade’s reporting</a>
            </strong>{" "}
            on available data.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Can I reuse or reprint Atlas data and visualizations?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            The Atlas is a free, public resource intended to advance the
            understanding of inclusive economic growth. We encourage
            collaboration on and sharing of Atlas concepts, visualizations and
            data.{" "}
          </p>
          <p>
            When referring to concepts, visualizations and data from the Atlas,
            please consult our{" "}
            <strong>
              <a href="/data-use-permissions">
                Use &amp; Permissions guidelines
              </a>
            </strong>{" "}
            and follow our referencing conventions.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why doesn’t your data match Comtrade exactly? Has your data been
          modified?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            We perform our own data cleaning on the dataset. We exploit the fact
            that trade flows are in theory recorded twice: as exports and
            imports. In practice, however, trade values are recorded imperfectly
            and reported on an untimely basis, and sometimes not reported at
            all.
          </p>
          <p>
            Our data cleaning process can be summarized in the following three
            steps:
          </p>
          <ul>
            <li>
              We first correct import values (which are reported including the
              costs of freight and insurance – CIF) in order to compare to the
              same flows reported by exporters (which are reported free on board
              – FOB);
            </li>
            <li>
              We then estimate an index of reliability of countries when
              reporting trade flows, based on the consistency of trade totals
              reported by all exporter and importer combinations over time;
            </li>
            <li>
              Our final step is to generate our estimate of the trade values
              using the data reported by exporters and importers, by taking into
              account how reliable each country is.
            </li>
          </ul>
          <p>
            This is essentially an accounting process: we cross-reference the
            reported exports and imports of countries against each other.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why should I use your website rather than Comtrade itself, WITS, or
          some other export data source?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            The Atlas features a unique cleaning of trade data to account for
            inconsistent reporting standards and presents a specific point of
            view of how trade data predicts economic growth and new economic
            opportunities. The Atlas studies the relatedness of products, as a
            measure of the capabilities and know-how of countries, rather than
            merely the export volumes of specific products. By mapping global
            production and diversification patterns in the product space, we are
            able to use what a country currently produces to predict what new
            product sectors are likely to develop in the coming years—and to
            make recommendations for which sectors offer the greatest complexity
            gains, paving the way for higher incomes.
          </p>
          <p>
            After a decade of research on The Atlas, we have generated a set of
            stylized facts on trade and production, including that poor
            countries are defined by their production of few products that
            nearly every country can produce, while rich countries produce many
            products, and products that few countries can produce. Beyond basic
            visualizations of existing trade, we offer the Product Space and
            Product Feasibility scatterplots to offer a positive perspective of
            the products that are more likely to succeed. We find these methods
            predict product appearance and overall economic growth better than
            any other single measure of the economy.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          What is the difference between the HS and SITC datasets?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            The difference is a tradeoff between time length and detail. The
            SITC data go back further in time, from 1962 to now, but struggles
            to dissect new products that did not exist in 1962 (e.g. cell
            phones). The HS data is only available back to 1995, but provides a
            much more detailed and recent product classification. This is
            especially important for product introduced in the last two decades,
            such as high-tech products and electronic goods. Using one or the
            other therefore depends on the nature of your analysis, but we
            generally find they lead to highly similar conclusions.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why do you use product classification at the four-digit level for the
          complexity visualizations?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            The reason for using four-digit data (specifying around 900
            products) is that it represents a good compromise between levels of
            detail, accuracy of the estimated similarities and complexities and
            differentiation between truly separate product types. That is,
            although we think it is still helpful to reflect on the differences
            in capabilities required to make t-shirts or men’s suits, there is
            less of a case for differentiating between, for example, “men’s
            linen suits of less-than-1cm thickness” and “men’s cotton suits of
            greater-than-1cm thickness.”
          </p>
          <p>
            Moreover, we generally find that using the data at the six- or
            eight-digit level suggests unwarranted levels of precision and, at
            over 5,000 products, crowds the visualizations.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why are certain countries named and classified the way they are?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            We use{" "}
            <strong>
              <a href="https://unstats.un.org/unsd/tradekb/Knowledgebase/Comtrade-Country-Code-and-Name">
                the official names
              </a>
            </strong>{" "}
            and data provided to UN Comtrade.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why are some countries missing from Country Profiles and the
          complexity ranking?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            Our coverage for Country Profiles and our complexity rankings
            require that countries disclose reliable data, that they have a
            population greater than 1 million and that their annual trade is
            above $1 billion in value.{" "}
          </p>
          <p>
            Countries excluded from these two features are often non-reporters
            (where inferring data from importing countries has weaknesses),
            heavy re-exporters (not allowing certainty of differentiating true
            value-added of the country), or smaller countries where
            discrepancies in reported data lead to misleading conclusions.
          </p>
        </AnswerToQuestion>
      </Details>
      <Details>
        <QuestionHeading>
          Why doesn’t your website contain data or visualizations for X?
        </QuestionHeading>
        <AnswerToQuestion>
          <p>
            We have a specific focus on studying growth and development from a
            capability and know-how perspective of economic complexity, and the
            content of our site reflects that purpose.
          </p>
          <p>
            Within this context, if you have a suggestion for additional
            content, feel free to{" "}
            <strong>
              <a href="mailto:growthlabtools@hks.harvard.edu">contact us</a>
            </strong>{" "}
            with your ideas.
          </p>
        </AnswerToQuestion>
      </Details>
      {/* 
            
            Template for adding new questions:

            <Details>
                <QuestionHeading></QuestionHeading>
                <AnswerToQuestion>
                    
                </AnswerToQuestion>
            </Details> 
            
            */}
    </>
  );
};

export default FAQContent;
