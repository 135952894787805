import styled from "@emotion/styled";
import { H1 } from "../../components/styling/TextStyling";
import GlossaryContent from "./Content";
import { DetailsPageContainer } from "../sharedComponents/staticPageComponents";
import ResearchFooter from "../sharedComponents/ResearchFooter";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const PageTitle = styled(H1)`
  margin-top: 0;
`;

const Glossary = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <DetailsPageContainer>
        <PageTitle>Glossary</PageTitle>
        <GlossaryContent />
      </DetailsPageContainer>
      <ResearchFooter />
    </>
  );
};

export default Glossary;
