import { useCombobox } from "downshift";
import { useState } from "react";
import {
  MainMenu,
  ClearButton,
  ToggleButton,
  ArrowDown,
  ArrowUp,
  ClearButtonSymbol,
  DropdownContainer,
  DropdownInputField,
  ComboBoxContainer,
} from "./styles";

interface ComboBoxInput {
  items: any[];
  hashFunction: any;
  placeholder: string;
  ItemRenderComponent: any;
  onChangeEvent: any;
  selectedValue: any | undefined;
}

const SearchBoxPrimitive = ({
  items,
  hashFunction,
  placeholder,
  ItemRenderComponent,
  onChangeEvent,
  selectedValue,
}: ComboBoxInput) => {
  function ComboBox() {
    const [displayItems, setDisplayItems] = useState(items);

    const {
      // Prop getters:
      getToggleButtonProps, // Attributes to be applied to a menu toggle button <button>
      getLabelProps, // Attributes to be applied to <label> element, e.g., aria-label
      getMenuProps, // Attributes to be applied to <ul> or root of menu to be rendered
      getInputProps, // Attributes to be applied to <input> element, e.g., aria-label
      getItemProps, // Attributes to be applied to menu items, e.g., <li> elements

      // State values:
      selectedItem, // Current selected item
      highlightedIndex,
      isOpen, // Is the dropdown open?
      inputValue, // the value in the input field

      // Actions:
      closeMenu, // closes the menu
      openMenu, // opens the menu
      selectItem, // selects a given item
      setHighlightedIndex, // sets a new highlighted item index
      setInputValue, // sets a new value in the input
      toggleMenu, // toggles menu open state
    } = useCombobox({
      // This handles the autocomplete behavior, i.e., as the user types, filtered list of items displayed
      onInputValueChange({ inputValue, isOpen }) {
        if (inputValue && inputValue !== placeholder) {
          const lowerCasedInputValue = inputValue
            ? inputValue.toLowerCase()
            : "";
          const updatedDisplayItems = items.filter((item) => {
            if (
              !inputValue ||
              hashFunction(item)?.toLowerCase()?.includes(lowerCasedInputValue)
            ) {
              return true;
            } else {
              return false;
            }
          });
          setDisplayItems(updatedDisplayItems);
        } else if (isOpen && inputValue === "") {
          setDisplayItems(items);
        }
      },
      onIsOpenChange({ isOpen, selectedItem, highlightedIndex }) {
        if (isOpen) {
          setInputValue("");
        } else {
          if (selectedItem) {
            setInputValue(hashFunction(selectedItem));
          } else {
            setInputValue("");
          }
        }
      },
      onSelectedItemChange({ selectedItem }) {
        if (selectedItem) {
          onChangeEvent({ id: selectedItem.id });
        } else if (!selectedItem) {
          onChangeEvent({ id: undefined });
          setDisplayItems(items);
        }
      },
      // The actual items to be displayed in the dropdown at time of render
      items: displayItems,
      // Accessor function to get label for item in dropdown
      itemToString(item) {
        return item ? hashFunction(item) : "";
      },
    });

    if (selectedValue && !selectedItem) {
      selectItem(selectedValue);
    }

    const handleClearButtonClick = () => {
      selectItem(null);
      onChangeEvent({ id: undefined });
      setDisplayItems(items);
    };
    let clearButton = undefined;
    if (selectedItem) {
      clearButton = (
        <ClearButton onClick={() => handleClearButtonClick()}>
          {ClearButtonSymbol}
        </ClearButton>
      );
    } else {
      clearButton = undefined;
    }

    return (
      <ComboBoxContainer>
        <DropdownContainer>
          <DropdownInputField
            className={inputValue === "" ? "input--placeholder" : undefined}
            placeholder={placeholder}
            {...getInputProps()}
          />
          <ToggleButton
            aria-label="toggle menu"
            type="button"
            {...getToggleButtonProps()}
          >
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </ToggleButton>
          {clearButton}
        </DropdownContainer>
        {isOpen && (
          <MainMenu {...getMenuProps()}>
            {/* Mapping `items` to <li> elements, if menu is open */}
            {displayItems.map((item: any, index: number) => {
              // getItemProps is called here for each item in the <ul> --
              // passing `item` and `index` helps Downshift keep track of which item
              // gets selected or highlighted from user interact
              return (
                <ItemRenderComponent
                  item={item}
                  index={index}
                  highlightedIndex={highlightedIndex}
                  selectedItem={selectedItem}
                  mappedItemIndex={index}
                  getItemProps={getItemProps}
                  hashFunction={hashFunction}
                  displayItems={displayItems}
                  setDisplayItems={setDisplayItems}
                />
              );
            })}
          </MainMenu>
        )}
      </ComboBoxContainer>
    );
  }
  return <ComboBox />;
};

export default SearchBoxPrimitive;
