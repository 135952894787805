import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  SideBarContainer,
  SideBarUl,
  ActiveLink,
  InactiveLink,
} from "./staticPageComponents";

interface SidebarNavItemProps {
  assignedHeadingSubPath: string;
  activeHeadingId: string | undefined;
  setFirstRender: (arg: boolean) => void;
  index: number;
}

const SidebarNavItem = ({
  children,
  assignedHeadingSubPath,
  activeHeadingId,
  setFirstRender,
  index,
}: React.PropsWithChildren<SidebarNavItemProps>) => {
  const isActive = activeHeadingId
    ? assignedHeadingSubPath === activeHeadingId
    : index === 0;
  const Root = isActive ? ActiveLink : InactiveLink;
  const navigate = useNavigate();

  return (
    <Root
      onClick={() => {
        setFirstRender(true);
        navigate(`#${assignedHeadingSubPath}`, { replace: true });
      }}
    >
      <Link replace={true} to={`#${assignedHeadingSubPath}`}>
        {children}
      </Link>
    </Root>
  );
};

interface SidebarProps {
  activeHeadingId: string | undefined;
  setFirstRender: (arg: boolean) => void;
  items: { id: string; label: string }[];
}

const Sidebar = ({ activeHeadingId, setFirstRender, items }: SidebarProps) => {
  return (
    <SideBarContainer>
      <SideBarUl>
        {items.map((item, i) => (
          <SidebarNavItem
            key={item.id}
            assignedHeadingSubPath={item.id}
            activeHeadingId={activeHeadingId}
            setFirstRender={setFirstRender}
            index={i}
          >
            {item.label}
          </SidebarNavItem>
        ))}
      </SideBarUl>
    </SideBarContainer>
  );
};

export default Sidebar;
