import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import {
  generateCountryKey,
  generateGroupKey,
  generateProductClassDigitLevelYearKey,
} from "../sharedUtilities/useFetchMetadata";

export interface SharedData {
  countries: any | undefined;
  regions: any | undefined;
  subregions: any | undefined;
  productsHs92: any | undefined;
  productsHs12: any | undefined;
  productsSitc: any | undefined;
  productYear: any | undefined;
  deflators: any | undefined;
  countryYear: any | undefined;
  groupYear: any | undefined;
}

const initialState: Record<string, any> = {
  countries: undefined,
  regions: undefined,
  subregions: undefined,
  productsHs92: undefined,
  productsHs12: undefined,
  productsSitc: undefined,
  productYear: {},
  deflators: undefined,
  countryYear: {},
  groupYear: {},
};

export const sharedDataSlice = createSlice({
  name: "sharedDataStore",
  initialState,
  reducers: {
    updateCountriesMetadata: (state, action: PayloadAction<any>) => {
      state.countries = action.payload;
    },
    updateRegionsMetadata: (state, action: PayloadAction<any>) => {
      state.regions = action.payload;
    },
    updateSubregionsMetadata: (state, action: PayloadAction<any>) => {
      state.subregions = action.payload;
    },
    updateProductsHs92Metadata: (state, action: PayloadAction<any>) => {
      state.productsHs92 = action.payload;
    },
    updateProductsHs12Metadata: (state, action: PayloadAction<any>) => {
      state.productsHs12 = action.payload;
    },
    updateProductsSitcMetadata: (state, action: PayloadAction<any>) => {
      state.productsSitc = action.payload;
    },
    updateProductYearMetadata: (state, action: PayloadAction<any>) => {
      const { year, productClass, productLevel, data } = action.payload;
      let yearProductClassKey = generateProductClassDigitLevelYearKey({
        productClass,
        productLevel,
        year,
      });
      state.productYear[yearProductClassKey] = data;
    },
    updateDeflatorsMetadata: (state, action: PayloadAction<any>) => {
      state.deflators = action.payload;
    },
    updateCountryYearMetadata: (state, action: PayloadAction<any>) => {
      const { countryId, data } = action.payload;
      let countryKey = generateCountryKey({ countryId });
      if (countryKey) {
        state.countryYear[countryKey] = data;
      }
    },
    updateGroupYearMetadata: (state, action: PayloadAction<any>) => {
      const { groupId, data } = action.payload;
      let groupKey = generateGroupKey({ groupId });
      if (groupKey) {
        state.groupYear[groupKey] = data;
      }
    },
    resetMetaData: (state) => {
      return {
        ...state,
        productYear: initialState.productYear,
        productsHs92: initialState.productsHs92,
        productsHs12: initialState.productsHs12,
        productsSitc: initialState.productsSitc,
      };
    },
  },
});

export const {
  updateCountriesMetadata,
  updateRegionsMetadata,
  updateSubregionsMetadata,
  updateProductsHs92Metadata,
  updateProductsHs12Metadata,
  updateProductsSitcMetadata,
  updateProductYearMetadata,
  updateDeflatorsMetadata,
  updateCountryYearMetadata,
  updateGroupYearMetadata,
  resetMetaData,
} = sharedDataSlice.actions;

export const selectCountriesMetadata = (state: RootState) =>
  state.sharedData.countries;
export const selectRegionsMetadata = (state: RootState) =>
  state.sharedData.regions;
export const selectSubregionsMetadata = (state: RootState) =>
  state.sharedData.subregions;
export const selectProductsHs92Metadata = (state: RootState) =>
  state.sharedData.productsHs92;
export const selectProductsHs12Metadata = (state: RootState) =>
  state.sharedData.productsHs12;
export const selectProductsSitcMetadata = (state: RootState) =>
  state.sharedData.productsSitc;
export const selectProductYearMetadata = (state: RootState) =>
  state.sharedData.productYear;
export const selectCountryYearMetadata = (state: RootState) =>
  state.sharedData.countryYear;
export const selectGroupYearMetadata = (state: RootState) =>
  state.sharedData.groupYear;

export default sharedDataSlice.reducer;
