import { useState, useEffect, createContext } from "react";
import useWindowResize from "../../sharedUtilities/useWindowResize";

export const ChartContainerSizeContext = createContext({
  chartWidth: 0,
  chartHeight: 0,
});

export default ({ chartContainerRef, children }: any) => {
  const [chartContainerSize, setChartContainerSize] = useState<{
    chartWidth: number;
    chartHeight: number;
  }>({ chartWidth: 0, chartHeight: 0 });
  const [windowWidth, windowHeight] = useWindowResize();

  const updateChartContainerSize = () => {
    if (chartContainerRef && chartContainerRef.current) {
      const node = chartContainerRef.current;
      const width = node.clientWidth;
      const height = node.clientHeight - 10; // Adjusting by the container padding
      if (
        chartContainerSize === undefined ||
        chartContainerSize.chartWidth !== width ||
        chartContainerSize.chartHeight !== height
      ) {
        setChartContainerSize({ chartWidth: width, chartHeight: height });
      }
    }
  };

  useEffect(
    () => updateChartContainerSize(),
    [chartContainerRef, windowWidth, windowHeight],
  );

  updateChartContainerSize();

  return (
    <ChartContainerSizeContext.Provider value={chartContainerSize}>
      {children}
    </ChartContainerSizeContext.Provider>
  );
};
