import { gql } from "@apollo/client";

const query = gql`
  query GetGroupYear($groupId: Int, $yearMin: Int, $yearMax: Int) {
    groupYear: groupYear(
      groupId: $groupId
      yearMin: $yearMin
      yearMax: $yearMax
    ) {
      groupId
      year
      population
    }
  }
`;

export default query;
