import { useState } from "react";
import GlossaryContent from "../../pages/glossary/Content";
import GlossaryIcon from "../../assets/images/ui/glossary.svg";
import ModalBase, { ModalType } from "../modals/ModalBase";
import {
  OptionIcon,
  OptionLabel,
  OptionsListItem,
} from "../navigationBar/Components";

const GlossaryModalButton = ({
  menuOptionClassName,
  isNavigationBarOpen,
}: any) => {
  let [isGlossaryModalOpen, setIsGlossaryModalOpen] = useState<boolean>(false);
  const toggleGlossaryModal = () => {
    const newIsGlossaryModalOpen = !isGlossaryModalOpen;
    setIsGlossaryModalOpen(newIsGlossaryModalOpen);
  };

  let glossaryModal = null;

  if (isGlossaryModalOpen) {
    glossaryModal = (
      <ModalBase
        modalType={ModalType.Glossary}
        toggleModal={toggleGlossaryModal}
      >
        <GlossaryContent />
      </ModalBase>
    );
  }

  return (
    <>
      <OptionsListItem
        className={menuOptionClassName}
        onClick={toggleGlossaryModal}
      >
        <OptionIcon>
          <img src={GlossaryIcon} alt={"Glossary"} />
        </OptionIcon>
        {isNavigationBarOpen === true && <OptionLabel>Glossary</OptionLabel>}
      </OptionsListItem>
      {glossaryModal}
    </>
  );
};

export default GlossaryModalButton;
