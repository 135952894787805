import styled from "@emotion/styled";
import { VizType } from "../../visualization/Utils";
import TreeMapIcon from "../../assets/images/vizTypeIcons/treemap-white.svg";
import GeoMapIcon from "../../assets/images/vizTypeIcons/geomap-white.svg";
import OverTimeIcon from "../../assets/images/vizTypeIcons/overtime-white.svg";
import GlobalShareIcon from "../../assets/images/vizTypeIcons/globalshare-white.svg";
import ProductSpaceIcon from "../../assets/images/vizTypeIcons/productspace-white.svg";
import FeasibilityIcon from "../../assets/images/vizTypeIcons/feasibility-white.svg";

interface Props {
  vizType: VizType;
}

const VizTypeListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0px 0px;
  padding: 5px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  flex: 1 0 0px;
  font-size: 9px;
  box-sizing: border-box;

  & img {
    width: 100%;
    max-height: 20px;
    margin-top: 3px;
  }

  &.selected {
    background-color: #567388;
  }
`;

export default function VizTypeSelectorButton({ vizType }: Props) {
  let buttonLabel: string | undefined;
  let buttonIcon;

  if (vizType === VizType.Tree) {
    buttonLabel = "Trade Composition";
    buttonIcon = TreeMapIcon;
  } else if (vizType === VizType.Geo) {
    buttonLabel = "Trade Map";
    buttonIcon = GeoMapIcon;
  } else if (vizType === VizType.OverTime) {
    buttonLabel = "Trade Over Time";
    buttonIcon = OverTimeIcon;
  } else if (vizType === VizType.MarketShare) {
    buttonLabel = "Global Share";
    buttonIcon = GlobalShareIcon;
  } else if (vizType === VizType.ProductSpace) {
    buttonLabel = "Product Space";
    buttonIcon = ProductSpaceIcon;
  } else if (vizType === VizType.Feasibility) {
    buttonLabel = "Growth Opportunity";
    buttonIcon = FeasibilityIcon;
  }

  return (
    <VizTypeListItem>
      {buttonLabel}
      <img src={buttonIcon} alt={`${buttonLabel} visualization`} />
    </VizTypeListItem>
  );
}
