import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const TotalValueContext = createContext<
  | [
      number | undefined,
      React.Dispatch<React.SetStateAction<number | undefined>>,
    ]
  | undefined
>(undefined);

export const TotalValueProvider = ({ children }) => {
  const totalValueState = useState<number | undefined>(undefined);
  const [, setTotalValue] = totalValueState;
  const location = useLocation();
  const currentVizType = location.pathname.split("/")[2] as any;
  useEffect(() => {
    setTotalValue(null);
  }, [currentVizType, setTotalValue]);
  return (
    <TotalValueContext.Provider value={totalValueState}>
      {children}
    </TotalValueContext.Provider>
  );
};

export const useTotalValue = () => {
  const context = useContext(TotalValueContext);
  if (!context) {
    throw new Error("useTotalValue must be used within a TotalValueProvider");
  }
  return context;
};
