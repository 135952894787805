import { useRef, useEffect, memo } from "react";
import { axisLeft, format, select } from "d3";
import { AxisGElement } from "./styles";
import { formatYAxisValue } from "../Utils";
import { OverTimeLayoutOption } from "../charts/overtime/Utils";

const YAxis = ({ scale, chartMargin, overTimeLayout }: any) => {
  const axisRef = useRef<SVGGElement | null>(null);

  let axisGenerator: any;
  if (overTimeLayout) {
    if (overTimeLayout === OverTimeLayoutOption.Share) {
      axisGenerator = axisLeft(scale)
        .ticks(10)
        .tickFormat((tick: any) => format(".0%")(tick));
    } else {
      axisGenerator = axisLeft(scale)
        .ticks(10)
        .tickFormat((tick: any) => "$" + formatYAxisValue(tick));
    }
  } else {
    axisGenerator = axisLeft(scale)
      .ticks(10)
      .tickFormat((tick: any) => "$" + formatYAxisValue(tick));
  }

  useEffect(() => {
    if (axisRef && axisRef.current) {
      const gElement = select(axisRef.current);

      // Render the result of calling the axis generator to the <g> element that will hold the axis
      axisGenerator(gElement);
    }
  }, [axisRef, scale.domain()]);

  return (
    <AxisGElement
      ref={axisRef}
      transform={`translate(${chartMargin.left}, 0)`}
    ></AxisGElement>
  );
};

export default memo(YAxis);
