import { BodyContainer, FooterContainer } from "../ContentPageGrid";
import HeaderBanner from "./header/HeaderBanner";
import Content from "./Content";
import Footer from "../sharedComponents/Footer";
import styled from "@emotion/styled";
import InfoBanner from "../../components/InfoBanner";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const LandingPageContainer = styled.div`
    display; flex;
    flex-direction: column;
    min-height: 100vh;

`;

const LandingPageBodyContainer = styled(BodyContainer)`
  padding: 0px;
`;

const ContentPage = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <InfoBanner />
      <LandingPageContainer>
        <HeaderBanner />
        <LandingPageBodyContainer>
          <Content />
        </LandingPageBodyContainer>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </LandingPageContainer>
    </>
  );
};

export default ContentPage;
