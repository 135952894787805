import { index } from "d3";
import { worldGroupDatum } from "../../../graphql/queries/getLocationsMetadata";

const transformProducts = ({ exporter, inputData, productYearData }: any) => {
  if (inputData.length === 0) {
    return undefined;
  }

  if (exporter) {
    let mappedLocationProductYearData;

    if (exporter === worldGroupDatum.groupId) {
      /*
            NOTE: When the selected exporter is `World`, we will coerce all product-year 
            values to 100% of total market share; this is to smooth over variations in the 
            individual country data that may yield `globalMarketShare` values > 100% for specific 
            products.
            */

      mappedLocationProductYearData = inputData
        .map((productYearDatum: any) => {
          return {
            ...productYearDatum,
            globalMarketShare: 1,
          };
        })
        .sort((a: any, b: any) => +a.year - +b.year);
    } else {
      const productYearDataLookup = index(
        productYearData,
        (d) => d.groupYear,
      ) as any;
      mappedLocationProductYearData = inputData
        .map((productYearDatum: any) => {
          let {
            year,
            productId: sectorId,
            exportValue: locationExportValue,
          } = productYearDatum;

          let globalExportValue, globalMarketShare;

          let matchingGlobalProductYearData = productYearDataLookup.get(year);
          if (matchingGlobalProductYearData) {
            let { groupData } = matchingGlobalProductYearData;
            let matchingSectorData = groupData.find(
              ({ productId }: any) => productId === sectorId,
            );
            if (matchingSectorData) {
              globalExportValue = matchingSectorData.exportValue;
            }
          }

          if (globalExportValue) {
            globalMarketShare = locationExportValue / globalExportValue;
          }

          return {
            ...productYearDatum,
            globalMarketShare,
          };
        })
        .sort((a: any, b: any) => +a.year - +b.year);
    }

    return mappedLocationProductYearData;
  }
};

export default transformProducts;
