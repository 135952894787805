import styled from "@emotion/styled";
import { globalPageMargin } from "../components/styling/cssVariables";
import { gridAreas as globalGridAreas } from "../components/styling/GlobalGrid";
import { navigationBarColor } from "../components/styling/cssVariables";
import { hamburgerMenuContainerHeight } from "../components/navigationBar/styles";

export const gridAreas = {
  header: "HeaderArea",
  body: "BodyArea",
  controls: "ControlsArea",
  footer: "FooterArea",
};

export const gridLines = {
  globalPageLeft: "GlobalPageLeft",
  globalPageRight: "GlobalPageRight",
  globalPageTop: "GlobalPageTop",
  globalPageBottom: "GlobalPageBottom",
  globalControlsLeft: "GlobalControlsLeft",
  globalHeaderBottom: "GlobalHeaderBottom",
  globalFooterTop: "GlobalFooterTop",
  globalFooterBottom: "GlobalFooterBottom",
};

export const PageContentContainer = styled.div`
  grid-area: ${globalGridAreas.content};
  display: grid;
  min-height: 100vh;
  grid-template-rows: [ ${gridLines.globalPageTop}] auto [ ${gridLines.globalPageBottom}];
  grid-template-columns: [ ${gridLines.globalPageLeft}] 1fr [ ${gridLines.globalPageRight}];
  grid-template-areas:
    "${gridAreas.header}"
    "${gridAreas.body}"
    "${gridAreas.footer}";
`;

export const HeaderContainer = styled.div`
  grid-area: ${gridAreas.header};
  background-color: ${navigationBarColor};
  display: flex;
  flex-direction: row;
  height: ${hamburgerMenuContainerHeight};
  justify-content: space-between;
  align-items: center;
  padding: 0px ${globalPageMargin};
  position: relative;
`;

export const FixedHamburgerMenuContainer = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 300px;
`;

export const BodyContainer = styled.div`
  grid-area: ${gridAreas.body};
  padding: 0px ${globalPageMargin} 0px 0px;
`;

export const FooterContainer = styled.div`
  grid-area: ${gridAreas.footer};
  display: flex;
  flex-direction: column;
  justify-content: end;
`;
