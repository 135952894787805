/** @jsxImportSource @emotion/react */
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { H1, H2, Paragraph } from "../../components/styling/TextStyling";
import { ContentContainer, ParentContainer } from "../about";
import Sidebar from "../sharedComponents/Sidebar";
import { Link } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash;
  const activeHeadingId = hash.replace("#", "");

  const [firstRender, setFirstRender] = useState<boolean>(true);

  const headingRefs = {
    data: useRef<HTMLElement | null>(null),
    cleaning: useRef<HTMLElement | null>(null),
    goods: useRef<HTMLElement | null>(null),
    services: useRef<HTMLElement | null>(null),
    reliability: useRef<HTMLElement | null>(null),
    updates: useRef<HTMLElement | null>(null),
  };

  const orderedHeadingRefs = Object.entries(headingRefs).map(([id, ref]) => ({
    id,
    ref,
  }));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleSections = entries.filter((entry) => entry.isIntersecting);
        if (visibleSections.length > 0) {
          const currentSection = visibleSections[0].target;
          const currentSectionId = orderedHeadingRefs.find(
            (h) => h.ref.current === currentSection,
          )?.id;
          if (currentSectionId) {
            navigate(`#${currentSectionId}`, { replace: true });
          }
        }
      },
      { threshold: 0.01, rootMargin: "0px 0px -80% 0px" },
    );

    orderedHeadingRefs.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      orderedHeadingRefs.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  useLayoutEffect(() => {
    if (activeHeadingId && firstRender) {
      const nodeRef = headingRefs[activeHeadingId];
      if (nodeRef && nodeRef.current) {
        const node = nodeRef.current;
        const headerOffset = 60;
        const y =
          node.getBoundingClientRect().top + window.scrollY - headerOffset;

        window.scrollTo({
          top: y,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setFirstRender(false);
    }
  }, [activeHeadingId, firstRender]);

  useEffect(() => {
    if (!activeHeadingId) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, activeHeadingId]);

  const content = (
    <ContentContainer>
      <Box my={4}>
        <H1>Atlas Data</H1>
        <section ref={headingRefs.data}>
          <H2>Data</H2>
          <Paragraph>
            The Atlas contains trade data for over 6,000 goods and services
            spanning 250 countries and territories.
          </Paragraph>
          <Paragraph>
            The raw trade data on goods are derived from countries' reporting to
            the{" "}
            <a
              href="https://comtrade.un.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              United Nations Statistical Division (Comtrade)
            </a>
            . The trade data on services are from the{" "}
            <a
              href="https://data.imf.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              International Monetary Fund (IMF) Direction of Trade Statistics
              database
            </a>
            .
          </Paragraph>
          <Paragraph>
            Please visit the <Link to="/data-downloads">Downloads page</Link>{" "}
            for more information on downloading Atlas data.
          </Paragraph>
        </section>

        <section ref={headingRefs.cleaning}>
          <H2>Data Cleaning</H2>
          <Paragraph>
            Since not all countries report trade consistently, our research team
            - led by Sebastian Bustos, with key inputs from Muhammed Yildirim
            and Ricardo Hausmann - developed a unique method to clean the trade
            data to account for inconsistent reporting practices and thereby
            generate estimates of trade flows between countries. This data
            cleaning process is known as the{" "}
            <strong>Bustos-Yildirim Method</strong> in the literature and can be
            summarized in the following three steps:
          </Paragraph>
          <ol>
            <li>
              <Paragraph>
                We first correct import values (which are reported including the
                costs of freight and insurance -- CIF) in order to compare to
                the same flows reported by exporters (which are reported free on
                board -- FOB);
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                We then estimate an index of reliability of countries when
                reporting trade flows, based on the consistency of trade totals
                reported by all exporter and importer combinations over time;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                The final step is to generate our estimate of the trade values
                using the data reported by exporters and importers, by taking
                into account how reliable each country is.
              </Paragraph>
            </li>
          </ol>
          <Paragraph>
            This is essentially an accounting process where we exploit the fact
            that trade flows are in theory recorded twice: as exports and
            imports. In practice, however, trade values are recorded imperfectly
            and reported by countries on an untimely basis or sometimes not at
            all. We cross-reference the reported exports and imports of
            countries against each other to produce reliable estimations.
          </Paragraph>
        </section>

        <section ref={headingRefs.goods}>
          <H2>Goods Data</H2>
          <Paragraph>
            The Atlas visualizes bilateral trade flows for over 6000 goods,
            classified by one of two systems - Harmonized System (HS) and
            Standard International Trade Classification (SITC).
          </Paragraph>
          <Paragraph>
            HS data offers a contemporary and detailed classification of goods,
            but covers a relatively shorter time period. Important
            characteristics of HS data in The Atlas include the following:
          </Paragraph>
          <ul>
            <li>
              <Paragraph>
                Products: approximately 5,000, organized into 10 sectors
              </Paragraph>
            </li>
            <li>
              <Paragraph>Versions: HS 1992 (H0) and HS 2012 (H4)</Paragraph>
            </li>
            <li>
              <Paragraph>Start of Coverage: 1995</Paragraph>
            </li>
            <li>
              <Paragraph>
                Detail Level: HS categories break down to 1-, 2-, 4-, or 6-digit
                detail levels
              </Paragraph>
            </li>
          </ul>
          <Paragraph>
            SITC data offers a longer time-series but covers fewer goods, as the
            classifications have remained consistent since the 1960s, despite
            the onset of new products (e.g. electronics). Important
            characteristics of SITC data in The Atlas include the following:
          </Paragraph>
          <ul>
            <li>
              <Paragraph>
                Products: approximately 700, organized into 10 sectors
              </Paragraph>
            </li>
            <li>
              <Paragraph>Versions: SITC Rev. 2</Paragraph>
            </li>
            <li>
              <Paragraph>Start of Coverage: 1962</Paragraph>
            </li>
            <li>
              <Paragraph>
                Detail Level: SITC categories break down to 1-, 2-, or 4-digit
                detail levels
              </Paragraph>
            </li>
          </ul>
        </section>

        <section ref={headingRefs.services}>
          <H2>Services Data</H2>
          <Paragraph>
            Unlike trade in goods, services trade is intangible and does not
            flow through customs offices - a primary source of statistical
            records. As such, services data in The Atlas is subject to the
            following 3 limitations:
          </Paragraph>
          <ul>
            <li>
              <Paragraph>
                Trade flows: Countries report their services exports and imports
                unilaterally as opposed to commodity data which is reported
                bilaterally. Therefore, we cannot mirror countries' services
                reporting with partner reporting, to improve the quality of the
                data. As a result, the destination for location-partner data for
                services is broadly categorized as “Services Partners” in the
                Atlas.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Year Range: Coverage for Services data begins in 1980 however,
                certain years may be unavailable due to undisclosed or delayed
                country reporting.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Country Coverage: Due to significantly limited or delayed
                reporting, services data is available for roughly 50 to 75
                percent of all countries in The Atlas.
              </Paragraph>
            </li>
          </ul>
        </section>

        <section ref={headingRefs.reliability}>
          <H2>Country Reliability</H2>
          <Paragraph>
            Due to limited, delayed, or inaccurate reporting, trade data from
            certain countries may contain a significant degree of error. Our
            data cleaning method accounts for some of these inconsistencies. Be
            advised that the data shown for these countries reflect best
            estimates and call for careful consideration before using.
          </Paragraph>
        </section>

        <section ref={headingRefs.updates}>
          <H2>Data Updates</H2>
          <Paragraph>
            Roughly 95% of Atlas global trade data is updated annually, usually
            sometime between April to June.
          </Paragraph>
          <Paragraph>
            Atlas data updates are dependent the disclosure of trade data, to UN
            Comtrade, by the countries themselves. To reach the minimum
            threshold of disclosure, for a full update, can take between 12 to
            18 months. As a result, it is common for the Growth Lab to release a
            full Atlas data update up to a year and a half following the
            reporting year.
          </Paragraph>
          <Paragraph>
            Since countries disclose additional updates and corrections at
            varying times throughout the year, we release minor revisions to the
            Atlas data approximately once per quarter. We also make occasional
            updates as our data cleaning and validation processes improve.
          </Paragraph>
          <Paragraph>
            For more information on country trade data availability, please
            visit{" "}
            <a
              href="https://comtrade.un.org/data"
              target="_blank"
              rel="noopener noreferrer"
            >
              Comtrade's reporting on available data
            </a>
            .
          </Paragraph>
        </section>
      </Box>
    </ContentContainer>
  );

  const sidebarItems = [
    { id: "data", label: "Data" },
    { id: "cleaning", label: "Data Cleaning" },
    { id: "goods", label: "Goods Data" },
    { id: "services", label: "Services Data" },
    { id: "reliability", label: "Country Reliability" },
    { id: "updates", label: "Data Updates" },
  ];

  return (
    <ParentContainer>
      {content}
      <Sidebar
        activeHeadingId={activeHeadingId}
        setFirstRender={setFirstRender}
        items={sidebarItems}
      />
    </ParentContainer>
  );
};

export default About;
