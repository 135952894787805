import styled from "@emotion/styled";
import { YAxisScalingOption } from "./Chart";
import { memo, useState } from "react";
import { chartMargin as chartMarginBase } from "../../components/styles";
import { UIView } from "../../Utils";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { usePageQueryParams } from "../../defaultSettings";

interface YAxisScalingOptionsSelectorProperties {
  view: UIView;
  yAxisScalingOptionsSelectorMargin: number;
  tradeDirection: any;
  tradeFlow: any;
}

const YAxisScalingOptionSelectorContainer = styled.div<{
  $containerWidth: number;
}>`
  width: ${({ $containerWidth }) => $containerWidth}px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding-bottom: ${chartMarginBase.bottom}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9rem;
  color: #232323;
  box-sizing: border-box;
  pointer-events: none;
`;

const YAxisScalingOptionButton = styled.button`
  width: 30px;
  padding: 5px 0px;
  text-transform: uppercase;
  border: 1px solid #000000;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
  line-height: 30px;
  position: relative;
  cursor: pointer;
  pointer-events: all;
  background-color: #ffffff;
`;

const YAxisOptionsFlyout = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
  writing-mode: horizontal-tb;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border: 1px solid #000000;
`;

const YAxisOptionsFlyoutOption = styled.button`
  cursor: pointer;
  padding: 5px 5px;
  text-transform: uppercase;
  &:hover,
  &.selected {
    background-color: #334c60;
    color: #ffffff;
  }
  background-color: #ffffff;
  border: none;
`;

const YAxisOptionArrow = styled.span`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-top: 5px;
  margin-left: 3px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
`;

const YAxisScalingOptionsSelector = ({
  view,
  tradeDirection,
  tradeFlow,
  yAxisScalingOptionsSelectorMargin,
}: YAxisScalingOptionsSelectorProperties) => {
  const [{ yAxis: currentYAxisScalingOption }, setQuery] = usePageQueryParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let flyoutMenuOptions: YAxisScalingOption[] | undefined;
  if (view === UIView.Markets) {
    flyoutMenuOptions = [
      YAxisScalingOption.Current,
      YAxisScalingOption.Constant,
    ];
  } else if (view === UIView.Products) {
    flyoutMenuOptions = [
      YAxisScalingOption.Current,
      YAxisScalingOption.Constant,
      YAxisScalingOption.PerCapita,
      YAxisScalingOption.PerCapitaConstant,
    ];
  }

  let currentYAxisScalingOptionLabel;
  if (currentYAxisScalingOption === YAxisScalingOption.Current) {
    currentYAxisScalingOptionLabel = `Current ${tradeFlow} ${tradeDirection}`;
  } else {
    currentYAxisScalingOptionLabel = currentYAxisScalingOption;
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <YAxisScalingOptionSelectorContainer
      $containerWidth={yAxisScalingOptionsSelectorMargin}
    >
      <YAxisScalingOptionButton aria-describedby={id} onClick={handleClick}>
        {currentYAxisScalingOptionLabel}
        <YAxisOptionArrow />
      </YAxisScalingOptionButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: { overflow: "visible" },
          },
        }}
      >
        <div>
          <YAxisOptionsFlyout>
            {flyoutMenuOptions &&
              flyoutMenuOptions.map((scalingOption: YAxisScalingOption) => {
                let scalingOptionLabel;
                if (scalingOption === YAxisScalingOption.Current) {
                  scalingOptionLabel = `Current ${tradeFlow} ${tradeDirection}`;
                } else {
                  scalingOptionLabel = scalingOption;
                }
                let className =
                  currentYAxisScalingOption &&
                  currentYAxisScalingOption === scalingOption
                    ? "selected"
                    : undefined;

                let handleScalingOptionClick = () => {
                  setQuery({ yAxis: scalingOption });
                  handleClose();
                };
                return (
                  <YAxisOptionsFlyoutOption
                    className={className}
                    onClick={handleScalingOptionClick}
                  >
                    {scalingOptionLabel}
                  </YAxisOptionsFlyoutOption>
                );
              })}
          </YAxisOptionsFlyout>
          <IconButton
            sx={{
              position: "absolute",
              top: "-16px",
              right: "-16px",
              backgroundColor: "rgb(153, 153, 153)",
              fontSize: "0.5rem",
              padding: "0px 0px",
              color: "white",
            }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Popover>
    </YAxisScalingOptionSelectorContainer>
  );
};

export default memo(YAxisScalingOptionsSelector);
