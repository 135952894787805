import { scaleLinear } from "d3-scale";
import styled from "@emotion/styled";
import {
  color01,
  color02,
  color03,
  color04,
  color05,
  color06,
  color07,
  color08,
  color09,
  color10,
  color11,
  color12,
  complexity01,
  complexity02,
  complexity03,
  complexity04,
  complexity05,
  complexity06,
  complexity08,
  complexity09,
  complexity10,
  complexity11,
  complexity12,
} from "./complexityColorScale";

const complexityToPercentageScale = scaleLinear<number>()
  .domain([complexity01, complexity12])
  .range([0, 100]);

const Gradient = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  background-image: linear-gradient(
    to right,
    ${color01} 0%,
    ${color02} ${complexityToPercentageScale(complexity02)}%,
    ${color03} ${complexityToPercentageScale(complexity03)}%,
    ${color04} ${complexityToPercentageScale(complexity04)}%,
    ${color05} ${complexityToPercentageScale(complexity05)}%,
    ${color06} ${complexityToPercentageScale(complexity06)}%,
    /* Note: we want an immediate transition from "orange-y" colors to "green-ish" colors
        but that is not possible with CSS gradient so we allow an extremly short transition
        from the orange to green spectrum over a distance of 0.01%. */
      ${color07} ${complexityToPercentageScale(complexity06) + 0.01}%,
    ${color08} ${complexityToPercentageScale(complexity08)}%,
    ${color09} ${complexityToPercentageScale(complexity09)}%,
    ${color10} ${complexityToPercentageScale(complexity10)}%,
    ${color11} ${complexityToPercentageScale(complexity11)}%,
    ${color12} 100%
  );
`;

const Container = styled.div`
  width: 350px;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 10px 1fr;
  margin: 0 auto;
`;
const ExtremesContainer = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  color: rgb(102, 102, 102);
`;

const ComplexityColorScaleLegend = () => {
  return (
    <Container>
      <ExtremesContainer>
        <div>Low Complexity</div>
        <div>High Complexity</div>
      </ExtremesContainer>
      <Gradient />
    </Container>
  );
};

export default ComplexityColorScaleLegend;
