import React, { createContext, useContext, useRef } from "react";

interface DownloadContextType {
  svgRef: React.MutableRefObject<SVGSVGElement>;
  dataRef: React.MutableRefObject<any[]>;
  canvasRef: React.MutableRefObject<HTMLCanvasElement>;
  titleRef: React.MutableRefObject<string>;
}

const DownloadContext = createContext<DownloadContextType | undefined>(
  undefined,
);

export const DownloadProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const dataRef = useRef<any[]>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const titleRef = useRef<string>("");
  return (
    <DownloadContext.Provider value={{ svgRef, dataRef, canvasRef, titleRef }}>
      {children}
    </DownloadContext.Provider>
  );
};

export const useDownload = () => {
  const context = useContext(DownloadContext);
  if (!context) {
    throw new Error("useDownload must be used within a DownloadProvider");
  }
  return context;
};
