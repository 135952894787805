import styled from "@emotion/styled";
import Arrow from "./arrow";

const arrowIconAspectRatio = 15 / 29; // read from SVG file

interface ArrowProps {
  color: string;
  height: number;
}

export const ArrowIconContainer = styled(Arrow)<ArrowProps>`
  width: ${(props) => props.height * arrowIconAspectRatio}px;
  height: ${(props) => props.height}px;

  line {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 3;
    stroke: ${(props) => props.color};
  }

  polygon {
    fill: ${(props) => props.color};
  }
`;
