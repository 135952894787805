import React, { useRef, useState } from "react";
import {
  GlobalContainer,
  MainContentContainer,
} from "../components/styling/GlobalGrid";
import {
  VisualizationContainer,
  ControlsContainer,
  VisualizationGrid,
  ControlsInputsRowContainer,
  VisualizationTitleContainer,
} from "./VizGrid";
import { Outlet } from "react-router-dom";
import ControlsPanel from "../components/controls";
import NavigationBar from "../components/navigationBar/NavigationBar";
import { OverlayPortal } from "./Utils";
import GenericResizeContainer from "./components/GenericResizeContainer";
import VisualizationTitleGenerator from "./components/VisualizationTitleGenerator";
import { TotalValueProvider } from "./TotalValueContext";
import TotalValue from "./components/TotalValue";
import FindInViz from "../components/controls/findInViz/FindInViz";
import useFetchMetadata, {
  MetadataFetchType,
} from "../sharedUtilities/useFetchMetadata";
import { DownloadProvider } from "./DownloadContext";

function App() {
  const visualizationElementRef = useRef<HTMLDivElement | null>(null);
  const [findInVizOptions, setFindInVizOptions] = useState([]);
  const [highlightedItem, setHighlightedItem] = useState();
  const hs92MetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsHs92,
  });
  const hs12MetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsHs12,
  });

  const sitcMetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsSitc,
  });
  return (
    <DownloadProvider>
      <TotalValueProvider>
        <GlobalContainer id="root">
          <NavigationBar />
          <MainContentContainer>
            <VisualizationContainer>
              <ControlsContainer>
                <ControlsInputsRowContainer>
                  <ControlsPanel
                    findInVizOptions={findInVizOptions}
                    highlightedItem={highlightedItem}
                    setHighlightedItem={setHighlightedItem}
                  />
                </ControlsInputsRowContainer>
              </ControlsContainer>
              <VisualizationTitleContainer>
                <TotalValue />
                <VisualizationTitleGenerator />
                <FindInViz
                  findInVizOptions={findInVizOptions}
                  highlightedItem={highlightedItem}
                  setHighlightedItem={setHighlightedItem}
                />
              </VisualizationTitleContainer>
              <VisualizationGrid>
                <GenericResizeContainer
                  chartContainerRef={visualizationElementRef}
                >
                  <Outlet
                    context={{
                      visualizationElementRef,
                      findInVizOptions,
                      setFindInVizOptions,
                      highlightedItem,
                      setHighlightedItem,
                    }}
                  />
                </GenericResizeContainer>
              </VisualizationGrid>
            </VisualizationContainer>
            <OverlayPortal />
          </MainContentContainer>
        </GlobalContainer>
      </TotalValueProvider>
    </DownloadProvider>
  );
}

export default App;
