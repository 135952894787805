export const GREEN_PRODUCT_HIGH_RCA = "#2DB464";
export const NOT_GREEN_PRODUCT_HIGH_RCA = "#FBB895";
export const GREEN_PRODUCT_LOW_RCA = "#C6E4C9";
export const NOT_GREEN_PRODUCT_LOW_RCA = "#E9E9E9";

export interface ProductData {
  greenProduct?: boolean;
  exportRca?: number;
}

export const greenColorScale = (
  datum: ProductData,
  rcaThreshold: number,
): string => {
  let hasHighRca = datum.exportRca > rcaThreshold;
  if (rcaThreshold === 0) {
    hasHighRca = true;
  }

  if (datum.greenProduct) {
    return hasHighRca ? GREEN_PRODUCT_HIGH_RCA : GREEN_PRODUCT_LOW_RCA;
  } else {
    return hasHighRca ? NOT_GREEN_PRODUCT_HIGH_RCA : NOT_GREEN_PRODUCT_LOW_RCA;
  }
};

export const getLegendCategories = (rcaThreshold: number) => [
  {
    id: "green-high-rca",
    color: GREEN_PRODUCT_HIGH_RCA,
    name: `Green\u00A0Products\u00A0(RCA>${rcaThreshold})`,
  },
  {
    id: "not-green-high-rca",
    color: NOT_GREEN_PRODUCT_HIGH_RCA,
    name: `NOT\u00A0Green\u00A0Products\u00A0(RCA>${rcaThreshold})`,
  },
  {
    id: "green-low-rca",
    color: GREEN_PRODUCT_LOW_RCA,
    name: `Green\u00A0Products\u00A0(RCA<${rcaThreshold})`,
  },
  {
    id: "not-green-low-rca",
    color: NOT_GREEN_PRODUCT_LOW_RCA,
    name: `NOT\u00A0Green\u00A0Products\u00A0(RCA<${rcaThreshold})`,
  },
];
