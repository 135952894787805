import { routesAsSet } from "./index";
import { Route, RouteWithChildren } from "./getRoutingHooks";
import { RouteID } from "./routes";
import { usePageQueryParams } from "../visualization/defaultSettings";
import useFetchMetadata, {
  MetadataFetchStatus,
  MetadataFetchType,
} from "../sharedUtilities/useFetchMetadata";
import { useEffect } from "react";
import { getNumericIdFromStringLocationId } from "../sharedUtilities/Utils";

interface Props {
  pathname: string;
}

export const countryProfilesRedirectFlag = "redirectFromCP";

export function getRouteID({ pathname }: Props) {
  const matchedRouteWithID = [...routesAsSet].filter(
    (route: Route | RouteWithChildren) => route.path === pathname,
  );
  if (matchedRouteWithID.length == 1) {
    return matchedRouteWithID[0].id as RouteID;
  } else {
    throw new Error("Invalid route");
  }
}

export function getRoutePathFromRouteID({ routeId }: { routeId: RouteID }) {
  let matchedPath: string | undefined;
  let matchedRoute = [...routesAsSet].find(
    (route: any) => route.id === routeId,
  );
  if (matchedRoute) {
    matchedPath = matchedRoute.path;
  } else {
    matchedPath = undefined;
  }

  return matchedPath;
}

export function detectCountryProfilesRedirectFlag(
  searchParams: URLSearchParams,
) {
  let countryProfilesRedirectFlagDetected = false;
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param === countryProfilesRedirectFlag && value === "true") {
      countryProfilesRedirectFlagDetected = true;
    }
  }

  return countryProfilesRedirectFlagDetected;
}

export function retrieveOldCountryIdFromCountryProfileRedirect(
  searchParams: URLSearchParams,
) {
  let oldCountryId: string | undefined = undefined;

  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param === "exporter") {
      oldCountryId = value;
    }
  }

  return oldCountryId;
}

export const useOldCountryIds = () => {
  const [query, setQuery] = usePageQueryParams();
  const {
    view: currentView,
    exporter: currentExporter,
    importer: currentImporter,
    product: currentProduct,
    year: currentYear,
    startYear: currentStartYear,
    endYear: currentEndYear,
    productClass: currentProductClass,
    productLevel: currentProductLevel,
    tradeFlow: currentTradeFlow,
  } = query;
  let queryParamsArray = [
    currentView,
    currentExporter,
    currentImporter,
    currentProduct,
    currentYear,
    currentStartYear,
    currentEndYear,
    currentProductClass,
    currentProductLevel,
    currentTradeFlow,
  ];

  const {
    metadataStatus: locationMetadataStatus,
    metadata: locationMetadata,
    error: locationError,
  } = useFetchMetadata({ metadataFetchType: MetadataFetchType.Location });

  useEffect(() => {
    const params = new URLSearchParams(Object.entries(query));
    let countryProfileRedirectFlagDetected =
      detectCountryProfilesRedirectFlag(params);

    if (countryProfileRedirectFlagDetected) {
      // If the Country Profiles redirect flag is in the URL,
      // need to first map old country ID to new country ID,
      // and then replace state with the new country ID

      if (locationMetadataStatus === MetadataFetchStatus.Success) {
        let mappedCountryId: string | undefined;

        let oldCountryId = retrieveOldCountryIdFromCountryProfileRedirect(
          query as any,
        );

        if (oldCountryId !== undefined) {
          let numericOldCountryId =
            getNumericIdFromStringLocationId(oldCountryId);

          let { countries } = locationMetadata;
          let matchingLocationMetadatum = countries.find(
            (country: any) => country.legacyCountryId == numericOldCountryId,
          );
          if (matchingLocationMetadatum) {
            mappedCountryId = matchingLocationMetadatum.countryId;
          }
        }
        setQuery({ exporter: mappedCountryId });
        // navigate("/explore/treemap", { replace: true });
      }
    }
  }, [...queryParamsArray, locationMetadataStatus]);

  // if (hasLoadedOnce === false) {
  //   let stateFromSearchParams = getQueryParamsFromSearchParams(search);

  //   // TODO: Need to check for old URL pattern here, and
  //   // extract parameter values; then, map those old parameter
  //   // values to new set of parameters; then,
  //   // do test to see if mapped params === state params, and
  //   // update state if necessary, AND update URL pattern
  //   if (Object.keys(stateFromSearchParams).length != 0) {
  //     let searchAndStateAreEqual = testQueryParamSetsForEquality(
  //       stateFromSearchParams,
  //       currentQueryParams
  //     );
  //     // if (!searchAndStateAreEqual) {
  //     //   dispatch(replaceState(stateFromSearchParams));
  //     // }
  //   }
  //   setHasLoadedOnce(true);
  // }
};
