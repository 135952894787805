import styled from "@emotion/styled";

const ZoomButton = styled.button`
  width: 80px;
  height: 22px;
  border: 1px solid rgb(136, 136, 153);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(136, 136, 153);
  opacity: 0.75;
  margin-bottom: 10px;
  font-weight: bold;
  background-color: #fff;
  font-size: 9.6px;
  font-size: 0.6rem;
  text-transform: uppercase;
  pointer-events: auto;
`;

const ZoomContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 0px;
  width: 100px;
  z-index: 2;
`;
const ZoomControls = ({ zoom }) => {
  return (
    <ZoomContainer>
      <ZoomButton
        onClick={(e) => {
          e.stopPropagation();
          zoom.scale({ scaleX: 1.2, scaleY: 1.2 });
        }}
      >
        + ZOOM
      </ZoomButton>
      <ZoomButton
        onClick={(e) => {
          e.stopPropagation();
          zoom.scale({ scaleX: 0.8, scaleY: 0.8 });
        }}
      >
        - ZOOM
      </ZoomButton>
      <ZoomButton
        onClick={(e) => {
          e.stopPropagation();
          zoom.reset();
        }}
      >
        RESET ZOOM
      </ZoomButton>
    </ZoomContainer>
  );
};
export default ZoomControls;
