import { gql } from "@apollo/client";

const query = gql`
  fragment ProductMetadata on Product {
    productId
    productLevel
    code
    nameEn
    nameShortEn
    showFeasibility
    parent {
      productId
      productLevel
    }
    topParent {
      productId
      productLevel
    }
  }

  query GetSITCProducts($servicesClass: ServicesClass) {
    section: productSitc(productLevel: 1, servicesClass: $servicesClass) {
      ...ProductMetadata
    }

    twoDigit: productSitc(productLevel: 2, servicesClass: $servicesClass) {
      ...ProductMetadata
    }

    fourDigit: productSitc(productLevel: 4, servicesClass: $servicesClass) {
      ...ProductMetadata
    }
  }
`;

export default query;
