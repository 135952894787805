import { memo, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";
import TooltipContent from "./Tooltip";
import { ProductClass } from "../../Utils";

interface AnimatedPointsProps {
  filteredData: any;
  ySelection: string;
  metaDataLookup: Map<any, any>;
  reset: Function;
  highlightedItem: string | null;
  setHighlightedItem: Function;
  productClass: ProductClass;
  totalExportLookup: any;
}

const AnimatedPoints: React.FC<AnimatedPointsProps> = ({
  filteredData,
  ySelection,
  metaDataLookup,
  reset,
  highlightedItem,
  setHighlightedItem,
  productClass,
  totalExportLookup,
}) => {
  useEffect(() => {
    reset();
  }, [filteredData?.[0]?.countryId]);
  const transitions = useTransition(filteredData, {
    key: (d: any) => `${d.productId}-${ySelection}`,
    from: (d: any) => ({
      cx: d.cx,
      cy: d.cy,
      r: d.r,
      opacity: 0.25,
      fillOpacity: 0.25,
    }),
    enter: (d: any) => ({
      cx: d.cx,
      cy: d.cy,
      r: d.r,
      opacity: d.opacity,
      fill: d.fill,
      fillOpacity: d.fillOpacity,
    }),
    update: (d: any) => ({
      cx: d.cx,
      cy: d.cy,
      r: d.r,
      opacity: d.opacity,
      fill: d.fill,
      fillOpacity: d.fillOpacity,
    }),
    leave: { opacity: 0, config: { duration: 0 } },
  });

  return transitions((style, item, _, index) => (
    <Tippy
      key={`${item.productId}-${ySelection}`}
      content={
        <TooltipContent
          nameShortEn={metaDataLookup.get(item.productId)?.data?.nameShortEn}
          code={metaDataLookup.get(item.productId)?.data?.code}
          productClass={productClass}
          fillColor={item.fill}
          sectorName={
            metaDataLookup.get(
              metaDataLookup.get(item.productId)?.data?.topParent?.productId,
            )?.data?.nameShortEn
          }
          distance={item?.distance}
          exportValue={totalExportLookup.get(item.productId)?.exportValue}
          exportRca={item?.exportRca}
          pci={totalExportLookup.get(item.productId)?.pci}
          ySelection={ySelection}
          cog={item?.cog}
        />
      }
      plugins={[followCursor]}
      followCursor={true}
      trigger="mouseenter"
      disabled={highlightedItem === item.productId}
    >
      <animated.circle
        key={`${item.productId}-${ySelection}`}
        {...style}
        id={metaDataLookup.get(item.productId)?.data.nameShortEn}
        stroke="black"
        cursor="pointer"
        onClick={() => setHighlightedItem(item.productId)}
      />
    </Tippy>
  ));
};

const MemoizedAnimatedPoints = memo(AnimatedPoints);
export default MemoizedAnimatedPoints;
