import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { format, scaleLinear } from "d3";
import { ScaleLinear } from "d3";
import { ColorBy, UIView } from "../../Utils";
import { LocationDetailLevel } from "../../Utils";
import { memo, useEffect, useRef, useState } from "react";
import { usePageQueryParams } from "../../defaultSettings";

const labelCellPadding = 8;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Label = styled.div`
  color: #ffffff;
  pointer-events: none;
  position: fixed;
  overflow: hidden;
  padding: ${labelCellPadding}px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  opacity: 0;

  animation-name: ${fadeIn};
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-delay: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  & * {
    box-sizing: border-box;
  }
`;

const TextContent = styled.p`
  margin: 0px;
  padding: 0px;
  padding-right: ${labelCellPadding}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  line-height: 120%;
  display: inline-block;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const HiddenTextContent = styled(TextContent)`
  text-overflow: unset;
  overflow: unset;
  white-space: unset;
  width: unset;
  visibility: hidden;
`;

const PercentOfTotalShown = styled.p<{ $fontSize: number }>`
  position: absolute;
  top: calc(${({ $fontSize }) => $fontSize}rem + ${labelCellPadding}px);
  left: ${labelCellPadding}px;
  padding: 0px;
  margin: 0px;
  text-align: left;
  line-height: 120%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const fontSizeScale: ScaleLinear<number, number> = scaleLinear()
  .domain([0, 0.1])
  .range([0.5, 3])
  .clamp(true);

const cellWidthToLabelWidthRatioThreshold = (cellWidth: number) => {
  return cellWidth - 2 * labelCellPadding;
};

const cellWidthToPercentageLabelWidthRatioThreshold = (input: number) => {
  return input - 2 * labelCellPadding;
};

const cellHeightToLabelHeightRatioThreshold = (input: number) => {
  return input - 2 * labelCellPadding;
};

const ellipsisCharacter = "…";

const mustShowLabelThreshold = 0.05;
const doNotShowLabelThreshold = 0.001;
const minimumCharactersToDisplay = 3;

const TreemapCellLabel = ({
  leaf,
  totalSum,
  key,
  nonServices,
  servicesWidth,
}: any) => {
  const [
    {
      view: currentUiView,
      colorBy: currentColorBySelection,
      locationLevel: currentLocationDetailLevel,
    },
  ] = usePageQueryParams();

  const textLabelContentRef = useRef<HTMLDivElement | null>(null);
  const valueLabelContentRef = useRef<HTMLDivElement | null>(null);

  const [showTextLabel, setShowTextLabel] = useState<boolean>(false);
  const [showValueLabel, setShowValueLabel] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false);

  const fractionOfTotalValue = leaf.value / totalSum;

  const cellWidth = leaf.x1 - leaf.x0;
  const cellHeight = leaf.y1 - leaf.y0;

  const cellFontSize = fontSizeScale(fractionOfTotalValue);

  const cellStyle = {
    width: `${cellWidth}px`,
    height: `${cellHeight}px`,
    fontSize: `${cellFontSize}rem`,
  };

  let labelValueToUse: string = "";
  if (currentUiView === UIView.Markets) {
    if (currentLocationDetailLevel === LocationDetailLevel.country) {
      labelValueToUse = leaf.data.nameEn;
    } else if (currentLocationDetailLevel === LocationDetailLevel.subregion) {
      labelValueToUse = leaf.data.hybrid_level_2.nameEn;
    } else if (currentLocationDetailLevel === LocationDetailLevel.region) {
      labelValueToUse = leaf.data.hybrid_level_1.nameEn;
    }
  } else if (currentUiView === UIView.Products) {
    labelValueToUse = leaf.data.nameEn;
  }

  let cellValueLabelString;
  if (currentColorBySelection === ColorBy.Sector) {
    cellValueLabelString = format(".2%")(fractionOfTotalValue);
  } else if (currentColorBySelection === ColorBy.Complexity) {
    cellValueLabelString = format(".3f")(leaf.data.pci);
  }

  let hiddenTextContentLabel = (
    <HiddenTextContent
      ref={textLabelContentRef}
    >{`${labelValueToUse.slice(0, minimumCharactersToDisplay)}${ellipsisCharacter}`}</HiddenTextContent>
  );

  let hiddenCellValueLabel = (
    <HiddenTextContent ref={valueLabelContentRef}>
      {cellValueLabelString}
    </HiddenTextContent>
  );

  let textContentLabel = <TextContent>{labelValueToUse}</TextContent>;
  let cellValueLabel = (
    <PercentOfTotalShown $fontSize={cellFontSize}>
      {cellValueLabelString}
    </PercentOfTotalShown>
  );

  const xOffset = nonServices ? leaf.x0 + servicesWidth : leaf.x0;

  useEffect(() => {
    if (textLabelContentRef && textLabelContentRef.current) {
      let textLabelContentNode = textLabelContentRef.current;
      let { width: textLabelWidth, height: textLabelHeight } =
        textLabelContentNode.getBoundingClientRect();

      if (fractionOfTotalValue >= mustShowLabelThreshold) {
        setShowTextLabel(true);
      } else if (fractionOfTotalValue < doNotShowLabelThreshold) {
        setShowTextLabel(false);
      } else if (
        textLabelWidth > 0 &&
        textLabelWidth <= cellWidthToLabelWidthRatioThreshold(cellWidth) &&
        textLabelHeight > 0 &&
        textLabelHeight <= cellHeightToLabelHeightRatioThreshold(cellHeight)
      ) {
        setShowTextLabel(true);
      }

      setIsFirstRender(true);
    }
  }, [textLabelContentRef.current]);

  useEffect(() => {
    if (valueLabelContentRef && valueLabelContentRef.current) {
      let valueLabelContentNode = valueLabelContentRef.current;
      let { width: valueLabelWidth, height: valueLabelHeight } =
        valueLabelContentNode.getBoundingClientRect();
      let combinedHeight = 2 * valueLabelHeight;
      if (
        valueLabelWidth > 0 &&
        valueLabelWidth <=
          cellWidthToPercentageLabelWidthRatioThreshold(cellWidth) &&
        valueLabelHeight > 0 &&
        combinedHeight <= cellHeightToLabelHeightRatioThreshold(cellHeight)
      ) {
        setShowValueLabel(true);
      }

      setIsFirstRender(true);
    }
  }, [valueLabelContentRef.current, showTextLabel]);

  if (isFirstRender === false) {
    return (
      <foreignObject
        x={xOffset}
        y={leaf.y0}
        width={cellWidth}
        height={cellHeight}
        pointerEvents="none"
      >
        <Label style={cellStyle}>
          {hiddenTextContentLabel}
          {hiddenCellValueLabel}
        </Label>
      </foreignObject>
    );
  } else if (showTextLabel === true || showValueLabel === true) {
    return (
      <foreignObject
        x={xOffset}
        y={leaf.y0}
        width={cellWidth}
        height={cellHeight}
        pointerEvents="none"
      >
        <Label key={key} style={cellStyle}>
          {showTextLabel && textContentLabel}
          {showTextLabel && showValueLabel && cellValueLabel}
        </Label>
      </foreignObject>
    );
  } else {
    return null;
  }
};

export default memo(TreemapCellLabel);
