import styled from "@emotion/styled";
import {
  BaseMenuItem,
  ArrowUp,
  ArrowDown,
} from "../../mainDropdownPrimitive/styles";
import { ProductMetadatumLevel } from "../../../graphql/types";
import { UIView, getColorMap } from "../../../visualization/Utils";
import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";
import { allProductsDatum } from "../../../graphql/queries/getProductsMetadata";
import { getProductClassShortLabel } from "./Utils";
import { usePageQueryParams } from "../../../visualization/defaultSettings";

export const ProductMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
  margin-bottom: 2px;
`;

export const IconContainer = styled.div`
  flex-basis: 4px;
  width: 4px;
  align-self: stretch;
`;

export const LabelContainer = styled.div`
  flex-basis: 100%;
  padding-left: 0.33rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 6px;
  padding-bottom: 6px;

  // height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin: auto 0px;
`;

export const ProductCodeLabel = styled.span`
  display: contents;
  color: rgb(128, 128, 128);
  font-size: 0.8125rem;
`;

interface ProductDropdownItemInput {
  item: any;
  hashFunction: any;
  getItemProps: any;
  mappedItemIndex: number;
  index: number;
  highlightedIndex: number;
  displayItems: any;
  setDisplayItems: any;
  updateVisibilityFunction: any;
  selectedItem: any;
  isSearching?: boolean;
  virtualizedStyle?: any;
}

interface LocationDropdownItemInputWithRef extends ProductDropdownItemInput {
  ref: any;
}

const ToggleButtonArrow = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  &:hover {
    background-color: rgb(230, 230, 230);
  }
`;

const toggleButtonClick = ({
  item,
  updateVisibilityFunction,
  displayItems,
  setDisplayItems,
}: any) => {
  /*
    When click on toggle button,

    Update this item's isExpanded value
    call newItems = updateItemsWithVisibilityStatus
    setDisplayItems(newItems)

    */
  updateVisibilityFunction({
    itemToUpdate: item,
    displayItems,
    setDisplayItems,
  });
};

const ExpandToggleButton = ({
  style,
  item,
  updateVisibilityFunction,
  displayItems,
  setDisplayItems,
}: any) => {
  const { isExpanded, id } = item;
  // If isExpanded === true: upward-pointing arrow
  // If isExpanded === false: downward-pointing arrow
  return (
    <ToggleButtonArrow
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        toggleButtonClick({
          item,
          updateVisibilityFunction,
          displayItems,
          setDisplayItems,
        });
      }}
    >
      {isExpanded ? <ArrowUp /> : <ArrowDown />}
    </ToggleButtonArrow>
  );
};

const ProductDropdownItem = ({
  item,
  hashFunction,
  index,
  selectedItem,
  highlightedIndex,
  mappedItemIndex,
  getItemProps,
  updateVisibilityFunction,
  displayItems,
  setDisplayItems,
  isSearching,
  virtualizedStyle,
}: ProductDropdownItemInput) => {
  const dropdownItemRef = useRef<HTMLDivElement | null>(null);

  const {
    id,
    level: productLevel,
    product_section,
    name_short_en: label,
    code: productCode,
    isVisible,
    isVisibleChange,
  } = item;

  const [{ productClass: currentProductClass }] = usePageQueryParams() as any;

  let productClassShortLabelString = getProductClassShortLabel({
    productClass: currentProductClass,
  });

  let productCodeLabel = null;
  if (productClassShortLabelString !== undefined) {
    productCodeLabel = (
      <ProductCodeLabel>
        ({productCode} {productClassShortLabelString})
      </ProductCodeLabel>
    );
  }

  let completeItemLabel;
  if (productCodeLabel) {
    completeItemLabel = (
      <>
        {item.name_short_en} {productCodeLabel}
      </>
    );
  } else {
    completeItemLabel = item.name_short_en;
  }

  let classLevelName = "level";
  let highlightedClassName =
    index === highlightedIndex ? "menu-item--highlighted" : "";

  let useColorMap = getColorMap({
    view: UIView.Products,
    productClass: currentProductClass,
  });

  let color: string | undefined = undefined;
  if (useColorMap) {
    if (
      id !== allProductsDatum.productId &&
      useColorMap.has(product_section.productId)
    ) {
      color = useColorMap.get(product_section.productId);
    }
  }

  const [listItemHeight, setListItemHeight] = useState<number | undefined>(
    undefined,
  );

  // let initialVisibilityStyle: any;
  // let targetVisibilityStyle: any;
  // if(isVisible) {
  //     if(isVisibleChange) {
  //         initialVisibilityStyle = {
  //             height: '0px',
  //             opacity: 0,
  //             // transform: 'scaleY(0)',
  //         };
  //     } else {
  //         initialVisibilityStyle = {
  //             height: '36px',
  //             opacity: 1,
  //             // transform: 'scaleY(1)',
  //         };
  //     }

  //     targetVisibilityStyle = {
  //         height: '36px',
  //         opacity: 1,
  //         // transform: 'scaleY(1)',
  //     };
  // } else if(!isVisible) {
  //     if(isVisibleChange) {
  //         initialVisibilityStyle = {
  //             height: '36px',
  //             opacity: 1,
  //             // transform: 'scaleY(1)',
  //         };
  //     } else {
  //         initialVisibilityStyle = {
  //             height: '0px',
  //             opacity: 0,
  //             // transform: 'scaleY(0)',
  //         };
  //     }

  //     targetVisibilityStyle = {
  //         height: '0px',
  //         opacity: 0,
  //         // transform: 'scaleY(0)',

  //     };
  // }

  const [itemHasTransitioned, setItemHasTransitioned] =
    useState<boolean>(false);

  useEffect(() => {
    if (isSearching === true) return;

    if (dropdownItemRef && dropdownItemRef.current) {
      if (!itemHasTransitioned) {
        let useComputedHeight;
        if (!listItemHeight) {
          let { height: computedHeight } = dropdownItemRef.current
            .querySelector("li")!
            .getBoundingClientRect();
          useComputedHeight = computedHeight;
        } else {
          useComputedHeight = listItemHeight;
        }

        let startingHeight, endingHeight;
        if (isVisibleChange) {
          startingHeight = 0;
        } else {
          startingHeight = useComputedHeight;
        }
        endingHeight = useComputedHeight;

        d3.select(dropdownItemRef.current)
          .style("visibility", "visible")
          .style("overflow", "hidden")
          .style("height", `${startingHeight}px`);

        if (isVisibleChange) {
          d3.select(dropdownItemRef.current)
            .transition()
            .duration(200)
            .ease(d3.easeLinear)
            .style("height", `${endingHeight}px`);
        }

        setItemHasTransitioned(true);
      }
    }
  }, [dropdownItemRef.current, isVisible, isVisibleChange]);

  let initialVisibilityStyle: any;
  if (virtualizedStyle) {
    initialVisibilityStyle = virtualizedStyle;
  } else {
    initialVisibilityStyle = {
      visibility: "hidden",
    };
  }

  let expandToggleButtonStyle = isSearching ? { display: "none" } : undefined;

  if (productLevel === ProductMetadatumLevel.sixDigit) {
    const style = {
      borderLeft: `3px solid ${color}`,
      marginLeft: "36px",
    };

    return (
      <div style={initialVisibilityStyle} ref={dropdownItemRef}>
        <BaseMenuItem
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...getItemProps({ item, index: mappedItemIndex })}
        >
          <ProductMenuItem className={classLevelName}>
            <IconContainer style={style}></IconContainer>
            <LabelContainer>{completeItemLabel}</LabelContainer>
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (productLevel === ProductMetadatumLevel.fourDigit) {
    const style = {
      borderLeft: `3px solid ${color}`,
      marginLeft: "24px",
    };

    return (
      <div style={initialVisibilityStyle} ref={dropdownItemRef}>
        <BaseMenuItem
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...getItemProps({ item, index: mappedItemIndex })}
        >
          <ProductMenuItem className={classLevelName}>
            <IconContainer style={style}></IconContainer>
            <LabelContainer>{completeItemLabel}</LabelContainer>
            <ExpandToggleButton
              style={expandToggleButtonStyle}
              item={item}
              updateVisibilityFunction={updateVisibilityFunction}
              displayItems={displayItems}
              setDisplayItems={setDisplayItems}
            />
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (productLevel === ProductMetadatumLevel.twoDigit) {
    const style = {
      borderLeft: `3px solid ${color}`,
      marginLeft: "12px",
    };

    return (
      <div style={initialVisibilityStyle} ref={dropdownItemRef}>
        <BaseMenuItem
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...getItemProps({ item, index: mappedItemIndex })}
        >
          <ProductMenuItem className={classLevelName}>
            <IconContainer style={style}></IconContainer>
            <LabelContainer>{completeItemLabel}</LabelContainer>
            <ExpandToggleButton
              style={expandToggleButtonStyle}
              item={item}
              updateVisibilityFunction={updateVisibilityFunction}
              displayItems={displayItems}
              setDisplayItems={setDisplayItems}
            />
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (productLevel === ProductMetadatumLevel.section) {
    const style = {
      borderLeft: `12px solid ${color}`,
      marginLeft: "0px",
      height: "32px",
      width: "12px",
    };

    return (
      <div style={initialVisibilityStyle} ref={dropdownItemRef}>
        <BaseMenuItem
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...getItemProps({ item, index: mappedItemIndex })}
        >
          <ProductMenuItem className={classLevelName}>
            <IconContainer style={style}></IconContainer>
            <LabelContainer>{completeItemLabel}</LabelContainer>
            <ExpandToggleButton
              style={expandToggleButtonStyle}
              item={item}
              updateVisibilityFunction={updateVisibilityFunction}
              displayItems={displayItems}
              setDisplayItems={setDisplayItems}
            />
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (productLevel === ProductMetadatumLevel.allProducts) {
    const style = {
      ...initialVisibilityStyle,
      visibility: "visible",
      marginLeft: "0px",
      paddingLeft: "0px",
      position: "sticky",
      top: "0px",
      left: "0px",
      backgroundColor: "#ffffff",
      zIndex: "100",
      borderBottom: "1px solid #CCCCCC",
    };

    return (
      <div style={style} ref={dropdownItemRef}>
        <BaseMenuItem
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...getItemProps({ item, index: mappedItemIndex })}
        >
          <ProductMenuItem className={classLevelName}>
            <LabelContainer>{hashFunction(item)}</LabelContainer>
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else {
    throw new Error("Not a valid product metadatum level.");
  }
};

export default ProductDropdownItem;
