import { BaseMenuItem } from "../../mainDropdownPrimitive/styles";
import { ProductMetadatumLevel } from "../../../graphql/types";
import { memo } from "react";
import { getProductClassShortLabel } from "./Utils";
import {
  IconContainer,
  LabelContainer,
  ProductCodeLabel,
  ProductMenuItem,
} from "./ProductDropdownItem";
import { usePageQueryParams } from "../../../visualization/defaultSettings";

interface ProductDropdownItemInput {
  item: any;
  hashFunction: any;
}

const ProductDropdownItemForPreCalculation = ({
  item,
  hashFunction,
}: ProductDropdownItemInput) => {
  const {
    id,
    level: productLevel,
    product_section,
    name_short_en: label,
    code: productCode,
    isVisible,
    isVisibleChange,
  } = item;
  const [{ productClass: currentProductClass }] = usePageQueryParams() as any;

  let productClassShortLabelString = getProductClassShortLabel({
    productClass: currentProductClass,
  });

  let productCodeLabel = null;
  if (productClassShortLabelString !== undefined) {
    productCodeLabel = (
      <ProductCodeLabel>
        ({productCode} {productClassShortLabelString})
      </ProductCodeLabel>
    );
  }

  let completeItemLabel;
  if (productCodeLabel) {
    completeItemLabel = (
      <>
        {item.name_short_en} {productCodeLabel}
      </>
    );
  } else {
    completeItemLabel = item.name_short_en;
  }

  let classLevelName = "level";
  let color = "#000000";

  if (productLevel === ProductMetadatumLevel.sixDigit) {
    const style = {
      borderLeft: `3px solid ${color}`,
      marginLeft: "36px",
    };

    return (
      <div className={"hidden"} data-productid={item.id}>
        <BaseMenuItem>
          <ProductMenuItem className={classLevelName}>
            <IconContainer style={style}></IconContainer>
            <LabelContainer>{completeItemLabel}</LabelContainer>
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (productLevel === ProductMetadatumLevel.fourDigit) {
    const style = {
      borderLeft: `3px solid ${color}`,
      marginLeft: "24px",
    };

    return (
      <div className={"hidden"} data-productid={item.id}>
        <BaseMenuItem>
          <ProductMenuItem className={classLevelName}>
            <IconContainer style={style}></IconContainer>
            <LabelContainer>{completeItemLabel}</LabelContainer>
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (productLevel === ProductMetadatumLevel.twoDigit) {
    const style = {
      borderLeft: `3px solid ${color}`,
      marginLeft: "12px",
    };

    return (
      <div className={"hidden"} data-productid={item.id}>
        <BaseMenuItem>
          <ProductMenuItem className={classLevelName}>
            <IconContainer style={style}></IconContainer>
            <LabelContainer>{completeItemLabel}</LabelContainer>
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (productLevel === ProductMetadatumLevel.section) {
    const style = {
      borderLeft: `12px solid ${color}`,
      marginLeft: "0px",
      height: "32px",
      width: "12px",
    };

    return (
      <div className={"hidden"} data-productid={item.id}>
        <BaseMenuItem>
          <ProductMenuItem className={classLevelName}>
            <IconContainer style={style}></IconContainer>
            <LabelContainer>{completeItemLabel}</LabelContainer>
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (productLevel === ProductMetadatumLevel.allProducts) {
    const style: any = {
      marginLeft: "0px",
      paddingLeft: "0px",
      position: "sticky",
      top: "0px",
      left: "0px",
      backgroundColor: "#ffffff",
      zIndex: "100",
      borderBottom: "1px solid #CCCCCC",
    };

    return (
      <div style={style} className={"hidden"} data-productid={item.id}>
        <BaseMenuItem>
          <ProductMenuItem className={classLevelName}>
            <LabelContainer>{hashFunction(item)}</LabelContainer>
          </ProductMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else {
    return <></>;
  }
};

export default memo(ProductDropdownItemForPreCalculation);
