import { useQuery } from "@apollo/client";
import {
  VisualizationContentContainer,
  VisualizationHandlesContainer,
  VisualizationBottomRowContainer,
} from "../../VizGrid";
import {
  ProductLevel,
  VizType,
  determineAPICallLocationOrder,
  getCategoriesForChartLegend,
  getLocationQualifiers,
  getProductClassYearRange,
  getTradeDirection,
} from "../../Utils";
import CategoryLabels from "../../components/CategoryLabels";
import {
  useRef,
  useState,
  useEffect,
  useCallback,
  ReactElement,
  useMemo,
} from "react";
import Chart from "./Chart";
import { UIView } from "../../Utils";
import determineEndpointFacet from "../../../graphql/determineEndpointFacet";
import { CategoryDatum } from "../../components/LegendLabel";
import Tooltip, { TooltipContent } from "../../components/Tooltip";
import { convertProductStringToNumberId } from "../../../sharedUtilities/Utils";
import { allProductsDatum } from "../../../graphql/queries/getProductsMetadata";
import { memo } from "react";
import { mapQueryArgumentsToAPIEndpointInputs } from "../../../graphql/Utils";
import { TooltipLineMarker } from "../../components/Tooltip";
import { useOutletContext } from "react-router-dom";
import useFetchMetadata, {
  MetadataFetchType,
} from "../../../sharedUtilities/useFetchMetadata";
import GraphLoading from "../../components/GraphLoading";
import GraphNotice, { GraphNoticeType } from "../../components/GraphNotice";
import { usePageQueryParams } from "../../defaultSettings";
import { useTotalValue } from "../../TotalValueContext";

interface OverTimeInputProperties {
  locationMetadataFetch: any;
  hs92MetadataFetch: any;
  hs12MetadataFetch: any;
  sitcMetadataFetch: any;
}

export const yAxisScalingOptionsSelectorMargin = 100;

const OverTime = ({
  locationMetadataFetch,
  hs92MetadataFetch,
  hs12MetadataFetch,
  sitcMetadataFetch,
}: OverTimeInputProperties) => {
  const [
    {
      tradeFlow,
      importer,
      exporter,
      product: productId,
      view,
      productClass,
      productLevel,
      startYear: yearMin,
      endYear: yearMax,
      servicesClass,
      yAxis,
    },
  ] = usePageQueryParams();

  const tooltipContentRef = useRef<HTMLDivElement | null>(null);

  const [chartContainerOffset, setChartContainerOffset] = useState<
    { left: number; top: number } | undefined
  >(undefined);
  const [tooltipContent, actuallySetTooltipContent] = useState<
    | {
        isFixed: boolean | undefined;
        isFixedCoords: [number, number] | undefined;
        contentData: any | undefined;
      }
    | undefined
  >(undefined);

  const [hiddenCategories, setHiddenCategories] = useState<string[]>([]);
  const [totalValue, setTotalValue] = useTotalValue();
  const [yAxisMarkerExtent, setYAxisMarkerExtent] = useState<
    { min: number; max: number } | undefined
  >(undefined);

  const {
    visualizationElementRef: overtimeElement,
    findInVizOptions,
    setFindInVizOptions: actuallySetFindInVizOptions,
    highlightedItem,
    setHighlightedItem,
  }: any = useOutletContext();

  const deflatorsMetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.Deflators,
  });

  let locationReferenceId;
  let locationReferenceLevel;

  let {
    exporterLocationLevel,
    exporterIsUndefined,
    exporterIsWorld,
    importerLocationLevel,
    importerIsUndefined,
    importerIsWorld,
  } = getLocationQualifiers({ exporter, importer });

  if (!exporterIsUndefined && !importerIsUndefined) {
    if (!exporterIsWorld && importerIsWorld) {
      locationReferenceId = exporter;
      locationReferenceLevel = exporterLocationLevel;
    } else if (exporterIsWorld && !importerIsWorld) {
      locationReferenceId = importer;
      locationReferenceLevel = importerLocationLevel;
    } else if (!exporterIsWorld && !importerIsWorld) {
      // If both exporter and importer are defined and neither is World,
      // use the exporter as the reference location for computing per capita trade values
      // with countryYear
      locationReferenceId = exporter;
      locationReferenceLevel = exporterLocationLevel;
    } else {
      locationReferenceId = exporter;
      locationReferenceLevel = exporterLocationLevel;
    }
  } else if (exporterIsUndefined) {
    locationReferenceId = importer;
    locationReferenceLevel = importerLocationLevel;
  } else if (importerIsUndefined) {
    locationReferenceId = exporter;
    locationReferenceLevel = exporterLocationLevel;
  }

  let locationYearMetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.LocationYear,
    locationReferenceId,
    locationReferenceLevel,
  });

  const setTooltipContent = useCallback(
    (input: any) => {
      if (input === undefined) {
        setHighlightedItem(undefined);
      }
      actuallySetTooltipContent(input);
    },
    [setHighlightedItem, actuallySetTooltipContent],
  );

  const setFindInVizOptions = useCallback(
    (input: any) => {
      actuallySetFindInVizOptions(input);
    },
    [actuallySetFindInVizOptions],
  );

  const [xAxisTooltipValue, setXAxisTooltipValue] = useState({
    year: undefined,
    x: undefined,
    y: undefined,
  });

  const [snapTooltipToCoordinates, setSnapTooltipToCoordinates] = useState<
    [number, number] | undefined
  >(undefined);

  useEffect(() => {
    let snapCoordinates: [number, number] | undefined;

    if (
      xAxisTooltipValue &&
      xAxisTooltipValue &&
      chartContainerOffset &&
      yAxisMarkerExtent
    ) {
      let { x } = xAxisTooltipValue;
      let { min, max } = yAxisMarkerExtent;
      let { left, top } = chartContainerOffset;
      snapCoordinates = [x + left, top + max];
    } else {
      snapCoordinates = undefined;
    }

    setSnapTooltipToCoordinates(snapCoordinates);
  }, [xAxisTooltipValue, yAxisMarkerExtent, chartContainerOffset]);

  useEffect(() => {
    if (overtimeElement && overtimeElement.current) {
      const node = overtimeElement.current;
      const { left, top } = node.getBoundingClientRect();

      if (
        chartContainerOffset === undefined ||
        chartContainerOffset.left !== left ||
        chartContainerOffset.top !== top
      ) {
        setChartContainerOffset({ left, top });
      }
    }
  }, [overtimeElement]);

  useEffect(() => {
    setHighlightedItem(undefined);
  }, [view]);

  const useProductLevel = useMemo(() => {
    if (view === UIView.Markets) {
      if (productId === allProductsDatum.productId) {
        return ProductLevel.ProductSection;
      } else {
        return undefined;
      }
    } else {
      return productLevel;
    }
  }, [view, productId, productLevel]);

  const defaultYearRange = useMemo(
    () =>
      getProductClassYearRange({
        productClass: productClass as any,
      }),
    [productClass],
  );

  const narrowedOverTimeInputVariables = useMemo(
    () =>
      mapQueryArgumentsToAPIEndpointInputs({
        exporter,
        importer,
        view,
      }),
    [exporter, importer, view],
  );

  let productIdForAPI = useMemo(() => {
    if (view === UIView.Products) {
      return undefined;
    } else if (productId === allProductsDatum.productId) {
      return undefined;
    } else {
      return convertProductStringToNumberId(productId);
    }
  }, [productId, view]);

  const overTimeInputVariables: any = useMemo(
    () => ({
      productClass: productClass,
      yearMin: defaultYearRange.startYear,
      yearMax: defaultYearRange.endYear,
      productLevel: useProductLevel,
      servicesClass,
      productId: productIdForAPI,
      ...narrowedOverTimeInputVariables,
    }),
    [
      productClass,
      defaultYearRange,
      useProductLevel,
      servicesClass,
      productIdForAPI,
      narrowedOverTimeInputVariables,
    ],
  );

  // Update API call location order
  const { locationForAPI, partnerForAPI } = useMemo(
    () =>
      determineAPICallLocationOrder({
        variables: overTimeInputVariables,
      }),
    [overTimeInputVariables],
  );

  const { queryToUse, queryIsInvalid } = useMemo(
    () =>
      determineEndpointFacet({
        view: view as any,
        variables: overTimeInputVariables,
      }),
    [view, overTimeInputVariables],
  );

  /* Note: Here, we are caching the onHover function with useCallback; this is to prevent unnecessary rerenders of <Chart>
    whenever `tooltipContent` state gets updated (and the whole  component gets rerendered) */
  const onHover = useCallback(
    ({ series, setTooltipFixed }: any) => {
      if (!series) {
        if (setTooltipFixed) {
          setTooltipContent({ isFixed: setTooltipFixed });
        } else {
          setTooltipContent(undefined);
        }
      } else {
        if (xAxisTooltipValue && xAxisTooltipValue.year) {
          let filteredSeries = series.find(
            (d: any) => d.year === xAxisTooltipValue.year,
          );
          const { pointMin, pointMax, productId } = filteredSeries;
          if (
            !yAxisMarkerExtent ||
            yAxisMarkerExtent.min !== pointMin ||
            yAxisMarkerExtent.max !== pointMax
          ) {
            setYAxisMarkerExtent({ min: pointMin, max: pointMax });
          }
          let mappedDatum = {
            year: filteredSeries.year,
            ...filteredSeries.properties,
          };
          setTooltipContent({
            isFixed: setTooltipFixed,
            contentData: mappedDatum,
          });
        } else {
          setTooltipContent({
            contentData: undefined,
            isFixed: setTooltipFixed,
          });
        }
      }
    },
    [setTooltipContent, xAxisTooltipValue, yAxisMarkerExtent],
  );

  const clearTooltip = useCallback(() => {
    setTooltipContent(undefined);
  }, [setTooltipContent]);

  const { loading, error, data, previousData } = useQuery(queryToUse, {
    variables: overTimeInputVariables,
    skip: queryIsInvalid,
  });
  let content: any = undefined;
  const successResponse = data ? data : previousData;
  const currentTradeDirection = useMemo(() => {
    return getTradeDirection({
      exporter: exporter,
      importer: importer,
      locationForAPI,
      partnerForAPI,
    });
  }, [exporter, importer, locationForAPI, partnerForAPI]);

  if (successResponse) {
    if (exporter || importer || productId) {
      content = (
        <Chart
          handleTooltip={onHover}
          clearTooltip={clearTooltip}
          inputData={successResponse}
          queryVariables={{
            view,
            productClass,
            servicesClass,
            productLevel,
            exporter,
            importer,
            yearMin,
            yearMax,
            yAxis,
          }}
          yearRangeForProductClass={defaultYearRange}
          hiddenCategories={hiddenCategories}
          setTotalValue={setTotalValue}
          yAxisMarkerExtent={yAxisMarkerExtent}
          setXAxisTooltipValue={setXAxisTooltipValue}
          setFindInVizOptions={setFindInVizOptions}
          findInVizOptions={findInVizOptions}
          highlightedItem={highlightedItem}
          tradeDirection={currentTradeDirection}
          tradeFlow={tradeFlow}
          locationMetadataFetch={locationMetadataFetch}
          hs92MetadataFetch={hs92MetadataFetch}
          hs12MetadataFetch={hs12MetadataFetch}
          sitcMetadataFetch={sitcMetadataFetch}
          locationYearMetadataFetch={locationYearMetadataFetch}
          deflatorsMetadataFetch={deflatorsMetadataFetch}
          isLoading={loading}
        />
      );
    }
  } else if (error) {
    content = <GraphNotice graphNoticeType={GraphNoticeType.Error} />;
    console.log(error);
  }

  let categoriesForLegend: CategoryDatum[] = getCategoriesForChartLegend({
    view,
    productClass,
  });

  let resetText: string = "";
  if (view === UIView.Markets) {
    resetText = "Show All Regions";
  } else if (view === UIView.Products) {
    resetText = "Show All Sectors";
  }

  let [tooltipLineMarkerElement, setTooltipLineMarkerElement] =
    useState<ReactElement | null>(null);
  let [tooltipExplanationElement, setTooltipExplanationElement] =
    useState<ReactElement | null>(null);

  useEffect(() => {
    let tooltipContentElement;
    let lineMarkerElement;
    if (
      tooltipContent &&
      tooltipContent.contentData !== undefined &&
      xAxisTooltipValue &&
      xAxisTooltipValue.year !== undefined &&
      loading !== true
    ) {
      tooltipContentElement = (
        <TooltipContent
          datum={tooltipContent && tooltipContent.contentData}
          view={view}
          totalValue={totalValue}
          tradeDirection={currentTradeDirection}
          productClass={productClass}
          ref={tooltipContentRef}
        />
      );

      let { year, x } = xAxisTooltipValue;
      if (yAxisMarkerExtent) {
        lineMarkerElement = (
          <TooltipLineMarker
            year={year}
            x={x}
            yAxisMarkerExtent={{
              min: yAxisMarkerExtent.min - 2,
              max: yAxisMarkerExtent.max + 2,
            }}
          />
        );
      } else {
        lineMarkerElement = null;
      }
    } else {
      lineMarkerElement = null;
      tooltipContentElement = null;
    }

    setTooltipExplanationElement(tooltipContentElement);
    setTooltipLineMarkerElement(lineMarkerElement);
  }, [
    xAxisTooltipValue.year,
    tooltipContent,
    xAxisTooltipValue,
    loading,
    view,
    totalValue,
    currentTradeDirection,
    productClass,
    yAxisMarkerExtent,
  ]);

  const clearHighlightedItem = () => setHighlightedItem(undefined);
  const graphLoading = <GraphLoading />;

  return (
    <>
      <Tooltip
        explanation={tooltipExplanationElement}
        cursor={"default"}
        overrideStyles={true}
        clearHighlightedItem={clearHighlightedItem}
        isFixed={tooltipContent && tooltipContent.isFixed}
        isFixedCoords={snapTooltipToCoordinates}
        snapToPosition={true}
        vizType={VizType.OverTime}
      >
        <VisualizationContentContainer ref={overtimeElement}>
          {content}
          {!loading && tooltipLineMarkerElement}
          {loading && graphLoading}
        </VisualizationContentContainer>
      </Tooltip>
      <VisualizationBottomRowContainer>
        <VisualizationHandlesContainer>
          <CategoryLabels
            categories={categoriesForLegend}
            allowToggle={true}
            hiddenCategories={hiddenCategories}
            setHiddenCategories={setHiddenCategories}
            resetText={resetText}
            fullWidth={true}
          />
        </VisualizationHandlesContainer>
      </VisualizationBottomRowContainer>
    </>
  );
};

const OverTimeEntry = () => {
  const locationMetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.Location,
  });
  const hs92MetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsHs92,
  });
  const hs12MetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsHs12,
  });

  const sitcMetadataFetch = useFetchMetadata({
    metadataFetchType: MetadataFetchType.ProductsSitc,
  });

  return (
    <OverTime
      locationMetadataFetch={locationMetadataFetch}
      hs92MetadataFetch={hs92MetadataFetch}
      hs12MetadataFetch={hs12MetadataFetch}
      sitcMetadataFetch={sitcMetadataFetch}
    />
  );
};

export default memo(OverTimeEntry);
