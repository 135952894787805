import styled from "@emotion/styled";
import {
  demiFontWeight,
  semiBoldFontWeight,
  normalFontWeight,
} from "../../components/styling/cssVariables";

export const PageSection = styled.div`
  margin: 5vh 0 5vh;
  display: flex;
  min-width: 250px;
  grid-column: 2;
`;

export const H2 = styled.h2`
  font-weight: ${demiFontWeight};
  font-size: 1.375rem;
  color: #99a;
`;

export const H4 = styled.p`
  font-weight: ${semiBoldFontWeight};
  font-size: 0.9375rem;
  line-height: 1.4;
`;

export const SubSectionHeader = styled.p`
  font-weight: ${normalFontWeight};
  font-size: calc(2vh + 0.5rem);
  letter-spacing: 0.04rem;
  color: #7589ca;
  text-transform: uppercase;
`;

export const breakpointSmall = 640;

export const ArrowIcon = () => {
  return (
    <svg viewBox="0 0 16 16">
      <polygon points="7.2,0 7.2,2.5 11.7,2.5 0,14.2 1.8,16 13.5,4.3 13.5,8.8 16,8.8 16,2.5 16,1.8 16,0 "></polygon>
    </svg>
  );
};
