import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const navigationBarItemHoverBackgroundColor = "#567388";

export const delayVisibility = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const inAndOutOpacity = keyframes`
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }


    100% {
        opacity: 1;
    }
`;

export const OptionIcon = styled.div`
  width: 23px;
  height: 23px;
  text-align: center;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    margin: auto;
    width: 18px;
  }
`;

export const OptionLabel = styled.span`
  display: block;
  color: #ffffff;
  text-align: center;
  font-size: 10px;

  animation-name: ${delayVisibility};
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

export const OptionsListItem = styled.div`
  flex: 1 1;
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 9px;
  cursor: pointer;

  &.expanded {
    animation-name: ${inAndOutOpacity};
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &.closed {
    animation-name: ${inAndOutOpacity};
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &:hover > div {
    background-color: ${navigationBarItemHoverBackgroundColor};
    border-radius: 15px;
  }
`;

export const disabledVizTooltipClassName = "disabledVizTooltip";
export const VizTypeList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  margin: 0px;

  & a {
    text-decoration: none;
    color: inherit;
  }
`;

export const OptionsList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  margin-top: auto;

  & a {
    text-decoration: none;
    color: inherit;
  }
`;

export const OptionsListRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 9px;

  &.expanded {
    animation-name: ${inAndOutOpacity};
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &.closed {
    animation-name: ${inAndOutOpacity};
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`;

export const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0px;
  width: calc(300px - 45px);
  box-sizing: border-box;
  margin-right: 5px;

  &:hover {
    background-color: ${navigationBarItemHoverBackgroundColor};
  }

  &.selected {
    background-color: #567388;
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.45);
  }

  &.disabledVisOption {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.disabledVisOption > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
  }

  &.disabledVisOption:hover .${disabledVizTooltipClassName} {
    visibility: visible;
  }
`;

export const MenuRowIcon = styled.div`
  width: 65px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
`;

export const MenuHeaderGrowthLabIcon = styled(MenuRowIcon)`
  width: 100%;
  padding: 10px 10px 0px 10px;
  margin: 0px;
  margin-left: 4px;
  justify-content: flex-start;

  & img {
    width: 190px;
    clip-path: inset(0px 150px 0px 0px);
  }

  & img.expanded {
    clip-path: unset;
  }
`;

export const MenuRowLabel = styled.p`
  flex: 1 0;
  color: #ffffff;
  font-size: 11px;
  margin: 0px;
  padding: 4px 6px 4px 4px;

  & p {
    width: 100%;
  }
`;

export const OpenPanelArrowContainer = styled.div`
  width: 100%;
  text-align: center;
  cursor: pointer;

  &.expanded {
    text-align: right;
    padding-right: 5px;
  }

  &.closed {
    text-align: center;
    padding-right: 0px;
  }
`;

export const OpenPanelArrowButtonContainer = styled.div`
  display: inline-block;
  padding: 8px;
  width: fit-contents;

  &:hover {
    background-color: ${navigationBarItemHoverBackgroundColor};
    border-radius: 15px;
  }
`;

export const OpenPanelArrow = styled.div`
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  padding: 3px;
  width: 6px;
  height: 6px;
  cursor: pointer;

  &.openArrow {
    transform: rotate(-45deg) translate(-2px, -2px);
    -webkit-transform: rotate(-45deg) translate(-2px, -2px);
  }

  &.closeArrow {
    transform: rotate(135deg) translate(-2px, -2px);
    -webkit-transform: rotate(135deg) translate(-2px, -2px);
  }
`;

export const countryProfilesUrlBase = "/countries";

export const NotAvailableTooltip = styled.div`
  position: fixed;
  left: 70px;
  max-width: 220px;
  font-size: 0.7rem;
  background-color: #ffffff;
  text-align: left;
  padding: 5px;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: 0px 2px 15px #ccc;
  margin-top: -10px;
  cursor: default;

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 10px);
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff;
  }
`;

export const DisabledVizTypeTooltipContainer = styled.div`
  margin: 0px;
  padding: 0px;
  width: 300px;
`;

export const ExternalLinkImage = styled.img`
  width: 16px;
`;
