import { useState } from "react";
import ShareIcon from "../../assets/images/ui/share.svg";
import {
  OptionIcon,
  OptionLabel,
  OptionsListItem,
} from "../navigationBar/Components";
import {
  Modal,
  Box,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import { modalStyle } from "./GuideModalButton";

const getShareWindowParams = (width: number, height: number) =>
  `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${height},width=${width}`;

const getShareFunctions = (url) => {
  return {
    shareFacebook: () => {
      const baseURL = "https://www.facebook.com/sharer.php";
      const shareURL = `${baseURL}?u=${encodeURIComponent(url)}`;
      window.open(shareURL, "", getShareWindowParams(360, 600));
    },

    shareTwitter: (text: string) => {
      const baseURL = "https://twitter.com/intent/tweet";
      const shareURL = `${baseURL}?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}&via=HarvardGrwthLab`;
      window.open(shareURL, "", getShareWindowParams(420, 550));
    },

    shareLinkedIn: (title: string, summary: string) => {
      const baseURL = "https://www.linkedin.com/shareArticle";
      const source = encodeURIComponent(url);
      const shareURL = `${baseURL}?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}&source=${encodeURIComponent(source)}`;
      window.open(shareURL, "", getShareWindowParams(570, 520));
    },

    shareEmail: (
      subjectCopy: string,
      bodyBeforeLineBreakCopy: string,
      bodyAfterLineBreakCopy: string,
    ) => {
      const subject = encodeURIComponent(subjectCopy);
      const bodyBeforeLineBreak = encodeURIComponent(bodyBeforeLineBreakCopy);
      const bodyAfterLineBreak = encodeURIComponent(bodyAfterLineBreakCopy);
      const href = `mailto:?subject=${subject}&body=${bodyBeforeLineBreak}%0D%0A%0D%0A${bodyAfterLineBreak}`;
      window.location.href = href;
    },
  };
};

const ShareModalButton = ({
  menuOptionClassName,
  isNavigationBarOpen,
}: any) => {
  let [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const shareFunctions = getShareFunctions(window.location.href);

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  let shareModal = null;

  if (isShareOpen) {
    shareModal = (
      <Modal open={isShareOpen} onClose={() => setIsShareOpen(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2">
            Direct Link
          </Typography>
          <Box
            onClick={handleCopy}
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              mb: 2,
              cursor: "pointer",
              width: "100%",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              value={window.location.href}
              sx={{
                cursor: "pointer",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                    <LinkIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <Button
                      onClick={handleCopy}
                      sx={{
                        m: 0,
                        cursor: "pointer",
                        backgroundColor: "#334c60",
                        color: "white",
                        height: "100%",
                        borderRadius: "0 4px 4px 0",
                        position: "absolute",
                        right: 0,
                        top: 0,
                        bottom: 0,
                        minWidth: "unset",
                        padding: "0 10px",
                        "&:hover": {
                          backgroundColor: "#2a3f50",
                        },
                      }}
                    >
                      Copy
                    </Button>
                  </InputAdornment>
                ),
                readOnly: true,
                inputProps: {
                  sx: {
                    cursor: "pointer",
                  },
                },
              }}
            />
          </Box>
          <Typography variant="h6" component="h2">
            Social Media Sharing
          </Typography>
          <Box display="flex" justifyContent="space-around" mt={2}>
            <IconButton
              onClick={() =>
                shareFunctions.shareTwitter(
                  "Check out this data visualization from the Atlas of Economic Complexity",
                )
              }
            >
              <TwitterIcon fontSize="large" />
            </IconButton>
            <IconButton
              onClick={() =>
                shareFunctions.shareLinkedIn(
                  "Check out this data visualization from the Atlas of Economic Complexity",
                  "Check out this data visualization from the Atlas of Economic Complexity",
                )
              }
            >
              <LinkedInIcon fontSize="large" />
            </IconButton>
            <IconButton onClick={shareFunctions.shareFacebook}>
              <FacebookIcon fontSize="large" />
            </IconButton>
            <IconButton
              onClick={() =>
                shareFunctions.shareEmail(
                  "Atlas of Economic Complexity Visualization",
                  "Check out this data visualization from the Atlas of Economic Complexity",
                  window.location.href,
                )
              }
            >
              <EmailIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <>
      <OptionsListItem
        className={menuOptionClassName}
        onClick={() => setIsShareOpen(true)}
      >
        <OptionIcon>
          <img src={ShareIcon} alt={"Share"} />
        </OptionIcon>
        {isNavigationBarOpen === true && <OptionLabel>Share</OptionLabel>}
      </OptionsListItem>
      {shareModal}
    </>
  );
};

export default ShareModalButton;
