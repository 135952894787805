export enum LocationMetadatumLevel {
  country = "country",
  subregion = "subregion",
  region = "region",
  world = "world",
}

export enum ProductMetadatumLevel {
  allProducts = 9,
  section = 1,
  twoDigit = 2,
  fourDigit = 4,
  sixDigit = 6,
}

export enum LocationLevel {
  Country = "country",
  Group = "group",
}

export enum GroupLevel {
  Region = "region",
  Subregion = "subregion",
}
