import { scaleLinear, scaleLog } from "d3-scale";
import first from "lodash-es/first";
import last from "lodash-es/last";
import range from "lodash-es/range";
import styled from "@emotion/styled";
import { min, max } from "d3";
import { TradeDirection, computeMonetaryValueByTradeFlow } from "../../Utils";
import { groupAndSummarizeByLocationLevel } from "./transformLocations";
import {
  getOrdersOfMagnitudeDifference,
  spectrumStartPercent,
  spectrumEndPercent,
  colorRangeStart,
  colorRangeEnd,
} from "./Utils";
import { getLocationLevelFromStringLocationId } from "../../../sharedUtilities/Utils";

import { format } from "d3";

const horizontalMargin = 5; // in percentage
const width = 100 - 2 * horizontalMargin; // in percentage;

// Height of bar as percentage of graph's total height:
const barHeightPercentage = 0.02;
const barHeight = 100 * barHeightPercentage; // in `vh`:
const textHeight = 1; // in rem
const totalHeight = `calc(${barHeight}vh + ${textHeight}rem)`;

const Root = styled.div`
  width: ${width}%;
  height: ${totalHeight};
  pointer-events: none;
  position: relative;
`;

const TickValue = styled.div`
  transform: translateX(-50%);
  position: absolute;
  top: ${barHeight}vh;
  font-size: 0.7rem;
  margin-top: 1vh;
`;
const TickMark = styled.div`
  position: absolute;
  top: 0;
  height: ${barHeight}vh;
  background-color: white;
  width: 2px;
  transform: translateX(-50%);
`;

const legendStart = 0;
const legendSpectrumStart = spectrumStartPercent * 100;
const legendSpectrumEnd = spectrumEndPercent * 100;
const legendEnd = 100;
const getCSSLinearGradient = () => {
  const spectrumScale = scaleLinear<string, string>()
    .domain([legendSpectrumStart, legendSpectrumEnd])
    .range([colorRangeStart, colorRangeEnd]);
  const spectrumGradientString = range(legendSpectrumStart, legendEnd + 1).map(
    (value) => `${spectrumScale(value)} ${value}%`,
  );
  const spectrumStartSring = `${colorRangeStart} 0`;
  const spectrumEndString = `${colorRangeEnd} 100%`;
  const linearGradientString = [
    spectrumStartSring,
    ...spectrumGradientString,
    spectrumEndString,
  ].join(", ");
  return linearGradientString;
};

const ColorBar = styled.div`
  width: 100%;
  height: ${barHeight}vh;
  border: 1px solid white;
  background-image: linear-gradient(to right, ${getCSSLinearGradient()});
  box-sizing: border-box;
`;

const formatLegendTickValue = (value: number): string => {
  return format(".1s")(value).replace("G", "B");
};

export const getScaleAndTicksForTickLabels = (
  domainStart: number,
  domainEnd: number,
  legendStartX: number,
  legendEndX: number,
) => {
  const scale = scaleLog<number, number>()
    .domain([domainStart, domainEnd])
    .range([legendStartX, legendEndX]);

  // Request the number of ticks equal to the difference of orders of magnitude
  // between the two endpoints will ensure that `de` will return one tick for
  // every power of ten:
  const numberOfTicks = getOrdersOfMagnitudeDifference(domainStart, domainEnd);

  const rawTickValues = scale.ticks(numberOfTicks);
  // If tick values do not include the start and end points, include them:
  let tickValues = rawTickValues;
  if (first(rawTickValues) !== domainStart) {
    tickValues = [domainStart, ...tickValues];
  }
  if (last(rawTickValues) !== domainEnd) {
    tickValues = [...tickValues, domainEnd];
  }
  return {
    scale,
    tickValues,
  };
};

const ColorLegend = ({ data, location, tradeFlow }: any) => {
  const locationLevel = getLocationLevelFromStringLocationId(location);

  const groupedData = groupAndSummarizeByLocationLevel({
    data,
    location,
    locationLevel,
    tradeFlow,
  });

  const dataWithoutPct = groupedData.filter((country: any) => {
    let monetaryValue = computeMonetaryValueByTradeFlow({
      datum: country,
      tradeFlow,
      tradeDirection: TradeDirection.Imports,
    });
    return monetaryValue && monetaryValue > 0;
  });
  const allValues: any[] = dataWithoutPct
    .map((country: any) =>
      computeMonetaryValueByTradeFlow({
        datum: country,
        tradeFlow,
        tradeDirection: TradeDirection.Imports,
      }),
    )
    .filter((value: any) => value !== undefined);

  let minValue = min(allValues, (value: number) => value) as number;
  let maxValue = max(allValues, (value: number) => value) as number;

  const [domainStart, domainEnd] = scaleLog()
    .domain([minValue, maxValue])
    .nice()
    .domain();

  const { scale, tickValues } = getScaleAndTicksForTickLabels(
    domainStart,
    domainEnd,
    legendStart,
    legendEnd,
  );

  const tickValueElems = tickValues
    .filter((d) => d > 0)
    .map((value) => {
      const style = {
        left: `${scale(value)}%`,
      };
      if (!formatLegendTickValue(value)) return null;
      return (
        <TickValue style={style} key={value}>
          {"$" + formatLegendTickValue(value)}
        </TickValue>
      );
    });

  const tickElems = tickValues.map((value) => {
    const style = {
      left: `${scale(value)}%`,
    };
    return <TickMark style={style} key={value} />;
  });

  return (
    <Root>
      <ColorBar />
      {tickElems}
      {tickValueElems}
    </Root>
  );
};

export default ColorLegend;
