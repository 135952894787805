import styled from "@emotion/styled";
import { memo } from "react";

const ChartGridlinesElement = styled.g`
  pointer-events: none;

  & line {
    stroke: #e6e6e6;
    stroke-width: 1;
  }
`;

const YAxisGridlines = ({ scale, containerSize, chartMargin }: any) => {
  const ticks = scale.ticks(10);
  return (
    <ChartGridlinesElement transform={`translate(${chartMargin.left}, 0)`}>
      {ticks &&
        ticks.map((tick: number) => {
          return (
            <line
              x1={0}
              x2={containerSize.width - chartMargin.left - chartMargin.right}
              y1={scale(tick)}
              y2={scale(tick)}
            />
          );
        })}
    </ChartGridlinesElement>
  );
};

export default memo(YAxisGridlines);
