import styled from "@emotion/styled";

const Container = styled.div`
  position: absolute;
  display: flex;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  background-color: #eaedef;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  font-style: italic;
`;

export enum GraphNoticeType {
  Error = "error",
  NoData = "noData",
  ComplexityNotAvailable = "complexityNotAvailable",
  Default = "default",
  CountrySelectionRequired = "countrySelectionRequired",
}

const noticeMessages = {
  [GraphNoticeType.Error]: "There was an error loading the data",
  [GraphNoticeType.NoData]: "No data to display",
  [GraphNoticeType.ComplexityNotAvailable]:
    "Complexity visualizations are not available for regions and subregions",
  [GraphNoticeType.CountrySelectionRequired]:
    "A country selection is required for this visualization",

  [GraphNoticeType.Default]: "There was an error loading the data",
};

const GraphNotice = ({
  graphNoticeType,
}: {
  graphNoticeType: GraphNoticeType;
}) => {
  let noticeTextContent;
  if (graphNoticeType === GraphNoticeType.Error) {
    noticeTextContent = noticeMessages[graphNoticeType];
  } else if (graphNoticeType === GraphNoticeType.NoData) {
    noticeTextContent = noticeMessages[graphNoticeType];
  } else {
    noticeTextContent = noticeMessages[graphNoticeType];
  }

  return (
    <Container
      onMouseMove={(e: any) => e.stopPropagation()}
      onClick={(e: any) => e.stopPropagation}
    >
      {noticeTextContent}
    </Container>
  );
};

export default GraphNotice;
