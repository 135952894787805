import { useRef, useEffect, memo } from "react";
import { axisLeft, select, format } from "d3";
import { chartMargin, AxisGElement } from "./styles";

const formatAsPercentage = ({ value, maxScaleValue }: any): string => {
  let valueAsPercentage = value * 100;
  let valueAsPercentageToPrecision: string = valueAsPercentage.toPrecision(2);
  if (Number(valueAsPercentageToPrecision) == 0) {
    return format(".0%")(value);
  } else {
    if (maxScaleValue <= 0.001) {
      if (
        valueAsPercentageToPrecision[
          valueAsPercentageToPrecision.length - 1
        ] === "0"
      ) {
        if (Number(valueAsPercentageToPrecision) < 0.01) {
          return format(".3%")(value);
        } else {
          return format(".2%")(value);
        }
      } else {
        return format(".3%")(value);
      }
    } else if (maxScaleValue <= 0.01) {
      if (
        valueAsPercentageToPrecision[
          valueAsPercentageToPrecision.length - 1
        ] === "0"
      ) {
        if (Number(valueAsPercentageToPrecision) < 0.1) {
          return format(".2%")(value);
        } else {
          return format(".2%")(value);
        }
      } else {
        return format(".2%")(value);
      }
    } else if (maxScaleValue <= 0.1) {
      return format(".1%")(value);
    } else {
      return format(".0%")(value);
    }
  }
};

const YAxisPercentage = ({ scale }: any) => {
  const axisRef = useRef<SVGGElement | null>(null);

  const maxScaleValue = Math.max(scale.domain()[0], scale.domain()[1]);

  const axisGenerator = axisLeft(scale)
    .ticks(10)
    .tickFormat((value: any) => formatAsPercentage({ value, maxScaleValue }));

  useEffect(() => {
    if (axisRef && axisRef.current) {
      const gElement = select(axisRef.current);

      // Render the result of calling the axis generator to the <g> element that will hold the axis
      axisGenerator(gElement);
    }
  }, [axisRef, scale.domain()]);

  return (
    <AxisGElement
      ref={axisRef}
      transform={`translate(${chartMargin.left}, 0)`}
    ></AxisGElement>
  );
};

export default memo(YAxisPercentage);
