import { allProductsDatum } from "../../../graphql/queries/getProductsMetadata";
import { ProductMetadatumLevel } from "../../../graphql/types";
import { ProductClass } from "../../../visualization/Utils";

interface ProductObject {
  productId: string | undefined;
  productLevel: number | undefined;
  code: string | undefined;
  nameEn: string | undefined;
  nameShortEn: string | undefined;
}

interface ParentedProductObject extends ProductObject {
  parent: ProductObject | null;
}

interface GroupAndOrderProductOptionsInput {
  section: ParentedProductObject[];
  twoDigit: ParentedProductObject[];
  fourDigit: ParentedProductObject[];
  sixDigit: ParentedProductObject[] | undefined;
}

export const productSearchStringFunction = (item: any) =>
  `${item.name_short_en} ${item.code}`;

const sortProductsByCode = (productA: any, productB: any) => {
  const codeA = Number(productA.code!);
  const codeB = Number(productB.code!);
  if (codeA < codeB) {
    return -1;
  }
  if (codeA > codeB) {
    return 1;
  }
  return 0;
};

export function groupAndOrderProductOptions({
  section,
  twoDigit,
  fourDigit,
  sixDigit,
}: GroupAndOrderProductOptionsInput) {
  const fetchedProductData: any[] = [];

  const sectionDataSortedByCode = [...section].sort(sortProductsByCode);

  const twoDigitDataSortedByCode = [...twoDigit].sort(sortProductsByCode);
  const fourDigitDataSortedByCode = [...fourDigit].sort(sortProductsByCode);
  const sixDigitDataSortedByCode = sixDigit
    ? [...sixDigit].sort(sortProductsByCode)
    : undefined;

  // TO DO: a non-manual way to create the "All products" object
  fetchedProductData.push({
    id: allProductsDatum.productId,
    name_short_en: allProductsDatum.nameShortEn,
    level: allProductsDatum.productLevel,
    product_section: undefined,
    product_twoDigit: undefined,
    product_fourDigit: undefined,
    product_sixDigit: undefined,
  });

  sectionDataSortedByCode.forEach((datumSection) => {
    fetchedProductData.push({
      id: datumSection.productId,
      code: datumSection.code,
      name_short_en: datumSection.nameShortEn,
      level: datumSection.productLevel,
      product_section: datumSection,
      product_twoDigit: undefined,
      product_fourDigit: undefined,
      product_sixDigit: undefined,
    });
    let sectionTwoDigit = twoDigitDataSortedByCode.filter(
      (t) => t.parent && t.parent.productId === datumSection.productId,
    );
    sectionTwoDigit.forEach((datumTwoDigit) => {
      fetchedProductData.push({
        id: datumTwoDigit.productId,
        code: datumTwoDigit.code,
        name_short_en: datumTwoDigit.nameShortEn,
        level: datumTwoDigit.productLevel,
        product_section: datumSection,
        product_twoDigit: datumTwoDigit,
        product_fourDigit: undefined,
        product_sixDigit: undefined,
      });
      let sectionFourDigit = fourDigitDataSortedByCode.filter(
        (t) => t.parent && t.parent.productId === datumTwoDigit.productId,
      );
      sectionFourDigit.forEach((datumFourDigit) => {
        fetchedProductData.push({
          id: datumFourDigit.productId,
          code: datumFourDigit.code,
          name_short_en: datumFourDigit.nameShortEn,
          level: datumFourDigit.productLevel,
          product_section: datumSection,
          product_twoDigit: datumTwoDigit,
          product_fourDigit: datumFourDigit,
          product_sixDigit: undefined,
        });
        if (sixDigitDataSortedByCode) {
          let sectionSixDigit = sixDigitDataSortedByCode.filter(
            (t) => t.parent && t.parent.productId === datumFourDigit.productId,
          );
          sectionSixDigit.forEach((datumSixDigit) => {
            fetchedProductData.push({
              id: datumSixDigit.productId,
              code: datumSixDigit.code,
              name_short_en: datumSixDigit.nameShortEn,
              level: datumSixDigit.productLevel,
              product_section: datumSection,
              product_twoDigit: datumTwoDigit,
              product_fourDigit: datumFourDigit,
              product_sixDigit: datumSixDigit,
            });
          });
        }
      });
    });
  });

  return fetchedProductData;
}

export const productFilterSearchResults = ({
  searchTerm,
  displayItems,
  setDisplayItems,
  selectedItem,
}: any) => {
  // First, update the visibility of any items that match search terms
  let modifiedDisplayItems = [...displayItems];

  let lowerCasedInputValue: string | undefined = searchTerm
    ? searchTerm.toLowerCase()
    : undefined;

  if (lowerCasedInputValue) {
    let parentsToMakeVisible = new Set();

    const updatedDisplayItems = modifiedDisplayItems.map((item: any) => {
      const updatedItem = Object.assign({}, item);
      if (!lowerCasedInputValue || lowerCasedInputValue === "") {
        updatedItem.isVisible = false;
      } else if (
        productSearchStringFunction(item)
          .toLowerCase()
          .includes(lowerCasedInputValue)
      ) {
        updatedItem.isVisible = true;
      } else {
        updatedItem.isVisible = false;
      }

      if (searchTerm && updatedItem.isVisible === true) {
        if (updatedItem.level === ProductMetadatumLevel.sixDigit) {
          let productSectionId = updatedItem.product_section.productId;
          let productTwoDigitId = updatedItem.product_twoDigit.productId;
          let productFourDigitId = updatedItem.product_fourDigit.productId;
          parentsToMakeVisible.add(productSectionId);
          parentsToMakeVisible.add(productTwoDigitId);
          parentsToMakeVisible.add(productFourDigitId);
        } else if (updatedItem.level === ProductMetadatumLevel.fourDigit) {
          let productSectionId = updatedItem.product_section.productId;
          let productTwoDigitId = updatedItem.product_twoDigit.productId;
          parentsToMakeVisible.add(productSectionId);
          parentsToMakeVisible.add(productTwoDigitId);
        } else if (updatedItem.level === ProductMetadatumLevel.twoDigit) {
          let productSectionId = updatedItem.product_section.productId;
          parentsToMakeVisible.add(productSectionId);
        }
      }

      return updatedItem;
    });

    // Then, update the visibility of parent elements
    const updatedDisplayItemsWithParents = updatedDisplayItems.map(
      (item: any) => {
        const itemWithStatus = Object.assign({}, item);

        if (parentsToMakeVisible.has(itemWithStatus.id)) {
          itemWithStatus.isVisible = true;
        }

        return itemWithStatus;
      },
    );

    setDisplayItems(updatedDisplayItemsWithParents);
  }
};

export const updateVisibilityStatus = ({
  itemToUpdate,
  displayItems,
  setDisplayItems,
}: any) => {
  // First, update the isExpanded state of the given element
  let modifiedDisplayItems = [...displayItems];

  let updatedItems: any;
  if (itemToUpdate) {
    updatedItems = modifiedDisplayItems.map((item: any) => {
      const itemWithStatus = Object.assign({}, item);
      if (item.id === itemToUpdate.id) {
        itemWithStatus.isExpanded = !item.isExpanded;
      }

      return itemWithStatus;
    });
  } else {
    updatedItems = modifiedDisplayItems;
  }

  const itemsWithVisibilityStatus = updatedItems.map((item: any) => {
    const itemWithStatus = Object.assign({}, item);

    // "All Products" object is always visible
    if (itemWithStatus.level === ProductMetadatumLevel.allProducts) {
      itemWithStatus.isVisible = true;

      // 1-digit product object is always visible
    } else if (itemWithStatus.level === ProductMetadatumLevel.section) {
      itemWithStatus.isVisible = true;
    } else if (itemWithStatus.level === ProductMetadatumLevel.twoDigit) {
      let parentId = itemWithStatus.product_section.productId; // parent of subregion is region, i.e., hybrid_level_1
      let parent = updatedItems.find(
        (findItem: any) => findItem.id === parentId,
      );
      if (parent.isExpanded) {
        itemWithStatus.isVisible = true;
      } else {
        itemWithStatus.isVisible = false;
        // If the subregion's parent is NOT expanded, this subregion should not be expanded either
        itemWithStatus.isExpanded = false;
      }
    } else if (itemWithStatus.level === ProductMetadatumLevel.fourDigit) {
      let parentSectionId = itemWithStatus.product_section.productId;
      let parentTwoDigitId = itemWithStatus.product_twoDigit.productId; // parent of country is subregion, i.e., hybrid_level_2
      let parentSection = updatedItems.find(
        (findItem: any) => findItem.id === parentSectionId,
      );
      let parentTwoDigit = updatedItems.find(
        (findItem: any) => findItem.id === parentTwoDigitId,
      );

      if (parentSection.isExpanded && parentTwoDigit.isExpanded) {
        itemWithStatus.isVisible = true;
      } else {
        itemWithStatus.isVisible = false;
      }
    } else if (itemWithStatus.level === ProductMetadatumLevel.sixDigit) {
      let parentSectionId = itemWithStatus.product_section.productId;
      let parentTwoDigitId = itemWithStatus.product_twoDigit.productId; // parent of country is subregion, i.e., hybrid_level_2
      let parentFourDigitId = itemWithStatus.product_fourDigit.productId;
      let parentSection = updatedItems.find(
        (findItem: any) => findItem.id === parentSectionId,
      );
      let parentTwoDigit = updatedItems.find(
        (findItem: any) => findItem.id === parentTwoDigitId,
      );
      let parentFourDigit = updatedItems.find(
        (findItem: any) => findItem.id === parentFourDigitId,
      );

      if (
        parentSection.isExpanded &&
        parentTwoDigit.isExpanded &&
        parentFourDigit.isExpanded
      ) {
        itemWithStatus.isVisible = true;
      } else {
        itemWithStatus.isVisible = false;
      }
    }

    itemWithStatus.previousIsVisible = item.isVisible;
    if (itemWithStatus.isVisible !== item.isVisible) {
      itemWithStatus.isVisibleChange = true;
    } else {
      itemWithStatus.isVisibleChange = false;
    }

    return itemWithStatus;
  });

  setDisplayItems(itemsWithVisibilityStatus);
};

export const productHashFunction = ({
  item,
  productClassShortLabelString,
}: any) => {
  let code;
  if (item.id !== allProductsDatum.productId) {
    code = item.code;
  } else {
    code = undefined;
  }

  if (code) {
    return `${item.name_short_en} (${item.code} ${productClassShortLabelString})`;
  } else {
    return `${item.name_short_en} (${productClassShortLabelString})`;
  }
};

export const getProductClassShortLabel = ({
  productClass,
}: {
  productClass: ProductClass;
}) => {
  let shortLabel;

  if (productClass === ProductClass.HS92Products) {
    shortLabel = "HS92";
  } else if (productClass === ProductClass.HS12Products) {
    shortLabel = "HS12";
  } else if (productClass === ProductClass.SITCProducts) {
    shortLabel = "SITC";
  } else {
    shortLabel = undefined;
  }

  return shortLabel;
};
