import styled from "@emotion/styled";
import { headerLinkMetadata } from "../header/Utils";
import { RouteID } from "../../routing/routes";
import { LinkMetadataItemProps } from "../header/Utils";
import { useState } from "react";
import { Link } from "react-router-dom";
import { navigationBarColor } from "../styling/cssVariables";

const HamburgerMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 300px;
  color: rgb(51, 51, 52);
  position: absolute;
  top: 0px;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  z-index: 1000;
  background-color: ${navigationBarColor};
`;

const HamburgerMenuListItem = styled.li`
  width: 100%;
  color: #ffffff;

  align-items: center;
  cursor: default;
  position: relative;
  background-color: transparent;
  color: #ffffff;

  & a,
  span {
    display: flex;
    padding: 5px 18px;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    font-size: 1.3rem;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  // & {
  //     :hover, .highlight {
  //         background-color: none;
  //         color: white;
  //     }
  // }
`;

const InnerUl = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  list-style-type: none;
`;

const InnerLi = styled.li`
  color: white;
  text-align: left;
  color: #ffffff;

  & a {
    text-transform: none;
    letter-spacing: 0px;
  }
`;

interface ExpandableMenuItemInterface {
  route: string;
  routeId: RouteID;
  label: string;
  subMenuItems: LinkMetadataItemProps[] | undefined;
  isOpen: boolean;
  setIsOpen: any;
  closeMenu: () => void;
}

const ExpandableMenuItem = ({
  routeId,
  route,
  label,
  subMenuItems,
  isOpen,
  setIsOpen,
  closeMenu,
}: ExpandableMenuItemInterface) => {
  let highlightClassName: string | null = null;

  let submenuContents: JSX.Element | null = null;
  if (isOpen && subMenuItems) {
    highlightClassName = "highlight";

    submenuContents = (
      <InnerUl>
        {subMenuItems.map(({ routeId, route, label }) => {
          return (
            <InnerLi>
              <a href={route}>{label}</a>
            </InnerLi>
          );
        })}
      </InnerUl>
    );
  } else {
    highlightClassName = null;
    submenuContents = null;
  }

  let primaryMenuItemLink;
  if (subMenuItems) {
    primaryMenuItemLink = (
      <span>
        {label} {subMenuItems ? "+" : ""}
      </span>
    );
  } else if (routeId !== RouteID.Explore && routeId !== RouteID.Landing) {
    primaryMenuItemLink = <a href={route}>{label}</a>;
  } else {
    primaryMenuItemLink = <Link to={route}>{label}</Link>;
  }

  return (
    <>
      <HamburgerMenuListItem
        className={highlightClassName ? highlightClassName : ""}
        onClick={() => {
          if (isOpen) {
            setIsOpen(null);
          } else {
            setIsOpen(label);
          }
        }}
      >
        {primaryMenuItemLink}
        {submenuContents}
      </HamburgerMenuListItem>
    </>
  );
};

const HamburgerMenu = ({ closeMenu }: { closeMenu: any }) => {
  const [isOpen, setIsOpen] = useState(null);
  return (
    <HamburgerMenuList>
      {headerLinkMetadata.map((navigationLink) => {
        return (
          <ExpandableMenuItem
            isOpen={isOpen === navigationLink.label}
            setIsOpen={setIsOpen}
            routeId={navigationLink.routeId}
            route={navigationLink.route}
            label={navigationLink.label}
            subMenuItems={navigationLink.children}
            closeMenu={closeMenu}
          />
        );
      })}
    </HamburgerMenuList>
  );
};

export default HamburgerMenu;
