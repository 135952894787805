import styled from "@emotion/styled";
import { demiFontWeight } from "../styling/cssVariables";

export const ModalRoot = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 76, 96, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ModalContainerBase = styled.div`
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(220, 220, 220);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px 0px;
`;

export const ModalContentContainer = styled(ModalContainerBase)`
  position: relative;
  padding: 0px;
  margin: 0px;
  text-align: left;
  height: auto;
  max-height: 100%;
  position: relative;
  width: 45%;
  margin-top: 50px;
  margin-bottom: 50px;

  & h2 {
    width: 100%;
  }

  a:link,
  a:visited {
    color: rgb(81, 131, 193);
  }
`;

export const CloseButton = styled.button`
  flex: 0 1 auto;
  cursor: pointer;
  color: #aaa;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 12px;
  display: inline-block;
  border: none;
  background: none;
`;

export const ModalHeaderIcon = styled.div`
  flex: 0 1 auto;
  width: 30px;
  height: 100%;
  padding: 12px;
  padding-left: 0px;
  margin-right: 10px;
  box-sizing: border-box;
  background-color: #334c60;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center center;
`;

export const ModalHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  margin: 0px;
  padding: 0 2.5vw 0 2.5vw;
  background-color: #d7dbdd;
  display: flex;
  flex-direction: row;

  & h1 {
    text-transform: uppercase;
    color: #334c60;
    flex: 1 1 auto;
    font-weight: ${demiFontWeight};
    display: inline-block;
    font-size: 1.6rem;
    margin: 0px;
    padding: 12px 0px;
  }
`;

export const ModalBodyContainer = styled.div`
  width: 100%;
  padding: 2.5vw 2.5vw 2.5vw 2.5vw;
  box-sizing: border-box;
  height: auto;
  max-height: 100%;
  overflow: hidden;

  & p {
    margin-top: 0px;
  }
  flex-grow: 1;
  flex-shrink: 1;
`;

export const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  & p {
    color: rgba(51, 51, 51);
  }
`;

export const GlossaryModalBody = styled(ModalBody)`
  & > div {
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
  }

  summary {
    font-size: 1.25rem;
    line-height: 0.75rem;
  }
`;

export const ClearButtonSymbol = "✕";
