import { LegacyRef, useEffect, useMemo } from "react";
import { AxisBottom, AxisLeft, Orientation } from "@visx/axis";
import { GridRows } from "@visx/grid";
import MemoizedAnimatedPoints from "./AnimatedPoints";
import Tippy from "@tippyjs/react";
import TooltipContent from "./Tooltip";
import { Zoom } from "@visx/zoom";
import { extent, index, rgb, scaleLinear, sum } from "d3";
import { OpportunitySizeOption } from "./utils";
import { XAxisLabels, YAxisLabels, YAxisOptions } from "./Axis";
import GraphLoading from "../../components/GraphLoading";
import { useParentSize } from "@visx/responsive";
import ZoomControls from "./ZoomControls";
import { RectClipPath } from "@visx/clip-path";
import "tippy.js/dist/tippy.css";
import { usePageQueryParams } from "../../defaultSettings";
import { useOutletContext } from "react-router-dom";
import { ClickAwayListener } from "@mui/material";
import { useTotalValue } from "../../TotalValueContext";
import { useDownload } from "../../DownloadContext";
import { formatNumberAsUSD } from "../../../sharedUtilities/formatNumbers";

const initialTransform = {
  scaleX: 0.9,
  scaleY: 0.9,
  translateX: 25,
  translateY: 25,
  skewX: 0,
  skewY: 0,
};

const yPadding = 60;

const rescaleYAxis = (scale: any, zoom: any) => {
  let newDomain = scale.range().map((r: any) => {
    return scale.invert(
      (r - zoom.transformMatrix.translateY) / zoom.transformMatrix.scaleY,
    );
  });
  return scale.copy().domain(newDomain);
};
const rescaleXAxis = (scale: any, zoom: any) => {
  let newDomain = scale.range().map((r: any) => {
    return scale.invert(
      (r - zoom.transformMatrix.translateX) / zoom.transformMatrix.scaleX,
    );
  });
  return scale.copy().domain(newDomain);
};

const Scatterplot = ({
  displayData,
  countryProductYear,
  productYear,
  loading,
  countryData,
  metaDataLookup,
  metaDataTree,
  colorMap,
  hiddenCategories,
}) => {
  const { svgRef, dataRef } = useDownload();
  const [, setTotalValue] = useTotalValue();
  const {
    parentRef,
    width,
    height: fullHeight,
  } = useParentSize({ debounceTime: 150 });

  const height = fullHeight - 20;
  const [
    { productClass, productLevel, sizing, hideExports, yAxis: ySelection },
  ] = usePageQueryParams();

  const { setFindInVizOptions, highlightedItem, setHighlightedItem }: any =
    useOutletContext();

  const totalExportLookup = useMemo(() => {
    const totalExportLookup = index(productYear, (d: any) => d.productId);
    return totalExportLookup;
  }, [productYear]) as any;
  const { filteredData, filteredDataLookup, xScale, yScale, rScale } =
    useMemo(() => {
      const filteredCPY = countryProductYear.filter((d) => {
        return (
          metaDataLookup.get(d.productId)?.data.showFeasibility &&
          (hideExports === "On" ? d.exportRca < 1 : true)
        );
      });

      const xScale = scaleLinear()
        .domain(extent(filteredCPY, ({ distance }) => parseFloat(distance)))
        .range([yPadding, width + yPadding]);

      const yScale = scaleLinear()
        .domain(
          extent(filteredCPY, (d: any) =>
            ySelection === "cog"
              ? parseFloat(d.cog)
              : parseFloat(totalExportLookup.get(d.productId).pci),
          ),
        )
        .range([height - yPadding / 2, 0]);
      const fdLookup = index(filteredCPY, (d: any) => d.productId);

      const rScale = scaleLinear()
        .domain(
          extent(
            productYear.filter((d) => !!fdLookup.get(d.productId)),
            (d: any) => parseFloat(d.exportValue),
          ),
        )
        .range([3, 50]);
      const filtered = filteredCPY
        .map((d: any) => {
          const productData = metaDataLookup.get(d.productId)?.data;
          const topParentId = productData?.topParent?.productId;
          return {
            cx: xScale(d.distance),
            cy: yScale(
              ySelection === "cog"
                ? d.cog
                : totalExportLookup.get(d.productId).pci,
            ),
            r:
              sizing === OpportunitySizeOption.WorldTrade
                ? rScale(totalExportLookup.get(d.productId).exportValue)
                : 4,
            opacity: hiddenCategories.includes(topParentId) ? 0.25 : 1,
            fillOpacity: d.exportRca < 1 ? 0.65 : 0.95,
            fill: hiddenCategories.includes(topParentId)
              ? "white"
              : d.exportRca < 1
                ? colorMap?.get(topParentId)
                : rgb(colorMap?.get(topParentId)).darker().formatHex(),
            topParentId: topParentId,
            showFeasibility: productData?.showFeasibility,
            ...d,
          };
        })
        .sort((d1, d2) => d2.r - d1.r);
      const filteredDataLookup = index(
        filtered,
        (d: any) => d.productId,
      ) as any;

      return {
        filteredData: filtered,
        filteredDataLookup,
        xScale,
        yScale,
        rScale,
      };
    }, [
      countryProductYear,
      width,
      height,
      productYear,
      hideExports,
      ySelection,
      totalExportLookup,
      metaDataLookup,
      sizing,
      hiddenCategories,
      colorMap,
    ]);

  useEffect(() => {
    const totalValue = sum(filteredData, (d: any) => d.exportValue);
    setTotalValue(totalValue);
  }, [filteredData, setTotalValue]);
  useEffect(() => {
    const fivOptions = filteredData
      .map((d) => metaDataLookup.get(d.productId))
      .map((d: any) => ({
        id: d.data.productId,
        label: d.data.code
          ? `${d.data.nameShortEn} (${d.data.code} ${productClass})`
          : d.data.nameShortEn,
      }));
    setFindInVizOptions(fivOptions);
  }, [
    filteredData,
    metaDataLookup,
    metaDataTree,
    productClass,
    productLevel,
    setFindInVizOptions,
  ]);
  useEffect(() => {
    const mappedData = filteredData.map((item) => ({
      Name: metaDataLookup.get(item.productId)?.data.nameEn || "",
      Code: metaDataLookup.get(item.productId)?.data.code || "",
      Year: item.year,
      "World Trade": formatNumberAsUSD(
        totalExportLookup.get(item.productId)?.exportValue || 0,
      ),
      "Revealed Comparative Advantage (RCA)": item.exportRca,
      Distance: item.distance,
      "Product Complexity Index (PCI)":
        totalExportLookup.get(item.productId)?.pci || 0,
      "Opportunity Gain": item.cog,
      Sector: metaDataLookup.get(item.topParentId)?.data.nameShortEn || "",
    }));

    dataRef.current = mappedData;
  }, [dataRef, filteredData, metaDataLookup, totalExportLookup]);

  return (
    <>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          position: "relative",
        }}
      >
        <YAxisOptions />
        <YAxisLabels
          topLabel={ySelection === "pci" ? "More Complex" : "More Opportunity"}
          bottomLabel={
            ySelection === "pci" ? "Less Complex" : "Less Opportunity"
          }
        />
        {loading && <GraphLoading />}
        <div style={{ width: "100%", height: "100%" }} ref={parentRef}>
          <Zoom
            width={width}
            height={height}
            scaleXMin={1 / 2}
            scaleXMax={4}
            scaleYMin={1 / 2}
            scaleYMax={4}
            initialTransformMatrix={initialTransform}
            key={`${ySelection}-${productClass}`}
          >
            {(zoom) => {
              const zoomedXScale = rescaleXAxis(xScale, zoom);
              const zoomedYScale = rescaleYAxis(yScale, zoom);
              return (
                <>
                  <ZoomControls zoom={zoom} />
                  <svg
                    style={{ backgroundColor: "white" }}
                    ref={(el) => {
                      if (el) {
                        (zoom.containerRef as any).current = el;
                        svgRef.current = el;
                      }
                    }}
                    width={width}
                    height={height}
                  >
                    <RectClipPath
                      id="zoom-clip"
                      width={width - yPadding}
                      height={height - yPadding / 2}
                      x={yPadding}
                    />
                    <g clipPath="url(#zoom-clip)">
                      <rect
                        width={width - yPadding}
                        x={yPadding}
                        height={height - yPadding / 2}
                        rx={14}
                        fill="transparent"
                        onTouchStart={zoom.dragStart}
                        onTouchMove={zoom.dragMove}
                        onTouchEnd={zoom.dragEnd}
                        onMouseDown={zoom.dragStart}
                        onMouseMove={zoom.dragMove}
                        onMouseUp={zoom.dragEnd}
                        onMouseLeave={() => {
                          if (zoom.isDragging) zoom.dragEnd();
                        }}
                        style={{
                          touchAction: "none",
                          cursor: zoom.isDragging ? "grabbing" : "grab",
                        }}
                      />
                    </g>
                    <AxisBottom
                      scale={zoomedXScale}
                      tickLabelProps={() => ({
                        dy: "0.5em",
                        textAnchor: "middle",
                      })}
                      orientation={Orientation.bottom}
                      top={height - yPadding / 2}
                    />
                    <g transform={`translate(${yPadding})`}>
                      <AxisLeft
                        scale={zoomedYScale}
                        tickLabelProps={() => ({
                          dx: "-0.5em",
                          textAnchor: "end",
                        })}
                      />
                      <GridRows
                        scale={zoomedYScale}
                        width={width - yPadding}
                        height={height}
                        stroke="rgba(0, 0, 0, 0.1)"
                      />
                    </g>
                    <g clipPath="url(#zoom-clip)">
                      {ySelection === "pci" && (
                        <>
                          <text
                            id="asdasd"
                            y={zoomedYScale(countryData?.eci) + 20}
                            x={yPadding + 6}
                            fontWeight="600"
                            fill="#666666"
                          >
                            ECI ({productClass}) {countryData?.eci?.toFixed(2)}
                          </text>
                          <line
                            strokeDasharray="3 2"
                            y1={zoomedYScale(countryData?.eci)}
                            y2={zoomedYScale(countryData?.eci)}
                            x1="0"
                            x2={width}
                            strokeWidth={3}
                            stroke="#999999"
                            vectorEffect="non-scaling-stroke"
                          />
                        </>
                      )}
                      <g transform={zoom.toString()}>
                        {width && (
                          <MemoizedAnimatedPoints
                            filteredData={filteredData}
                            ySelection={ySelection}
                            metaDataLookup={metaDataLookup}
                            reset={zoom.reset}
                            highlightedItem={highlightedItem}
                            setHighlightedItem={setHighlightedItem}
                            productClass={productClass}
                            totalExportLookup={totalExportLookup}
                          />
                        )}
                        {highlightedItem && (
                          <ClickAwayListener
                            onClickAway={() => setHighlightedItem(null)}
                          >
                            <Tippy
                              content={
                                <TooltipContent
                                  nameShortEn={
                                    metaDataLookup.get(highlightedItem)?.data
                                      ?.nameShortEn
                                  }
                                  code={
                                    metaDataLookup.get(highlightedItem)?.data
                                      ?.code
                                  }
                                  productClass={productClass}
                                  fillColor={
                                    filteredDataLookup.get(highlightedItem)
                                      ?.fill
                                  }
                                  sectorName={
                                    metaDataLookup.get(
                                      metaDataLookup.get(highlightedItem)?.data
                                        ?.topParent?.productId,
                                    )?.data?.nameShortEn
                                  }
                                  distance={
                                    filteredDataLookup.get(highlightedItem)
                                      ?.distance
                                  }
                                  exportValue={
                                    filteredDataLookup.get(highlightedItem)
                                      ?.exportValue
                                  }
                                  exportRca={
                                    filteredDataLookup.get(highlightedItem)
                                      ?.exportRca
                                  }
                                  pci={
                                    totalExportLookup.get(highlightedItem)?.pci
                                  }
                                  ySelection={ySelection}
                                  cog={
                                    filteredDataLookup.get(highlightedItem)?.cog
                                  }
                                />
                              }
                              visible={true}
                            >
                              <circle
                                r={filteredDataLookup.get(highlightedItem)?.r}
                                cx={filteredDataLookup.get(highlightedItem)?.cx}
                                cy={filteredDataLookup.get(highlightedItem)?.cy}
                                fill={
                                  filteredDataLookup.get(highlightedItem)?.fill
                                }
                                style={{ pointerEvents: "none" }}
                                stroke="rgb(70, 70, 90)"
                                strokeWidth={2}
                              />
                            </Tippy>
                          </ClickAwayListener>
                        )}
                      </g>
                    </g>
                  </svg>
                </>
              );
            }}
          </Zoom>
        </div>
      </div>
      <div>
        <XAxisLabels />
      </div>
    </>
  );
};
export default Scatterplot;
