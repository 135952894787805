import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { normalFontWeight, semiBoldFontWeight } from "./cssVariables";

export const H1 = styled.h1`
  font-size: 2.5rem;
  line-height: 1.2;
  color: rgb(51, 51, 51);
  font-weight: ${semiBoldFontWeight};
  margin-bottom: 1rem;
`;

export const H2 = styled.h2`
  font-weight: ${semiBoldFontWeight};
  font-size: 1.75rem;
  line-height: 1.14;
  color: rgb(51, 51, 51);
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

export const H3 = styled.h3`
  font-size: 1.25rem;
  line-height: 1.2;
  color: rgb(51, 51, 51);
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

/* Used on Landing Page */
export const h4Style = css`
  font-weight: ${semiBoldFontWeight};
  font-size: 0.9375rem;
  line-height: 1.4;
`;

export const H4 = styled.p`
  ${h4Style}
`;

export const SubSectionHeader = styled.p`
  font-weight: ${normalFontWeight};
  font-size: calc(2vh + 0.5rem);
  letter-spacing: 0.04rem;
  color: #7589ca;
  text-transform: uppercase;
`;

//

const paraMarginBottom = "1rem";

export const ParagraphStyling = css`
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(102, 102, 102);
  font-weight: 400;
  margin-bottom: ${paraMarginBottom};
  margin-top: 0;
`;

export const Paragraph = styled.p`
  ${ParagraphStyling}
`;
