import { useLayoutEffect, useState } from "react";
import { debounce } from "lodash-es";

/* This hook is adapted from:
https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
*/
const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState<
    [number | undefined, number | undefined]
  >([undefined, undefined]);

  useLayoutEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }, 100);

    window.addEventListener("resize", debouncedResizeHandler);

    return () => window.removeEventListener("resize", debouncedResizeHandler);
  }, []);

  return windowSize;
};

export default useWindowResize;
