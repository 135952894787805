export enum OpportunitySizeOption {
  None = "None",
  WorldTrade = "WorldTrade",
  CountryTrade = "CountryTrade",
}

export enum HideWorldTradeOption {
  On = "On",
  Off = "Off",
}

export enum ShowTableOption {
  On = "On",
  Off = "Off",
}

export type RcaThreshold = number;
