import styled from "@emotion/styled";
import { highlightColor } from "../../styling/cssVariables";
import FindInVizInvertedIcon from "../assets/find-in-viz-no-background.svg";

export const MainMenu = styled.ul`
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  margin-block: 0;
  border: 1px solid rgb(204, 204, 204);
  border-top: none;
  box-sizing: border-box;

  & .menu-item--highlighted {
    background-color: ${highlightColor};
  }
`;

export const BaseMenuItem = styled.li`
  height: 36px;
  cursor: pointer;
  width: calc(100% - 0px);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.875rem;
  color: rgb(51, 51, 51);
  // padding: 2px;
  box-sizing: border-box;
  position: relative;
  padding-right: 10px;
  overflow: hidden;

  &.menu-item--highlighted {
    background-color: ${highlightColor};
  }
`;

export const ClearButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  display: inline;
  right: 0px;
  height: 100%;
  top: 0px;
  right: 25.6px;
  bottom: 0px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.6rem;
  color: rgb(170, 170, 170);
`;

const ArrowBase = styled.div`
  --triangle-half-base: 5px;
  --triangle-height: 5px;
  --triangle-color: #999;

  width: 0;
  height: 0;
  border-left: var(--triangle-half-base) solid transparent;
  border-right: var(--triangle-half-base) solid transparent;
`;

export const ArrowUp = styled(ArrowBase)`
  border-bottom: var(--triangle-height) solid var(--triangle-color);
`;

export const ArrowDown = styled(ArrowBase)`
  border-top: var(--triangle-height) solid var(--triangle-color);
`;

export const ClearButtonSymbol = "✕";

export const ToggleButton = styled.button`
  position: absolute;
  right: 0px;
  top: 0px;
  margin: auto 0px;
  padding-right: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  z-index: 100;
  height: 100%;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
    width: 100%;
    display: flex
    align-items: center;
    position: relative;
    height: 34px;
    line-height: 1.15;
    box-sizing: border-box;
    
    padding: 0px;
    border: 1px solid rgb(204, 204, 204);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    &::before {
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 25px;
        height: 100%;
        content: '';
        background-image: url(${FindInVizInvertedIcon});
        background-position: center center;
        background-repeat: no-repeat;
    }
`;

export const DropdownInputField = styled.input`
  width: 100%;
  height: 100%;
  font-size: 1rem;
  box-sizing: border-box;
  border: none;
  padding-left: 25px;
  padding-right: 40px;
  background: none;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.input--placeholder {
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`;

export const ComboBoxContainer = styled.div`
  width: 100%;
  position: relative;
  background: none;
`;
