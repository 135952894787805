import { gql } from "@apollo/client";

const query = gql`
  fragment ProductMetadata on Product {
    productId
    productLevel
    code
    nameEn
    nameShortEn
    showFeasibility
    parent {
      productId
      productLevel
    }
    topParent {
      productId
      productLevel
    }
    greenProduct
  }

  query GetHS92Products($servicesClass: ServicesClass) {
    section: productHs92(productLevel: 1, servicesClass: $servicesClass) {
      ...ProductMetadata
    }

    twoDigit: productHs92(productLevel: 2, servicesClass: $servicesClass) {
      ...ProductMetadata
    }

    fourDigit: productHs92(productLevel: 4, servicesClass: $servicesClass) {
      ...ProductMetadata
    }

    sixDigit: productHs92(productLevel: 6, servicesClass: $servicesClass) {
      ...ProductMetadata
    }
  }
`;

export default query;
