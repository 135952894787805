import Joyride, { CallBackProps, STATUS, Placement } from "react-joyride";
import { H3, Paragraph } from "../styling/TextStyling";

const steps = [
  {
    target: ".viewSelector",
    content: (
      <div>
        <H3>Start Your Exploration</H3>
        <br />
        <Paragraph style={{ textAlign: "left" }}>
          Begin building your visualization by selecting one of two dimensions
          to explore: a product or a location.
        </Paragraph>
        <Paragraph style={{ textAlign: "left" }}>
          By selecting <b style={{ color: "#D1B656" }}>Product</b> you can
          explore questions such as, what did China export or import to in 2022?
        </Paragraph>
        <Paragraph style={{ textAlign: "left" }}>
          By selecting <b style={{ color: "#D1B656" }}>Location</b> you can
          explore questions such as, to which countries did China export bananas
          in 2022?
        </Paragraph>
      </div>
    ),
    disableBeacon: true,
  },
  {
    target: ".customizeVisualization",
    content: (
      <div>
        <H3>Customize Your Visualization</H3>
        <br />
        <Paragraph style={{ textAlign: "left" }}>
          Use the dropdown menus to select the exporter and importer location or
          product, in a given year.
        </Paragraph>
        <Paragraph style={{ textAlign: "left" }}>
          Click on Settings to customize trade and complexity visualizations
          even more.
        </Paragraph>
      </div>
    ),
    disableBeacon: true,
  },
  {
    target: ".analyzeTradeFlows",
    content: (
      <div>
        <H3>Analyze Trade Flows</H3>
        <br />
        <Paragraph style={{ textAlign: "left" }}>
          Use these 4 visualizations to analyze export or import flows.
        </Paragraph>
      </div>
    ),
    disableBeacon: true,
    placement: "right-start" as Placement,
  },
  {
    target: ".analyzeCountryComplexity",
    content: (
      <div>
        <H3>Analyze Country Complexity and Opportunities</H3>
        <br />
        <Paragraph style={{ textAlign: "left" }}>
          Use these 2 visualizations to analyze the{" "}
          <b style={{ color: "#D1B656" }}>know how</b> of a location and its
          potential paths to diversification.
        </Paragraph>
      </div>
    ),
    disableBeacon: true,
    placement: "right-end" as Placement,
  },
  {
    target: ".learnMoreAndShare",
    content: (
      <div>
        <H3>Learn More and Share!</H3>
        <br />
        <Paragraph style={{ textAlign: "left" }}>
          You can always find this tutorial at the bottom left, along with
          important data notes, a glossary of terms and different ways to share
          Atlas information.
        </Paragraph>
      </div>
    ),
    disableBeacon: true,
    placement: "right-end" as Placement,
  },
];

const Walkthrough = ({ setRunWalkthrough }) => {
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status as any)) {
      setRunWalkthrough(false);
    }
  };
  const filteredSteps = steps.filter(({ target }) => {
    const element = document.querySelector<HTMLElement>(target as string);
    return element !== null && element.offsetParent !== null;
  });

  return (
    <Joyride
      steps={filteredSteps}
      styles={{
        options: {
          primaryColor: "#D1B656",
          zIndex: 1000,
        },
      }}
      continuous={true}
      showProgress={true}
      spotlightClicks={true}
      spotlightPadding={10}
      floaterProps={{ disableAnimation: true }}
      callback={handleJoyrideCallback}
      showSkipButton
    />
  );
};
export default Walkthrough;
