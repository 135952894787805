import {
  ModalRoot,
  ModalOverlay,
  ModalContentContainer,
  ModalHeader,
  ModalHeaderIcon,
  CloseButton,
  ClearButtonSymbol,
  ModalBody,
  GlossaryModalBody,
  ModalBodyContainer,
} from "./ModalStyles";
import DataNotesOnIcon from "../../assets/images/ui/datanotes_on.svg";
import DataNotesOffIcon from "../../assets/images/ui/datanotes_off.svg";
import GlossaryIcon from "../../assets/images/ui/glossary.svg";
import SubregionMapIcon from "../../assets/images/ui/subregion-map.svg";

import { navigationBarElementId } from "../navigationBar/NavigationBar";

export enum ModalType {
  DataNotes = "Data Notes",
  DataIssues = "Data Issues",
  Glossary = "Glossary",
  SubregionMap = "Subregion Map",
  Generic = "Generic",
  ProductOverlay = "ProductOverlay",
}

const ModalBase = ({ modalType, toggleModal, children }: any) => {
  let modalPaddingLeft;
  let navigationBarElement = document.querySelector(
    `#${navigationBarElementId}`,
  );
  if (navigationBarElement) {
    let { width } = navigationBarElement.getBoundingClientRect();
    modalPaddingLeft = `${width}px`;
  } else {
    modalPaddingLeft = "0px";
  }

  let modalHeader: any;
  let modalBodyContents: any;
  let modalHeaderIcon: any;
  let overrideStyle: any = { marginLeft: modalPaddingLeft };

  if (modalType === ModalType.DataNotes) {
    modalHeader = "Data Notes";
    modalBodyContents = <ModalBody>{children}</ModalBody>;
    modalHeaderIcon = DataNotesOffIcon;
  } else if (modalType === ModalType.DataIssues) {
    modalHeader = "Data Issues";
    modalBodyContents = <ModalBody>{children}</ModalBody>;
    modalHeaderIcon = DataNotesOnIcon;
  } else if (modalType === ModalType.Glossary) {
    modalHeader = "Glossary";
    modalBodyContents = <GlossaryModalBody>{children}</GlossaryModalBody>;
    modalHeaderIcon = GlossaryIcon;
  } else if (modalType === ModalType.SubregionMap) {
    modalHeader = "Subregion Map";
    modalBodyContents = <ModalBody>{children}</ModalBody>;
    modalHeaderIcon = SubregionMapIcon;
    overrideStyle = {
      ...overrideStyle,
      width: "70%",
    };
  } else if (modalType === ModalType.ProductOverlay) {
    modalHeader = (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        Most Related Nearby Products
      </div>
    );
    modalHeaderIcon = undefined;
    modalBodyContents = <ModalBody>{children}</ModalBody>;
    overrideStyle = {
      ...overrideStyle,
      width: "85%",
    };
  } else {
    modalHeader = "";
    modalBodyContents = <ModalBody>{children}</ModalBody>;
    modalHeaderIcon = undefined;
  }

  return (
    <ModalRoot>
      <ModalOverlay onClick={() => toggleModal()}>
        <ModalContentContainer
          style={overrideStyle}
          onClick={(e: any) => e.stopPropagation()}
        >
          <ModalHeader>
            {modalHeaderIcon ? (
              <ModalHeaderIcon
                style={{
                  maskImage: `url(${modalHeaderIcon}`,
                  WebkitMaskImage: `url(${modalHeaderIcon}`,
                }}
              />
            ) : null}
            <h1>{modalHeader}</h1>
            <CloseButton onClick={() => toggleModal()}>
              {ClearButtonSymbol}
            </CloseButton>
          </ModalHeader>
          <ModalBodyContainer>{modalBodyContents}</ModalBodyContainer>
        </ModalContentContainer>
      </ModalOverlay>
    </ModalRoot>
  );
};

export default ModalBase;
