import styled from "@emotion/styled";
import {
  BaseMenuItem,
  ArrowDown,
  ArrowUp,
} from "../../mainDropdownPrimitive/styles";
import { LocationMetadatumLevel } from "../../../graphql/types";
import {
  groupedRegionColorMap,
  otherGroupId,
  undeclaredCountryId,
  unidentifiedServicesCountryId,
} from "../../../sharedUtilities/Utils";
import * as d3 from "d3";
import { getNumericIdFromStringLocationId } from "../../../sharedUtilities/Utils";
import { useRef, memo, useEffect, useState } from "react";
import WorldIcon from "../../../assets/images/geomap.svg";
import { useLocation } from "react-router-dom";
import { VizType } from "../../../visualization/Utils";

const LocationMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 100%;
  margin-bottom: 2px;
`;

const LocationIcon = styled.div`
  width: 36px;
  height: 32px;
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  & img {
    object-fit: contain;
    max-width: 100%;
  }

  &.icon {
    height: 32px;
    padding: 3px;
    box-sizing: border-box;
  }

  &.icon img {
    height: 100%;
    box-sizing: border-box;
  }

  &.flag,
  &.world {
    height: 100%;
  }

  &.flag img {
    border: 1px solid #ddd;
  }
`;

const IconContainer = styled.div`
  flex-basis: 36px;
  width: 36px;
  height: 100%;
`;

const LabelContainer = styled.div`
  width: 100%;
  padding-left: 10px;
  overflow: hidden;
  ppadding: auto 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface LocationDropdownItemInput {
  item: any;
  hashFunction: any;
  getItemProps: any;
  mappedItemIndex: number;
  index: number;
  highlightedIndex: number;
  displayItems: any;
  setDisplayItems: any;
  updateVisibilityFunction: any;
  selectedItem: any;
  virtualizedStyle: any;
  isSearching: boolean;
}

const ToggleButtonArrow = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgb(230, 230, 230);
  }
`;

const toggleButtonClick = ({
  item,
  updateVisibilityFunction,
  displayItems,
  setDisplayItems,
}: any) => {
  /*
    When click on toggle button,

    Update this item's isExpanded value
    call newItems = updateItemsWithVisibilityStatus
    setDisplayItems(newItems)

    */
  updateVisibilityFunction({
    itemToUpdate: item,
    displayItems,
    setDisplayItems,
  });
};

const ExpandToggleButton = ({
  item,
  style,
  updateVisibilityFunction,
  displayItems,
  setDisplayItems,
}: any) => {
  const { isExpanded, id } = item;
  // If isExpanded === true: upward-pointing arrow
  // If isExpanded === false: downward-pointing arrow
  return (
    <ToggleButtonArrow
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        toggleButtonClick({
          item,
          updateVisibilityFunction,
          displayItems,
          setDisplayItems,
        });
      }}
    >
      {isExpanded ? <ArrowUp /> : <ArrowDown />}
    </ToggleButtonArrow>
  );
};

/* NOTE:
    This needs to be outside of `LocationDropdownItem`, 
    otherwise the value of const Image changes on every render,
    causing the image to be re-rendered each time
*/
const Image = memo(function Image({ src }: any) {
  return <img src={src} />;
});

const WorldIconContainer = styled.img`
  opacity: 0.5;
  width: 35px;
  margin-left: 5px;

  && {
    border: 1px solid white;
  }
`;

const LocationDropdownItem = ({
  item,
  hashFunction,
  index,
  selectedItem,
  highlightedIndex,
  mappedItemIndex,
  getItemProps,
  updateVisibilityFunction,
  displayItems,
  setDisplayItems,
  isSearching,
  virtualizedStyle,
}: LocationDropdownItemInput) => {
  const location = useLocation();
  const currentVizType = location.pathname.split("/")[2] as any;
  const disableRegions =
    currentVizType === VizType.Feasibility ||
    currentVizType === VizType.ProductSpace;
  const dropdownItemRef = useRef<HTMLDivElement | null>(null);

  const {
    id,
    locationLevel,
    hybrid_level_1: regionGroup,
    name_short_en: label,
    isVisible,
    isVisibleChange,
  } = item;
  const numericId = getNumericIdFromStringLocationId(id);
  let classLevelName = "level";

  let imageSource = "";
  let color: string | undefined = groupedRegionColorMap.has(regionGroup)
    ? groupedRegionColorMap.get(regionGroup)
    : undefined;
  if (locationLevel === LocationMetadatumLevel.country) {
    if (
      item.id === undeclaredCountryId ||
      item.id === unidentifiedServicesCountryId
    ) {
      imageSource = require("../../../assets/images/country_flags/Flag-Undeclared.png");
    } else {
      let iso3Code = item.iso3Code;

      imageSource = require(
        "../../../assets/images/country_flags/Flag-" + iso3Code + ".svg",
      );
    }
  } else if (locationLevel === LocationMetadatumLevel.region) {
    if (id !== otherGroupId) {
      imageSource = require(
        "../../../assets/images/region_icons/Icon-" +
          label.toLowerCase() +
          ".svg",
      );
    }
  }

  const [itemHasTransitioned, setItemHasTransitioned] =
    useState<boolean>(false);
  const [listItemHeight, setListItemHeight] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (dropdownItemRef && dropdownItemRef.current) {
      if (!itemHasTransitioned) {
        let useComputedHeight;
        if (!listItemHeight) {
          let { height: computedHeight } = dropdownItemRef.current
            .querySelector("li")!
            .getBoundingClientRect();
          useComputedHeight = computedHeight;
        } else {
          useComputedHeight = listItemHeight;
        }

        let startingHeight, endingHeight;

        if (isVisibleChange) {
          startingHeight = 0;
        } else {
          startingHeight = useComputedHeight;
        }
        endingHeight = useComputedHeight;

        d3.select(dropdownItemRef.current)
          .style("visibility", "visible")
          .style("overflow", "hidden")
          .style("height", `${startingHeight}px`);

        if (isVisibleChange) {
          d3.select(dropdownItemRef.current)
            .transition()
            .duration(200)
            .ease(d3.easeLinear)
            .style("height", `${endingHeight}px`);
        }

        setItemHasTransitioned(true);
      }
    }
  }, [dropdownItemRef.current, isVisible, isVisibleChange]);

  let initialVisibilityStyle: any;
  if (virtualizedStyle) {
    initialVisibilityStyle = virtualizedStyle;
  } else {
    initialVisibilityStyle = {
      visibility: "hidden",
    };
  }

  let expandToggleButtonStyle = isSearching ? { display: "none" } : undefined;

  if (locationLevel === LocationMetadatumLevel.region) {
    const style = {
      backgroundColor: color,
    };
    const itemProps = {
      ...getItemProps({ item, index: mappedItemIndex }),
      disabled: disableRegions,
    };
    if (disableRegions) {
      itemProps.onClick = () =>
        updateVisibilityFunction({
          itemToUpdate: item,
          displayItems,
          setDisplayItems,
        });
    }
    return (
      <div style={initialVisibilityStyle} ref={dropdownItemRef}>
        <BaseMenuItem
          style={{ cursor: disableRegions ? "default" : "pointer" }}
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...itemProps}
        >
          <LocationMenuItem className={classLevelName}>
            <IconContainer style={style}>
              <LocationIcon className="icon">
                <Image src={imageSource} />
              </LocationIcon>
            </IconContainer>
            <LabelContainer>{hashFunction(item)}</LabelContainer>
            <ExpandToggleButton
              style={expandToggleButtonStyle}
              item={item}
              updateVisibilityFunction={updateVisibilityFunction}
              displayItems={displayItems}
              setDisplayItems={setDisplayItems}
            />
          </LocationMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (locationLevel === LocationMetadatumLevel.country) {
    const style = {
      borderLeft: `4px solid ${color}`,
      marginLeft: "48px",
      paddingLeft: "4px",
    };

    return (
      <div style={initialVisibilityStyle} ref={dropdownItemRef}>
        <BaseMenuItem
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...getItemProps({ item, index: mappedItemIndex })}
        >
          <LocationMenuItem className={classLevelName}>
            <IconContainer style={style}>
              <LocationIcon className="flag">
                <Image src={imageSource} />
              </LocationIcon>
            </IconContainer>
            <LabelContainer>{hashFunction(item)}</LabelContainer>
          </LocationMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (locationLevel === LocationMetadatumLevel.subregion) {
    const style = {
      borderRight: `4px solid ${color}`,
      marginLeft: "-4px",
    };
    const itemProps = {
      ...getItemProps({ item, index: mappedItemIndex }),
      disabled: disableRegions,
    };
    if (disableRegions) {
      itemProps.onClick = () =>
        updateVisibilityFunction({
          itemToUpdate: item,
          displayItems,
          setDisplayItems,
        });
    }
    return (
      <div style={initialVisibilityStyle} ref={dropdownItemRef}>
        <BaseMenuItem
          style={{ cursor: disableRegions ? "pointer" : "default" }}
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...itemProps}
        >
          <LocationMenuItem className={classLevelName}>
            <IconContainer style={style}>
              <LocationIcon className="icon" />
            </IconContainer>
            <LabelContainer>{hashFunction(item)}</LabelContainer>
            <ExpandToggleButton
              style={expandToggleButtonStyle}
              item={item}
              updateVisibilityFunction={updateVisibilityFunction}
              displayItems={displayItems}
              setDisplayItems={setDisplayItems}
            />
          </LocationMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else if (locationLevel === LocationMetadatumLevel.world) {
    const style = {
      ...initialVisibilityStyle,
      visibility: "visible",
      marginLeft: "0px",
      paddingLeft: "0px",
      position: "sticky",
      top: "0px",
      left: "0px",
      backgroundColor: "#ffffff",
      zIndex: "100",
      borderBottom: "1px solid #CCCCCC",
    };
    const itemProps = {
      ...getItemProps({ item, index: mappedItemIndex }),
      disabled: disableRegions,
    };
    if (disableRegions) {
      itemProps.onClick = () =>
        updateVisibilityFunction({
          itemToUpdate: item,
          displayItems,
          setDisplayItems,
        });
    }
    return (
      <div style={style} ref={dropdownItemRef}>
        <BaseMenuItem
          style={{ cursor: disableRegions ? "pointer" : "default" }}
          className={
            mappedItemIndex === highlightedIndex ||
            (selectedItem && item.id === selectedItem.id)
              ? "menu-item--highlighted"
              : ""
          }
          {...itemProps}
        >
          <LocationMenuItem className={classLevelName}>
            <IconContainer>
              <LocationIcon className="world">
                <WorldIconContainer src={WorldIcon} />
              </LocationIcon>
            </IconContainer>
            <LabelContainer>{hashFunction(item)}</LabelContainer>
          </LocationMenuItem>
        </BaseMenuItem>
      </div>
    );
  } else {
    throw new Error("Not a valid location metadatum level.");
  }
};

export default LocationDropdownItem;
