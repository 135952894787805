import { ClickAwayListener } from "@mui/material";
import Tippy from "@tippyjs/react";

const HighlightOverlay = ({
  linkLookup,
  selection,
  nodeLookup,
  tip,
  metaDataLookup,
  setHighlightedItem,
}) => {
  const focusNode = nodeLookup.get(selection);
  if (!focusNode) {
    return null;
  }
  return (
    <>
      <ClickAwayListener onClickAway={() => setHighlightedItem(null)}>
        <Tippy
          content={
            <div
              style={{
                backgroundColor: "black",
                opacity: 0.75,
              }}
            >
              {metaDataLookup.get(selection)?.data?.nameShortEn}
            </div>
          }
          visible={tip}
        >
          <circle
            cx={focusNode.x}
            cy={focusNode.y}
            r={focusNode.r}
            strokeWidth="1.5"
            stroke="white"
            style={{
              cursor: "pointer",
              pointerEvents: "none",
            }}
            fill={focusNode.fill}
          />
        </Tippy>
      </ClickAwayListener>
      {linkLookup[selection]?.map((productId) => {
        const node = nodeLookup.get(productId);
        return (
          <circle
            key={`highlight-node-${productId}-${tip}`}
            cx={node.x}
            cy={node.y}
            r={node.r}
            strokeWidth="1.5"
            stroke="black"
            style={{
              cursor: "pointer",
              pointerEvents: "none",
            }}
            fill={node.fill}
          />
        );
      })}
      {linkLookup[selection]?.map((productId) => {
        const source = nodeLookup.get(selection);
        const target = nodeLookup.get(productId);
        return (
          <line
            key={`highlight-line-${productId}-${tip}`}
            x1={source.x}
            x2={target.x}
            y1={source.y}
            y2={target.y}
            strokeWidth="1"
            stroke="black"
            style={{
              cursor: "pointer",
              pointerEvents: "none",
            }}
            strokeLinecap="round"
          />
        );
      })}
    </>
  );
};

export default HighlightOverlay;
