import styled from "@emotion/styled";
import { DecileClassification } from "../../topProductsTable/StarRating";
import Table from "../../topProductsTable/Table";
import { index, scaleThreshold } from "d3";
import { useParentSize } from "@visx/responsive";
import { useMemo } from "react";
import GraphLoading from "../../components/GraphLoading";
import { usePageQueryParams } from "../../defaultSettings";

const gridLines = {
  graphTop: "countryProfilesGraphTop",
  graphBottom: "countryProfilesGraphBottom",

  graphContentLeft: "countryProfilesGraphContentLeft",
  graphContentRight: "countryProfilesGraphContentRight",
};

export const GraphAreaContainer = styled.div`
  grid-row: ${gridLines.graphTop} / ${gridLines.graphBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;
const diamondSizes = [
  DecileClassification.Top,
  DecileClassification.Top,
  DecileClassification.Ninth,
  DecileClassification.Eighth,
  DecileClassification.Seventh,
  DecileClassification.Sixth,
  DecileClassification.Fifth,
  DecileClassification.Fourth,
  DecileClassification.Third,
  DecileClassification.Second,
  DecileClassification.Last,
  DecileClassification.Empty,
  DecileClassification.Empty,
].reverse() as any;

const thresholdsToArr = (t: any) => {
  const { variable, __typename, ...rest } = t || {};
  return Object?.values(rest) as any;
};

export const useTableScales = (countryYearThresholds) =>
  useMemo(() => {
    const thresholdLookup = index(
      countryYearThresholds,
      (d: any) => d.variable,
    ) as any;

    const complexityScale = scaleThreshold()
      .domain(thresholdsToArr(thresholdLookup.get("pci")))
      .range(diamondSizes);

    const feasibilityScale = scaleThreshold()
      .domain(thresholdsToArr(thresholdLookup.get("normalized_distance")))
      .range(diamondSizes);

    const opportunityScale = scaleThreshold()
      .domain(thresholdsToArr(thresholdLookup.get("normalized_cog")))
      .range(diamondSizes);

    const rcaScale = scaleThreshold()
      .domain(thresholdsToArr(thresholdLookup.get("normalized_export_rca")))
      .range(diamondSizes);
    return { complexityScale, opportunityScale, feasibilityScale, rcaScale };
  }, [countryYearThresholds]);

const ProductsTable = ({
  displayData,
  countryProductYear,
  productYear,
  loading,
  countryData,
  metaDataLookup,
  metaDataTree,
  colorMap,
  countryLookup,
  hiddenCategories,
  countryYearThresholds,
}) => {
  const filteredData = countryProductYear.filter((d) => d.normalizedPci);
  const [{ year }] = usePageQueryParams();
  const totalExportLookup = index(productYear, (d: any) => d.productId) as any;
  const { parentRef, width, height } = useParentSize({ debounceTime: 150 });
  const { complexityScale, opportunityScale, feasibilityScale, rcaScale } =
    useTableScales(countryYearThresholds);

  const tableData = filteredData
    .map((d) => {
      return {
        ...d,
        id: d.productId,
        color: colorMap.get(
          metaDataLookup.get(d.productId)?.data?.topParent?.productId,
        ),
        code: metaDataLookup.get(d.productId)?.data?.code,
        name: metaDataLookup.get(d.productId)?.data?.nameShortEn,
        complexityDecile: complexityScale(d.normalizedPci),
        opportunityGainDecile: opportunityScale(d.normalizedCog),
        rcaDecile: rcaScale(d.exportRca),
        feasibilityDecile: feasibilityScale(d.normalizedDistance),
        globalExportValue: totalExportLookup.get(d.productId).exportValue,
        normalizedPCI: d.normalizedPci,
        normalizedFeasibility: d.normalizedDistance,
        globalExportValueChange: totalExportLookup.get(d.productId)
          .exportValueGrowth5,
        //balanced approach
        score:
          0.35 * d.normalizedCog +
          0.15 * d.normalizedPci +
          0.5 * d.normalizedDistance,
      };
    })
    .sort((d1, d2) => d2.score - d1.score)
    .filter(
      (d) =>
        !hiddenCategories.includes(
          metaDataLookup.get(d.productId)?.data?.topParent?.productId,
        ),
    );

  return (
    <div style={{ height: "100%", width: "100%" }} ref={parentRef}>
      {loading && <GraphLoading zIndex={1000} />}
      <Table
        products={tableData}
        getFluentString={() => "hello"}
        width={width}
        height={height}
        zIndexFromParent={20}
        year={year}
      />
    </div>
  );
};

export default ProductsTable;
