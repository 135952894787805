import { LocationMetadatumLevel } from "../../../graphql/types";
import { worldGroupDatum } from "../../../graphql/queries/getLocationsMetadata";

export interface ParentObject {
  id: string;
}

export interface GroupObject {
  groupType: string;
  groupId: string;
  parentId: string | null;
  groupName: string;
  members: string[];
}

export interface LocationObject {
  countryId: string | undefined;
  iso3Code: string | undefined;
  locationLevel: string | undefined;
  nameEn: string | undefined;
  nameShortEn: string | undefined;
  // isDataTrustworthy: boolean | undefined;
  // hasReportedServicesInAnyYear: boolean | undefined;
  // hasReportedServicesLastYear: boolean | undefined;
}

export interface EnhancedLocationObject extends LocationObject {
  label: string | undefined;
  id: string | undefined;
  value: string | undefined;
  locationLevel: LocationMetadatumLevel | undefined;
  searchString: string | undefined;
  name_short_en: string | undefined;
  hybrid_level_1: string | undefined;
  hybrid_level_2: string | undefined;
}
export interface GroupAndOrderLocationOptionsInput {
  countries: LocationObject[];
  regions: GroupObject[];
  subregions: GroupObject[];
}

export const locationSearchStringFunction = (item: any) =>
  `${item.name_short_en} ${item.iso3Code}`;

export const locationFilterSearchResults = ({
  searchTerm,
  displayItems,
  setDisplayItems,
}: any) => {
  let modifiedDisplayItems = [...displayItems];

  const updatedDisplayItems = modifiedDisplayItems.map((item: any) => {
    const updatedItem = { ...item };
    if (
      !searchTerm ||
      locationSearchStringFunction(item)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ) {
      updatedItem.isVisible = true;
    } else {
      updatedItem.isVisible = false;
    }
    return updatedItem;
  });

  setDisplayItems(updatedDisplayItems);
};

export const updateVisibilityStatus = ({
  itemToUpdate,
  displayItems,
  setDisplayItems,
}: any) => {
  let modifiedDisplayItems = [...displayItems];

  let updatedItems: any;
  if (itemToUpdate) {
    updatedItems = modifiedDisplayItems.map((item: any) => {
      const itemWithStatus = { ...item };
      if (item.id === itemToUpdate.id) {
        itemWithStatus.isExpanded = !item.isExpanded;
      }
      return itemWithStatus;
    });
  } else {
    updatedItems = modifiedDisplayItems;
  }

  const itemsWithVisibilityStatus = updatedItems.map((item: any) => {
    const itemWithStatus = { ...item };

    if (itemWithStatus.locationLevel === LocationMetadatumLevel.world) {
      itemWithStatus.isVisible = true;
    } else if (
      itemWithStatus.locationLevel === LocationMetadatumLevel.subregion
    ) {
      let parentId = itemWithStatus.hybrid_level_1;
      let parent = updatedItems.find(
        (findItem: any) => findItem.id === parentId,
      );
      itemWithStatus.isVisible = parent.isExpanded;
      if (!parent.isExpanded) {
        itemWithStatus.isExpanded = false;
      }
    } else if (
      itemWithStatus.locationLevel === LocationMetadatumLevel.country
    ) {
      let parentRegionId = itemWithStatus.hybrid_level_1;
      let parentSubregionId = itemWithStatus.hybrid_level_2;
      let parentRegion = updatedItems.find(
        (findItem: any) => findItem.id === parentRegionId,
      );
      let parentSubregion = updatedItems.find(
        (findItem: any) => findItem.id === parentSubregionId,
      );
      itemWithStatus.isVisible =
        parentRegion.isExpanded && parentSubregion.isExpanded;
    }

    itemWithStatus.isVisibleChange =
      itemWithStatus.isVisible !== item.isVisible;

    return itemWithStatus;
  });

  setDisplayItems(itemsWithVisibilityStatus);
};

export function groupAndOrderLocationOptions({
  countries,
  regions,
  subregions,
}: GroupAndOrderLocationOptionsInput) {
  let sortedCountriesByName = [...countries].sort((countryA, countryB) => {
    const nameA = countryA.nameShortEn!.toUpperCase(); // ignore upper and lowercase
    const nameB = countryB.nameShortEn!.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  let sortedSubregionsByName = [...subregions].sort(
    (subregionA, subregionB) => {
      const nameA = subregionA.groupName.toUpperCase(); // ignore upper and lowercase
      const nameB = subregionB.groupName.toUpperCase(); // ignore upper and lowercase

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    },
  );

  let sortedRegionsByName = [...regions].sort((regionA, regionB) => {
    const nameA = regionA.groupName.toUpperCase(); // ignore upper and lowercase
    const nameB = regionB.groupName.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const fetchedLocationData: any[] = [];

  // First, push `world` group datum to array
  fetchedLocationData.push({
    id: worldGroupDatum.groupId,
    name_short_en: worldGroupDatum.groupName,
    name_en: worldGroupDatum.groupName,
    code: worldGroupDatum.groupName,
    locationLevel: LocationMetadatumLevel.world,
    hybrid_level_1: undefined,
    hybrid_level_2: undefined,
  });

  sortedRegionsByName.forEach((region) => {
    fetchedLocationData.push({
      id: region.groupId,
      name_short_en: region.groupName,
      name_en: region.groupName,
      code: region.groupName,
      locationLevel: LocationMetadatumLevel.region,
      hybrid_level_1: region.groupId,
      hybrid_level_2: undefined,
    });
    let groupSubregions = sortedSubregionsByName.filter(
      (m) => m.parentId && m.parentId === region.groupId,
    );
    groupSubregions.forEach((subregion) => {
      fetchedLocationData.push({
        id: subregion.groupId,
        name_short_en: subregion.groupName,
        name_en: subregion.groupName,
        code: subregion.groupName,
        locationLevel: LocationMetadatumLevel.subregion,
        hybrid_level_1: region.groupId,
        hybrid_level_2: subregion.groupId,
      });
      const subregionMemberCountries = sortedCountriesByName.filter(
        (country: any) => subregion.members.includes(country.countryId),
      );
      let countriesToAdd: EnhancedLocationObject[] = [];
      subregionMemberCountries.forEach((country) => {
        let c: EnhancedLocationObject = {
          ...country,
          id: country.countryId,
          label: country.nameEn,
          value: country.countryId,
          searchString: country.nameShortEn,
          name_short_en: country.nameShortEn,
          hybrid_level_1: region.groupId,
          hybrid_level_2: subregion.groupId,
          locationLevel: LocationMetadatumLevel.country,
        };
        countriesToAdd.push(c);
      });
      fetchedLocationData.push(...countriesToAdd);
    });
  });

  return fetchedLocationData;
}
