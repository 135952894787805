import { useParentSize } from "@visx/responsive";
import { useTableScales } from "../growth/ProductsTable";
import Table from "../../topProductsTable/Table";

import { usePageQueryParams } from "../../defaultSettings";

const OverlayTable = ({
  colorMap,
  metaDataLookup,
  displayData,
  totalExportLookup,
  selection,
  linkLookup,
  nodeLookup,
  countryExportLookup,
  highlight,
  setRingItem,
}) => {
  const [{ year }] = usePageQueryParams();
  const { countryYearThresholds = [] } = displayData;
  const { complexityScale, opportunityScale, feasibilityScale, rcaScale } =
    useTableScales(countryYearThresholds);
  const { parentRef, width, height } = useParentSize({ debounceTime: 150 });
  const fellowNodes = linkLookup[selection]?.map((n: any) => nodeLookup.get(n));

  const tableData = fellowNodes
    // .filter((d) => d?.productId && countryExportLookup.get(d?.productId))
    .map((d) => ({ ...displayData, ...countryExportLookup.get(d?.productId) }))
    .map((d) => {
      return {
        ...d,
        id: d.productId,
        color: colorMap.get(
          metaDataLookup.get(d.productId)?.data?.topParent?.productId,
        ),
        code: metaDataLookup.get(d.productId)?.data?.code,
        name: metaDataLookup.get(d.productId)?.data?.nameShortEn,
        complexityDecile: complexityScale(d.normalizedPci),
        opportunityGainDecile: opportunityScale(d.normalizedCog),
        rcaDecile: rcaScale(d.exportRca),
        feasibilityDecile: feasibilityScale(d.normalizedDistance),
        globalExportValue: totalExportLookup.get(d.productId)?.exportValue || 0,
        normalizedPCI: d.normalizedPci,
        normalizedFeasibility: d.normalizedDistance,
        globalExportValueChange:
          totalExportLookup.get(d.productId)?.exportValueGrowth5 || 0,
        //balanced approach
        score:
          0.35 * d.normalizedCog +
          0.15 * d.normalizedPci +
          0.5 * d.normalizedDistance,
      };
    })
    .filter((d) => metaDataLookup.get(d.productId)?.data?.showFeasibility)
    .sort((d1, d2) => d2.score - d1.score);

  return (
    <div ref={parentRef} style={{ height: "100%", width: "100%" }}>
      <Table
        products={tableData}
        getFluentString={() => "hello"}
        width={width}
        height={height}
        zIndexFromParent={20}
        year={year}
        condensed
        highlight={highlight}
        setRingItem={setRingItem}
      />
    </div>
  );
};
export default OverlayTable;
