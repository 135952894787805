import noop from "lodash-es/noop";
import React from "react";
import styled from "@emotion/styled";
import { failIfValidOrNonExhaustive } from "./Table";
import SortingIcon from "./sorting_icon";

// Color when the the current column is not being sorted on:
const arrowUnfocusedColor = "#333";
// Color when the current column is being sorted on and the arrow direction
// matches the sort direction:
const arrowFocusedSelectedColor = "#333";
// Color when the current column is being sorted on and the arrow direction
// doesn't match the sort direction:
const arrowFocusedUnselectedColor = "#d6d6d6";
// Normal opacity for columns that are not being sorted on:
const unfocusedArrowOpacity = 0.2;
// Opacity for column being sorted on or hover state of colum that is not being
// sorted on:
const focusedArrowOpacity = 1;

const TitleCellContainer = styled.div`
  border-bottom: 2px solid #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  background-color: white;
  font-size: 0.875rem;
  line-height: 1.5;

  --arrow-opacity: ${unfocusedArrowOpacity};

  &:hover {
    --arrow-opacity: ${focusedArrowOpacity};
  }

  @media (max-width: 1050px) {
    font-size: 0.8rem;
  }
`;

// Taken from the SVG file:
const aspectRatio = 13.6 / 7.9;
const arrowWidth = 10; // in px

const ArrowsContainer = styled.div`
  width: ${arrowWidth}px;
  height: ${arrowWidth * aspectRatio}px;
  position: relative;
  margin-left: 0.5rem;

  --up-arrow-color: ${arrowUnfocusedColor};
  --down-arrow-color: ${arrowUnfocusedColor};

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: ${arrowWidth}px;
    fill-opacity: var(--arrow-opacity);

    polygon:first-child {
      fill: var(--up-arrow-color);
    }

    polygon:last-child {
      fill: var(--down-arrow-color);
    }
  }

  @media (max-width: 1050px) {
    width: 7px;
    height: ${7 * aspectRatio}px;

    svg {
      width: 7px;
    }
  }
`;

// Allow CSS custom properties
declare module "csstype" {
  interface Properties {
    "--up-arrow-color"?: string;
    "--down-arrow-color"?: string;
    "--arrow-opacity"?: number;
  }
}

export enum SortCriterion {
  Complexity,
  OpportunityGain,
  Feasibility,
  ExportValue,
  ExportValueChange,
  RCA,
}

export enum SortDirection {
  Ascending,
  Descending,
}

type TitleCellProps = {
  children?: any;
  stickColumnToLeft?: boolean;
} & (
  | {
      hasAssignedSortCriterion: true;
      assignedSortCriterion: SortCriterion;
      onClick: (criterion: SortCriterion) => void;
      currentSortCriterion: SortCriterion;
      currentSortDirection: SortDirection;
      zIndex: number;
      promoteZIndex: () => void;
      restoreZIndex: () => void;
    }
  | {
      hasAssignedSortCriterion: false;
      zIndex: number;
    }
);

const TitleCell: React.FunctionComponent<TitleCellProps> = (props) => {
  let onClick: React.MouseEventHandler;
  let onMouseEnter: React.MouseEventHandler;
  let onMouseLeave: React.MouseEventHandler;
  let style: React.CSSProperties;
  let arrows: React.ReactElement<any> | null;
  if (props.hasAssignedSortCriterion === true) {
    const {
      assignedSortCriterion,
      currentSortCriterion,
      currentSortDirection,
      promoteZIndex,
      restoreZIndex,
      zIndex,
    } = props;
    let arrowUpColor: string | undefined,
      arrowDownColor: string | undefined,
      arrowOpacity: number | undefined;
    if (currentSortCriterion === assignedSortCriterion) {
      arrowOpacity = focusedArrowOpacity;
      if (currentSortDirection === SortDirection.Ascending) {
        arrowUpColor = arrowFocusedUnselectedColor;
        arrowDownColor = arrowFocusedSelectedColor;
      } else if (currentSortDirection === SortDirection.Descending) {
        arrowUpColor = arrowFocusedSelectedColor;
        arrowDownColor = arrowFocusedUnselectedColor;
      } else {
        failIfValidOrNonExhaustive(
          currentSortDirection,
          "Invalid sort direction " + currentSortDirection,
        );
        // These lines will never be executed:
        arrowUpColor = undefined;
        arrowDownColor = undefined;
      }
    } else {
      arrowUpColor = undefined;
      arrowDownColor = undefined;
      arrowOpacity = undefined;
    }
    const arrowStyle: React.CSSProperties = {
      "--up-arrow-color": arrowUpColor,
      "--down-arrow-color": arrowDownColor,
      "--arrow-opacity": arrowOpacity,
    };
    arrows = (
      <ArrowsContainer style={arrowStyle}>
        <SortingIcon />
      </ArrowsContainer>
    );
    onClick = () => props.onClick(assignedSortCriterion);
    onMouseEnter = promoteZIndex;
    onMouseLeave = restoreZIndex;
    style = {
      zIndex,
      cursor: props.hasAssignedSortCriterion ? "pointer" : "auto",
    };
  } else {
    onClick = noop;
    onMouseEnter = noop;
    onMouseLeave = noop;
    arrows = null;
    style = {
      zIndex: props.zIndex,
      cursor: props.hasAssignedSortCriterion ? "pointer" : "auto",
      left: props.stickColumnToLeft === true ? 0 : undefined,
    };
  }
  return (
    <TitleCellContainer
      onClick={onClick}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {props.children}
      {arrows}
    </TitleCellContainer>
  );
};

export default TitleCell;
