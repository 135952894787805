import { scaleLinear } from "d3";
import { Text } from "@visx/text";
import { getFillColor } from ".";

const getTextAnchor = (angle) => {
  if (angle >= 0 && angle < Math.PI / 2) {
    return "start";
  } else if (angle >= Math.PI / 2 && angle < Math.PI) {
    return "end";
  } else if (angle >= Math.PI && angle < (3 * Math.PI) / 2) {
    return "end";
  } else {
    return "start";
  }
};

const getVerticalAnchor = (angle) => {
  if (angle >= 0 && angle <= Math.PI) {
    return "start";
  } else {
    return "end";
  }
};

const moveTextAwayFromCenter = (x, y, angle, radius) => {
  const translateX = x + radius * Math.cos(angle);
  const translateY = y + radius * Math.sin(angle);
  return `translate(${translateX}, ${translateY})`;
};

const ProductRing = ({
  linkLookup,
  selection,
  nodeLookup,
  metaDataLookup,
  colorMap,
  setRingItem,
  highlighted,
  highlight,
  currentColorBySelection,
  clusterColorLookup,
  complexityColorScale,
  totalExportLookup,
  countryExportLookup,
  rcaThreshold,
}) => {
  const width = 600;
  const height = 600;

  const focusNode = nodeLookup.get(selection);
  const focusProductData = metaDataLookup.get(focusNode.productId)?.data;
  const focusTopParentId = focusProductData?.topParent?.productId;
  const fellowNodes = linkLookup[selection]?.map((n: any) => nodeLookup.get(n));

  const angleScale = scaleLinear()
    .domain([0, fellowNodes.length])
    .range([0, 2 * Math.PI]);
  const radius = Math.min(width, height) / 2 - 120;

  const centerX = width / 2;
  const centerY = height / 2;
  const { fill: parentFill } = getFillColor(
    focusNode,
    focusProductData,
    focusTopParentId,
    currentColorBySelection,
    colorMap,
    clusterColorLookup,
    complexityColorScale,
    totalExportLookup,
    [],
    countryExportLookup,
    rcaThreshold,
  );
  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${height} ${width}`}>
      <circle cx={centerX} cy={centerY} r={radius} fill="none" stroke="#ccc" />
      <circle
        cx={centerX}
        cy={centerY}
        r={nodeLookup.get(selection).r}
        fill={parentFill}
      />
      <Text
        x={centerX}
        y={centerY + nodeLookup.get(selection).r}
        textAnchor="middle"
        width={100}
        verticalAnchor="start"
      >
        {focusProductData.nameShortEn}
      </Text>
      {fellowNodes.map((d, i) => {
        const productData = metaDataLookup.get(d.productId)?.data;
        const topParentId = productData?.topParent?.productId;
        const x = centerX + radius * Math.cos(angleScale(i) - Math.PI / 2);
        const y = centerY + radius * Math.sin(angleScale(i) - Math.PI / 2);
        const angle = angleScale(i) - Math.PI / 2;
        const r = nodeLookup.get(d.productId).r;
        const transform = moveTextAwayFromCenter(x, y, angle, r + 10);
        const { fill } = getFillColor(
          d,
          productData,
          topParentId,
          currentColorBySelection,
          colorMap,
          clusterColorLookup,
          complexityColorScale,
          totalExportLookup,
          [],
          countryExportLookup,
          rcaThreshold,
        );
        return (
          <>
            <circle
              key={i}
              cx={x}
              cy={y}
              r={r}
              fill={fill}
              onClick={() => setRingItem(d.productId)}
              cursor="pointer"
              strokeWidth="2"
              stroke="black"
              strokeOpacity={d.productId === highlighted ? 1 : 0}
              onMouseEnter={() => highlight(d.productId)}
              onMouseLeave={() => highlight(null)}
            />

            <Text
              key={`label-${i}`}
              dy="0.35em"
              textAnchor={getTextAnchor(angle)}
              verticalAnchor={getVerticalAnchor(angle)}
              width={125}
              style={{ pointerEvents: "none" }}
              transform={transform}
            >
              {productData.nameShortEn}
            </Text>
          </>
        );
      })}
    </svg>
  );
};

export default ProductRing;
