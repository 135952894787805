import styled from "@emotion/styled";
import { HeaderContainer as GlobalHeaderContainer } from "../../ContentPageGrid";
import { HeaderAtlasLogo, HeaderNavigationList } from "./styles";
import { Link } from "react-router-dom";
import GrowthLabLogoImageLight from "../../../assets/images/GL_logo_white.png";
import { headerLinkMetadata } from "../../../components/header/Utils";
import { useRef, useEffect } from "react";
import HeaderBackgroundVideo from "../video/header-video.mp4";
import { demiFontWeight } from "../../../components/styling/cssVariables";
import FlyoutMenuItem from "../../../components/header/FlyoutMenuItem";

const HeaderContainer = styled(GlobalHeaderContainer)`
  height: 3.75rem;
  grid-area: unset;
  background: none;
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: border-box;
  border: none;
`;

const backgroundGradient = "linear-gradient(90deg, #222833 0%, #425069 100%)";
const headerHeight = "62vh";

const VideoContainer = styled(HeaderContainer)`
  position: absolute;
  z-index: -1;
  top: 0px;

  overflow: hidden;
  background: ${backgroundGradient};
  width: 100%;
  padding: 0px;

  height: ${headerHeight};
`;

const VideoBackground = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  pointer-events: none;
  user-select: none;
`;

const HeaderBackground = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = true;
    }
  }, [videoRef]);

  return (
    <VideoContainer>
      <VideoBackground ref={videoRef} loop={true} autoPlay={true} playsInline>
        <source src={HeaderBackgroundVideo} />
      </VideoBackground>
    </VideoContainer>
  );
};

const HeaderTitle = styled.p`
  font-weight: ${demiFontWeight};
  font-size: clamp(24px, 5vw, 50px);
  text-transform: uppercase;
  text-align: center;
  margin-top: 1.6em;
  margin-bottom: 0.3em;
`;
const Description = styled.p`
  font-size: 24px;
  line-height: 1.25em;
  font-weight: ${demiFontWeight};
  text-align: center;
  max-width: 560px;
  margin: 0 auto 40px;
`;

const LowerContentContainer = styled.div`
  letter-spacing: 0.02rem;
  color: white;
`;

const MinHeightContainer = styled.div`
  min-height: 62vh;
`;

const HeaderBanner = () => {
  return (
    <MinHeightContainer>
      <HeaderBackground />
      <HeaderContainer>
        <HeaderAtlasLogo>
          <Link to={"/"}>
            <img
              src={GrowthLabLogoImageLight}
              alt="The Atlas of Economic Complexity"
            />
          </Link>
        </HeaderAtlasLogo>
        <HeaderNavigationList>
          {headerLinkMetadata.map((navigationLink) => {
            return (
              <FlyoutMenuItem
                routeId={navigationLink.routeId}
                route={navigationLink.route}
                label={navigationLink.label}
                subMenuItems={navigationLink.children}
              />
            );
          })}
        </HeaderNavigationList>
      </HeaderContainer>
      <LowerContentContainer>
        <HeaderTitle>The Atlas of Economic Complexity</HeaderTitle>
        <Description>
          Harvard Growth Lab’s research and data visualization tool used to
          understand the economic dynamics and new growth opportunities for
          every country worldwide.
        </Description>
      </LowerContentContainer>
    </MinHeightContainer>
  );
};

export default HeaderBanner;
