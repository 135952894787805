import styled from "@emotion/styled";
import { semiBoldFontWeight } from "../styling/cssVariables";

const gridLines = {
  labelsRowTop: "ControlsLabelsRowTop",
  labelsRowBottom: "ControlsLabelsRowBottom",
  inputsRowTop: "ControlsInputsRowTop",
  inputsRowBottom: "ControlsInputsRowBottom",
  productMarketSelectorLeft: "ControlsProductMarketSelectorLeft",
  productMarketSelectorRight: "ControlsProductMarketSelectorRight",
  arrowIconLeft: "ControlsArrowIconLeft",
  arrowIconRight: "ControlsArrowIconRight",
  firstDropdownLeft: "ControlsFirstDropdownLeft",
  firstDropdownRight: "ControlsFirstDropdownRight",
  swapIconLeft: "ControlsSwapIconLeft",
  swapIconRight: "ControlsSwapIconRight",
  secondDropdownLeft: "ControlsSecondDropdownLeft",
  secondDropdownRight: "ControlsSecondDropdownRight",
  firstYearDropdownLeft: "ControlsFirstYearDropdownLeft",
  firstYearDropdownRight: "ControlsFirstYearDropdownRight",
  secondYearDropdownLeft: "ControlsSecondYearDropdownLeft",
  secondYearDropdownRight: "ControlsSecondYearDropdownRight",
  findInVizLeft: "ControlsFindInVizLeft",
  findInVizRight: "ControlsFindInVizRight",
  settingsLeft: "ControlsSettingsLeft",
  settingsRight: "ControlsSettingsRight",
  visualizationTitleTop: "VisualizationTitleTop",
  visualizationTitleBottom: "VisualizationTitleBottom",
};

const gridAreas = {
  labelForProductMarketSelector: "ControlsLabelForProductMarketSelectorArea",
  inputForProductMarketSelector: "ControlsInputForProductMarketSelectorArea",
  arrowIcon: "ControlsArrowIconArea",
  labelForFirstDropdown: "ControlsLabelForFirstDropdownArea",
  inputForFirstDropdown: "ControlsInputForFirstDropdownArea",
  swapIcon: "ControlsSwapIconArea",
  labelForSecondDropdown: "ControlsLabelForSecondDropdownArea",
  inputForSecondDropdown: "ControlsInputForSecondDropdownArea",
  labelForFirstYearDropdown: "ControlsLabelForFirstYearDropdownArea",
  inputForFirstYearDropdown: "ControlsInputForFirstYearDropdownArea",
  labelForSecondYearDropdown: "ControlsLabelForSecondYearDropdownArea",
  inputForSecondYearDropdown: "ControlsInputForSecondYearDropdownArea",
  findInViz: "ControlsFindInVizArea",
  settings: "ControlsSettingsArea",
  visualizationTitle: "VisualizationTitleArea",
};

export const ControlsGrid = styled.div`
  display: grid;
  grid-template-rows: [ ${gridLines.labelsRowTop}] 20px [ ${gridLines.labelsRowBottom} ${gridLines.inputsRowTop}] 34px [ ${gridLines.inputsRowBottom} ${gridLines.visualizationTitleTop}] 34px [ ${gridLines.visualizationTitleBottom}];
  grid-template-columns: [ ${gridLines.productMarketSelectorLeft}] 180px [ ${gridLines.productMarketSelectorRight} ${gridLines.arrowIconLeft}] 55px [ ${gridLines.arrowIconRight} ${gridLines.firstDropdownLeft}] 275px [ ${gridLines.firstDropdownRight} ${gridLines.swapIconLeft}] 55px [ ${gridLines.swapIconRight} ${gridLines.secondDropdownLeft}] 275px [ ${gridLines.secondDropdownRight} ${gridLines.firstYearDropdownLeft}] 85px [ ${gridLines.firstYearDropdownRight} ${gridLines.secondYearDropdownLeft}] 85px [ ${gridLines.secondYearDropdownRight} ${gridLines.findInVizLeft}] 1fr [ ${gridLines.findInVizRight} ${gridLines.settingsLeft}] 1fr [ ${gridLines.settingsRight}];
  grid-template-areas:
    "${gridAreas.labelForProductMarketSelector} ${gridAreas.arrowIcon} ${gridAreas.labelForFirstDropdown} ${gridAreas.swapIcon} ${gridAreas.labelForSecondDropdown} ${gridAreas.labelForFirstYearDropdown} ${gridAreas.labelForSecondYearDropdown} ${gridAreas.findInViz} ${gridAreas.settings}"
    "${gridAreas.inputForProductMarketSelector} ${gridAreas.arrowIcon} ${gridAreas.inputForFirstDropdown} ${gridAreas.swapIcon} ${gridAreas.inputForSecondDropdown} ${gridAreas.inputForFirstYearDropdown} ${gridAreas.inputForSecondYearDropdown} ${gridAreas.findInViz} ${gridAreas.settings}"
    "${gridAreas.visualizationTitle}";
`;

export const VisualizationTitleContainer = styled.div`
  grid-area: ${gridAreas.visualizationTitle};
  align-items: center;
  display: flex;
`;

export const VisualizationTitle = styled.div`
  background-color: #cccccc;
`;

export const LabelContainer = styled.div`
  height: 20px;
  width: 100%;
  text-align: left;
  font-size: 13px;
  text-transform: uppercase;
  cursor: default;
  font-weight: ${semiBoldFontWeight};
`;

export const InputContainer = styled.div`
  height: 34px;
`;

export const DropdownInputContainer = styled(InputContainer)`
  width: 275px;
`;

export const YearInputContainer = styled(InputContainer)`
  width: 85px;
`;

export const IconContainer = styled(InputContainer)``;

export const SpacerElement = styled(LabelContainer)`
  content: "";
`;

export const SegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  &.withMargin {
    margin-left: 20px;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
