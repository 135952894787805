export const sortProductsForRibbons = ({
  productA,
  productB,
  sortingOrder,
}: any) => {
  const productATopLevelParent = productA.topLevelParent;
  const productBTopLevelParent = productB.topLevelParent;

  const productAIndex = productATopLevelParent
    ? sortingOrder.indexOf(productATopLevelParent)
    : 0;
  const productBIndex = productBTopLevelParent
    ? sortingOrder.indexOf(productBTopLevelParent)
    : 0;

  return productAIndex - productBIndex;
};

export const sortLocationsForRibbons = ({
  locationA,
  locationB,
  sortingOrder,
}: any) => {
  const topLevelParentLocationA = locationA.topLevelParent;
  const topLevelParentLocationB = locationB.topLevelParent;

  let locationAIndex = topLevelParentLocationA
    ? sortingOrder.indexOf(topLevelParentLocationA)
    : 0;
  let locationBIndex = topLevelParentLocationB
    ? sortingOrder.indexOf(topLevelParentLocationB)
    : 0;

  return locationAIndex - locationBIndex;
};

export enum OverTimeOrderingOption {
  Community = "community",
  Totals = "totals",
}

export enum OverTimeLayoutOption {
  Value = "value",
  Share = "share",
}
