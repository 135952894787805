import { memo } from "react";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";

const ProductSpacePlot = memo(
  ({
    processedData,
    metaDataLookup,
    highlightedItem,
    select,
    setRingItem,
    setHighlightedItem,
  }: any) => {
    return (
      <>
        {processedData.map((node, i) => {
          return (
            <Tippy
              key={`tip-${node.productId}-${i}`}
              content={
                <div
                  style={{
                    backgroundColor: "black",
                    opacity: 0.75,
                  }}
                >
                  {metaDataLookup.get(node.productId)?.data?.nameShortEn}
                </div>
              }
              plugins={[followCursor]}
              followCursor={true}
              trigger="mouseenter"
              disabled={highlightedItem === node.productId}
            >
              <circle
                cx={node.x}
                cy={node.y}
                r={node.r}
                fill={node.fill}
                strokeWidth="1"
                stroke="grey"
                style={{ cursor: "pointer" }}
                onMouseEnter={() => select(node.productId)}
                onMouseLeave={() => select(null)}
                onClick={() => {
                  setHighlightedItem(null);
                  setRingItem(node.productId);
                }}
              />
            </Tippy>
          );
        })}
      </>
    );
  },
);
ProductSpacePlot.displayName = "ProductSpacePlot";
export default ProductSpacePlot;
