// import { requiredParams as marketshareRequired } from "./charts/marketshare";

import {
  ColorBy,
  LocationDetailLevel,
  ProductClass,
  ProductLevel,
  ServicesClass,
  TradeFlow,
  UIView,
  VizType,
  generateRandomCountrySelection,
} from "./Utils";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { worldGroupDatum } from "../graphql/queries/getLocationsMetadata";
import { earliestHs92Year, latestProductYear } from "../graphql/Utils";
import { allProductsDatum } from "../graphql/queries/getProductsMetadata";
import {
  OverTimeLayoutOption,
  OverTimeOrderingOption,
} from "./charts/overtime/Utils";
import { HideWorldTradeOption } from "./charts/growth/utils";
import { NodeSizing } from "./topProductsTable/Table";
import { useLocation } from "react-router-dom";
import { YAxisScalingOption } from "./charts/overtime/Chart";

export const resetParamsPerViz = {
  [VizType.Tree]: ["colorBy"],
  [VizType.Geo]: ["view", "productLevel"],
  [VizType.OverTime]: ["yAxis"],
  [VizType.MarketShare]: [
    "view",
    "productLevel",
    "productClass",
    "tradeFlow",
    "startYear",
    "endYear",
  ],
  [VizType.Feasibility]: ["view", "productLevel", "yAxis"],
  [VizType.ProductSpace]: ["view", "productLevel", "productClass"],
};

export const primarySelections = [
  "exporter",
  "importer",
  "view",
  "year",
  "startYear",
  "endYear",
  "product",
];

const defaultRandomCountry = generateRandomCountrySelection();
export const defaultSettingsPerViz: any = {
  [VizType.Tree]: {
    exporter: withDefault(StringParam, defaultRandomCountry),
    importer: withDefault(StringParam, worldGroupDatum.groupId),
    view: withDefault(StringParam, UIView.Products),
    productClass: withDefault(StringParam, ProductClass.HS92Products),
    servicesClass: withDefault(StringParam, ServicesClass.unilateral),
    productLevel: withDefault(NumberParam, ProductLevel.Product4digit),
    locationLevel: withDefault(StringParam, LocationDetailLevel.country),
    year: withDefault(NumberParam, latestProductYear),
    startYear: withDefault(NumberParam, earliestHs92Year),
    endYear: withDefault(NumberParam, latestProductYear),
    tradeFlow: withDefault(StringParam, TradeFlow.Gross),
    colorBy: withDefault(StringParam, ColorBy.Sector),
    product: withDefault(StringParam, allProductsDatum.productId),
  },
  [VizType.Geo]: {
    exporter: withDefault(StringParam, defaultRandomCountry),
    view: withDefault(StringParam, UIView.Markets),
    importer: withDefault(StringParam, worldGroupDatum.groupId),
    productClass: withDefault(StringParam, ProductClass.HS92Products),
    year: withDefault(NumberParam, latestProductYear),
    startYear: withDefault(NumberParam, earliestHs92Year),
    endYear: withDefault(NumberParam, latestProductYear),
    tradeFlow: withDefault(StringParam, TradeFlow.Gross),
    productLevel: withDefault(NumberParam, ProductLevel.ProductSection),
    product: withDefault(StringParam, allProductsDatum.productId),
  },
  [VizType.OverTime]: {
    exporter: withDefault(StringParam, defaultRandomCountry),
    importer: withDefault(StringParam, worldGroupDatum.groupId),
    view: withDefault(StringParam, UIView.Products),
    productClass: withDefault(StringParam, ProductClass.HS92Products),
    servicesClass: withDefault(StringParam, ServicesClass.unilateral),
    productLevel: withDefault(NumberParam, ProductLevel.ProductSection),
    product: withDefault(StringParam, allProductsDatum.productId),
    locationLevel: withDefault(StringParam, LocationDetailLevel.region),
    year: withDefault(NumberParam, latestProductYear),
    startYear: withDefault(NumberParam, earliestHs92Year),
    endYear: withDefault(NumberParam, latestProductYear),
    tradeFlow: withDefault(StringParam, TradeFlow.Gross),
    layout: withDefault(StringParam, OverTimeLayoutOption.Value),
    ordering: withDefault(StringParam, OverTimeOrderingOption.Community),
    yAxis: withDefault(StringParam, YAxisScalingOption.Current),
  },
  [VizType.MarketShare]: {
    exporter: withDefault(StringParam, defaultRandomCountry),
    view: withDefault(StringParam, UIView.Products),
    productClass: withDefault(StringParam, ProductClass.HS92Products),
    servicesClass: withDefault(StringParam, ServicesClass.unilateral),
    productLevel: withDefault(NumberParam, ProductLevel.ProductSection),
    year: withDefault(NumberParam, latestProductYear),
    startYear: withDefault(NumberParam, earliestHs92Year),
    endYear: withDefault(NumberParam, latestProductYear),
    tradeFlow: withDefault(StringParam, TradeFlow.Gross),
  },
  [VizType.Feasibility]: {
    view: withDefault(StringParam, UIView.Products),
    productLevel: withDefault(NumberParam, ProductLevel.Product4digit),
    productClass: withDefault(StringParam, ProductClass.HS92Products),
    exporter: withDefault(StringParam, defaultRandomCountry),
    year: withDefault(NumberParam, latestProductYear),
    startYear: withDefault(NumberParam, earliestHs92Year),
    endYear: withDefault(NumberParam, latestProductYear),
    hideExports: withDefault(StringParam, HideWorldTradeOption.On),
    sizing: withDefault(StringParam, NodeSizing.WorldTrade),
    yAxis: withDefault(StringParam, "pci"),
  },
  [VizType.ProductSpace]: {
    exporter: withDefault(StringParam, defaultRandomCountry),
    view: withDefault(StringParam, UIView.Products),
    productClass: withDefault(StringParam, ProductClass.HS92Products),
    productLevel: withDefault(NumberParam, ProductLevel.Product4digit),
    tradeFlow: withDefault(StringParam, TradeFlow.Gross),
    year: withDefault(NumberParam, latestProductYear),
    startYear: withDefault(NumberParam, earliestHs92Year),
    endYear: withDefault(NumberParam, latestProductYear),
    colorBy: withDefault(StringParam, ColorBy.Cluster),
    sizing: withDefault(StringParam, NodeSizing.WorldTrade),
    rcaThreshold: withDefault(NumberParam, 1),
  },
};

export const usePageQueryParams = () => {
  const location = useLocation();
  const currentVizType = location.pathname.split("/")[2] as any;
  const queryState = useQueryParams(defaultSettingsPerViz[currentVizType]);
  return queryState;
};

export const mergeParams = (vizType, params) => {
  const dontFilterEmptyString =
    vizType === VizType.Tree ||
    vizType === VizType.OverTime ||
    vizType === VizType.Geo;
  const augmentedParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => {
      if (dontFilterEmptyString && value === "") {
        return [key, value];
      } else if (value === "") {
        return [key, worldGroupDatum.groupId];
      } else {
        return [key, value];
      }
    }),
  );

  const sharedParams =
    Object.fromEntries(
      Object.entries(augmentedParams).filter(([key, value]) => {
        return (
          Object.keys(defaultSettingsPerViz[vizType]).includes(key) &&
          defaultSettingsPerViz[vizType][key].default !== value
        );
      }),
    ) || ({} as any);
  resetParamsPerViz[vizType].forEach((key) => {
    delete sharedParams[key];
  });
  return new URLSearchParams(sharedParams).toString();
};
