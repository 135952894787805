import {
  ProductLevel,
  TradeDirection,
  computeMonetaryValueByTradeFlow,
  computeTotalSumByTradeFlow,
} from "../../Utils";
import { groups } from "d3";

const productShareThreshold = 0.01 / 100; // Product share threshold of 0.01%

const transformProducts = ({
  productLevel,
  data,
  products,
  totalValue,
  tradeDirection,
  tradeFlow,
}: any) => {
  if (data.length === 0) {
    return undefined;
  }

  if (productLevel === ProductLevel.ProductSection) {
    const dataWithParents = data.map((d: any) => {
      const productId = d.productId;
      let findMatchingMetadata = products!.find(
        (product: any) => product.productId === productId,
      );
      let nameEn = undefined;
      let productCode = undefined;
      let sectorId = undefined;
      if (findMatchingMetadata) {
        nameEn = findMatchingMetadata.nameShortEn;
        productCode = findMatchingMetadata.code;
        sectorId = findMatchingMetadata.topParent.productId;
      }

      return {
        ...d,
        topLevelParent: "root",
        nameEn,
        productCode,
        sectorId,
      };
    });

    const rootObject = { productId: "root", topLevelParent: undefined };
    return [rootObject, ...dataWithParents];
  } else {
    let topLevelParents: Set<any> = new Set();

    data.forEach((d: any) => {
      const productId = d.productId;
      let findMatchingMetadata = products!.find(
        (product: any) => product.productId === productId,
      );
      let topLevelParent;
      if (findMatchingMetadata) {
        topLevelParent = findMatchingMetadata.topParent.productId;

        topLevelParents.add(topLevelParent);
      }
    });

    const dataWithParents = data.map((d: any) => {
      const productId = d.productId;
      let findMatchingMetadata = products!.find(
        (product: any) => product.productId === productId,
      );
      let topLevelParent = undefined;
      let nameEn = undefined;
      let productCode = undefined;
      let sectorId;
      if (findMatchingMetadata) {
        topLevelParent = findMatchingMetadata.topParent.productId;
        nameEn = findMatchingMetadata.nameShortEn;
        productCode = findMatchingMetadata.code;
        sectorId = findMatchingMetadata.topParent.productId;
      }

      return {
        ...d,
        topLevelParent,
        nameEn,
        productCode,
        sectorId,
      };
    });

    let topLevelParentsObjects = [...topLevelParents.values()].map((t) => ({
      productId: t,
      topLevelParent: "root",
    }));

    const rootObject = { productId: "root", topLevelParent: undefined };

    if (totalValue && productLevel === ProductLevel.Product6digit) {
      let productsAboveThreshold = dataWithParents.filter((product: any) => {
        let monetaryValue = computeMonetaryValueByTradeFlow({
          datum: product,
          tradeFlow,
          tradeDirection,
        });
        if (monetaryValue) {
          return monetaryValue / totalValue >= productShareThreshold;
        } else {
          return false;
        }
      });

      let productsBelowThreshold = dataWithParents.filter((product: any) => {
        let monetaryValue = computeMonetaryValueByTradeFlow({
          datum: product,
          tradeFlow,
          tradeDirection,
        });
        if (monetaryValue) {
          return monetaryValue / totalValue < productShareThreshold;
        } else {
          return false;
        }
      });

      let productsBelowThresholdGrouped = groups(
        productsBelowThreshold,
        ({ topLevelParent }: any) => topLevelParent,
      ).map((groupedProduct: any) => {
        const productId = groupedProduct[0];

        const sumExportValue = computeTotalSumByTradeFlow({
          data: groupedProduct[1],
          tradeFlow,
          tradeDirection: TradeDirection.Exports,
        });
        const sumImportValue = computeTotalSumByTradeFlow({
          data: groupedProduct[1],
          tradeFlow,
          tradeDirection: TradeDirection.Imports,
        });

        let matchingSectionMetadata = products!.find(
          (product: any) =>
            product.productLevel === ProductLevel.ProductSection &&
            product.productId === productId,
        );

        let groupNameEn = matchingSectionMetadata
          ? `Other ${matchingSectionMetadata.nameShortEn}, < ${productShareThreshold * 100}%`
          : `Other, < ${productShareThreshold * 100}%`;
        return {
          ...groupedProduct[1][0],
          exportValue: sumExportValue,
          importValue: sumImportValue,
          groupedProduct: true,
          nameEn: groupNameEn,
        };
      });

      return [
        rootObject,
        ...topLevelParentsObjects,
        ...productsAboveThreshold,
        ...productsBelowThresholdGrouped,
      ];
    } else {
      return [rootObject, ...topLevelParentsObjects, ...dataWithParents];
    }
  }
};

export default transformProducts;
