import { getLocationQualifiers } from "../../Utils";

// Upper/lower bounds of color range of map:
export const colorRangeStart = "#B1E0BB";
export const colorRangeEnd = "#0A4486";

// For example, if
// `spectrumStartPercent` is 1/2 and
// the `nice`-d domain starts at 10^3 and ends at 10^7 then the color spectrum
// starts at 10^5 because 5 is midway between 3 and 7:
export const spectrumStartPercent = 0.5;
export const spectrumEndPercent = 0.9;

export const getOrdersOfMagnitudeDifference = (
  domainStart: number,
  domainEnd: number,
) => {
  // Note: the `log10` operations will produce integers because `domainStart`
  // and `domainEnd` will be powers of ten because they have been `nice`-d by `d3`:
  const domainStartOrderOfMagnitude = Math.log10(domainStart);
  const domainEndOrderOfMagnitude = Math.log10(domainEnd);
  return domainEndOrderOfMagnitude - domainStartOrderOfMagnitude;
};

export const defaultMapFillColor = "white";
export const defaultMapStrokeColor = "#8AABAB";
export const primaryCountryStrokeColor = "#FFFFFF";
export const primaryCountryColor = "#e8c258";

export const getReferenceLocation = ({ exporter, importer }: any) => {
  const {
    exporterIsUndefined,
    exporterIsWorld,
    importerIsUndefined,
    importerIsWorld,
  } = getLocationQualifiers({ exporter, importer });
  let referenceLocation;

  if (!exporterIsUndefined && !importerIsUndefined) {
    if (exporterIsWorld) {
      referenceLocation = importer;
    } else if (importerIsWorld) {
      referenceLocation = exporter;
    } else {
      referenceLocation = exporter;
    }
  } else {
    if (exporterIsUndefined) {
      referenceLocation = importer;
    } else if (importerIsUndefined) {
      referenceLocation = exporter;
    } else {
      referenceLocation = exporter;
    }
  }

  return referenceLocation;
};
