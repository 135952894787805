import { gql } from "@apollo/client";
import { ProductMetadatumLevel } from "../types";

export const allProductsDatum = {
  productId: "all-products",
  nameShortEn: "All Products",
  nameEn: "All Products",
  productLevel: ProductMetadatumLevel.allProducts,
};

const query = gql`
  query GetProducts($productClass: ProductClass!) {
    products: allProducts(productClass: $productClass) {
      id
      shortName
      level
      parent {
        id
        type: productType
      }
    }
  }
`;

export default query;
