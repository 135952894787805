import { useSpring, animated } from "react-spring";

const AnimatedZoomGroup = ({ children, zoom }) => {
  const props = useSpring({
    to: {
      transform: `translate(${zoom.translateX}px, ${zoom.translateY}px)
                      scale(${zoom.scaleX}, ${zoom.scaleY})
                      skew(${zoom.skewX}deg, ${zoom.skewY}deg)`,
    },
    from: {
      transform: `translate(${zoom.translateX}px, ${zoom.translateY}px)
          scale(${zoom.scaleX}, ${zoom.scaleY})
          skew(${zoom.skewX}deg, ${zoom.skewY}deg)`,
    },
    config: {
      stiffness: 1000,
      damping: 20,
      tension: 1000,
      mass: 1,
      clamp: true,
    },
  });

  return <animated.g style={props}>{children}</animated.g>;
};

export default AnimatedZoomGroup;
