import styled from "@emotion/styled";
import { useRef } from "react";
import { BaseMenuItem } from "./styles";

const YearMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 2px;
`;

const LabelContainer = styled.div`
  width: calc(100% - 1px);
  width: 100%;
  padding-left: 10px;
  overflow: hidden;
  ppadding: auto 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface YearDropdownItemInput {
  item: any;
  hashFunction: any;
  getItemProps: any;
  mappedItemIndex: number;
  index: number;
  highlightedIndex: number;
  displayItems: any;
  setDisplayItems: any;
  updateVisibilityFunction: any;
  selectedItem: any;
}

const YearDropdownItem = ({
  item,
  hashFunction,
  selectedItem,
  index,
  highlightedIndex,
  getItemProps,
  displayItems,
  setDisplayItems,
}: YearDropdownItemInput) => {
  const dropdownItemRef = useRef(null);

  return (
    <BaseMenuItem
      ref={dropdownItemRef}
      className={
        index === highlightedIndex ||
        (selectedItem && item.value === selectedItem.value)
          ? "menu-item--highlighted"
          : ""
      }
      key={`item_${item.value}`}
      {...getItemProps({ item, index })}
    >
      <YearMenuItem>
        <LabelContainer>{hashFunction(item)}</LabelContainer>
      </YearMenuItem>
    </BaseMenuItem>
  );
};

export default YearDropdownItem;
