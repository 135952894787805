const Placeholder = () => {
  return (
    <>
      <h1>Placeholder page</h1>
      <p>With some placeholder content</p>
    </>
  );
};

export default Placeholder;
